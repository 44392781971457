import { sortBy } from "lodash";
import { usersOrdersHistory } from "../../services/userServices";

export const initialStates = {
  isLoading: true,
  isError: false,
  showCount: 10,
  refreshPageCount: 0,
  itemsList: [],
  showAssets: false,
  showTypes: false,
  filter: {
    name: "",
    type: "",
    startDate: undefined,
    endDate: undefined,
  },
};

export function reducer(state, action) {
  switch (action.type) {
    case "INIT_SUCCESS":
      return { ...state, itemsList: action.payload, isLoading: false };
    case "INIT_FAILED":
      return { ...state, isError: true };
    case "INCREASE_VIEW":
      return { ...state, showCount: state.showCount + 10 };
    case "FILTER":
      if (action.payload.type === "date") {
        return {
          ...state,
          filter: {
            ...state.filter,
            startDate: action.payload.value["from"],
            endDate: action.payload.value["to"],
          },
        };
      }
      if (action.payload.type === "symbol") {
        return {
          ...state,
          showAssets: false,
          filter: {
            ...state.filter,
            name: action.payload.value,
          },
        };
      }
      if (action.payload.type === "type") {
        return {
          ...state,
          showTypes: false,
          filter: {
            ...state.filter,
            type: action.payload.value,
          },
        };
      }
      return state;
    case "RESET_TIME":
      return {
        ...state,
        filter: { ...state.filter, startDate: undefined, endDate: undefined },
      };
    case "RESET_SYMBOL":
      return { ...state, filter: { ...state.filter, name: "" } };
    case "RESET_TYPE":
      return { ...state, filter: { ...state.filter, type: "" } };
    case "SHOW_MORE":
      return state;
    case "TOGGLE_ASSETS":
      return { ...state, showAssets: !state.showAssets };
    case "TOGGLE_SHOW_TYPES":
      return { ...state, showTypes: !state.showTypes };
    case "REFRESH":
      return {
        ...state,
        refreshPageCount: state.refreshPageCount + 1,
        isLoading: true,
        isError: false,
      };

    default:
      return state;
  }
}

export const fetchPageList = async (signal) => {
  const result = {
    list: [],
    error: false,
  };
  try {
    const { data, status } = await usersOrdersHistory(signal);
    if (status) {
      result.list = sortBy(data["data"], "createdAt").reverse();
    } else {
      result.error = true;
    }
  } catch (ex) {
    result.error = true;
  } finally {
    return result;
  }
};
