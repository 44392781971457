import { parseFullSymbol } from "./helpers.js";

const channelToSubscription = new Map();
var AccessGetDataFromSocket = false;
// "5", "15", "30", "60","180","240","360","720","1D","2D","3D"

function getNextDailyBarTime(barTime, res) {
  let resolution = res;
  if (resolution.includes("D")) {
    const date = new Date(barTime * 1000);
    date.setDate(date.getDate() + resolution.split("D")[0]);
    return date.getTime() / 1000;
  } else {
    const date = new Date(barTime * 1000);
    date.setMinutes(date.getMinutes() + resolution);
    return date.getTime() / 1000;
  }
}
function getUpdateList(id) {
  AccessGetDataFromSocket = id;
}
export function getDataFromWS(data) {
  if (!!AccessGetDataFromSocket) {
    for (const channel of AccessGetDataFromSocket) {
      const subscriptionItem = channelToSubscription.get(channel);
      if (subscriptionItem === undefined) {
        return;
      }
      if (data[0] === channel) {
        data[1].forEach((data) => {
          const lastDailyBar = subscriptionItem.lastDailyBar;
          const nextDailyBarTime = getNextDailyBarTime(
            lastDailyBar.time,
            subscriptionItem.resolution
          );
          let bar;
          let _time;
          if (channel !== "USDTIRT") {
            _time = data.time * 1000;
          } else {
            _time = data.time;
          }
          if (parseInt(_time) >= nextDailyBarTime) {
            bar = {
              time: nextDailyBarTime,
              open: data.price,
              high: data.price,
              low: data.price,
              close: data.price
            };
          } else {
            bar = {
              ...lastDailyBar,
              high: Math.max(lastDailyBar.high, data.price),
              low: Math.min(lastDailyBar.low, data.price),
              close: data.price
            };
          }
          subscriptionItem.lastDailyBar = bar;
          return subscriptionItem.handlers.forEach((handler) =>
            handler.callback(bar)
          );
        });
      }
    }
  }
}
export function subscribeOnStream(
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscribeUID,
  onResetCacheNeededCallback,
  lastDailyBar
) {
  const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
  const channelString = `${parsedSymbol.fromSymbol}${parsedSymbol.toSymbol}`;
  const handler = {
    id: subscribeUID,
    callback: onRealtimeCallback
  };
  let subscriptionItem = channelToSubscription.get(channelString);
  // if (subscriptionItem) {
  //   // already subscribed to the channel, use the existing subscription
  //   subscriptionItem.handlers.push(handler);
  //   return;
  // }
  subscriptionItem = {
    subscribeUID,
    resolution,
    lastDailyBar,
    handlers: [handler]
  };
  let list_coins = [];
  channelToSubscription.set(channelString, subscriptionItem);
  for (const channelString of channelToSubscription.keys()) {
    list_coins.push(channelString);
  }
  getUpdateList(list_coins);
}

export function unsubscribeFromStream(subscriberUID) {
  for (const channelString of channelToSubscription.keys()) {
    const subscriptionItem = channelToSubscription.get(channelString);
    const handlerIndex = subscriptionItem.handlers.findIndex(
      (handler) => handler.id === subscriberUID
    );
    if (handlerIndex !== -1) {
      subscriptionItem.handlers.splice(handlerIndex, 1);

      if (subscriptionItem.handlers.length === 0) {
        console.log(
          "[unsubscribeBars]: Unsubscribe from streaming. Channel:",
          channelString
        );
        channelToSubscription.delete(channelString);
        break;
      }
    }
  }
}
