export const appUserDocumentsInfo = (
  state = {
    rialPayment: false,
    documentStatus: false,
    isFetched: false,
  },
  action
) => {
  switch (action.type) {
    case "SET_USER_DOCS_VALIDATION":
      return action.payload;
    default:
      return state;
  }
};
