import NotificationError from "../../helpers/errors_notifications/NotificationError";
import styles from "./Terms.module.scss";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "../../styles/icon/arrow_back_fill.svg";

export default function Terms() {
  const history = useHistory();
  const ArzfiTerms = [
    {
      text: "کاربران سایت می‌پذیرند که کلیه‌ی فعالیت‌های آن‌ها در ارزفی در چارچوب قوانین جمهوری اسلامی بوده و هیچ گونه فعالیتی خارج از این چارچوب انجام نخواهند داد.",
    },
    {
      text: "احراز هویت برای استفاده از خدمات ارزفی ضروری بوده و کاربران موظفند اطلاعات صحیح خود را بارگذاری نمایند. بدیهی است در صورت وجود هرگونه تخلف در احراز هویت، مسئولیت به عهده‌ی کاربر بوده و ارزفی حق توقف ارائه خدمات به فرد مذبور و ارجاع موارد تخلف به مراجع ذی صلاح را خواهد داشت. ارزفی خود را ملزم به حفظ اطلاعات شخصی کاربران خود می‌داند.",
    },
    {
      text: "کاربران ارزفی تعهد می دهند که از خدمات سایت ارزفی تنها برای خود استفاده نموده و مسئولیت استفاده از خدمات ارزفی برای فرد غیر که فرآیند احراز هویت را طی نکرده باشد به عهده کاربر خواهد بود. شماره حساب اعلام شده به سایت و همچنین آدرس کیف پول ها جهت برداشت رمزارز نیز می بایست متعلق به کاربر بوده و کاربران مجاز به دادن آدرس کیف پول متعلق به اشخاص دیگر نیستند.",
    },
    {
      text: "کاربران ارزفی می‌پذیرند در صورت تشخیص کارشناسان پشتیانی ارزفی، جهت حفظ امنیت دارایی حساب کاربریشان با اطلاع قبلی نسبت به برقراری تماس تصویری با تیم ارزفی همکاری نمایند. همچنین بنا به دستور نهادهای قضایی مانند پلیس فتا، ممکن است برداشت رمزارز به مدت 24 تا 72 ساعت کاری پس از زمان واریز از طریق درگاه شتابی، محدود گردد.    ",
    },
    {
      text: "کاربران ارزفی اذعان می‌دارند که از ریسک‌های مربوط به سرمایه‌گذاری در ارزهای دیجیتال مطلع بوده و با علم به این موضوع اقدام به سرمایه‌گذاری و تبادل می‌نمایند.",
    },
    {
      text: "ارزفی به عنوان بازار آنلاین تبادل ارزهای دیجیتال، هیچ گونه مسئولیتی در قبال نحوه‌ی معاملات کاربران و سود و زیان حاصل از آن ندارد.",
    },
    {
      text: "هرگونه سهل انگاری کاربران در حفظ اطلاعات امنیتی از جمله گذرواژه کاربر، به عهده‌ی کاربر بوده و ارزفی هیچ مسئولیتی به عهده نخواهد داشت. اکیداً توصیه می شود از گذرواژه پیچیده و امن و کد شناسایی دوعاملی استفاده شود.",
    },
    {
      text: "ارزفی این اطمینان را می‌دهد که دارایی‌های کاربران را نزد خود به امانت و به بهترین شکل و با بالاترین استانداردهای امنیتی ممکن، حفظ نماید. در صورت بروز هرگونه مشکل امنیتی، ارزفی متعهد به جبران خسارت خواهد بود. ",
    },
    {
      text: "در صورت تمایل برای برداشت ارزهای دیجیتال، مسئولیت ارائه‌ی آدرس صحیح کیف پول به عهده‌ی کاربر خواهد بود. در صورت بروز هر گونه مشکل اعم از اشتباه در ورود آدرس صحیح، نقص آدرس، مشکلات کیف پول مقصد و بلوکه شدن دارایی‌های کاربران در کیف پول مقصد، هیچ گونه مسئولیتی به عهده ی ارزفی نخواهد بود.    ",
    },
    {
      text: "ارزفی در مقابل واریز توکن یا کوین بر بستر اشتباه یا کوین هایی که در ارزفی پشتیبانی نمی شود هیچ گونه مسئولیتی نداشته و با توجه به مکانیسم ذخیره سازی سرد امکان استخراج این توکن ها با استفاده از ولت ثالث وجود ندارد. لذا مسئولیت هرگونه واریز اشتباه با خود کاربر بوده و کاربر حق هیچ گونه شکایتی را نخواهد داشت.    ",
    },
    {
      text: "درخواست برداشت ریال از حساب کاربری در سریع‌ترین زمان ممکن پس از ثبت، بررسی خواهد شد. زمان واریز پول به حساب کاربران بر اساس محدودیت‌های انتقال وجه بین بانکی، متفاوت خواهد بود. برای اطلاعات بیش‌تر، به قوانین انتقال وجه بین بانکی ( پایا ، ساتنا) مراجعه فرمایید.    ",
    },
    {
      text: "کاربر می‌پذیرد که به جز در موارد مورد تعهد ارزفی، حق هیچ گونه ادعا، طلب و شکایت از سایت ارزفی، مدیران، کارمندان و افراد مرتبط با این سایت را نخواهد داشت.    ",
    },
    {
      text: "اگر ارزفی تحت هر عنوان اشتباهاً یا من غیر حق، وجوه یا رمزارزی را به حساب کاربر منظور یا در محاسبات خود هر نوع اشتباهی نماید، هر زمان مجاز و مختار است راساً و مستقلاً و بدون انجام هیچ گونه تشریفات اداری و قضائی و دریافت اجازه کتبی از متعهد (صاحب حساب) در رفع اشتباه و برداشت از حساب‌های وی اقدام نماید و تشخیص ارزفی نسبت به وقوع اشتباه یا پرداخت بدون حق و لزوم برداشت از حساب معتبر خواهد بود و کاربر حق هرگونه اعتراض و ادعایی را در خصوص نحوه عملکرد ارزفی از هر جهت از خود ساقط می نماید.    ",
    },
    {
      text: "در صورت بروز هرگونه مشکل یا ابهام در هر یک از معاملات، ارزفی حق دارد مستقلاً آن معامله را ابطال و دارایی‌های هر یک از طرفین را به حساب خودشان عودت دهد. بدیهی است که در صورتی که اشکال از سمت ارزفی باشد، موظف خواهد بود که جبران خسارت نماید و در غیراین صورت کاربر حق هرگونه اعتراض و ادعایی را در خصوص نحوه عملکرد ارزفی از هر جهت از خود ساقط می‌نماید.    ",
    },
    {
      text: "در صورت هرگونه سوء استفاده از کد ریفرال ارزفی در فعالیت‌های خلاف قانون، تمامی مسئولیت آن به عهده کاربر بوده و ارزفی هیچ گونه مسئولیتی در قبال هرگونه سوء استفاده از سیستم معرفی خود به هر نحو ندارد. همچنین ارزفی حق باطل کردن کد معرف و بستن حساب در صورت مشاهده هرگونه سوء استفاده بدون نیاز به دستور قضایی را خواهد داشت.",
    },
    {
      text: "چنانچه در هر مرحله یا زمانی سایت ارزفی متوجه گردد که حساب معرفی شده به صورت اجاره ای میباشد، مجاز است که حساب کاربری را مسدود نموده و شخص متخلف را به مراجع ذیصلاح معرفی نماید . تمامی مسئولیت و عواقب ناشی از آن متوجه کاربر میباشد .    ",
    },
    {
      text: " برای امنیت حساب کاربری شما در مقابل افراد سود جو و جلوگیری از اجاره دادن حساب های بانکی، در ارزفی اطلاعاتی از شما ( شماره همراه، کدملی، تصویر کارت ملی، مشخصات فردی، آدرس، ایمیل و .. ) دریافت میکند که تمامی این اطلاعات فقط نزد ارزفی محفوظ بوده و توسط جمعی از جوانان نخبه کشور کد گذاری و نگهداری میشود .",
    },
  ];
  return (
    <div className={styles.container}>
      <div className={styles.title}>قوانین و شرایط استفاده</div>
      <div className={styles.back_btn} onClick={() => history.goBack()}>
        <img
          src={ArrowBackIcon}
          alt="BACK"
          width={20}
          className="convertPicToWhite"
        />
      </div>
      <div className={styles.place_notification}>
        <NotificationError
          bgBorder="rgb(201, 146, 44)"
          bgColor="rgb(201, 146, 44)"
          fontColor="#ffff"
          text="ارزفی تابع قوانین جمهوری اسلامی ایران بوده و بستری برای تبادل دارایی‌های دیجیتال مانند بیت کوین، لایت کوین و اتریوم با ریال می‌باشد. هیچ گونه تبادل ارزی اعم از خرید و فروش دلار یا سایر ارزهای کاغذی، در این بستر صورت نمی گیرد.          "
        />
      </div>
      <h4>تعهدنامه فعالیت در بستر تبادل دارایی‌های دیجیتال ارزفی</h4>
      <ol>
        {ArzfiTerms.map((term) => (
          <li className="font_color_white">{term.text}</li>
        ))}
      </ol>
      <div className={styles.button_container}>
        <button onClick={() => history.goBack()}>مطاله کردم!</button>
      </div>
    </div>
  );
}
