import { useContext, useEffect } from "react";
import MuiInputOne from "../../../../market/MuiInput/MuiInputOne";
import { tradesCTX } from "../../../../../context/tradesCTX";
import MuiInputTwo from "../../../../market/MuiInput/MuiInputTwo";
import PercentBalance from "../../../../PercentBalance";
import HelperText1 from "../HelperTexts/HelperText1";

export default function StopLimit({ type }) {
  const marketTradeContext = useContext(tradesCTX);
  const {
    //* --> State's Of Price And Amount
    orderPriceBuyStopLimit,
    setOrderPriceBuyStopLimit,
    orderAmountBuyStopLimit,
    setOrderAmountBuyStopLimit,
    orderBuyStopPrice,
    setOrderBuyStopPrice,

    orderSellStopPrice,
    setOrderSellStopPrice,
    orderPriceSellStopLimit,
    setOrderPriceSellStopLimit,
    orderAmountSellStopLimit,
    setOrderAmountSellStopLimit,
    areaBalance,
    assetBalance,
    lastTrades,
    //* --> State's Of Price And Amount
    //* --> Market Name
    currentTrade,
    //* <-- Market Name
    buyStopLimitSlider,
    setBuyStopLimitSlider,
    sellStopLimitSlider,
    setSellStopLimitSlider,
  } = marketTradeContext;

  const showUserEarn = (type) => {
    if (type === "sell") {
      let amount = orderPriceSellStopLimit * orderAmountSellStopLimit;
      let percent =
        orderPriceSellStopLimit *
          orderAmountSellStopLimit *
          currentTrade?.fee_rate ?? 0;
      amount = amount - percent;
      if (amount.toString().split(".")[1]?.length > currentTrade.sell_places) {
        amount = +amount.toFixed(currentTrade.sell_places);
      }
      if (amount < 0 || amount > 9999999999999 || isNaN(amount)) {
        amount = 0;
      }
      if (percent.toString().split(".")[1]?.length > currentTrade.sell_places) {
        percent = +percent.toFixed(currentTrade.sell_places);
      }
      return [amount, percent];
    } else {
      let amount = orderAmountBuyStopLimit * orderPriceBuyStopLimit;
      let percent = amount * currentTrade?.fee_rate ?? 0;

      amount = amount - percent;
      amount = amount / orderPriceBuyStopLimit;
      if (amount.toString().split(".")[1]?.length > currentTrade.sell_places) {
        amount = +amount.toFixed(currentTrade.sell_places);
      }
      if (amount < 0 || amount > 9999999999999 || isNaN(amount)) {
        amount = 0;
      }
      if (percent.toString().split(".")[1]?.length > currentTrade.sell_places) {
        percent = +percent.toFixed(currentTrade.sell_places);
      }
      return [amount, percent];
    }
  };

  useEffect(() => {
    if (buyStopLimitSlider) {
      let percent = +buyStopLimitSlider * 0.01;
      if (orderPriceBuyStopLimit) {
        let amount = (percent * areaBalance) / orderPriceBuyStopLimit;
        setOrderAmountBuyStopLimit(amount);
      }
    }
  }, [buyStopLimitSlider]);

  useEffect(() => {
    if (sellStopLimitSlider) {
      let percent = +sellStopLimitSlider * 0.01;
      let amount = percent * assetBalance;
      setOrderAmountSellStopLimit(amount);
    }
  }, [sellStopLimitSlider]);

  let BUY_PRICE = orderPriceBuyStopLimit * orderAmountBuyStopLimit;
  return (
    <div>
      {type === "buy" ? (
        <>
          <div className="mt-5">
            <MuiInputTwo
              amount={orderBuyStopPrice}
              setAmount={setOrderBuyStopPrice}
              decimalScale={currentTrade?.buy_places}
              placeholder={`(${currentTrade?.area_title_fa ?? "-"})استاپ`}
              xs={{ direction: "rtl" }}
              market={lastTrades}
            />
          </div>
          <div className="mt-10">
            <MuiInputTwo
              amount={orderPriceBuyStopLimit}
              setAmount={setOrderPriceBuyStopLimit}
              decimalScale={currentTrade?.buy_places}
              placeholder={`(${currentTrade?.area_title_fa ?? "-"})لیمیت`}
              xs={{ direction: "rtl" }}
              market={lastTrades}
            />
          </div>
          <div className="mt-10">
            <MuiInputOne
              amount={orderAmountBuyStopLimit}
              setAmount={setOrderAmountBuyStopLimit}
              decimalScale={currentTrade?.sell_places}
              adorment={currentTrade?.asset}
              placeholder={`(${currentTrade?.asset ?? "-"})مقدار`}
            />
          </div>
          <div className="mt-15">
            <PercentBalance
              value={buyStopLimitSlider}
              setValue={setBuyStopLimitSlider}
            />
          </div>
          <div className="mt-20">
            <HelperText1
              paySymbol={currentTrade?.area_title}
              getSymbol={currentTrade?.asset}
              wageSymbol={currentTrade?.area_title}
              wage={
                orderPriceBuyStopLimit && orderAmountBuyStopLimit
                  ? showUserEarn("buy")[1]
                  : 0
              }
              pay={
                +BUY_PRICE % 1
                  ? +BUY_PRICE?.toFixed(currentTrade?.buy_places)
                  : +BUY_PRICE
              }
              get={
                orderPriceBuyStopLimit && orderAmountBuyStopLimit
                  ? showUserEarn("buy")[0]
                  : 0
              }
              balance={areaBalance}
            />
          </div>
        </>
      ) : (
        <>
          <div className="mt-5">
            <MuiInputTwo
              amount={orderSellStopPrice}
              setAmount={setOrderSellStopPrice}
              decimalScale={currentTrade?.buy_places}
              placeholder={`(${currentTrade?.area_title_fa ?? "-"})استاپ`}
              xs={{ direction: "rtl" }}
              market={lastTrades}
            />
          </div>
          <div className="mt-10">
            <MuiInputTwo
              amount={orderPriceSellStopLimit}
              setAmount={setOrderPriceSellStopLimit}
              decimalScale={currentTrade?.buy_places}
              placeholder={`(${currentTrade?.area_title_fa ?? "-"})لیمیت`}
              xs={{ direction: "rtl" }}
              market={lastTrades}
            />
          </div>
          <div className="mt-10">
            <MuiInputOne
              amount={orderAmountSellStopLimit}
              setAmount={setOrderAmountSellStopLimit}
              adorment={currentTrade?.asset}
              decimalScale={currentTrade?.sell_places}
              placeholder={`(${currentTrade?.asset ?? "-"})مقدار`}
            />
          </div>
          <div className="mt-15">
            <PercentBalance
              value={sellStopLimitSlider}
              setValue={setSellStopLimitSlider}
            />
          </div>
          <div className="mt-20">
            <HelperText1
              paySymbol={currentTrade?.asset}
              getSymbol={currentTrade?.area_title}
              wageSymbol={currentTrade?.area_title}
              wage={
                orderPriceSellStopLimit && orderAmountSellStopLimit
                  ? showUserEarn("sell")[1]
                  : 0
              }
              pay={+orderAmountSellStopLimit}
              get={
                orderPriceSellStopLimit && orderAmountSellStopLimit
                  ? showUserEarn("sell")[0]
                  : 0
              }
              balance={assetBalance}
            />
          </div>
        </>
      )}
    </div>
  );
}
