// for bids
export function compareAsc( a, b ) {
  //console.log(a.i, b.i)
  if ( a.i > b.i ){
      return -1;
  }
  if ( a.i < b.i ){
      return 1;
  }
  return 0;
}
// for Asks
export function compareDesc( a, b ) {
  //console.log(a.i, b.i)
  if ( a.i < b.i ){
      return -1;
  }
  if ( a.i > b.i ){
      return 1;
  }
  return 0;
}

export function addSum(item, index, arr) {
  let sum;
  sum += parseFloat(item.a);
  arr[index] = {...item, s: sum};
}