//* --> Styles
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getUserMarketOrdersList } from "../../../actions/UsersMarketOrders";
import { GetUnexecutedOrders } from "../../../actions/UsersUnexecutedOrders";
import {
  FetchCancelUserOrder,
  FetchCancelUserStopOrder,
  FetchGetOrderStatus,
} from "../../../services/userServices";
import styles from "./MainMH.module.scss";
//* --> Components
import OpenOrders from "./OpenOrders";
import OrdersHistory from "./OrdersHistory";
//* --> STATIC'S
const ORDER_BTNS = [
  {
    id: 0,
    name: "open-orders",
    faName: "سفارشات باز",
  },
  {
    id: 1,
    name: "history-orders",
    faName: "تاریخچه سفارشات",
  },
];
export default function MainMH(props) {
  const dispatch = useDispatch();
  const {
    ordersType,
    setOrdersType,
    marketData,
    disabledCheckBoxOpen,
    showThisMarketOpenOrders,
    setShowThisMarkOpenOrders,
    disabledCheckBoxTable,
    showThisMarketDoneOrders,
    setShowThisMarketDoneOrders,
    typeTrade,
    dateTime,
    customDate,
    tradeMarketType,
  } = props;
  const [normalOrders, setNormalOrders] = useState([]);
  const [stopOrders, setStopOrders] = useState([]);
  const [ordersList, setOrdersList] = useState([]);

  const [cancelBtn, setCancelBtn] = useState(false);
  const [updateBtn, setUpdateBtn] = useState(false);
  //* --> Redux State's
  const isUserLogin = useSelector((state) => state.userId?.userHashCode);
  const userUnexecutedOrders = useSelector(
    (state) => state.unExecutedList?.data
  );
  const userUnexecutedStopOrders = useSelector(
    (state) => state.stopOrdersList?.data
  );
  const userOrdersRedux = useSelector((state) => state.marketOrdersList?.data);

  useEffect(() => {
    if (!isEmpty(isUserLogin)) {
      setNormalOrders(userUnexecutedOrders);
    }
  }, [isUserLogin, userUnexecutedOrders]);

  useEffect(() => {
    if (!isEmpty(isUserLogin)) {
      setStopOrders(userUnexecutedStopOrders);
    }
  }, [isUserLogin, userUnexecutedStopOrders]);

  useEffect(() => {
    if (!isEmpty(isUserLogin)) {
      setOrdersList(userOrdersRedux);
    }
  }, [isUserLogin, userOrdersRedux]);

  //* -->
  const handleChangeOrderType = (id) => {
    setOrdersType(id);
  };

  //* --> Functions For OpenOrders(Normal/Stop)

  const handleCancelNormalOrder = async (id) => {
    setCancelBtn(true);
    try {
      const { data } = await FetchCancelUserOrder(id);
      if (data.error) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        const filteredList = normalOrders.filter((c) => c.id !== id);
        setNormalOrders(filteredList);
        dispatch(getUserMarketOrdersList());
        dispatch(GetUnexecutedOrders());
      }
    } catch (ex) {
    } finally {
      setCancelBtn(false);
    }
  };
  const handleCancelStopOrder = async (id) => {
    setCancelBtn(true);
    try {
      const { data } = await FetchCancelUserStopOrder(id);
      if (data.error) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        const filteredList = stopOrders.filter((c) => c.id !== id);
        setStopOrders(filteredList);
      }
    } catch (ex) {
    } finally {
      setCancelBtn(false);
    }
  };
  const handleUpdateNormalOrders = async (id) => {
    setUpdateBtn(true);
    try {
      const { data } = await FetchGetOrderStatus(id);
      if (data.error) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        if (data.status === "APPROVED") {
          const filteredList = normalOrders.filter((c) => c.id !== id);
          setNormalOrders(filteredList);
          dispatch(getUserMarketOrdersList());
        } else {
          let filteredList = normalOrders.filter((c) => c.id === id)[0];
          if (filteredList) {
            filteredList.executed = data.executed;
            filteredList = normalOrders.append(filteredList);
            setNormalOrders(filteredList);
          }
        }
      }
    } catch (ex) {
    } finally {
      setTimeout(() => {
        setUpdateBtn(false);
      }, 6000);
    }
  };
  useEffect(() => {
    setNormalOrders(
      userUnexecutedOrders?.filter(
        (tx) =>
          (typeTrade ? tx.type === typeTrade : tx) &&
          (customDate.startDate ? tx.createdAt > customDate.startDate : tx) &&
          (customDate.endDate ? tx.createdAt < customDate.endDate : tx) &&
          (dateTime !== "c-custom" && dateTime ? tx.createdAt > dateTime : tx)
      )
    );
    setOrdersList(
      userOrdersRedux?.filter(
        (tx) =>
          (typeTrade ? tx.type === typeTrade : tx) &&
          (tradeMarketType ? tx.tradeType === tradeMarketType : tx) &&
          (customDate.startDate ? tx.createdAt > customDate.startDate : tx) &&
          (customDate.endDate ? tx.createdAt < customDate.endDate : tx) &&
          (dateTime !== "c-custom" && dateTime ? tx.createdAt > dateTime : tx)
      )
    );
    setStopOrders(
      userUnexecutedStopOrders?.filter(
        (tx) =>
          (typeTrade ? tx.type === typeTrade : tx) &&
          (customDate.startDate ? tx.createdAt > customDate.startDate : tx) &&
          (customDate.endDate ? tx.createdAt < customDate.endDate : tx) &&
          (dateTime !== "c-custom" && dateTime ? tx.createdAt > dateTime : tx)
      )
    );
  }, [dateTime, typeTrade, tradeMarketType, customDate]);
  return (
    <div className={styles.container}>
      <div className={`${styles.header_btns} dir-rtl border_color_272E40`}>
        {ORDER_BTNS.map((btn, idx) => (
          <button
            className={`${styles.btn} ${
              ordersType === btn.id
                ? styles.btn_active
                : "change_color_to_E1E2E4"
            }`}
            key={idx}
            onClick={() => handleChangeOrderType(btn.id)}
          >
            {btn.faName}
          </button>
        ))}
      </div>
      <div>
        {ordersType ? (
          <OrdersHistory
            marketData={marketData}
            disabledCheckbox={disabledCheckBoxTable}
            setShowMarket={setShowThisMarketDoneOrders}
            checkedCheckbox={showThisMarketDoneOrders}
            items={ordersList}
          />
        ) : (
          <OpenOrders
            marketData={marketData}
            disabledCheckBoxOpen={disabledCheckBoxOpen}
            showThisMarketOpenOrders={showThisMarketOpenOrders}
            setShowThisMarkOpenOrders={setShowThisMarkOpenOrders}
            normalOrders={normalOrders}
            cancel={cancelBtn}
            update={updateBtn}
            handleCancelNormalOrder={handleCancelNormalOrder}
            handleUpdateNormalOrders={handleUpdateNormalOrders}
            stopOrders={stopOrders}
            handleCancelStopOrder={handleCancelStopOrder}
          />
        )}
      </div>
    </div>
  );
}
