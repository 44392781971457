import styles from "./ErrorMessage.module.scss";
import CloseButton from "../../styles/icon/closeButton.svg";
export default function ErrorMessage({ setMessageBox, messageBox }) {
  return (
    <div>
      <div
        className={styles.containerForShowMessage}
        onClick={() => setMessageBox("")}
      ></div>
      <div className={styles.showMessageBox} onClick={() => setMessageBox("")}>
        <div className={styles.closeMessageBox}>
          <img src={CloseButton} alt="close" width={13} height={13} />
        </div>
        <div className={styles.textOfMessage}>{messageBox}</div>
      </div>
    </div>
  );
}
