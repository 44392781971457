import { useState } from "react";
import Popover from "@mui/material/Popover";
//* --> Styles
import styles from "./PopoverMT.module.scss";
//* --> Icons
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import StartIcon from "@mui/icons-material/Start";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { Link } from "react-router-dom";

export default function PopoverMT({ children, asset, faAssetName, market }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <span aria-describedby={id} onClick={handleClick}>
        {children}
      </span>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <nav
          className={` changeBackgroundColorToHardBlack ${styles.container}`}
        >
          <Link to={`/deposit/${asset}`}>
            <div className={`${styles.item} change_color_to_E1E2E4`}>
              <div className={`${styles.rotate_90} ${styles.icon_item}`}>
                <KeyboardTabIcon fontSize="inherit" className="no-svg-change" />
              </div>
              <div className={styles.text_item}>واریز {faAssetName}</div>
            </div>
          </Link>
          <Link to={`/deposit/USDT`}>
            <div className={`${styles.item} change_color_to_E1E2E4`}>
              <div className={`${styles.rotate_90} ${styles.icon_item}`}>
                <KeyboardTabIcon fontSize="inherit" className="no-svg-change" />
              </div>
              <div className={styles.text_item}>واریز تتر</div>
            </div>
          </Link>
          <Link to={`/withdraw/${asset}`}>
            <div className={`${styles.item} change_color_to_E1E2E4`}>
              <div className={`${styles.rotate_minus_90} ${styles.icon_item}`}>
                <StartIcon fontSize="inherit" className="no-svg-change" />
              </div>
              <div className={styles.text_item}>برداشت {faAssetName}</div>
            </div>
          </Link>
          <Link to={`/withdraw/USDT`}>
            <div className={`${styles.item} change_color_to_E1E2E4`}>
              <div className={`${styles.rotate_minus_90} ${styles.icon_item}`}>
                <StartIcon fontSize="inherit" className="no-svg-change" />
              </div>
              <div className={styles.text_item}>برداشت تتر</div>
            </div>
          </Link>
          <Link to={`/buy/USDT`}>
            <div className={`${styles.item} change_color_to_E1E2E4`}>
              <div className={`${styles.rotate_minus_90} ${styles.icon_item}`}>
                <AddCircleOutlineOutlinedIcon
                  fontSize="inherit"
                  className="no-svg-change"
                />
              </div>
              <div className={styles.text_item}>خرید تتر</div>
            </div>
          </Link>
          <Link to={`/buy/${asset}`}>
            <div className={`${styles.item} change_color_to_E1E2E4`}>
              <div className={`${styles.rotate_minus_90} ${styles.icon_item}`}>
                <AddCircleOutlineOutlinedIcon
                  fontSize="inherit"
                  className="no-svg-change"
                />
              </div>
              <div className={styles.text_item}>خرید {faAssetName}</div>
            </div>
          </Link>
          <Link to={`/market/history?q=${market}`}>
            <div
              className={`${styles.item} ${styles.item_no_border} change_color_to_E1E2E4`}
            >
              <div className={`${styles.history} ${styles.icon_item}`}>
                <ListAltOutlinedIcon
                  fontSize="inherit"
                  className="no-svg-change"
                />
              </div>
              <div className={styles.text_item}>تاریخچه معاملات</div>
            </div>
          </Link>
        </nav>
      </Popover>
    </>
  );
}
