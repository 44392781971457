import appIcons from "../../utils/appIcons";
import styles from "./Buttons.module.scss";

export default function ButtonTitle(props) {
  const { title = "", onClick, onClickReset,showReset } = props;
  return (
    <div className={styles.buttonTitleContainer} onClick={onClick}>
      <span>{title}</span>
      {showReset && (
        <i data-close="true" onClick={onClickReset}>
          {appIcons("closeIcon", { fontSize: "inherit" })}
        </i>
      )}
      <i>{appIcons("ArrowDownIcon", { fontSize: "inherit" })}</i>
    </div>
  );
}
