import { isEmpty } from "lodash";
import styles from "./NotificationDeposit.module.scss";
export default function ErrorMessage({ networks, setDepositNotification }) {
  return (
    <div>
      <div className={styles.background_container}></div>
      <div className={styles.notification_container}>
        <p className={styles.title_notification}>توجه</p>
        <div className={styles.line_in_notification}></div>
        <p className={styles.network_permissions}>
          امکان واریز این ارز از <span>{networks?.length}</span> شبکه شامل:{" "}
          {networks?.map((net, i) => {
            return `${net}  `;
          })}{" "}
          فراهم میباشد.
        </p>
        <p className={styles.arzfi_notification}>
          توجه داشته باشید که حتما آدرس شبکه را صحیح دریافت کرده و ارز را به
          درستی منتقل نمایید. توجه داشته باشید ارزفی هیچگونه مسئولیتی در قبال از
          دست رفتن ارز شما نخواهد داشت.
        </p>
        <button
          className={styles.button_understand}
          onClick={() => setDepositNotification(false)}
          disabled={isEmpty(networks) ? true : false}
        >
          متوجه شدم
        </button>
      </div>
    </div>
  );
}
