import { isEmpty } from "lodash";
import { useMemo } from "react";
import { memo } from "react";
import Slider from "react-slick";
import { detectBanks } from "../../../../utils/detectBanks";

import styles from "./CardsSlider.module.scss";
const SliderSettings = {
  centerMode: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: "40px",
  arrows: false,
};
const CardsSlider = (props) => {
  const { userInfo = {} } = props;
  //# Check userInfo["message"] if is okay means: isLoading => false, else isLoading = true
  //# is Loading -> show Preview of cards, else is Empty cards show Non-Card,
  //# else cards ? show Slider of Cards
  const userCardsInfo = useMemo(() => {
    const status = { loading: true, cards: [] };
    if (userInfo["message"] === "ok") {
      status["loading"] = false;
      status["cards"] = userInfo["cards"];
      return status;
    }
    return status;
  }, [userInfo]);

  return (
    <Slider {...SliderSettings}>
      {userCardsInfo["loading"] ? (
        <div>
          <div className={styles.Skelete_Loading}></div>
        </div>
      ) : isEmpty(userCardsInfo["cards"]) ? (
        <div>
          <div className={styles.noCardRegister}>
            <p>کارت بانکی ثبت نشده</p>
          </div>
        </div>
      ) : (
        userCardsInfo["cards"].map((ctx, idx) => {
          const cardNumber = ctx["cardNumber"];
          let bankInfoFromJS = {};
          const bank = detectBanks.filter(
            (c) => String(c.card_no) === String(cardNumber).slice(0, 6)
          )[0];
          if (bank !== undefined) bankInfoFromJS = bank;
          return (
            <div key={idx}>
              <div
                className={styles.cardContainer}
                style={{ backgroundColor: bankInfoFromJS["color"] }}
                data-cardbg={bankInfoFromJS["color"] ?? "null"}
              >
                <div className={styles.header}>
                  <div className={styles.bank_image_container}>
                    {bankInfoFromJS["bank_logo"] && (
                      <img
                        src={bankInfoFromJS["bank_logo"]}
                        alt={bankInfoFromJS["bank_title"]}
                        width={35}
                        height={35}
                        loading="lazy"
                      />
                    )}
                  </div>
                  {bankInfoFromJS["bank_title"] && (
                    <div>
                      <p>{bankInfoFromJS["bank_title"]}</p>
                    </div>
                  )}
                </div>
                <div
                  className={styles.cardNo}
                  style={{ backgroundColor: bankInfoFromJS["color"] }}
                >
                  {ctx["cardNumber"].match(/.{1,4}/g).join("-")}
                </div>
                <div className={styles.cardIban}>{ctx["ibanNumber"]}</div>
                <div className={styles.cardStatus}>
                  <div>وضعیت:</div>
                  <div data-status={ctx["status"]}>
                    {ctx["status"] === "APPROVED"
                      ? "تایید شده"
                      : ctx["status"] === "REJECTED"
                      ? "رد شده"
                      : "در انتظار بررسی"}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      )}
    </Slider>
  );
};

export default memo(CardsSlider);
