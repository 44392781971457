import { memo, useContext, useEffect, useState } from "react";
//* --> Styles
import styles from "../HistoryMT.module.scss";
//* --> Context
import { tradesCTX } from "../../../../context/tradesCTX";
//* --> Components
import NoOrders from "../NoOrders";
//* --> Redux
import { useDispatch, useSelector } from "react-redux";
//* --> Services & Actions
import {
  FetchCancelUserOrder,
  FetchCancelUserStopOrder,
  FetchGetOrderStatus,
} from "../../../../services/userServices";
import { getUserMarketOrdersList } from "../../../../actions/UsersMarketOrders";
//* --> Utils
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import { MARKET_TRADE_TYPES, TYPE_TRADE } from "../index";
//* --> Material UI
import { Checkbox, FormControlLabel } from "@mui/material";
//* --> Icon's
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import moment from "jalali-moment";
import { UpdateUnexecutedOrder } from "../../../../actions/UsersUnexecutedOrders";

const filterButtons = [
  { id: 0, name: "normal-orders", faName: "سفارش معمولی" },
  { id: 1, name: "stop-orders", faName: "سفارش استاپ" },
];

function OpenOrders() {
  const dispatch = useDispatch();

  const [unExecutedOrders, setUnexecutedOrders] = useState([]);
  const [unExeStopOrders, setUnexeStopOrders] = useState([]);
  let [showList, setShowList] = useState([]);
  const [cancelBtn, setCancelBtn] = useState(false);
  const [updateBtn, setUpdateBtn] = useState(false);
  const [openOrderType, setOpenOrderType] = useState(0);
  const MarketContext = useContext(tradesCTX);
  const userUnexecutedOrders = useSelector(
    (state) => state.unExecutedList?.data
  );
  const userUnexecutedStopOrders = useSelector(
    (state) => state.stopOrdersList?.data
  );
  const isUserLogin = useSelector((state) => state.userId?.userHashCode);
  const {
    currentTrade,
    showThisMarketOpen,
    setShowThisMarketOpen,
    disabledCheckBoxOpen,
    setUpdateStopList,
    updateStopList,
    getNewAssets,
  } = MarketContext;

  if (openOrderType !== 1) {
    showList = unExecutedOrders;
  } else {
    showList = unExeStopOrders;
  }

  const handleChangeType = (id) => {
    setOpenOrderType(id);
  };
  useEffect(() => {
    let mounted = true;
    if (!isEmpty(isUserLogin)) {
      if (mounted) {
        setUnexecutedOrders(userUnexecutedOrders);
      }
    }
    return () => (mounted = false);
  }, [isUserLogin, userUnexecutedOrders]);

  useEffect(() => {
    if (!isEmpty(isUserLogin)) {
      setUnexeStopOrders(userUnexecutedStopOrders);
    }
  }, [isUserLogin, userUnexecutedStopOrders]);

  const handleCancelUnexecuted = async (id) => {
    setCancelBtn(true);
    try {
      const { data } = await FetchCancelUserOrder(id);
      if (data.error) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        const filteredList = unExecutedOrders.filter((c) => c.id !== id);
        setUnexecutedOrders(filteredList);
        dispatch(UpdateUnexecutedOrder(filteredList));
        getNewAssets();
        dispatch(getUserMarketOrdersList());
      }
    } catch (ex) {
    } finally {
      setCancelBtn(false);
    }
  };
  const handleCancelStopOrder = async (id) => {
    setCancelBtn(true);
    try {
      const { data } = await FetchCancelUserStopOrder(id);
      if (data.error) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        const filteredList = unExeStopOrders.filter((c) => c.id !== id);
        setUnexeStopOrders(filteredList);
        getNewAssets();
      }
    } catch (ex) {
    } finally {
      setCancelBtn(false);
    }
  };
  const handleUpdateUnexecuted = async (id) => {
    setUpdateBtn(true);
    try {
      const { data } = await FetchGetOrderStatus(id);
      if (data.error) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        if (data.status === "APPROVED") {
          const filteredList = unExecutedOrders.filter((c) => c.id !== id);
          setUnexecutedOrders(filteredList);
          dispatch(UpdateUnexecutedOrder(filteredList));
          dispatch(getUserMarketOrdersList());
        } else {
          let filteredList = unExecutedOrders.filter((c) => c.id === id)[0];
          if (filteredList) {
            if (data.executed !== undefined) {
              filteredList.executed = data.executed;
              filteredList = unExecutedOrders.append(filteredList);
              setUnexecutedOrders(filteredList);
            }
          }
        }
      }
    } catch (ex) {
    } finally {
      setTimeout(() => {
        setUpdateBtn(false);
      }, 6000);
    }
  };
  return (
    <div className={styles.open_orders_section}>
      <div className={styles.check}>
        <FormControlLabel
          sx={{
            padding: 0,
            margin: 0,
            height: "30px",
          }}
          label={
            <span
              style={{
                fontFamily: "IranSansWeb",
                fontSize: "12px",
                paddingRight: "5px",
                color: "#9D9EA8",
              }}
            >
              فقط نمایش {currentTrade?.asset_title_fa}/
              {currentTrade?.area_title_fa}
            </span>
          }
          control={
            <Checkbox
              disabled={disabledCheckBoxOpen}
              checked={showThisMarketOpen}
              onChange={() => setShowThisMarketOpen(!showThisMarketOpen)}
              size="small"
              sx={{
                color: "#9D9EA8",
                padding: 0,
                "&.Mui-checked": {
                  color: "#00c194",
                },
              }}
            />
          }
        />
      </div>
      <div className={styles.filter_buttons}>
        {filterButtons.map((btn, idx) => (
          <button
            className={`button-shadow ${
              btn.id === openOrderType ? "button-shadow-active" : ""
            }`}
            key={idx}
            onClick={() => handleChangeType(btn.id)}
          >
            {btn.faName}(
            {!btn.id
              ? unExecutedOrders?.length ?? 0
              : unExeStopOrders?.length ?? 0}
            )
          </button>
        ))}
      </div>
      <div>
        {!isEmpty(isUserLogin) ? (
          !isEmpty(showList) ? (
            showList.map((ord, i) => {
              return (
                <div
                  key={i}
                  className={`${styles.normal_orders} border_color_272E40`}
                >
                  {openOrderType !== 1 ? (
                    <>
                      <div className={styles.one}>
                        <div className={styles.name}>
                          {ord.type === "BUY" ? (
                            <span
                              style={{ color: "#00C194" }}
                              className={styles.icon_buy_sell}
                            >
                              <FileDownloadOutlinedIcon
                                color="inherit"
                                fontSize="inherit"
                              />
                            </span>
                          ) : (
                            <span
                              style={{ color: "#ed6e72" }}
                              className={styles.icon_buy_sell}
                            >
                              <FileUploadOutlinedIcon
                                color="inherit"
                                fontSize="inherit"
                              />
                            </span>
                          )}
                          <span>{ord.faName}</span>
                          <span>‌({ord.market})</span>
                        </div>
                        <div className={styles.work}>
                          <button
                            className={styles.cancel_btn}
                            onClick={() => handleCancelUnexecuted(ord.id)}
                            disabled={cancelBtn}
                          >
                            لغو
                          </button>
                          <button
                            className={styles.update_btn}
                            disabled={updateBtn}
                            onClick={() => handleUpdateUnexecuted(ord.id)}
                          >
                            آپدیت
                          </button>
                        </div>
                      </div>
                      <div
                        className={`${styles.second} change_color_to_9D9EA8`}
                      >
                        <div className={`eng-number-font ${styles.date}`}>
                          {moment(ord.createdAt, "YYYY-MM-DDThh:mm:ss.000Z")
                            .locale("fa")
                            .format("YYYY/MM/DD HH:mm:ss")}
                        </div>
                        <div className={styles.market_type}>
                          <span>
                            {MARKET_TRADE_TYPES.filter(
                              (c) => c.title === ord.tradeType
                            )[0]?.label ?? "-"}
                          </span>
                        </div>
                        <div className={styles.area_price}>
                          <div style={{ fontSize: "10px" }} className="dir-rtl">
                            مبلغ کل ({currentTrade?.area_title_fa})
                          </div>
                          <div>
                            <span className="eng-number-font">
                              {Number(ord?.totalPrice ?? 0) % 1
                                ? Number(ord?.totalPrice ?? 0)?.toFixed(2)
                                : Number(ord?.totalPrice ?? 0)}
                            </span>
                            <span style={{ paddingLeft: "3px" }}>
                              {currentTrade?.area_title}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={styles.three}>
                        <div className={styles.amount}>
                          <div
                            className={`${styles.titles} change_color_to_9D9EA8`}
                          >
                            مقدار
                          </div>
                          <div className="change_color_to_E1E2E4 eng-number-font">
                            <span>{+ord.amount}</span>
                          </div>
                        </div>
                        <div className={styles.dones}>
                          <div
                            className={`${styles.titles} change_color_to_9D9EA8`}
                          >
                            انجام شده
                          </div>
                          <div className="change_color_to_E1E2E4 eng-number-font">
                            {Number(ord.executed ?? 0)}
                          </div>
                        </div>
                        <div className={styles.undones}>
                          <div
                            className={`${styles.titles} change_color_to_9D9EA8`}
                          >
                            انجام نشده
                          </div>
                          <div className="change_color_to_E1E2E4 eng-number-font">
                            {Number(ord.amount) - Number(ord.executed)}
                          </div>
                        </div>
                      </div>
                      <div className={styles.four}>
                        <div>
                          <div
                            className={`${styles.titles} change_color_to_9D9EA8`}
                          >
                            لیمیت
                          </div>
                          <div className="change_color_to_E1E2E4 eng-number-font">
                            {ord.price % 1 ? ord.price : +ord.price}
                          </div>
                        </div>
                        <div className={styles.trade_type}>
                          <div
                            className={`${styles.titles} change_color_to_9D9EA8`}
                          >
                            نوع
                          </div>
                          <div
                            className={
                              TYPE_TRADE.filter((c) => c.title === ord.type)[0]
                                ?.color ?? "-"
                            }
                          >
                            {TYPE_TRADE.filter((c) => c.title === ord.type)[0]
                              ?.label ?? "-"}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={styles.one}>
                        <div className={styles.name}>
                          {ord.type === "BUY" ? (
                            <span
                              style={{ color: "#00C194" }}
                              className={styles.icon_buy_sell}
                            >
                              <FileDownloadOutlinedIcon
                                color="inherit"
                                fontSize="inherit"
                              />
                            </span>
                          ) : (
                            <span
                              style={{ color: "#ed6e72" }}
                              className={styles.icon_buy_sell}
                            >
                              <FileUploadOutlinedIcon
                                color="inherit"
                                fontSize="inherit"
                              />
                            </span>
                          )}
                          <span>{ord.faName}</span>
                          <span>‌({ord.market})</span>
                        </div>
                        <div className={styles.work}>
                          <button
                            className={styles.cancel_btn}
                            onClick={() => handleCancelStopOrder(ord.id)}
                            disabled={cancelBtn}
                          >
                            لغو
                          </button>
                        </div>
                      </div>
                      <div
                        className={`${styles.second} change_color_to_9D9EA8`}
                      >
                        <div className={`eng-number-font ${styles.date}`}>
                          {moment(ord.createdAt, "YYYY-MM-DDThh:mm:ss.000Z")
                            .locale("fa")
                            .format("YYYY/MM/DD HH:mm:ss")}
                        </div>
                        <div className={styles.market_type}>
                          <span>
                            {MARKET_TRADE_TYPES.filter(
                              (c) => c.title === ord.tradeType
                            )[0]?.label ?? "-"}
                          </span>
                        </div>
                        <div className={styles.area_price}>
                          <div style={{ fontSize: "10px" }} className="dir-rtl">
                            مبلغ کل ({currentTrade?.area_title_fa})
                          </div>
                          <div>
                            <span className="eng-number-font">
                              {Number(ord?.totalPrice ?? 0) % 1
                                ? Number(ord?.totalPrice ?? 0)?.toFixed(2)
                                : Number(ord?.totalPrice ?? 0)}
                            </span>
                            <span style={{ paddingLeft: "3px" }}>
                              {currentTrade?.area_title}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={styles.three}>
                        <div className={styles.amount}>
                          <div
                            className={`${styles.titles} change_color_to_9D9EA8`}
                          >
                            مقدار
                          </div>
                          <div className="change_color_to_E1E2E4 eng-number-font">
                            <span>{+ord.amount}</span>
                          </div>
                        </div>
                        <div className={styles.dones}>
                          <div
                            className={`${styles.titles} change_color_to_9D9EA8`}
                          >
                            استاپ
                          </div>
                          <div className="change_color_to_E1E2E4 eng-number-font">
                            {ord.stop}
                          </div>
                        </div>
                      </div>
                      <div className={styles.four}>
                        <div>
                          <div
                            className={`${styles.titles} change_color_to_9D9EA8`}
                          >
                            لیمیت
                          </div>
                          <div className="change_color_to_E1E2E4 eng-number-font">
                            {ord.price % 1 ? ord.price : +ord.price}
                          </div>
                        </div>
                        <div className={styles.trade_type}>
                          <div
                            className={`${styles.titles} change_color_to_9D9EA8`}
                          >
                            نوع
                          </div>
                          <div
                            className={
                              TYPE_TRADE.filter((c) => c.title === ord.type)[0]
                                ?.color ?? "-"
                            }
                          >
                            {TYPE_TRADE.filter((c) => c.title === ord.type)[0]
                              ?.label ?? "-"}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            })
          ) : (
            <NoOrders text="سفارش فعالی یافت نشد" />
          )
        ) : (
          <NoOrders text="برای مشاهده سفارشات وارد حساب خود شوید" />
        )}
      </div>
    </div>
  );
}

export default memo(OpenOrders);
