import styles from "./authHeader.module.scss";
import appLogo from "../../../styles/pics/logo.png";
import { useSelector } from "react-redux";
import { LineChart, Line } from "recharts";
import { useHistory } from "react-router";
import { take } from "lodash";
import { showBetterPrice } from "../../../utils/showBetterPrice";
export default function AuthHeader() {
  const marketData = useSelector((state) => state.market?.data);
  const filteredCoins = marketData?.filter(
    (c) => c.symbol !== "USDT" && c.symbol !== "BNB" && c.symbol !== "USDC"
  );
  const takeJustThreeCoins = take(filteredCoins, 3);
  const history = useHistory();
  //Data for RechartJS
  const drawChart = (coin) => {
    return coin.klines.map((k) => ({ pv: Math.pow(k[1], 4) }));
  };
  //
  return (
    <>
      <section className={styles.container}>
        <div className={styles.app_logo_container}>
          <img src={appLogo} alt="ArzFi" onClick={() => history.push("/")} />
        </div>
        <div className={styles.balls_place}>
          <div className={`hiddenOfDarkMode ${styles.ball_gradient_1}`}></div>
          <div className={`hiddenOfDarkMode ${styles.ball_gradient_2}`}></div>
        </div>
      </section>
    </>
  );
}
