import { memo } from "react";
import AliceCarousel from "react-alice-carousel";

//# STYLES
import styles from "./Swipers.module.scss";
function PrimarySwiper(props) {
  const { items = [], inerval = 3000, disableDot = false } = props;
  return (
    <AliceCarousel
      autoPlay
      autoPlayStrategy="none"
      autoPlayInterval={inerval}
      animationDuration={1000}
      animationType="fadeout"
      infinite
      vertica
      touchTracking={false}
      disableDotsControls={disableDot}
      disableButtonsControls
      items={items}
    />
  );
}
export default memo(PrimarySwiper);
