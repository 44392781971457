import styles from "./PaymentStatus.module.scss";
import { useHistory, useLocation } from "react-router";
import successIcon from "../../styles/icon/successPay.svg";
import failedIcon from "../../styles/icon/failPay.svg";
import successPic from "../../styles/pics/paysuccess.svg";
import failedPic from "../../styles/pics/payfail.svg";
import { useEffect } from "react";
import { useState } from "react";
import { errorMessage } from "../../utils/messages";
import configService from "../../services/config.json";

////P - FAILURE
export const PaymentFailed = () => {
  return (
    <section className={styles.status_container}>
      <div className={`${styles.status_title} ${styles.text_failed}`}>
        پرداخت ناموفق
      </div>
      <div className={`${styles.status_notification} ${styles.bg_failed}`}>
        <p>پرداخت شما ناموفق بود! دوباره تلاش کنید</p>
        <div className={styles.image}>
          <img src={failedIcon} alt="" width={18} />
        </div>
      </div>
      <main className={`${styles.status_details} ${styles.border_failed} `}>
        <div className={styles.failed_text_edit}>
          در صورت کسر وجه از حساب بانکی شما, تا ۷۲ ساعت آینده مبلغ از سمت بانک
          به حساب شما برگشت داده خواهد شد
        </div>
      </main>
      <div className={styles.status_icon}>
        <img src={failedPic} alt="FAILED" width={250} height={250} />
      </div>
    </section>
  );
};
////P - ACCEPTED
export const PaymentSuccess = ({ details }) => {
  return (
    <section className={styles.status_container}>
      <div className={`${styles.status_title} ${styles.text_success}`}>
        تایید پرداخت
      </div>
      <div className={`${styles.status_notification} ${styles.bg_success}`}>
        <p>پرداخت شما با موفقیت انجام شد</p>
        <div className={styles.image}>
          <img src={successIcon} alt="" />
        </div>
      </div>
      <main className={`${styles.status_details} ${styles.border_success}`}>
        <div className={styles.grid_item}>
          <div>وضعیت تراکنش:</div>
          <div>
            <div>{details?.status === 1 ? "موفق" : "ناموفق"}</div>
          </div>
        </div>
        <div className={styles.grid_item}>
          <div>کد رهگیری:</div>
          <div>
            <div>{details?.transId}</div>
          </div>
        </div>
        <div className={styles.grid_item}>
          <div>شماره کارت:</div>
          <div>
            <div>{details?.cardNumber}</div>
          </div>
        </div>
        <div className={styles.grid_item}>
          <div>مبلغ:</div>
          <div>
            <div> {details?.amount} تومان</div>
          </div>
        </div>
      </main>
      <div className={styles.status_icon}>
        <img src={successPic} alt="SUCCESS" width={250} height={250} />
      </div>
    </section>
  );
};
export default function PaymentStatus2() {
  const [whichStatus, setWhichStatus] = useState(null);
  const [details, setDetails] = useState(null);
  const location = useLocation();
  const trans_id = new URLSearchParams(location.search).get("trans_id");
  const order_id = new URLSearchParams(location.search).get("order_id");
  const amount = new URLSearchParams(location.search).get("amount");
  const np_status = new URLSearchParams(location.search).get("np_status");

  const history = useHistory();
  useEffect(() => {
    if (np_status !== "Unsuccessful") {
      (async () => {
        const data = { trans_id, order_id, amount };
        const options = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            //'x-access-token': authTokens.accessToken
          },
          method: "POST",
          body: JSON.stringify(data),
        };
        const response = await fetch(
          `${configService.arzfi}/users/payment/verify`,
          options
        );
        const messages = await response.json();
        if (response.ok) {
          if (messages.error) {
            setDetails(messages);
            setWhichStatus("failed");
          } else {
            setDetails(messages);
            setWhichStatus("success");
          }
        } else {
          if (response.status === 404) {
            errorMessage("خطایی رخ داد، لطفا مجددا تلاش کنید");
          } else {
            errorMessage(messages.errors);
          }
        }
      })();
    } else {
      setWhichStatus("failed");
    }
  }, []);
  return (
    <div className={styles.container}>
      {/* Bottom component rendered when data released and send props to their components
        else Render ""Loading""
      */}
      <main>
        {whichStatus === "success" ? (
          <PaymentSuccess details={details} />
        ) : whichStatus === "failed" ? (
          <PaymentFailed />
        ) : (
          <div
            style={{
              lineHeight: "20vh",
              textAlign: "center",
              fontFamily: "IranSansWeb",
            }}
          >
            در حال پردازش
          </div>
        )}
        <div className={styles.container_btn}>
          {/* if status payment failure, btn styles => status_btn_failed, if success => status_btn_success */}
          <button
            className={`${styles.status_btn} ${
              whichStatus === "success"
                ? styles.status_btn_success
                : styles.status_btn_failed
            }`}
            onClick={() => history.replace("/my-wallet")}
          >
            بازگشت به کیف پول&zwnj;ها
          </button>
        </div>
      </main>
    </div>
  );
}
