import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { DangerMuiBtn, SuccessMuiBtn } from "../../MuiButtons";
import { Alert, Checkbox, FormControlLabel, Stack } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: "800px",
  outline: "none",
  bgcolor: "var(--third2-color)",
  border: "2px solid var(--border-color)",
  boxShadow: 24,
  p: 4,
};

export default function PrimaryModal(props) {
  const { message, onClose, showTitle = true, open } = props;

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={onClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box sx={style}>
            {showTitle && (
              <Alert
                severity="warning"
                sx={{ direction: "rtl", fontFamily: "IranSansWeb" }}
              >
                {showTitle}
              </Alert>
            )}
            {message && (
              <Stack
                id="transition-modal-description"
                sx={{ mt: showTitle ? 2 : 0, direction: "rtl" }}
              >
                {message}
              </Stack>
            )}
            <Stack direction="row" spacing={1} marginTop={3}>
              <SuccessMuiBtn text="تایید" onClick={onClose} />
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
