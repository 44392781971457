import { Box, IconButton, Stack, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import React, { useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styles from "./WalletPage.module.scss";
import { DepWthStatus, transferTypes } from "../../utils/_appStatus";
import { ShowJalaliMoment } from "../../utils/convertMoment";
import appIcons from "../../utils/appIcons";
import { useState } from "react";
import { FetchCancelAssetWithdrawal } from "../../services/userServices";
import { errorMessage, successMessage } from "../../utils/messages";
import PaperButton from "../../components/Buttons/PaperButton";
import { ThreeDots } from "react-loader-spinner";
function UsersWithdrawals({ state, dispatch }) {
  const { isLoading, isError, wthList = [] } = state;
  const [disabledIDs, setDisabledIDs] = useState([]);
  const ref = useRef(null);

  const onRequestCancelWTH = async (id) => {
    setDisabledIDs((prev) => [...prev, id]);
    try {
      const body = JSON.stringify({ id });
      const { status, data } = await FetchCancelAssetWithdrawal(body);
      console.log(data);
      if (status === 200) {
        if (data.error) {
          errorMessage(data.message);
          dispatch({ type: "REFRESH" });
        } else {
          successMessage(data.message);
          dispatch({ type: "WTH_CANCELED", payload: id });
        }
      }
    } catch {
    } finally {
      setDisabledIDs((prev) => {
        const newList = [...prev].filter((c) => c !== id);
        return newList;
      });
    }
  };
  const handleRefreshWithLoading = () => {
    return dispatch({ type: "REFRESH_VIA_LOADING" });
  };
  return (
    <Stack height={1}>
      {!isLoading && !isError ? (
        !isEmpty(wthList) ? (
          <div ref={ref} className="scroll-container">
            <InfiniteScroll
              dataLength={wthList.length}
              next={() => {}}
              hasMore={false}
              height="calc(100vh - 250px)"
            >
              {wthList.map((ctx, index) => {
                const status = DepWthStatus[ctx["status"]];
                return (
                  <div key={ctx["createdAt"]} className={styles.item_wth}>
                    <div className={styles.top_item}>
                      <span
                        className={styles.status}
                        data-status={status?.type ?? "danger"}
                      >
                        {status?.name}
                      </span>
                    </div>
                    <div className={styles.flexItems}>
                      <span>{ShowJalaliMoment(ctx["createdAt"])}</span>
                      <span>{ctx["coinName"]}</span>
                    </div>
                    <div className={styles.flexItems}>
                      <span data-color={ctx["type"]}>
                        {parseFloat(ctx?.amount ?? 0)}
                      </span>
                      <span>مقدار</span>
                    </div>
                    <div className={styles.flexItems}>
                      <span>
                        {ctx.address ? ctx.address : ctx?.walletAddress ?? "-"}
                      </span>
                      <span>آدرس</span>
                    </div>
                    {ctx["txId"] && (
                      <div className={styles.flexItems}>
                        <span>{ctx["txId"]}</span>
                        <span>TxID</span>
                      </div>
                    )}
                    {ctx["status"] === "WAITING" && (
                      <div className={styles.flexItems}>
                        <Box>
                          <button
                            className={styles.button_group}
                            data-color="danger"
                            disabled={disabledIDs.includes(ctx["id"])}
                            onClick={() => onRequestCancelWTH(ctx["id"])}
                          >
                            لغو برداشت
                          </button>
                        </Box>
                        <span>عملیات</span>
                      </div>
                    )}
                    {ctx["transferType"] && (
                      <div className={styles.flexItems}>
                        <span>{transferTypes[ctx["transferType"]] ?? "-"}</span>
                        <span>نوع انتقال</span>
                      </div>
                    )}
                    {ctx["protocol"] && (
                      <div className={styles.flexItems}>
                        <span>{ctx["protocol"]}</span>
                        <span>شبکه</span>
                      </div>
                    )}
                    {ctx["explorer"] && (
                      <div className={styles.flexItems}>
                        <span>
                          <a
                            href={ctx["explorer"]}
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: "var(--primary-color)" }}
                          >
                            {ctx["explorer"]}
                          </a>
                        </span>
                        <span>جست و جو</span>
                      </div>
                    )}
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        ) : (
          <Stack height={1} alignItems="center" justifyContent="center">
            <Typography
              textAlign="center"
              color="var(--secondary-color)"
              sx={{ backgroundColor: "rgba(0,0,0,0.2)" }}
              width={1}
              py={1.5}
            >
              موردی یافت نشد
            </Typography>
          </Stack>
        )
      ) : isError ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          height="calc(100vh - 300px)"
        >
          <Typography color="var(--danger-color)" fontSize={14}>
            مشکلی در دریافت اطلاعات رخ داده است
          </Typography>
          <Stack mt={2}>
            <PaperButton onClick={handleRefreshWithLoading}>
              تلاش مجدد
            </PaperButton>
          </Stack>
        </Stack>
      ) : (
        <Stack alignItems="center" justifyContent="center" height={1}>
          <ThreeDots color="var(--primary-color)" width={40} />
        </Stack>
      )}
    </Stack>
  );
}

export default UsersWithdrawals;
