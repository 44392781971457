import moment from "jalali-moment";

export function convertToPersian(value) {
  const getDate = value?.split("T");
  if (getDate) {
    const globalDate = moment(`${getDate[0]}`, "YYYY/MM/DD");
    return globalDate.locale("fa").format("YYYY/MM/DD");
  }
}
export function convertToPersianWithTime(value) {
  if (value) {
    const globalDate = moment(`${value}`).format();
    const DateTime = globalDate.split("T");
    const justTime = DateTime[1].split("+");
    return justTime[0] + " - " + DateTime[0];
  }
}

export function ShowJalaliMoment(time) {
  if (time.includes("T")) {
    return moment(time, "YYYY-MM-DDThh:mm:ss.000Z")
      .locale("fa")
      .format("HH:mm YY/MM/DD");
  }
  return time;
}

export function convertJalaliToMilad(time) {
  return moment(time, "YYYY-MM-DDThh:mm:ss.000Z")
    .locale("en")
    .format("YYYY-MM-DDT23:mm:ss.000Z");
}
