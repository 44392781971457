import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
//Components
import { Paper, InputBase, Divider, Slider } from "@mui/material";
//Styles and Icons
import styles from "./Convert.module.scss";
import BTCIcon from "../../../../styles/icon/crypto/btc_logo.svg";
import Ethereum from "../../../../styles/icon/crypto/ethereum.svg";
import ArrowFillIcon from "../../../../styles/icon/ArrowFillDown.svg";
import SwitchIcon from "../../../../styles/icon/switchgreen.svg";
import EqualIcon from "../../../../styles/icon/equal.svg";
import MiniChartIcon from "../../../../styles/icon/minichart.svg";
import WarningRedIcon from "../../../../styles/icon/warningRed.svg";
//* -->‌
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export default function ConvertCustomPrice() {
  const userId = useSelector((state) => state.userId);
  const history = useHistory();
  return (
    <>
      <div className={styles.underBuild}>
        <h2>به زودی...!</h2>
      </div>
      <div className={`${styles.container} convert_basic`}>
        <header className={styles.buttons_swap_components}>
          <Link to="/convert-custom">
            <div
              className={`${styles.button_of_swap} ${styles.active_button_swap} `}
            >
              قیمت دلخواه
            </div>
          </Link>
          <Link to="/convert">
            <div className={`${styles.button_of_swap} `}>تبدیل</div>
          </Link>
        </header>
        <main className={styles.information_of_convert_container}>
          <div
            className={`${styles.pay_coin_information} ${styles.custom_coin_info}`}
          >
            <div className={styles.input_place}>
              <Paper
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  onChange={(e) => console.log(e.target.value)}
                />
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <div className={styles.box_coin_select}>
                  <div className={styles.ArrowPlace}>
                    <img
                      className={styles.forAnim_arrow}
                      src={ArrowFillIcon}
                      alt="list"
                      width={9}
                      height={9}
                    />
                  </div>
                  <div className={styles.coin_symbol}>
                    <p>BTC</p>
                  </div>
                  <div className={styles.coin_name}>
                    <span>بیت کوین</span>
                  </div>
                  <div className={styles.coin_pic}>
                    <img src={BTCIcon} alt="BTC" width={20} height={20} />
                  </div>
                </div>
              </Paper>
            </div>
            <div className={styles.user_coin_info}>
              <div>
                <span>موجودی: </span>
                <span>0</span> <span>بیت کوین</span>
              </div>
              <div>پرداخت میکنید</div>
            </div>
          </div>
          <section className={styles.custom_details_container}>
            <div className="customizePaperProf">
              <Paper
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                className="changeBackgroundColorToHardBlack"
              >
                <span className={`font_color_white ${styles.title_paper}`}>
                  منقضی شود
                </span>
                <Divider
                  sx={{ height: 28, m: 0.5 }}
                  orientation="vertical"
                  className="changeBackgroundColorToGreen"
                />
                <div className={`customizeSelect ${styles.select_day_expire}`}>
                  <Select
                    value="10"
                    fullWidth
                    className="font_color_white"
                    disabled
                  >
                    <MenuItem value={10}>‍‍1 روز</MenuItem>
                    <MenuItem value={20}>2 روز</MenuItem>
                    <MenuItem value={30}>3 روز</MenuItem>
                  </Select>
                </div>
              </Paper>
              <div className={`font_color_white ${styles.area_balance}`}>
                {/* موجودی: {areaBalance?.toLocaleString("en-US") ?? 0}{" "}
                {currentMarket.area_title_fa} */}
              </div>
            </div>
            <div>
              <div className="customizePaperProf">
                <Paper
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  className="changeBackgroundColorToHardBlack"
                >
                  <span className={`font_color_white ${styles.title_paper}`}>
                    قیمت
                  </span>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <div
                    className={`customizeNumberFormate  ${styles.select_price_container}`}
                  >
                    {/* <FormattedInputs
                      amount={orderPriceLimit}
                      setAmount={setOrderPriceLimit}
                      placeholder={(+currentMarket?.price)?.toFixed(
                        +currentMarket.buy_places
                      )}
                      decimalScale={currentMarket?.buy_places}
                    /> */}
                    <button
                    // onClick={() =>
                    //   decreasePrice(orderPriceLimit, setOrderPriceLimit)
                    // }
                    >
                      -
                    </button>
                    <button
                      onClick={() =>
                        // increasePrice(
                        //   orderPriceLimit,
                        //   setOrderPriceLimit,
                        //   currentMarket
                        // )
                        null
                      }
                    >
                      +
                    </button>
                  </div>
                </Paper>
              </div>
              <div className={`font_color_white ${styles.price_must_user_pay}`}>
                <span>{/* <LegendToggleIcon fontSize="inherit" /> */}</span>
                <span style={{ paddingLeft: "5px" }}>
                  {/* {orderAmountLimit && orderPriceLimit
                    ? handleCalculateDollar()
                    : 0}{" "} */}
                  <span style={{ fontSize: "11px" }}>
                    {/* {currentMarket.trading_area} */}
                  </span>
                </span>
              </div>
            </div>
          </section>
          <div className={styles.swap_coins}>
            <img src={SwitchIcon} alt="SWAP" width={30} height={30} />
          </div>
          <div
            className={`${styles.get_coin_information} ${styles.custom_coin2_info}`}
          >
            <div className={styles.input_place}>
              <Paper
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ paddingLeft: "8px" }}>
                  <img src={EqualIcon} alt="" width={20} height={17} />
                </div>
                <InputBase sx={{ ml: 1, flex: 1 }} disabled readOnly />
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <div className={styles.box_coin_select}>
                  <div className={styles.ArrowPlace}>
                    <img
                      className={styles.forAnim_arrow}
                      src={ArrowFillIcon}
                      alt="list"
                      width={9}
                      height={9}
                    />
                  </div>
                  <div className={styles.coin_symbol}>
                    <p>ETH</p>
                  </div>
                  <div className={styles.coin_name}>
                    <span>اتریوم</span>
                  </div>
                  <div className={styles.coin_pic}>
                    <img src={Ethereum} alt="ETH" width={20} height={20} />
                  </div>
                </div>
              </Paper>
            </div>
            <div className={styles.user_coin_info}>
              <div></div>
              <div>دریافت میکنید</div>
            </div>
          </div>
          <div
            className={`${styles.convert_coins_price} ${styles.custom_coin2_info}`}
          >
            <div className={styles.left_right_side_coin}>
              <div className={styles.icon_mini_chart}>
                <img src={MiniChartIcon} alt="" width={15} height={15} />
              </div>
              <div className={styles.price_of_one_coin}>
                <span>1</span>
                <span>BTC</span>
                <span> = </span>
              </div>
              <div className={styles.dollar_price_of_coin}>
                <div>دلار</div>
                <div>46.942.000</div>
              </div>
            </div>
            <div className={styles.line_between_price}></div>
            <div className={styles.left_right_side_coin}>
              <div className={styles.icon_mini_chart}>
                <img src={MiniChartIcon} alt="" width={15} height={15} />
              </div>
              <div className={styles.price_of_one_coin}>
                <span>1</span>
                <span>ETH</span>
                <span> = </span>
              </div>
              <div className={styles.dollar_price_of_coin}>
                <div>دلار</div>
                <div>3,720,000</div>
              </div>
            </div>
          </div>
        </main>
        <footer className={styles.footer_custom_convert}>
          <div className={styles.line_convert_footer}>
            <Slider
              aria-label="Temperature"
              valueLabelDisplay="auto"
              step={25}
              marks
              min={25}
              max={100}
            />
          </div>
          <div className={styles.showErrorNotification}>
            <img src={WarningRedIcon} alt="" width={25} height={25} />
            <div>
              حداقل مقدار تبدیل برای <span>بیت کوین</span> :‌ <span>0.002</span>
            </div>
          </div>
          <button
            type="submit"
            className={styles.button_convert}
            disabled={isEmpty(userId) ? true : false}
          >
            تبدیل
          </button>
          {isEmpty(userId) ? (
            <div className={styles.message_for_login}>
              ابتدا وارد{" "}
              <span onClick={() => history.push("/authorization/login")}>
                حساب کاربری
              </span>{" "}
              خود شوید
            </div>
          ) : (
            ""
          )}
        </footer>
      </div>
    </>
  );
}
