import { errorMessage, successMessage } from "./messages";

export const copyTextViaNavigator = (text, message) => {
  const clipBoard = navigator.clipboard;
  return clipBoard.writeText(text).then(() => {
    if (message) {
      successMessage(message);
    }
    return 200;
  });
};