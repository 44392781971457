import styles from "./ChangePassword.module.scss";
import { useHistory } from "react-router";
import { useState } from "react";
//ICO
import ArrowBack from "../../../../styles/icon/arrow_back_fill.svg";
import EyeIcon from "../../../../styles/icon/eyes.svg";
import PassVisibility from "../../../../helpers/PassVisibility";
import { checkMinimumPass } from "../../../Validator/Validator";
import { ERROR_CODE3, ERROR_CODE4 } from "../../../Validator/ErrorCodes";
import { userEditPasswordWithToken } from "../../../../services/userServices";
import { errorMessage, successMessage } from "../../../../utils/messages";
export default function ChangePassword() {
  const history = useHistory();
  const [changePasswordWithToken, setChangePasswordWithToken] = useState({
    oldPassword: "",
    password: "",
    confirm: "",
  });
  const [errorMessageApp, setErrorMessageApp] = useState({
    oldPassword: "",
    password: "",
    confirm: "",
  });
  const [disableBtn, setDisableBtn] = useState(false);

  //
  const handleChangePassword = async (e) => {
    e.preventDefault();
    let isMinPass = checkMinimumPass(changePasswordWithToken.password);
    if (!isMinPass)
      return setErrorMessageApp({
        ...errorMessageApp,
        password: ERROR_CODE3,
      });
    if (changePasswordWithToken.password !== changePasswordWithToken.confirm)
      return setErrorMessageApp({ ...errorMessageApp, confirm: ERROR_CODE4 });
    try {
      setDisableBtn(true);
      const { data } = await userEditPasswordWithToken({
        oldPassword: changePasswordWithToken.oldPassword,
        password: changePasswordWithToken.password,
        confirm: changePasswordWithToken.confirm,
      });
      if (data.error) {
        errorMessage(data.message);
      } else {
        successMessage(data.message);
        setChangePasswordWithToken({
          ...changePasswordWithToken,
          password: "",
          oldPassword: "",
          confirm: "",
        });
        history.replace("/my-profile");
      }
    } catch (ex) {
    } finally {
      setDisableBtn(false);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.backIcon}>
        <img
          src={ArrowBack}
          alt="BACK"
          width={15}
          height={15}
          onClick={() => history.goBack()}
          className="convertPicToWhite"
        />
      </div>
      <div className={`font_color_white ${styles.title_of_page}`}>
        تغییر رمز عبور
      </div>
      <form onSubmit={handleChangePassword}>
        <div className={styles.Inputs_container}>
          <div className={styles.one_input_container}>
            <input
              className="changeBackgroundToWhite changeFontColorToBlack"
              type="password"
              placeholder="رمز عبور فعلی"
              id="userCurrentPassword"
              onChange={(e) => {
                setChangePasswordWithToken({
                  ...changePasswordWithToken,
                  oldPassword: e.target.value,
                });
                setErrorMessageApp({ ...errorMessageApp, oldPassword: "" });
              }}
              value={changePasswordWithToken.oldPassword}
            />
            <div className={styles.show_hidden_pass_container}>
              <img
                src={EyeIcon}
                alt="visibility"
                width={15}
                height={15}
                onClick={() => PassVisibility("userCurrentPassword")}
              />
            </div>
          </div>
          {errorMessageApp.oldPassword && (
            <div className={styles.error_red}>
              {errorMessageApp.oldPassword}
            </div>
          )}
          <div className={styles.one_input_container}>
            <input
              className="changeBackgroundToWhite changeFontColorToBlack"
              type="password"
              placeholder="رمز عبور جدید"
              id="userNewPassword"
              onChange={(e) => {
                setChangePasswordWithToken({
                  ...changePasswordWithToken,
                  password: e.target.value,
                });
                setErrorMessageApp({ ...errorMessageApp, password: "" });
              }}
              value={changePasswordWithToken.password}
            />
            <div className={styles.show_hidden_pass_container}>
              <img
                src={EyeIcon}
                alt="visibility"
                width={15}
                height={15}
                onClick={() => PassVisibility("userNewPassword")}
              />
            </div>
          </div>
          {errorMessageApp.password && (
            <div className={styles.error_red}>{errorMessageApp.password}</div>
          )}
          <div className={styles.one_input_container}>
            <input
              className="changeBackgroundToWhite changeFontColorToBlack"
              type="password"
              placeholder="تکرار رمز عبور جدید"
              id="repeatUserNewPassword"
              onChange={(e) => {
                setChangePasswordWithToken({
                  ...changePasswordWithToken,
                  confirm: e.target.value,
                });
                setErrorMessageApp({ ...errorMessageApp, confirm: "" });
              }}
              value={changePasswordWithToken.confirm}
            />
            <div className={styles.show_hidden_pass_container}>
              <img
                src={EyeIcon}
                alt="visibility"
                width={15}
                height={15}
                onClick={() => PassVisibility("repeatUserNewPassword")}
              />
            </div>
          </div>
          {errorMessageApp.confirm && (
            <div className={styles.error_red}>{errorMessageApp.confirm}</div>
          )}
          <button type="submit" disabled={disableBtn}>
            ثبت
          </button>
        </div>
      </form>
    </div>
  );
}
