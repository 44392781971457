import styles from "./authFooter.module.scss";
export default function AuthFooter({ version }) {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.app_version}>{version}</div>
        <div className={styles.ball_gradient_footer}></div>
      </div>
    </>
  );
}
