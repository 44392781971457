import AuthHeader from "./AuthHeader";
import AuthFooter from "./AuthFooter";
import styles from "./authLayout.module.scss";
export default function AuthLayout({ children, version }) {
  return (
    <div className={styles.AuthContainer}>
      <header>
        <AuthHeader />
      </header>
      <main className={styles.main}>{children}</main>
      <footer>
        <AuthFooter version={version} />
      </footer>
    </div>
  );
}
