import { Box, Stack } from "@mui/system";
import Cookies from "js-cookie";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useMemo } from "react";
import { memo, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import PaperButton from "../Buttons/PaperButton";
import styles from "./Advertising.module.scss";
import serviceConfig from "../../services/config.json";

const COOKIE_NAME = "userViwedAdvertisment";

const Advertising = () => {
  const [invisible, setInvisible] = useState(true);
  const [advInfo, setAdvInfo] = useState({});

  //# Check and Set Cookies for get data From API
  useLayoutEffect(() => {
    const controller = new AbortController();
    let isMounted = true;
    const userViwedAdvertisment = Cookies.get(COOKIE_NAME);
    if (userViwedAdvertisment === undefined) {
      (async () => {
        try {
          const response = await fetch(`${serviceConfig.blog_url}banner-2-s`, {
            signal: controller.signal,
          });
          if (response.status === 200) {
            const data = await response.json();
            if (isMounted) {
              setAdvInfo(data[0]);
            }
            if (!isEmpty(data)) {
              setTimeout(() => {
                if (isMounted) {
                  Cookies.set(
                    COOKIE_NAME,
                    JSON.stringify({
                      userViewed: true,
                      topicViewdTitle: data[0]["title"],
                    }),
                    {
                      expires: 1,
                    }
                  );
                  setInvisible(false);
                }
              }, 2000);
            }
          }
        } catch (ex) {
          console.log("Error on get advertising:", ex);
        }
      })();
    }
    return () => {
      //# Unmount Componentf
      controller.abort();
      isMounted = false;
    };
  }, []);

  //# Scope function's
  const onRequestClose = () => {
    setInvisible(true);
  };

  const LinkAdvInfo = useMemo(() => {
    if (!isEmpty(advInfo)) {
      if (!isEmpty(advInfo["url"])) {
        const IN_APP_ROUTE = advInfo["url"].includes(serviceConfig.app_url);
        let url_in_app = null;
        if (IN_APP_ROUTE) {
          url_in_app = advInfo["url"].split(serviceConfig.app_url, 2)[1];
        }
        return {
          appRoute: IN_APP_ROUTE,
          url: url_in_app ? url_in_app : advInfo["url"],
        };
      }
    }
    return false;
  }, [advInfo]);

  return (
    <div className={styles.container} data-invisible={invisible}>
      <div className={styles.bg} onClick={onRequestClose}></div>
      <Stack className={styles.main}>
        <Box className={styles.image}>
          {!isEmpty(advInfo["image"]) && (
            <img
              src={serviceConfig.blog_url + advInfo["image"][0].url}
              alt={advInfo["title"]}
              width="100%"
              style={{maxHeight:"280px"}}
            />
          )}
        </Box>
        <b>{advInfo["title"]}</b>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginX={0.5}
          columnGap={1}
        >
          {LinkAdvInfo && (
            <Stack flex={1}>
              {LinkAdvInfo["appRoute"] ? (
                <Link to={LinkAdvInfo["url"]}>
                  <PaperButton>نمایش</PaperButton>
                </Link>
              ) : (
                <a href={LinkAdvInfo["url"]} target="_blank" rel="noreferrer">
                  <PaperButton>نمایش</PaperButton>
                </a>
              )}
            </Stack>
          )}
          <Stack flex={0.7}>
            <PaperButton type="error" onClick={onRequestClose}>
              بستن
            </PaperButton>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

export default memo(Advertising);
