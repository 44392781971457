import { ThreeDots } from "react-loader-spinner";
import styles from "./LoadingPage.module.scss";
import appLogo from "../../styles/pics/logo.png";

export const LoadingPage = () => {
  return (
    <div className={styles.LOADING_STYLES}>
      <div className={styles.LOADING_ANIMATION_CONTAINER}>
        <ThreeDots
          height="60"
          width="60"
          radius="7"
          color="#10D078"
          visible={true}
        />
      </div>
    </div>
  );
};
export const LoadingPage2 = () => {
  return (
    <div className={styles.LOADING_STYLES_2}>
      <div className={styles.LOADING_ANIMATION_CONTAINER_2}>
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#10D078"
          visible={true}
        />
      </div>
    </div>
  );
};
export const LoadingLogo = () => {
  return (
    <div className={styles.LOADING_STYLES_3}>
      <div className={styles.LOADING_ANIMATION_CONTAINER_3}>
        <img src={appLogo} alt="ArzFi" />
        <h2>ارائه دهنده خدمات برتر در زمینه رمز ارز</h2>
      </div>
    </div>
  );
};
