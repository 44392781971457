import * as React from "react";
import "./ArzfiChart.module.scss";
import { widget } from "../../charting_library";
import datafeed from "../../static/datafeed";

function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export class ArzfiChart extends React.PureComponent {
  static defaultProps = {
    interval: "30",
    datafeedUrl: datafeed,
    libraryPath: "/charting_library/",
    chartsStorageApiVersion: "1.1",
    clientId: "tradingview.com",
    userId: "public_user_id",
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
    timezone: "Asia/Tehran",
    timezoneId: "Asia/Tehran",
  };
  tvWidget = null;
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }
  componentDidUpdate() {
    const widgetOptions = {
      symbol: this.props.symbol,
      // BEWARE: no trailing slash is expected in feed URL
      datafeed: this.props.datafeedUrl,
      interval: this.props.interval,
      container: this.ref.current,
      library_path: this.props.libraryPath,
      locale: getLanguageFromURL() || "en",
      disabled_features: [
        "use_localstorage_for_settings",
        "header_symbol_search",
      ],
      enabled_features: ["hide_left_toolbar_by_default"],

      // enabled_features: ["study_templates"],
      timezone: "Asia/Tehran",
      timezoneId: "Asia/Tehran",
      charts_storage_api_version: this.props.chartsStorageApiVersion,
      client_id: this.props.clientId,
      user_id: this.props.userId,
      fullscreen: this.props.fullscreen,
      autosize: this.props.autosize,
      studies_overrides: this.props.studiesOverrides,
      overrides: {
        "symbolWatermarkProperties.transparency": 90,
        "scalesProperties.textColor": "#AAA",
        "scalesProperties.fontSize": 12,
        "paneProperties.leftAxisProperties.autoScale": true,
        "paneProperties.backgroundType": "solid",
        "paneProperties.background": this.props.isDarkMode ? "#191919" : "#fff",
      },
      theme: this.props.isDarkMode ? "dark" : "light",
      custom_css_url: "/css/custom-tradingview.css",
    };
    const tvWidget = new widget(widgetOptions);
    this.tvWidget = tvWidget;
    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        const button = tvWidget.createButton();
        button.setAttribute("title", "Click to show a notification popup");
        button.classList.add("apply-common-tooltip");
        button.addEventListener("click", () =>
          tvWidget.showNoticeDialog({
            title: "Notification",
            body: "Arzfi Application, Crypto market",
            callback: () => {
              console.log("Noticed!");
            },
          })
        );

        button.innerHTML = "Arzfi";
      });
    });
  }
  componentDidMount() {
    const widgetOptions = {
      symbol: this.props.symbol,
      datafeed: this.props.datafeedUrl,
      interval: this.props.interval,
      container: this.ref.current,
      library_path: this.props.libraryPath,
      locale: getLanguageFromURL() || "en",
      disabled_features: [
        "use_localstorage_for_settings",
        "header_symbol_search",
      ],
      enabled_features: ["hide_left_toolbar_by_default"],

      timezone: "Asia/Tehran",
      timezoneId: "Asia/Tehran",
      charts_storage_api_version: this.props.chartsStorageApiVersion,
      client_id: this.props.clientId,
      user_id: this.props.userId,
      fullscreen: this.props.fullscreen,
      autosize: this.props.autosize,
      studies_overrides: this.props.studiesOverrides,
      overrides: {
        "symbolWatermarkProperties.transparency": 90,
        "scalesProperties.textColor": "#AAA",
        "scalesProperties.fontSize": 12,
        "paneProperties.leftAxisProperties.autoScale": true,
        "paneProperties.backgroundType": "solid",
        "paneProperties.background": this.props.isDarkMode ? "#191919" : "#fff",
      },
      theme: this.props.isDarkMode ? "dark" : "light",
      custom_css_url: "/css/custom-tradingview.css",
    };
    const tvWidget = new widget(widgetOptions);
    this.tvWidget = tvWidget;
    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        const button = tvWidget.createButton();
        button.setAttribute("title", "Click to show a notification popup");
        button.classList.add("apply-common-tooltip");
        button.addEventListener("click", () =>
          tvWidget.showNoticeDialog({
            title: "Notification",
            body: "Arzfi Application, Crypto market",
            callback: () => {
              console.log("Noticed!");
            },
          })
        );
        button.innerHTML = "Arzfi";
      });
    });
  }
  componentWillUnmount() {
    if (this.tvWidget !== null) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  }

  render() {
    return (
      <>
        <div ref={this.ref} style={{ height: "100%" }} />
      </>
    );
  }
}
