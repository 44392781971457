import { isEmpty } from "lodash";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
//* --> Styles & Icons & Pic
import styles from "./OrdersHistory.module.scss";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
//* --> Components
import NoOrders from "../../../MarketTradeLayout/HistoryMT/NoOrders";
import MuiCheckbox from "../../../MuiCheckbox";
import moment from "jalali-moment";
import {
  MARKET_TRADE_TYPES,
  statusTrade,
  TYPE_TRADE,
} from "../../../MarketTradeLayout/HistoryMT";

export default function OrdersHistory(props) {
  const {
    marketData,
    disabledCheckbox,
    setShowMarket,
    checkedCheckbox,
    items,
  } = props;

  const [showNums, setShowNums] = useState(5);
  const [hasMoreItem, setHasMoreItem] = useState(true);

  //* --> State's

  const handleNext = () => {
    if (items.slice(0, showNums).length >= items.length) {
      setHasMoreItem(false);
      return;
    }
    setShowNums(showNums + 5);
  };
  return (
    <div className={styles.container}>
      <div className={`dir-rtl mt-8`}>
        <MuiCheckbox
          label={`فقط نمایش ${marketData?.asset_title_fa} ${marketData?.area_title_fa}`}
          disabled={disabledCheckbox}
          checked={checkedCheckbox}
          onChange={() => setShowMarket((show) => !show)}
        />
      </div>
      <main>
        <>
          {!isEmpty(items) ? (
            <InfiniteScroll
              dataLength={items.slice(0, showNums).length}
              loader={<span>برروزرسانی</span>}
              next={handleNext}
              hasMore={hasMoreItem}
            >
              {items.slice(0, showNums).map((ord, idx) => (
                <section
                  key={idx}
                  className={`${styles.normal_orders} border_color_272E40`}
                >
                  <div className={styles.one}>
                    <div className={styles.name}>
                      {ord.type === "BUY" ? (
                        <span
                          style={{ color: "#00C194" }}
                          className={styles.icon_buy_sell}
                        >
                          <FileDownloadOutlinedIcon
                            color="inherit"
                            fontSize="inherit"
                          />
                        </span>
                      ) : (
                        <span
                          style={{ color: "#ed6e72" }}
                          className={styles.icon_buy_sell}
                        >
                          <FileUploadOutlinedIcon
                            color="inherit"
                            fontSize="inherit"
                          />
                        </span>
                      )}
                      <span>{ord.faName}</span>
                      <span>‌({ord.market})</span>
                    </div>
                  </div>
                  <div className={`${styles.second} change_color_to_9D9EA8`}>
                    <div className={`eng-number-font ${styles.date}`}>
                      {moment(ord.createdAt, "YYYY-MM-DDThh:mm:ss.000Z")
                        .locale("fa")
                        .format("YYYY/MM/DD HH:mm:ss")}
                    </div>
                    <div className={styles.market_type}>
                      <span>
                        {MARKET_TRADE_TYPES.filter(
                          (c) => c.title === ord.tradeType
                        )[0]?.label ?? "-"}
                      </span>
                    </div>
                    <div className={styles.area_price}>
                      <div style={{ fontSize: "10px" }} className="dir-rtl">
                        مبلغ کل ({marketData?.area_title_fa})
                      </div>
                      <div>
                        <span className="eng-number-font">
                          {Number(ord?.totalPrice ?? 0) % 1
                            ? Number(ord?.totalPrice ?? 0)?.toFixed(2)
                            : Number(ord?.totalPrice ?? 0)}
                        </span>
                        <span style={{ paddingLeft: "3px" }}>
                          {marketData?.area_title}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.three}>
                    <div className={styles.amount}>
                      <div
                        className={`${styles.titles} change_color_to_9D9EA8`}
                      >
                        مقدار
                      </div>
                      <div className="change_color_to_E1E2E4 eng-number-font">
                        <span>{+ord.amount}</span>
                      </div>
                    </div>
                    <div className={styles.dones}>
                      <div
                        className={`${styles.titles} change_color_to_9D9EA8`}
                      >
                        انجام شده
                      </div>
                      <div className="change_color_to_E1E2E4 eng-number-font">
                        {+ord.executed}
                      </div>
                    </div>
                    <div className={styles.undones}>
                      <div
                        className={`${styles.titles} change_color_to_9D9EA8`}
                      >
                        انجام نشده
                      </div>
                      <div className="change_color_to_E1E2E4 eng-number-font">
                        {+ord.amount - +ord.executed}
                      </div>
                    </div>
                  </div>
                  <div className={styles.four}>
                    <div>
                      <div
                        className={`${styles.titles} change_color_to_9D9EA8`}
                      >
                        لیمیت
                      </div>
                      <div className="change_color_to_E1E2E4 eng-number-font">
                        {ord.price % 1 ? ord.price : +ord.price}
                      </div>
                    </div>
                    <div className={styles.trade_type}>
                      <div
                        className={`${styles.titles} change_color_to_9D9EA8`}
                      >
                        نوع
                      </div>
                      <div
                        className={
                          TYPE_TRADE.filter((c) => c.title === ord.type)[0]
                            ?.color ?? "-"
                        }
                      >
                        {TYPE_TRADE.filter((c) => c.title === ord.type)[0]
                          ?.label ?? "-"}
                      </div>
                    </div>
                    <div className={styles.status}>
                      <div
                        className={`${styles.titles} change_color_to_9D9EA8`}
                      >
                        وضعیت
                      </div>
                      <div className="change_color_to_E1E2E4">
                        {statusTrade.filter((c) => c.title === ord.status)[0]
                          ?.label ?? "-"}
                      </div>
                    </div>
                  </div>
                </section>
              ))}
            </InfiniteScroll>
          ) : (
            <div className="mt-40">
              <NoOrders text="سفارشی یافت نشد" />
            </div>
          )}
        </>
      </main>
    </div>
  );
}
