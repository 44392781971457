export const checkMinimumPass = (value) => {
  const pattern = /^.{6,}$/;
  const isValid = pattern.test(value);
  return isValid;
};

export const checkIDCardNumber = (value) => {
  const pattern = /^(?!(\d)\1{9})\d{10}$/;
  const isValid = pattern.test(value);
  return isValid;
};

export const checkEmail = (email) => {
  const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const isValid = pattern.test(email);
  return isValid;
};

export const checkValidOfRepeat = (value, repeatValue) => {
  const isValid = value === repeatValue;
  return isValid;
};

export const checkMobileNum = (value) => {
  const pattern = /^(\+98|0098|98|0)?9\d{9}$/g;
  const isValid = pattern.test(value);
  return isValid;
};

export const checkFileTypeImage = (value) => {
  const pattern = /^.*\.(jpg|JPG|PNG|png|jpeg|JPEG)$/;
  const isValidFile = pattern.test(value);
  return isValidFile;
};

export const checkTicketDescription = (value) => {
  const count = value.length;
  let isValid = false;
  count < 10 ? (isValid = false) : (isValid = true);
  return isValid;
};

export const checkTicketTitle = (value) => {
  const count = value.length;
  let isValid = false;
  count > 20 || count <= 3 ? (isValid = false) : (isValid = true);
  return isValid;
};
