const warning = "warning";
const danger = "danger";
const success = "success";

export const _bankStatus = {
  WAITING: { name: "در حال بررسی", type: warning },
  LOCKED: { name: "قفل شده", type: danger },
  INPROCCESS: { name: "در حال انجام", type: success },
  APPROVED: { name: "تایید/انجام شده", type: success },
  REJECTED: { name: "رد شده", type: danger },
  CANCELED: { name: "لغو شده", type: danger },
};
export const DepWthStatus = {
  processing: { name: "در حال اجرا", type: warning },
  confirming: { name: "در حال تایید", type: warning },
  cancel: { name: "لغو شده", type: danger },
  finish: { name: "انجام شده", type: success },
  //# Withdrawals Status
  WAITING: { name: "در حال بررسی", type: warning },
  LOCKED: { name: "قفل شده ", type: danger },
  INPROCCESS: { name: "در حال اجرا", type: warning },
  APPROVED: { name: "انجام شده", type: success },
  REJECTED: { name: "رد شده", type: danger },
  CANCELED: { name: "لغو شده", type: danger },
  TRANSFERACCOUNT: { name: "انتقال به اکانت", type: success },
  INPROCCESS2: { name: "در حال اجرا", type: warning },
  PENDING: { name: "در حال اجرا", type: warning },
};

export const transferTypes = {
  NETWORK: "شبکه",
  INTERNAL: "داخلی",
};

export const transactionsStatus = {
  BUY: { name: "خرید", type: success },
  SELL: { name: "فروش", type: danger },
  CHANGE: { name: "تبدیل", type: warning },
  DEPOSIT: { name: "واریز", type: success },
  WITHDRAW: { name: "برداشت", type: danger },
  PAY: { name: "پرداخت آنلاین", type: success },
  COMMISSION: { name: "کمیسیون", type: success },
  ETF: { name: "پرداخت دستی", type: success },
  AWARD: { name: "جایزه", type: success },
};

export const ticketsStauts = {
  CLOSED: { name: "بسته شده", type: danger },
  UNCHECKED: { name: "بررسی نشده", type: warning },
  WAITING: { name: "در حال بررسی", type: warning },
  ANSWERED: { name: "پاسخ داده شده", type: success },
  USER_ANSWERED: { name: "پاسخ کاربر", type: success },
};
