import React, { useRef } from "react";
import styles from "./Confirm2FaCode.module.scss";
import { useState } from "react";
import MessageModal from "../Common/MessageModal";
import { Stack } from "@mui/material";
import PaperButton from "../../components/Buttons/PaperButton";
import {
  internalTransferCoin,
  withdrawCoins,
} from "../../services/adminServices";
import { successMessage } from "../../utils/messages";
import { getWithdrawalSymbolDetails } from "./states";

const Confirm2FaCode = ({
  viewStates,
  setViewStates,
  state,
  dispatch,
  setOpen,
  external = true,
}) => {
  //# This Component for get confirm user(external or internal) to go next process(2fa)
  const [confirmDetails, setCofirmDetails] = useState(false);
  const [hasError, setHasError] = useState(false);
  const exRef = useRef(null);
  const intRef = useRef(null);

  const handleConfirmDetails = () => {
    setCofirmDetails(true);
  };
  const handleRejectDetails = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const handleConfirmExternalWithdrawal = async () => {
    if (exRef.current.value) {
      try {
        dispatch({ type: "LOADING", payload: true });
        const { status, data } = await withdrawCoins(
          JSON.stringify({
            asset: state["asset"]["symbol"],
            walletAddress: viewStates["wallet"],
            walletTag: viewStates["tag"],
            withdrawalAmount: viewStates["amount"],
            chain: state["selectedChain"]["chain"],
            token: exRef.current.value,
          })
        );
        if (data.error) {
          setHasError(data.error);
          dispatch({ type: "LOADING", payload: false });
        } else {
          successMessage(data.message);
          const response = await getWithdrawalSymbolDetails(
            state["asset"]["symbol"]
          );
          if (response["error"]) {
            dispatch({ type: "LOADING", payload: false });
          } else {
            dispatch({ type: "UPDATE", payload: response["data"] });
          }
          setTimeout(() => {
            dispatch({ type: "LOADING", payload: false });
          }, 200);
          setViewStates((prev) => ({
            ...prev,
            amount: "",
            wallet: "",
            tag: "",
          }));
          setOpen(false);
        }
      } catch {
        dispatch({ type: "LOADING", payload: false });

        setHasError("مشکل در پردازش اطلاعات");
      }
      return;
    }
    return setHasError("کد را به درستی وارد کنید");
  };

  const handleConfirmIntWithdrawal = async () => {
    if (intRef.current.value) {
      try {
        dispatch({ type: "LOADING", payload: true });
        const { status, data } = await internalTransferCoin(
          JSON.stringify({
            asset: state["asset"]["symbol"],
            transferAmount: viewStates["amount"],
            emailAddress: viewStates["email"],
            token: intRef.current.value,
          })
        );
        if (data.error) {
          setHasError(data.error);
          dispatch({ type: "LOADING", payload: false });
        } else {
          successMessage(data.message);
          const response = await getWithdrawalSymbolDetails(
            state["asset"]["symbol"]
          );
          if (response["error"]) {
            dispatch({ type: "LOADING", payload: false });
          } else {
            dispatch({ type: "UPDATE", payload: response["data"] });
          }
          setTimeout(() => {
            dispatch({ type: "LOADING", payload: false });
          }, 200);
          setViewStates((prev) => ({
            ...prev,
            amount: "",
            email: "",
          }));
          setOpen(false);
        }
      } catch {
        dispatch({ type: "LOADING", payload: false });

        setHasError("مشکل در پردازش اطلاعات");
      }
      return;
    }
    return setHasError("کد را به درستی وارد کنید");
  };
  return confirmDetails ? (
    external ? (
      <MessageModal type="success" title="کد دوعاملی (Google Authenticator)">
        <Stack
          mb={1}
          textAlign="center"
          fontSize={14}
          sx={{ direction: "rtl" }}
        >
          کد 6 رقمی ساخته شده در Google Authenticator را وارد کنید.
        </Stack>
        <Stack>
          <input
            className={styles.input}
            placeholder="کد را وارد کنید"
            ref={exRef}
          />
          {hasError && (
            <Stack sx={{ color: "var(--danger-color)", direction: "rtl" }}>
              {hasError}
            </Stack>
          )}
        </Stack>
        <Stack direction="row" mt={3} columnGap={1}>
          <PaperButton type="error" onClick={handleCancel}>
            لغو
          </PaperButton>
          <PaperButton onClick={handleConfirmExternalWithdrawal}>
            تایید
          </PaperButton>
        </Stack>
      </MessageModal>
    ) : (
      <MessageModal type="success" title="کد دوعاملی (Google Authenticator)">
        <Stack
          mb={1}
          textAlign="center"
          fontSize={14}
          sx={{ direction: "rtl" }}
        >
          کد 6 رقمی ساخته شده در Google Authenticator را وارد کنید.
        </Stack>
        <Stack>
          <input
            className={styles.input}
            placeholder="کد را وارد کنید"
            ref={intRef}
          />
          {hasError && (
            <Stack sx={{ color: "var(--danger-color)", direction: "rtl" }}>
              {hasError}
            </Stack>
          )}
        </Stack>
        <Stack direction="row" mt={3} columnGap={1}>
          <PaperButton type="error" onClick={handleCancel}>
            لغو
          </PaperButton>
          <PaperButton onClick={handleConfirmIntWithdrawal}>تایید</PaperButton>
        </Stack>
      </MessageModal>
    )
  ) : external ? (
    <MessageModal type="success" title="تایید اطلاعات">
      <Stack>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ direction: "rtl" }}
          columnGap={0.5}
          mb={1}
        >
          <span style={{ fontSize: "13px" }}>انتقال</span>
          <span style={{ fontFamily: "IranYekanBold" }}>
            {viewStates["amount"]} {state["asset"]["symbol"]}
          </span>
        </Stack>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ direction: "rtl" }}
          columnGap={0.5}
          mb={1}
        >
          <span style={{ fontSize: "13px" }}>در شبکه</span>
          <span style={{ fontFamily: "IranYekanBold" }}>
            {state["selectedChain"]["chain"]}
          </span>
        </Stack>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ direction: "rtl", wordBreak: "break-all" }}
          columnGap={1}
          mb={1}
        >
          <span
            style={{
              fontSize: "13px",
              display: "block",
              minWidth: "max-content",
            }}
          >
            به آدرس
          </span>
          <span style={{ fontFamily: "IranYekanBold" }}>
            {viewStates["wallet"]}
          </span>
        </Stack>
        <Stack mt={2} direction="row-reverse" columnGap={1}>
          <PaperButton type="success" onClick={handleConfirmDetails}>
            تایید
          </PaperButton>
          <PaperButton type="error" onClick={handleRejectDetails}>
            لغو
          </PaperButton>
        </Stack>
      </Stack>
    </MessageModal>
  ) : (
    <MessageModal type="success" title="تایید اطلاعات">
      <Stack>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ direction: "rtl" }}
          columnGap={0.5}
          mb={1}
        >
          <span style={{ fontSize: "13px" }}>انتقال</span>
          <span style={{ fontFamily: "IranYekanBold" }}>
            {viewStates["amount"]} {state["asset"]["symbol"]}
          </span>
        </Stack>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ direction: "rtl", wordBreak: "break-all" }}
          columnGap={1}
          mb={1}
        >
          <span
            style={{
              fontSize: "13px",
              display: "block",
              minWidth: "max-content",
            }}
          >
            به آدرس
          </span>
          <span style={{ fontFamily: "IranYekanBold" }}>
            {viewStates["email"]}
          </span>
        </Stack>
        <Stack mt={2} direction="row-reverse" columnGap={1}>
          <PaperButton type="success" onClick={handleConfirmDetails}>
            تایید
          </PaperButton>
          <PaperButton type="error" onClick={handleRejectDetails}>
            لغو
          </PaperButton>
        </Stack>
      </Stack>
    </MessageModal>
  );
};

export default Confirm2FaCode;
