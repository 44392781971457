import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import ButtonTitle from "../../components/Buttons/ButtonTitle";
import ModalViewSelectItem from "../Common/ModalViewSelectItem";
import styles from "./TicketsPage.module.scss";
import ImagePicker from "../../components/ImagePicker";
import { checkFileTypeImage } from "../../components/Validator/Validator";
import { recordsTicket } from "../../services/userServices";
import { errorMessage, successMessage } from "../../utils/messages";



function RecordTicket({ dispatch,TicketSubjects }) {
  const [pageProps, setPageProps] = useState({
    subject: "",
    title: "",
    desc: "",
    pic: null,
  });
  const [visibleModal, setVisibleModal] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [errors, setErros] = useState([]);

  const toggleModalVisiblity = () => {
    setVisibleModal((prev) => !prev);
  };
  const handleUpdateProps = (value, target) => {
    setPageProps((prev) => ({ ...prev, [target]: value }));
  };
  const handleChangeFile = (e) => {
    setPageProps((prev) => ({ ...prev, pic: e.target.files[0] }));
  };
  const handleDeletePicture = () => {
    setPageProps((prev) => ({ ...prev, pic: null }));
  };

  const handleSubmitTicket = async () => {
    //# First Check Inputs
    const currErrors = [];
    if (!pageProps.subject) currErrors.push("موضوع را انتخاب کنید");
    const titleLength = pageProps.title.trim().length;
    const descLentgh = pageProps.desc.trim().length;
    if (titleLength < 3 || titleLength > 30)
      currErrors.push(
        "عنوان نباید از 3 کاراکتر کمتر و از 20 کاراکتر بیشتر باشد"
      );
    if (descLentgh < 5 || descLentgh > 400)
      currErrors.push(
        "توضیحات نباید از 5 کاراکتر کمتر و از 400 کاراکتر بیشتر باشد"
      );
    if (pageProps["pic"]) {
      const valid = checkFileTypeImage(pageProps.pic.name);
      if (!valid) currErrors.push("فرمت عکس باید jpg, png و یا jpeg باشد.");
    }
    if (currErrors[0]) {
      return setErros(currErrors);
    } else {
      if (errors[0]) {
        setErros([]);
      }
    }
    setDisabledButton(true);
    try {
      const formData = new FormData();
      formData.append("department", pageProps["subject"]);
      formData.append("title", pageProps["title"]);
      formData.append("body", pageProps["desc"]);
      if (pageProps["pic"]) formData.append("file", pageProps["pic"]);
      const { status, data } = await recordsTicket(
        formData,
        !!pageProps["pic"]
      );
      console.log(status, data);
      if (status === 200) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          successMessage(data.message);
          setPageProps({ subject: "", title: "", pic: null, desc: "" });
          dispatch({ type: "REFERSH_NO_LOADING" });
        }
      }
    } catch {
    } finally {
      setDisabledButton(false);
    }
  };
  return (
    <>
      <Box mb={12}>
        <Box mt={2}>
          <ButtonTitle
            title={
              pageProps["subject"]
                ? TicketSubjects.filter((c) => c.id === pageProps.subject)[0]
                    ?.title
                : "موضوع تیکت"
            }
            onClick={toggleModalVisiblity}
          />
        </Box>
        <Box mt={2}>
          <input
            className={styles.title_input}
            placeholder="عنوان تیکت"
            value={pageProps["title"]}
            onChange={(e) => handleUpdateProps(e.target.value, "title")}
          />
        </Box>
        <Box mt={2}>
          <textarea
            className={styles.textArea_input}
            placeholder="متن تیکت"
            value={pageProps["desc"]}
            onChange={(e) => handleUpdateProps(e.target.value, "desc")}
          />
        </Box>
        <Box mt={2}>
          <ImagePicker
            onChange={handleChangeFile}
            accept="image/*"
            file={pageProps["pic"]}
            handleDeletePicture={handleDeletePicture}
          />
        </Box>
        {errors[0] && (
          <Box sx={{ direction: "rtl", color: "var(--danger-color)" }} mt={2}>
            {errors.map((ctx) => {
              return (
                <Typography key={ctx} fontSize={14}>
                  - {ctx}
                </Typography>
              );
            })}
          </Box>
        )}
        <Box mt={2}>
          <button
            className={styles.button_submit}
            onClick={handleSubmitTicket}
            disabled={disabledButton}
          >
            ثبت تیکت
          </button>
        </Box>
      </Box>
      <ModalViewSelectItem
        items={TicketSubjects}
        visible={visibleModal}
        onRequestClose={toggleModalVisiblity}
        onChange={(id) => {
          handleUpdateProps(id, "subject");
          toggleModalVisiblity();
        }}
        selected={pageProps["subject"]}
      />
    </>
  );
}

export default RecordTicket;
