import { isEmpty } from "lodash";
import { useRef } from "react";
import { useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  ShowJalaliMoment,
  convertJalaliToMilad,
} from "../../utils/convertMoment";

import styles from "./ViewList.module.scss";
import {
  DepWthStatus,
  transactionsStatus,
  transferTypes,
} from "../../utils/_appStatus";
import { IconButton, Stack, Typography } from "@mui/material";
import appIcons from "../../utils/appIcons";
import { ThreeDots } from "react-loader-spinner";
import PaperButton from "../../components/Buttons/PaperButton";
import { addComma } from "../../utils/addComma";
function ViewList(props) {
  const { state, handleShowNextItems, onClickRefreshDetails } = props;
  const ref = useRef();
  //# Controller's
  const SHOW_ITEMS = useMemo(() => {
    const values = { hasNext: false, items: [] };
    if (state["itemsList"][0]) {
      const simpleFiltered = state.itemsList.filter((ctx) => {
        return (
          ctx["coinName"].includes(state["filter"]["name"]) &&
          ctx["type"].includes(state["filter"]["type"])
        );
      });
      if (state["filter"]["startDate"]) {
        const stDate = convertJalaliToMilad(state["filter"]["startDate"]);
        const endDate = convertJalaliToMilad(state["filter"]["endDate"]);
        const ls = simpleFiltered.filter(
          (c) => c.createdAt >= stDate && c.createdAt <= endDate
        );
        values.items = ls.slice(0, state["showCount"]);
      } else {
        values.items = simpleFiltered.slice(0, state["showCount"]);
      }
      values.hasNext = values.items.length < state["itemsList"].length;
      return values;
    }
    return values;
  }, [state["filter"], state["itemsList"], state["showCount"]]);

  return (
    <div>
      {!state["isLoading"] && !state["isError"] ? (
        !isEmpty(SHOW_ITEMS["items"]) ? (
          <div className="scroll-container" ref={ref}>
            <InfiniteScroll
              dataLength={SHOW_ITEMS["items"].length}
              next={handleShowNextItems}
              hasMore={SHOW_ITEMS["hasNext"]}
              height="calc(100vh - 200px)"
              endMessage={<span style={{ marginBlock: "100px" }}></span>}
            >
              {SHOW_ITEMS["items"].map((ctx, index) => {
                const status = DepWthStatus[ctx["status"]];
                const currItemStatus = transactionsStatus[ctx["type"]];
                return (
                  <div key={ctx["id"]} className={styles.item}>
                    <div className={styles.top_item}>
                      <span
                        className={styles.type}
                        data-status={currItemStatus?.["type"]}
                      >
                        {currItemStatus?.name}
                      </span>
                      <span
                        className={styles.status}
                        data-status={status?.type ?? "danger"}
                      >
                        {status?.name}
                      </span>
                    </div>
                    <div className={styles.flexItems}>
                      <span>{ctx["faName"]}</span>
                      <span>{ctx["coinName"]}</span>
                    </div>
                    <div className={styles.flexItems}>
                      <span>{ShowJalaliMoment(ctx["createdAt"])}</span>
                      <span>زمان</span>
                    </div>
                    <div className={styles.flexItems}>
                      <span data-color={currItemStatus?.["type"]}>
                        {addComma(parseFloat(ctx?.amount ?? 0))}
                      </span>
                      <span>مقدار</span>
                    </div>
                    <div className={styles.flexItems}>
                      <span>{addComma(parseFloat(ctx?.price ?? 0))}</span>
                      <span>قیمت</span>
                    </div>
                    <div className={styles.flexItems}>
                      <span>{addComma(parseFloat(ctx?.totalPrice ?? 0))}</span>
                      <span>موجودی</span>
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        ) : (
          <Stack
            alignItems="center"
            justifyContent="center"
            height="calc(100vh - 300px)"
          >
            <Typography
              sx={{ backgroundColor: "var(--dangerOpacity-color)" }}
              width={1}
              color="var(--danger-color)"
              borderRadius={0.5}
              paddingY={1}
              textAlign="center"
              fontSize={14}
            >
              اطلاعاتی یافت نشد
            </Typography>
          </Stack>
        )
      ) : state["isError"] ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          height="calc(100vh - 300px)"
        >
          <Typography color="var(--danger-color)" fontSize={14}>
            مشکلی در دریافت اطلاعات رخ داده است
          </Typography>
          <Stack mt={2}>
            <PaperButton onClick={onClickRefreshDetails}>تلاش مجدد</PaperButton>
          </Stack>
        </Stack>
      ) : (
        <Stack
          alignItems="center"
          justifyContent="center"
          height="calc(100vh - 300px)"
        >
          <ThreeDots color="var(--primary-color)" width={40} />
        </Stack>
      )}
    </div>
  );
}

export default ViewList;
