import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { addCommaWithRialPrice } from "../../../utils/addComma";
import { useHistory, useParams } from "react-router";
import { Helmet } from "react-helmet";
//ICON's
import styles from "./LayoutCoin.module.scss";
import { isEmpty } from "lodash";
import { Collapse } from "@mui/material";
import appIcons from "../../../utils/appIcons";
//# Components
import SwapCoins from "./SwapCoins";
import { LoadingPage } from "../../LoadingPage/LoadingPage";
import { AddSocketPrice } from "../../../actions/appSocketPriceAction";
import { compareAsc, compareDesc } from "../../../utils/valueSort";
import {
  AddSocketBuyDepth,
  AddSocketSellDepth,
} from "../../../actions/AppSocketDepthsActions";
import { showBetterPrice } from "../../../utils/showBetterPrice";
import serviceConfig from "../../../services/config.json";

const showLowerPrice = (minBuy, priceBuy, minSell, priceSell, type) => {
  if (type === "buy") {
    return addCommaWithRialPrice(minBuy * priceBuy);
  }
  return addCommaWithRialPrice(minSell * priceSell);
};

const showLowerAmount = (buy, sell, type) => {
  if (type === "buy") return addCommaWithRialPrice(buy);
  return parseFloat(addCommaWithRialPrice(sell));
};

let WEB_SOCKET_COUNTER = 0;
export default function LayoutCoin({ children }) {
  const history = useHistory();
  const { name } = useParams();
  const dispatch = useDispatch();
  const coin = useSelector((state) => state.coinInfo.data);
  const marketListReducer = useSelector((state) => state.tradesList);
  const AppSocketInfo = useSelector((state) => state.appSocketInfo);
  const socketReducer = useSelector((state) => state.appSocket);
  const loadingBar = useSelector((state) => state.loadingBar.loading);
  const [showMore, setShowMore] = useState(false);
  const [showList, setShowList] = useState(false);
  const orderType = history.location.pathname.split("/")[1];

  const AREA_TYPE = useMemo(() => {
    const searchArray = history.location.search.split("?");
    if (searchArray[1]) {
      const TypeIsExist = searchArray[1].split("type=");
      switch (TypeIsExist[1].toUpperCase()) {
        case "IRT":
          return "IRT";
        case "USDT":
          return "USDT";
        default:
          return "IRT";
      }
    }
    return "IRT";
  }, [history.location]);

  const currentCoinSocket = useMemo(() => {
    if (!isEmpty(coin)) {
      return socketReducer[coin.symbol + AREA_TYPE] || {};
    } else {
      return {};
    }
  }, [socketReducer, AREA_TYPE, coin]);

  const currentCoinInAPIList = useMemo(() => {
    if (marketListReducer.hasOwnProperty("list") && !isEmpty(coin)) {
      return (
        marketListReducer.list.filter(
          (c) => c.market === coin.symbol + AREA_TYPE
        )[0] || {}
      );
    } else {
      return {};
    }
  }, [coin, AREA_TYPE, marketListReducer]);

  useEffect(() => {
    if (AREA_TYPE !== "IRT") {
      if (marketListReducer.hasOwnProperty("list") && !isEmpty(coin)) {
        if (name === coin.symbol) {
          const hasMarket = marketListReducer.list.filter(
            (c) => c.market === coin.symbol + AREA_TYPE
          )[0];
          if (hasMarket === undefined) {
            history.goBack();
          }
        }
      }
    }
  }, [marketListReducer, AREA_TYPE, coin]);

  const goBack = () => {
    setShowList(false);
    history.goBack();
  };
  const handleToggleList = () => {
    setShowList((prev) => !prev);
  };

  const showMinBuyDollarAmount = useMemo(() => {
    if (
      AREA_TYPE === "USDT" &&
      !isEmpty(currentCoinInAPIList) &&
      !isEmpty(currentCoinSocket)
    ) {
      let num = currentCoinInAPIList.least_amount * currentCoinSocket.last;
      if (!(num % 2)) {
        return num;
      } else {
        return num.toFixed(2);
      }
    }
    return 0;
  }, [currentCoinInAPIList, currentCoinSocket, AREA_TYPE]);

  //# Handle Get Data From Socket And Logic's

  useEffect(() => {
    let prevState = null;
    let newNumbers = [];
    let newSellNumbers = [];
    if (
      AppSocketInfo.CONNECTION &&
      AREA_TYPE !== "IRT" &&
      !isEmpty(currentCoinInAPIList)
    ) {
      AppSocketInfo.CONNECTION.send(
        JSON.stringify({
          id: ++WEB_SOCKET_COUNTER,
          method: "state.subscribe",
          params: [],
        })
      );
      AppSocketInfo.CONNECTION.send(
        JSON.stringify({
          id: ++WEB_SOCKET_COUNTER,
          method: "depth.subscribe",
          params: [
            currentCoinInAPIList.market,
            50,
            currentCoinInAPIList.default_merge,
          ],
        })
      );
      // coin.symbol
      AppSocketInfo.CONNECTION.onmessage = (message) => {
        const parseDeals = JSON.parse(message.data);
        if (parseDeals.method === "state.update") {
          if (!prevState) {
            if (parseDeals.params[0]) {
              prevState = parseDeals.params[0];
              dispatch(AddSocketPrice(prevState));
            }
          } else {
            for (const key in parseDeals.params[0]) {
              const element = parseDeals.params[0][key];
              prevState[key] = element;
            }
            dispatch(AddSocketPrice(prevState));
          }
        }
        if (parseDeals.method === "depth.update") {
          if (parseDeals.params[2] !== currentCoinInAPIList.market) {
            AppSocketInfo.CONNECTION.send(
              JSON.stringify({
                id: ++WEB_SOCKET_COUNTER,
                method: "depth.unsubscribe",
                params: [],
              })
            );
            AppSocketInfo.CONNECTION.send(
              JSON.stringify({
                id: ++WEB_SOCKET_COUNTER,
                method: "depth.subscribe",
                params: [
                  currentCoinInAPIList.market,
                  50,
                  currentCoinInAPIList.default_merge,
                ],
              })
            );
          } else {
            const asks = parseDeals.params[1].asks || [];
            const bids = parseDeals.params[1].bids || [];
            if (parseDeals.params[0]) {
              newNumbers = [];
              newSellNumbers = [];
              bids.map(function (value) {
                newNumbers.push({
                  i: value[0],
                  a: value[1],
                });
              });
              asks.map(function (value) {
                newSellNumbers.push({
                  i: value[0],
                  a: value[1],
                });
              });
            } else {
              if (bids.length > 0) {
                bids.map(function (value) {
                  newNumbers.find((o, i) => {
                    if (o.i === value[0]) {
                      newNumbers.splice(i, 1);
                      return true;
                    }
                  });
                  if (parseFloat(value[1]) !== 0) {
                    newNumbers.push({
                      i: value[0],
                      a: value[1],
                    });
                  }
                });
              }
              if (asks.length > 0) {
                asks.map(function (value) {
                  newSellNumbers.find((o, i) => {
                    if (o.i === value[0]) {
                      newSellNumbers.splice(i, 1);
                      return true;
                    }
                  });
                  if (parseFloat(value[1]) !== 0) {
                    newSellNumbers.push({
                      i: value[0],
                      a: value[1],
                    });
                  }
                });
              }
            }
            var SUM_ALL_BUYERS_FAST_ORDER = 0;
            var PRICE_FAST_ORDER = 0;
            newNumbers.sort(compareAsc);
            newNumbers.forEach(function addsumAllBuyers(item, index, arr) {
              SUM_ALL_BUYERS_FAST_ORDER += parseFloat(item.a);
              PRICE_FAST_ORDER += parseFloat(item.i * item.a);
              const avg = PRICE_FAST_ORDER / SUM_ALL_BUYERS_FAST_ORDER;
              arr[index] = {
                ...item,
                s: SUM_ALL_BUYERS_FAST_ORDER,
                avg: avg,
              };
            });
            //###
            dispatch(AddSocketBuyDepth(newNumbers));
            newSellNumbers.sort(compareDesc);
            var SUM_ALL_SELLERS_FAST_ORDER = 0;
            var PRICE_SELL_FAST_ORDER = 0;
            newSellNumbers.forEach(function addsumAllBuyers(item, index, arr) {
              SUM_ALL_SELLERS_FAST_ORDER += parseFloat(item.a);
              PRICE_SELL_FAST_ORDER += parseFloat(item.i * item.a);
              const avg = PRICE_SELL_FAST_ORDER / SUM_ALL_SELLERS_FAST_ORDER;
              arr[index] = { ...item, s: SUM_ALL_SELLERS_FAST_ORDER, avg: avg };
            });
            dispatch(AddSocketSellDepth(newSellNumbers));
          }
        }
      };
    }
    return () => {
      prevState = null;
      if (AppSocketInfo.CONNECTION) {
        AppSocketInfo.CONNECTION.send(
          JSON.stringify({
            id: ++WEB_SOCKET_COUNTER,
            method: "state.unsubscribe",
            params: [],
          })
        );
        AppSocketInfo.CONNECTION.send(
          JSON.stringify({
            id: ++WEB_SOCKET_COUNTER,
            method: "depth.unsubscribe",
            params: [],
          })
        );
      }
    };
  }, [dispatch, AppSocketInfo, AREA_TYPE, currentCoinInAPIList]);

  const SEOItemsList = useMemo(() => {
    if (!isEmpty(coin)) {
      return {
        name: `${coin.name} (${coin.faName})`,
        alternateName: `${coin.symbol}`,
        offers: [
          {
            "@type": "Offer",
            price: `${coin.usdtPrice}`,
            priceCurrency: "USD",
          },
          {
            "@type": "Offer",
            price: `${addCommaWithRialPrice(coin.arzfi_buy_price)}`,
            priceCurrency: "IRR",
          },
        ],
        additionalProperty: [
          {
            "@type": "PropertyValue",
            name: "Persian Name",
            value: `${coin.faName}`,
          },
        ],
      };
    }
    return null;
  }, [coin]);

  return (
    <>
      {!isEmpty(coin) && (
        <Helmet
          title={`خرید ارز ${coin.faName} (${coin.symbol}) - فروش ارز ${coin.faName}(
          ${coin.name}) - تبدیل ${coin.faName} / صرافی ارزفی (ArzFi Exchange)`}
        >
          <meta charSet="utf-8" />
          <link
            rel="canonical"
            href={`https://arzfi.com/market/${coin.symbol}`}
          />
          <meta
            name="description"
            content={`خرید و فروش و تبدیل ارز ${coin.faName} به بیش از ۵۰۰ ارز دیچیتال
            قیمت ${coin.faName} 
            نمودار قیمت ${coin.faName}
            ${coin.name}
            `}
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="Arzfi.com" />
          <meta name="twitter:creator" content="@ArzfiEx" />
          <meta property="og:type" content="website" />
          <meta
            name="keywords"
            content={`خرید ${coin.faName}, فروش ${coin.faName}, تبدیل ${coin.faName}, ${coin.name}, کیف پول, نمودار ${coin.name}`}
          />
          {!isEmpty(SEOItemsList) && (
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "FinancialProduct",
                ...SEOItemsList,
              })}
            </script>
          )}
        </Helmet>
      )}
      <SwapCoins showList={showList} setShowList={setShowList} />
      {loadingBar && <LoadingPage />}
      <div className={`${styles.container}`}>
        <div className={styles.header_container}>
          <div className={styles.back_btn} onClick={goBack}>
            {appIcons("ArrowBackIcon")}
          </div>
          <div className={styles.name} onClick={handleToggleList}>
            <h2>
              {coin?.symbol} / {AREA_TYPE}
            </h2>
            <span className={styles.more}>{appIcons("ArrowMoreIcon")}</span>
          </div>
          <div className={styles.icon} onClick={handleToggleList}>
            <img
              src={`${serviceConfig.image_url}/${coin?.symbol}.png`}
              alt={coin?.symbol}
              width={40}
              height={40}
            />
          </div>
        </div>
        {!isEmpty(coin) && (
          <div className={styles.middle_layout}>
            {/* Head Start */}
            <div className={styles.head_details}>
              <div className={styles.head_details_left}>
                <Link to={`/market/about/${coin.symbol}`}>
                  <div className={styles.desc}>توضیحات</div>
                </Link>
                <div
                  className={`eng-number-font ${styles.percent24}`}
                  data-green={coin.percent_change_24h >= 0}
                >
                  {coin.percent_change_24h}%
                </div>
              </div>
              <div>
                <h1>{coin.faName}</h1>
              </div>
            </div>
            {/* Head End */}
            {/* Body Start */}
            <div className={styles.body}>
              <div className={styles.item} data-svgcolor="true">
                <div className={styles.title}>قیمت</div>
                <div className={styles.desc}>
                  <span>{AREA_TYPE} </span>
                  <span className="eng-number-font">
                    {AREA_TYPE === "IRT"
                      ? addCommaWithRialPrice(
                          orderType === "buy"
                            ? coin.arzfi_price
                            : coin.arzfi_buy_price
                        )
                      : currentCoinSocket.last || 0}
                  </span>
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.title}>
                  حداقل مقدار {orderType === "buy" ? "خرید" : "فروش"}:
                </div>
                <div className={styles.desc}>
                  <span>{coin.symbol} </span>
                  <span className="eng-number-font" data-color={orderType}>
                    {AREA_TYPE === "IRT"
                      ? showLowerAmount(
                          coin.minAmount,
                          coin.minSellAmount,
                          orderType
                        )
                      : currentCoinInAPIList.least_amount || 0}
                  </span>
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.title}>
                  حداقل مبلغ {orderType === "buy" ? "خرید" : "فروش"}:
                </div>
                <div className={styles.desc}>
                  <span>{AREA_TYPE} </span>
                  <span className="eng-number-font" data-color={orderType}>
                    {AREA_TYPE === "IRT"
                      ? showLowerPrice(
                          coin.minAmount,
                          coin.arzfi_price,
                          coin.minSellAmount,
                          coin.arzfi_buy_price,
                          orderType
                        )
                      : showMinBuyDollarAmount}
                  </span>
                </div>
              </div>
            </div>
            {/* Body End */}
            {/* More Details Start */}
            <Collapse in={showMore}>
              <div className={styles.body}>
                <div className={styles.item}>
                  <div className={styles.title}>حجم بازار:</div>
                  <div className={styles.desc} style={{ direction: "ltr" }}>
                    <span>$ </span>
                    <span className="eng-number-font">
                      {addCommaWithRialPrice(coin.marketcap)}
                    </span>
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.title}>معاملات روزانه:</div>
                  <div className={styles.desc} style={{ direction: "ltr" }}>
                    <span>$ </span>
                    <span className="eng-number-font">
                      {addCommaWithRialPrice(coin.volume24h)}
                    </span>
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.title}>سکه در گردش:</div>
                  <div className={styles.desc} style={{ direction: "ltr" }}>
                    <span>{coin.symbol} </span>
                    <span className="eng-number-font">
                      {addCommaWithRialPrice(coin.circulationSup)}
                    </span>
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.title}>عرضه کل:</div>
                  <div className={styles.desc} style={{ direction: "ltr" }}>
                    <span>{coin.symbol} </span>
                    <span className="eng-number-font">
                      {addCommaWithRialPrice(coin.totalSup)}
                    </span>
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.title}>رتبه:</div>
                  <div className={styles.desc} style={{ direction: "ltr" }}>
                    <span># </span>
                    <span className="eng-number-font">{coin.cmcRank}</span>
                  </div>
                </div>
              </div>
            </Collapse>
            {/* More Details End */}

            {/* More Button Start */}
            <div
              className={styles.more_btn}
              onClick={() => setShowMore((prev) => !prev)}
              data-active={showMore}
            >
              {appIcons("ArrowDownIcon")}
            </div>
            {/* More Button End */}
          </div>
        )}
        <div>{children}</div>
      </div>
    </>
  );
}
