import * as React from "react";
import NumberFormat from "react-number-format";
import TextField from "@mui/material/TextField";
import { InputAdornment, OutlinedInput } from "@mui/material";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, suffix, prefix, decimalScale, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      allowNegative={false}
      decimalScale={decimalScale}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      prefix={prefix}
      suffix={suffix}
    />
  );
});

export default function MuiInputOne(props) {
  const {
    amount,
    setAmount,
    decimalScale,
    suff,
    pref,
    placeholder,
    adorment,
    xs,
    disabled,
    onFoucsInput,
  } = props;
  const handleChange = (event) => {
    setAmount(event.target.value);
  };

  return (
    <span className="input-trade-type-1">
      <TextField
        onFocus={onFoucsInput}
        fullWidth
        value={amount}
        onChange={handleChange}
        disabled={disabled}
        autoComplete="off"
        name="numberformat"
        id="formatted-numberformat-input"
        InputProps={{
          inputComponent: NumberFormatCustom,
          inputProps: {
            suffix: suff,
            prefix: pref,
            decimalScale,
            style: { xs },
          },
          endAdornment: adorment ? (
            <InputAdornment position="end">{adorment}</InputAdornment>
          ) : null,
        }}
        variant="outlined"
        placeholder={placeholder}
      />
    </span>
  );
}
