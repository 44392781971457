import { Box, Stack } from "@mui/material";
import React from "react";
import styles from "./WithdrawalCoinPage.module.scss";
import serviceConfig from "../../services/config.json";
import { addComma } from "../../utils/addComma";

const assetAmounts = (item) => {
  const res = { available: "0.00", locked: "0.00", balance: "0.00" };
  if (item["symbol"]) {
    const numFixed = parseFloat(item["balance"] - item["locked"]);
    const currBalance = parseFloat(item["balance"]);
    if (currBalance < 1) {
      res.balance = currBalance;
    } else {
      res.balance = addComma(currBalance);
    }
    if (numFixed < 1) {
      res.available = numFixed;
    } else {
      res.available = addComma(numFixed);
    }
    res.locked = parseFloat(item["locked"]);
    return res;
  }
  return res;
};
function SymbolInfo({ state, onRequestUpdate }) {
  return (
    <Stack>
      <Stack alignItems="center" justifyContent="center">
        <Box className={styles.imageHolder}>
          {state["asset"]["symbol"] && (
            <img
              src={`${serviceConfig.image_url}/${state["asset"]["symbol"]}.png`}
              alt={`${state["asset"]["symbol"]}`}
            />
          )}
        </Box>
      </Stack>
      <Stack
        className={styles.namesContainer}
        flexDirection="row"
        columnGap={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <span>
          {state["asset"]["name"] ?? "--"} - {state["asset"]["symbol"] ?? "--"}
        </span>
        <span data-rtl={true}>{state["asset"]["faName"] ?? "--"}</span>
      </Stack>
      <Stack className={styles.amountContainer}>
        <div className={styles.item}>
          <span>موجودل کل</span>
          <p>{assetAmounts(state["asset"])["balance"]}</p>
        </div>
        <div className={styles.item}>
          <span>قفل شده</span>
          <p>{assetAmounts(state["asset"])["locked"]}</p>
        </div>
        <div className={styles.item}>
          <span>قابل برداشت</span>
          <p>{assetAmounts(state["asset"])["available"]}</p>
        </div>
        <Stack alignItems="flex-start">
          <button onClick={onRequestUpdate}>برروزرسانی</button>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default SymbolInfo;
