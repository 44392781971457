import { Stack } from "@mui/system";

//# Styles ##
import styles from "./InstallPwAIOS.module.scss";
import Logo from "../../styles/pics/logo.png";
import { Typography } from "@mui/material";
import appIcons from "../../utils/appIcons";
import Cookies from "js-cookie";

export default function InstallPWAIOS(props) {
  const handleCancelPWA = () => {
    Cookies.set("user_not_allow_install_pwa_ios", true, { expires: 7 });
    props.setShowInstallPWA(false);
  };
  return (
    <Stack className={styles.container}>
      <Stack className={styles.logo} alignItems="center" marginTop={4}>
        <img src={Logo} alt="ArzFi LoGo" width={100} height={70} />
      </Stack>
      <Stack className={styles.heading}>
        <Typography component="strong">
          نصب نسخه تحت وب ارزفی (ArzFi)
        </Typography>
        <Typography component="strong" className={styles.v}>
          V{props.APP_VERSION}
        </Typography>
      </Stack>
      <Stack className={styles.box}>
        <Typography component="p">
          1.در نوار پایین گوشی دکمه {appIcons("IosShareIcon")} کلیک کنید.
        </Typography>
        <Typography component="p">
          2.منوی باز شده را به بالا بکشید و دکمه 'Add To Home Screen' را کلیلک
          کنید.
        </Typography>
        <Typography component="p">
          3.در آخر در بالای صفحه دکمه Add را کلیک کنید.
        </Typography>
      </Stack>
      <Stack className={styles.button}>
        <button onClick={handleCancelPWA}>انصراف</button>
      </Stack>
      <Stack className={styles.guid} alignItems="center">
        {appIcons("ArrowBackIcon")}
      </Stack>
    </Stack>
  );
}
