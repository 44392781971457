const PROFILE_ROUTES = [
  {
    id: "account",
    title: "حساب کاربری",
    route: null,
    icon: "PersonAccountIcon",
    children: [
      {
        id: "info_account",
        title: "اطلاعات حساب کاربری",
        route: "/my-profile/info",
        icon: "",
      },
      {
        id: "pass_account",
        title: "تغییر رمز عبور",
        route: "/my-profile/change-pass",
        icon: "",
      },
    ],
  },
  {
    id: "wallet",
    title: "کیف پول",
    route: "/my-wallet",
    icon: "WalletOutIcon",
  },
  {
    id: "finance",
    title: "مالی و حسابداری",
    route: null,
    icon: "FinanceIcon",
    children: [
      {
        id: "cart",
        title: "کارت های بانکی",
        route: "/my-profile/my-carts",
        icon: "",
      },
      {
        id: "addMoney",
        title: "افزایش اعتبار",
        route: "/my-profile/increase-balance",
        icon: "",
      },
      {
        id: "wthMoeny",
        title: "برداشت اعتبار",
        route: "/my-profile/decrease-balance",
        icon: "",
      },
      {
        id: "buyHistory",
        title: "تاریخچه خرید من",
        route: "/my-profile/orders?f=buy",
        icon: "",
      },
      {
        id: "sellHistory",
        title: "تاریخچه فروش من",
        route: "/my-profile/orders?f=sell",
        icon: "",
      },
      {
        id: "depHistory",
        title: "تاریخچه واریزها",
        route: "/my-profile/transactions_two?f=deposit",
        icon: "",
      },
      {
        id: "wthHistory",
        title: "تاریخچه برداشت ها",
        route: "/my-profile/transactions_two?f=withdraw",
        icon: "",
      },
      {
        id: "transHistory",
        title: "تاریخچه تراکنش ها",
        route: "/my-profile/transactions",
        icon: "",
      },
    ],
  },
  {
    id: "addMoney",
    title: "افزایش اعتبار",
    route: "/my-profile/increase-balance",
    icon: "IncreaseBalanceIcon",
  },
  {
    id: "markets",
    title: "بازار ها",
    route: "/market",
    icon: "BankAccountIcon",
  },
  {
    id: "tickets",
    title: "ثبت تیکت",
    route: "/tickets?view=record",
    icon: "AddMessageIcon",
  },
  {
    id: "show-tickets",
    title: "تیکت ها",
    route: "/tickets",
    icon: "MessageBoxIcon",
  },
  {
    id: "Invite",
    title: "دعوت دوستان",
    route: "/refer",
    icon: "AddPersonIcon",
  },
  {
    id: "support",
    title: "پشتیبانی",
    route: null,
    icon: "SupportIcon",
    onClick: "handleOpenImber",
  },
  {
    id: "guid",
    title: "راهنمای استفاده",
    route: "/app-guid",
    icon: "HelperIcon",
  },
  {
    id: "faq",
    title: "سوالات متداول",
    route: "/faq",
    icon: "FaqIcon",
  },
  {
    id: "logout",
    title: "خروج",
    route: null,
    onClick: "handleQuit",
    icon: "ExitIcon",
  },
];

export { PROFILE_ROUTES };