import React, { useEffect, useLayoutEffect } from "react";
import { useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useLocation, withRouter } from "react-router";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
//Application global components
import ApplicationSetting from "./components/market/ApplicationSetting/ApplicationSetting";
import FAQ from "./components/market/FAQ/FAQ";
import Terms from "./components/Terms";
//Authorization Components
import AuthLayout from "./components/Layouts/authLayout/AuthLayout";
import Login from "./components/authorization/login/Login";
import Register from "./components/authorization/register/Register";
import AuthUserContext from "./context/AuthUserContext";
import RecoveryPass from "./components/authorization/recoveryPass/RecoveryPass";
//Market Components
import MarketLayout from "./components/Layouts/marketLayout/MarketLayout";
// import Home from "./components/market/Home/Home";
import BuyCoin from "./components/market/TradeCoin/BuyCoin";
import SellCoin from "./components/market/TradeCoin/SellCoin";
import DescCoin from "./components/market/AboutCoin/DescCoin";
import Convert from "./components/market/ProfessionalMarket/Convert/Convert";
import ConvertCustomPrice from "./components/market/ProfessionalMarket/Convert/ConvertCustomPrice";
import DepositCoin from "./components/market/DepositCoin/DepositCoin";
import WithdrawalCoin from "./pages/WithdrawalCoin";
//* === PROF MARKET =====
import Trade from "./components/market/ProfessionalMarket/Trade";
import CoinMarket from "./components/market/ProfessionalMarket/Trade/CoinMarket";
import MarketOrders from "./components/market/ProfessionalMarket/Trade/MarketOrders";
//* === PROF MARKET =====

//User-Profile components
import UserProfile from "./components/market/UserProfile/UserProfile";
import IncreaseBalance from "./components/market/UserProfile/IncreaseBalance/IncreaseBalance";
import DecreaseUserBalance from "./components/market/UserProfile/DecreaseUserBalance/DecreaseUserBalance";
import CardsAccountInfo from "./components/market/UserProfile/CardsAccountInfo/CardsAccountInfo";
import ChangePassword from "./components/market/UserProfile/ChangePassword/ChangePassword";
import TransactionHistory from "./pages/TransactionHistory";
import DsWsHistory from "./pages/DsWsHistory";
import OrdersHistory from "./pages/OrdersHistory";
import Wallet from "./pages/Wallet";
import Tickets from "./pages/Tickets";
import Referral from "./pages/Referral";
import Logout from "./pages/Logout";

//Authentication  components
import Authentication from "./components/market/UserProfile/Authentication/Authentication";
import PersonInfo from "./components/market/UserProfile/Authentication/PersonInfo";
import PersonCardId from "./components/market/UserProfile/Authentication/PersonCardId";
import PersonPic from "./components/market/UserProfile/Authentication/PersonPic";
import TwoFactor from "./components/market/UserProfile/Authentication/TwoFactor/TwoFactor";
import AuthGuid from "./components/market/UserProfile/Authentication/AuthGuid/AuthGuid";
import ApplicationGuid from "./components/market/ApplicationGuid/ApplicationGuid";
import AccountDetails from "./pages/AccountDetails";
//Other Pages
import PaymentStatus2 from "./components/redirectPages/PaymentStatus2";
import PaymentStatus3 from "./components/redirectPages/PaymentStatus3";
import PaymentStatus from "./components/redirectPages/PaymentStatus";
import Page404 from "./pages/Page404";
import Privacy from "./components/Privacy";
import MarketLists from "./pages/MarketLists";
import HomeV2 from "./pages/HomeV2";
import Fees from "./pages/Fees";
import Notifications from "./pages/Notifications";
import About from "./pages/About";

//styles
import "react-toastify/dist/ReactToastify.css";
import "react-alice-carousel/lib/scss/alice-carousel.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { ToastContainer } from "react-toastify";

//Detect device
import { isIOS, isIOS13, isSafari } from "react-device-detect";
import Cookies from "js-cookie";
import InstallPWAIOS from "./components/InstallPwAIOS";
import WebSocketConnection from "./components/WebSocketConnection";
import { LoadingLogo } from "./components/LoadingPage/LoadingPage";
import { usersProfile } from "./services/userServices";

//#Socekt

function App(props) {
  const APP_VERSION = "5.2.7";
  const [showInstallPWA, setShowInstallPWA] = useState(false);
  const [showLogo, setShowLogo] = useState(true);
  const userCode = useSelector((state) => state.userId);
  const appTheme = useSelector((state) => state.appTheme);
  const location = useLocation();

  //## Check Device Is Iphone And Usage With PWA Or NOT ##
  useLayoutEffect(() => {
    if (isSafari || isIOS || isIOS13) {
      if (
        window !== undefined &&
        !window.matchMedia("(display-mode: standalone)").matches
      ) {
        const PermissionShowInstallIOS = !!Cookies.get(
          "user_not_allow_install_pwa_ios"
        );
        if (!PermissionShowInstallIOS) {
          setShowInstallPWA(true);
        }
      }
    }
    const LogoTimer = setTimeout(() => {
      setShowLogo(false);
    }, 1800);

    return () => {
      clearTimeout(LogoTimer);
    };
  }, []);
  useEffect(() => {
    switch (appTheme) {
      case "light":
        document.body.classList.add(appTheme);
        document.body.classList.remove("dark");
        document.documentElement.setAttribute("data-theme", appTheme);
        break;
      case "dark":
        document.body.classList.add(appTheme);
        document.body.classList.remove("light");
        document.documentElement.setAttribute("data-theme", appTheme);
        break;
      default:
        break;
    }
  }, [appTheme]);
  //
  useEffect(() => {
    if (
      location.pathname.includes("refer_code") &&
      !localStorage.getItem("refer_code")
    ) {
      localStorage.setItem(
        "refer_code",
        location.pathname.split("/refer_code=")[1]
      );
    }
  }, []);

  //# -->  Update Devices Usage PWA Version
  useEffect(() => {
    props.history.listen(() => {
      if (window.swUpdateReady) {
        window.swUpdateReady = false;
        window.stop();
        window.location.reload();
      }
    });
  }, []);

  useEffect(() => {
    if (userCode.id !== "default" && userCode.userHashCode) {
      (async () => {
        try {
          const { data, status } = await usersProfile();
          if (status === 200) {
            window.Raychat.setUser({
              email: data.user.email,
              name: data.user.fullname,
              phone: data.user.phoneNumber,
              updateOnce: true,
            });
          }
        } catch (ex) {}
      })();
    }
  }, [userCode]);
  return (
    <div>
      {showLogo && <LoadingLogo />}
      <WebSocketConnection />
      {showInstallPWA && (
        <InstallPWAIOS
          APP_VERSION={APP_VERSION}
          setShowInstallPWA={setShowInstallPWA}
        />
      )}
      <ToastContainer rtl pauseOnFocusLoss={false} hidden limit={3} />
      <Switch>
        <Route path={["/authorization"]}>
          <AuthLayout version={APP_VERSION}>
            <AuthUserContext>
              <Switch>
                <Route
                  path="/authorization"
                  exact
                  render={() => <Redirect to="/authorization/login" />}
                />

                <Route
                  path="/authorization/register"
                  render={() =>
                    !isEmpty(userCode) && userCode.id !== "default" ? (
                      <Redirect to="/" />
                    ) : (
                      <Register />
                    )
                  }
                />
                <Route
                  path="/authorization/login"
                  render={() =>
                    !isEmpty(userCode) && userCode.id !== "default" ? (
                      <Redirect to="/" />
                    ) : (
                      <Login />
                    )
                  }
                />
                <Route
                  path="/authorization/recovery-pass"
                  render={() =>
                    !isEmpty(userCode) && userCode.id !== "default" ? (
                      <Redirect to="/" />
                    ) : (
                      <RecoveryPass />
                    )
                  }
                />
              </Switch>
            </AuthUserContext>
          </AuthLayout>
        </Route>
        <Route path={["/"]}>
          <MarketLayout>
            <Route
              path="/login"
              exact
              render={() => <Redirect to="/authorization/login" />}
            />
            <Route
              path="/register"
              render={() => <Redirect to="/authorization/register" />}
            />
            <Switch>
              <Route path="/" exact render={() => <HomeV2 />} />
              <Route path="/market" exact render={() => <MarketLists />} />
              <Route path="/buy/:name" render={() => <BuyCoin />} />
              <Route path="/sell/:name" render={() => <SellCoin />} />
              <Route
                path="/my-wallet"
                render={() =>
                  !isEmpty(userCode) ? (
                    <Wallet />
                  ) : (
                    <Redirect to="/authorization/login" />
                  )
                }
              />
              <Route path="/privacy" exact render={() => <Privacy />} />
              <Route
                path="/market/about/:name"
                exact
                render={() => <DescCoin />}
              />
              <Route
                path="/withdraw/:name"
                render={() =>
                  !isEmpty(userCode) ? (
                    <WithdrawalCoin />
                  ) : (
                    <Redirect to="/authorization/login" />
                  )
                }
              />
              {/* <Route
                path="/professional-market"
                render={() => <ProfessionalMarket />}
              /> */}
              <Route
                path="/deposit/:name"
                render={() =>
                  !isEmpty(userCode) ? (
                    <DepositCoin />
                  ) : (
                    <Redirect to="/authorization/login" />
                  )
                }
              />
              <Route path="/convert" render={() => <Convert />} />
              <Route
                path="/convert-custom"
                render={() => <ConvertCustomPrice />}
              />
              <Route path="/market/trade" exact render={() => <Trade />} />
              <Route path="/market/trade/:coin" render={() => <CoinMarket />} />
              <Route
                path="/market/history"
                exact
                render={() =>
                  !isEmpty(userCode) ? (
                    <MarketOrders />
                  ) : (
                    <Redirect to="/authorization/login" />
                  )
                }
              />
              <Route
                path="/market/:name"
                exact
                render={() => {
                  let hasType = window.location.href.split("type=")[1];
                  let type;
                  switch (hasType) {
                    case "buy":
                      type = "buy";
                      break;
                    case "sell":
                      type = "sell";
                      break;
                    default:
                      type = "buy";
                      break;
                  }
                  return (
                    <Redirect
                      to={`/${type}/${window.location.pathname.split("/")[2]}`}
                    />
                  );
                }}
              />

              <Route
                path="/tickets"
                exact
                render={() =>
                  !isEmpty(userCode) ? (
                    <Tickets />
                  ) : (
                    <Redirect to="/authorization/login" />
                  )
                }
              />
              <Route
                path="/my-profile"
                exact
                render={() =>
                  !isEmpty(userCode) ? (
                    <UserProfile />
                  ) : (
                    <Redirect to="/authorization/login" />
                  )
                }
              />
              <Route
                path="/my-profile/info"
                render={() =>
                  !isEmpty(userCode) ? <AccountDetails /> : <Redirect to="/" />
                }
              />
              <Route
                path="/my-profile/increase-balance"
                render={() =>
                  !isEmpty(userCode) ? <IncreaseBalance /> : <Redirect to="/" />
                }
              />
              <Route
                path="/my-profile/decrease-balance"
                render={() =>
                  !isEmpty(userCode) ? (
                    <DecreaseUserBalance />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
              <Route
                path="/my-profile/my-carts"
                render={() =>
                  !isEmpty(userCode) ? (
                    <CardsAccountInfo />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
              <Route
                path="/refer"
                render={() =>
                  !isEmpty(userCode) ? <Referral /> : <Redirect to="/" />
                }
              />
              <Route
                path="/my-profile/change-pass"
                render={() =>
                  !isEmpty(userCode) ? <ChangePassword /> : <Redirect to="/" />
                }
              />
              <Route
                path="/my-profile/transactions"
                render={() =>
                  !isEmpty(userCode) ? (
                    <TransactionHistory />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
              <Route
                path="/my-profile/orders"
                render={() =>
                  !isEmpty(userCode) ? <OrdersHistory /> : <Redirect to="/" />
                }
              />
              <Route
                path="/my-profile/transactions_two"
                render={() =>
                  !isEmpty(userCode) ? <DsWsHistory /> : <Redirect to="/" />
                }
              />
              <Route
                path="/my-profile/authentication"
                exact
                render={() =>
                  !isEmpty(userCode) ? <Authentication /> : <Redirect to="/" />
                }
              />
              <Route
                path="/my-profile/authentication/personal-info"
                render={() =>
                  !isEmpty(userCode) ? <PersonInfo /> : <Redirect to="/" />
                }
              />
              <Route
                path="/my-profile/authentication/id-card"
                render={() =>
                  !isEmpty(userCode) ? <PersonCardId /> : <Redirect to="/" />
                }
              />
              <Route
                path="/my-profile/authentication/person-pic"
                render={() =>
                  !isEmpty(userCode) ? <PersonPic /> : <Redirect to="/" />
                }
              />
              <Route
                path="/my-profile/authentication/2fa"
                render={() =>
                  !isEmpty(userCode) ? <TwoFactor /> : <Redirect to="/" />
                }
              />
              <Route path="/my-profile/auth-guid" render={() => <AuthGuid />} />
              <Route path="/app-guid" render={() => <ApplicationGuid />} />
              <Route path="/faq" render={() => <FAQ />} />
              <Route
                path="/setting"
                render={() => <ApplicationSetting version={APP_VERSION} />}
              />
              <Route path="/notifications" render={() => <Notifications />} />
              <Route
                path="/logout"
                render={() =>
                  !isEmpty(userCode) ? <Logout /> : <Redirect to="/" />
                }
              />
              <Route path="/payment/verify" component={PaymentStatus} />
              <Route path="/payment/verify2" component={PaymentStatus2} />
              <Route path="/payment/verify3" component={PaymentStatus3} />
              <Route path="/terms" component={Terms} />
              <Route path="/about" component={About} />
              <Route path="/fees" component={Fees} />
              <Route path="*" exact component={Page404} />
            </Switch>
          </MarketLayout>
        </Route>
      </Switch>
    </div>
  );
}

export default withRouter(App);
