import { getCoinInfo } from "../services/marketServices";

export const getCoinDetail = (name) => {
  return async (dispatch) => {
    const { data } = await getCoinInfo(name);
    await dispatch({ type: "GET_SINGLE_COIN", payload: data });
  };
};

export const clearCoinDetails = () => {
  return (dispatch) => {
    dispatch({ type: "GET_SINGLE_COIN", payload: {} });
  };
};
