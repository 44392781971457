import { Stack } from "@mui/system";
import moment from "jalali-moment";
import { isEmpty } from "lodash";
import { useContext, useMemo, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { tradesCTX } from "../../../context/tradesCTX";
import { addCommaWithRialPrice } from "../../../utils/addComma";
import { TRADE_TYPE } from "../../market/ProfessionalMarket/Trade";
import styles from "./OrdersT.module.scss";

export default function OrdersT() {
  const MarketContext = useContext(tradesCTX);
  const { buyOffers, sellOffers, lastTrades, currentTrade } = MarketContext;
  const [type, setType] = useState(0);

  let percentBuyOffer = buyOffers[13]?.s;
  let percentSellOffer = sellOffers[13]?.s;

  const isIRT = useMemo(() => {
    return currentTrade?.area_title === "IRT";
  }, [currentTrade]);
  const showNumber = (num) => {
    if (isIRT) {
      return addCommaWithRialPrice(+num);
    }
    return num;
  };
  return (
    <div className={`${styles.container}`}>
      <div className={`border_bottom_to_151C30 ${styles.type}`}>
        <button
          className={`font_color_white ${!type ? styles.button_active : ""}`}
          disabled={!type}
          onClick={() => setType(0)}
        >
          سفارشات
        </button>
        <button
          className={`font_color_white ${type ? styles.button_active : ""} `}
          disabled={type}
          onClick={() => setType(1)}
        >
          معاملات
        </button>
      </div>
      <div className={`change_color_to_E1E2E4 ${styles.table_header}`}>
        <div>مقدار</div>
        <div>قیمت ({currentTrade?.area_title_fa})</div>
        <div>{type === 1 ? "زمان" : "مقدار"}</div>
      </div>
      {type !== 1 ? (
        <div className={styles.table_body_orders}>
          <div className={styles.buy_table}>
            {!isEmpty(buyOffers) ? (
              buyOffers.slice(0, 5).map((c, i) => {
                let percent = +c.s * 100;
                percent = percent / percentBuyOffer;
                return (
                  <div className={styles.item} key={i}>
                    <div className="font_color_white eng-number-font">
                      {Number(c?.a) > 1_000_000
                        ? String(c?.a)?.split(".")[0]
                        : Number(c?.a)}
                    </div>
                    <div className="color_green eng-number-font">
                      {showNumber(c.i)}
                    </div>
                    <div
                      className={styles.line_green}
                      style={{ width: percent + "%" }}
                    ></div>
                  </div>
                );
              })
            ) : (
              <Stack alignItems="center" justifyContent="center" height="75%">
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#10D078"
                  visible={true}
                />
              </Stack>
            )}
          </div>
          <div className={styles.sell_table}>
            {!isEmpty(sellOffers) ? (
              sellOffers
                .slice(0, 5)
                .reverse()
                .map((c, i) => {
                  let percent = +c.s * 100;
                  percent = percent / percentSellOffer;
                  return (
                    <div className={styles.item} key={i}>
                      <div className="color_red eng-number-font">
                        {showNumber(c.i)}
                      </div>
                      <div className="font_color_white eng-number-font">
                        {Number(c?.a) > 1_000_000
                          ? String(c?.a)?.split(".")[0]
                          : Number(c?.a)}
                      </div>
                      <div
                        className={styles.line_red}
                        style={{ width: percent + "%" }}
                      ></div>
                    </div>
                  );
                })
            ) : (
              <Stack alignItems="center" justifyContent="center" height="75%">
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#10D078"
                  visible={true}
                />
              </Stack>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.table_body_trades}>
          {!isEmpty(lastTrades) &&
            lastTrades.slice(0, 5).map((c, i) => (
              <div className={styles.item} key={i}>
                <div className="font_color_white eng-number-font">
                  {c.amount}
                </div>
                <div
                  className={
                    TRADE_TYPE.filter(
                      (ty) => ty.title.toLowerCase() === c.type
                    )[0]?.color ?? "color_main"
                  }
                >
                  <span className="eng-number-font">{showNumber(c.price)}</span>
                </div>
                <div className="font_color_white eng-number-font">
                  {moment.unix(c.time).format("hh:mm:ss")}
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
