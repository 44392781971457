//* --> Icons & Styles & Images
import styles from "./FilterMH.module.scss";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
//* --> Components
import HeaderMH from "../HeaderMH";
import Button3 from "./Button3";
//* --> Utils
import { RangeDatePicker } from "jalali-react-datepicker";
import { useEffect } from "react";

const typeButtons = [
  { text: "همه", value: "" },
  { text: "فروش", value: "SELL" },
  { text: "خرید", value: "BUY" },
];
const tradeMarketTypeButtons = [
  { text: "همه", value: "" },
  { text: "لیمیت", value: "limit" },
  { text: "مارکت", value: "market" },
];
const dateButtons = [
  { id: 0, text: "همه", value: "" },
  { id: 1, text: "۷ روز اخیر", value: "week" },
  { id: 2, text: "۳۰ روز اخیر", value: "month" },
];
export default function FilterMH(props) {
  const {
    open,
    setOpen,
    searchMarket,
    setSearchMarket,
    typeTrade,
    setTypeTrade,
    tradeMarketType,
    setTradeMarketType,
    date,
    setDate,
    marketData,
    openMarketList,
    setOpenMarketList,
    customDate,
    setCustomDate,
  } = props;

  const handleRestore = () => {
    setTradeMarketType("");
    setTypeTrade("");
    setDate("");
  };
  function changeDateFromJalaliToMiladi(value) {
    let sd = value.start._i;
    let ed = value.end._i;
    sd = sd.replace("-//", "") + " 00:00:00";
    ed = ed.replace("-//", "") + " 00:00:00";

    setCustomDate({
      ...customDate,
      endDate: ed,
      startDate: sd,
    });
  }
  useEffect(() => {
    if (customDate.startDate) {
      setDate("c-date");
    }
  }, [customDate]);
  return (
    <>
      {open && <div className={styles.bg} onClick={() => setOpen(false)}></div>}
      <div
        className={`${styles.container} changeBackgroundColorToBlack ${
          open ? styles.show : ""
        }`}
      >
        <HeaderMH setOpen={setOpen} />
        <div className={styles.main}>
          <h4 className="change_color_to_E1E2E4">فیلتر سفارش</h4>
          <div>
            <p className="change_color_to_E1E2E4">بازار</p>
            <div
              className={`${styles.select_market} changeBackgroundColorToHardBlack change_color_to_9D9EA8`}
              onClick={() => setOpenMarketList(true)}
            >
              <strong>
                {marketData?.asset_title_fa ?? "-"}/
                {marketData?.area_title_fa ?? "-"}
              </strong>
              <div>
                <ArrowBackIosNewIcon
                  fontSize="inherit"
                  className="no-svg-change"
                />
              </div>
            </div>
          </div>
          <div>
            <p className="change_color_to_E1E2E4">خرید و فروش</p>
            <div className={styles.container_type}>
              {typeButtons.map((btn, idx) => (
                <Button3
                  key={idx}
                  text={btn.text}
                  active={typeTrade}
                  value={btn.value}
                  setValue={setTypeTrade}
                />
              ))}
            </div>
          </div>
          <div>
            <p className="change_color_to_E1E2E4">نوع</p>
            <div className={styles.container_market_type}>
              {tradeMarketTypeButtons.map((btn, idx) => (
                <Button3
                  key={idx}
                  text={btn.text}
                  active={tradeMarketType}
                  value={btn.value}
                  setValue={setTradeMarketType}
                />
              ))}
            </div>
          </div>
          <div>
            <p className="change_color_to_E1E2E4">تاریخ</p>
            <div className={styles.container_custom_date}>
              <div className={styles.custom_labels}>
                <div className="change_color_to_9D9EA8">شروع</div>
                <div className="change_color_to_9D9EA8">پایان</div>
              </div>
              <div className="datePickerInMarketHistory">
                <RangeDatePicker
                  onClickSubmitButton={changeDateFromJalaliToMiladi}
                />
              </div>
            </div>
            <div className={styles.container_date}>
              {dateButtons.map((btn, idx) => (
                <Button3
                  sx={{ height: "30px", fontSize: "10px" }}
                  key={idx}
                  text={btn.text}
                  active={date}
                  value={btn.value}
                  setValue={setDate}
                />
              ))}
            </div>
          </div>
          <div className={styles.button_container}>
            <div>
              <button className={styles.confirm} onClick={() => setOpen(false)}>
                تایید
              </button>
            </div>
            <div>
              <button className={styles.restore} onClick={handleRestore}>
                باز تنظیم
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
