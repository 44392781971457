import styles from "./Authentication.module.scss";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userProfile } from "../../../../actions/user";
import {
  hiddenLoadingPage,
  showLoadingPage,
} from "../../../../actions/loadingPage";
import { LoadingPage } from "../../../LoadingPage/LoadingPage";
import {
  userEditProfile,
  usersVerifyInfo,
} from "../../../../services/userServices";
import {
  checkEmailCode,
  checkPhoneCode,
  sendEmailCode,
  sendPhoneCode,
} from "../../../../services/adminServices";
import { errorMessage, successMessage } from "../../../../utils/messages";
//Icons
import ArrowBackIcon from "../../../../styles/icon/arrow_back_fill.svg";
import ArrowRightIcon from "../../../../styles/icon/arrow_wallet.svg";
import Confirmed from "../../../../styles/icon/Confirmed.svg";
import NotConfirmed from "../../../../styles/icon/NotConfirmed.svg";
import pendingIcon from "../../../../styles/icon/pending.svg";
import { Stack, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import FlipIcon from "@mui/icons-material/Flip";
import { checkEmail, checkMobileNum } from "../../../Validator/Validator";
export const UserAcceptRules = ({ UserRulesInfo, SetAcceptRules }) => {
  return (
    <Stack sx={{ direction: "rtl" }}>
      <Stack className={`font_color_white`}>
        <Typography
          px={2}
          textAlign="justify"
          fontSize={17}
          lineHeight={2}
          bgcolor="var(--third2-color)"
          borderRadius={3}
          py={3}
        >
          ضمن خوش‌آمدگویی به شما کاربر محترم <span>ارزفی</span> ، به اطلاع
          می‌رساند جهت فراهم آوردن محیطی مطمئن برای تمامی کاربران سایت،‌ برای
          برداشت وجه ریالی و برداشت ارز (رمز ارز) در صورت داشتن واریز ریالی از
          درگاه یا واریز دستی به حساب ارزفی نیاز به تکمیل فرایند احراز هویت وجود
          دارد . لطفا پس از مطالعه توضیحات زیر،‌ برای شروع فعالیت مالی در سایت
          ارزفی ، فرایند احراز هویت خود را آغاز نمایید. احراز هویت پایه‌ای با
          تایید شماره تلفن همراه و ارسال مدارک هویتی (کارت ملی)، جهت تایید آن‌ها
          صورت می‌گیرد. با انجام این سطح از احراز هویت می‌توانید اقدام به معامله
          و برداشت وجه (رمزارز) نمایید. همچنین لازم به توضیح است که با توجه به
          قوانین بانکی کشور و امکان بلوکه شدن وجوه واریزشده از کارت‌های بانکی
          مسروقه،‌ کاربران جدید که به تازگی اقدام به ثبت و واریز وجه از طریق یک
          کارت بانکی می‌کنند، به مدت دو روز کاری برداشت اول کاربران به طول خواهد
          انجامید. این تاخیر صرفا جهت تایید اصالت کارت بانکی بوده و در طول این
          مدت کاربران می‌توانند به هر میزان با وجه واریزی خود در سیستم معامله
          نمایند و آن را تبدیل به سایر ارزهای دیجیتال کنند و محدودیت مذکورر صرفا
          مربوط به خارج کردن وجه واریزی از سیستم به مدت دو روز کاری است. طبق
          ماده ۵۶ قانون آیین‌نامه اجرایی قانون مبارزه با پولشویی، احراز هویت به
          کمک مدارک مشتریان جهت جلوگیری از هرگونه سو استفاده احتمالی الزامی است.
          توجه: قرار دادن شماره همراه و اطلاعات حساب کاربری در اختیار سایر اشخاص
          با بهانه‌هایی نظیر سرمایه‌گذاری و اجاره حساب طبق ماده ۲ قانون پولشویی
          پیگرد قانونی دارد و مجازات حبس تا هفت سال در انتظار متخلفین خواهد بود.
        </Typography>
      </Stack>
      <Stack mt={1}>
        <Stack
          color="var(--secondary-color)"
          width={200}
          margin="0 auto"
          height="45px"
          textAlign="center"
          alignItems="center"
          justifyContent="center"
          bgcolor="var(--primary-color)"
          borderRadius={3}
          onClick={() => {
            SetAcceptRules(true);
          }}
        >
          قوانین را میپذیرم
        </Stack>
      </Stack>
    </Stack>
  );
};
export const AuthenticationFileds = ({ docInfo }) => {
  const userInfo = useSelector((state) => state.fullUserInfo.data_profile);
  const [userInfoDetails, setUserInfoDetails] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    setUserInfoDetails(userInfo);
  }, [userInfo]);
  useEffect(() => {
    let acc = document.getElementsByClassName("accordion-for-user-details");
    let panel = document.getElementsByClassName("panel-buttons");
    for (var i = 0; i < acc.length; i++) {
      acc[i].onclick = function () {
        let setClasses = !this.classList.contains("active-user-details");
        setClass(acc, "active-user-details", "remove");
        setClass(panel, "show", "remove");
        if (setClasses) {
          this.classList.toggle("active-user-details");
          this.nextElementSibling.classList.toggle("show");
        }
      };
    }
    function setClass(els, className, fnName) {
      for (let i = 0; i < els.length; i++) {
        els[i].classList[fnName](className);
      }
    }
  }, []);
  const [verificationDetails, setVerificationDetails] = useState({
    phoneCodeSend: false,
    changePhoneNumber: false,
    phoneCode: "",
    emailCodeSend: false,
    changeEmailAddress: false,
    emailCode: "",
    newEmail: "",
    newPhone: "",
  });
  const [checkEmailRead, setCheckEmailRead] = useState(false);
  const [disableFetch, setDisableFetch] = useState(false);
  const handleSendPhoneCodeVerify = async () => {
    try {
      dispatch(showLoadingPage());
      const response = await sendPhoneCode();
      if (response.data.verify)
        setVerificationDetails({ ...verificationDetails, phoneCodeSend: true });
      else {
        errorMessage(response.data.message);
      }
    } catch (ex) {
      errorMessage("مشکلی پیش آمده! دوباره تلاش کنید");
    } finally {
      dispatch(hiddenLoadingPage());
    }
  };
  const handleSubmitPhoneCode = async () => {
    if (!verificationDetails.phoneCode) return;
    const code = verificationDetails.phoneCode;
    try {
      dispatch(showLoadingPage());
      setDisableFetch(true);
      const res = await checkPhoneCode(code);
      if (res.data.verify) {
        successMessage("شماره شما تایید شد");
        setVerificationDetails({
          ...verificationDetails,
          phoneCodeSend: false,
        });
        setUserInfoDetails({
          ...userInfoDetails,
          ...(userInfoDetails.user.smsStatus = true),
        });
      }
    } catch (ex) {
      errorMessage("کد وارد شده اشتباه است");
    } finally {
      setDisableFetch(false);
      dispatch(hiddenLoadingPage());
    }
  };
  ///
  //Email Code Send And Verify
  ///
  const handleSendEmailCodeVerify = async () => {
    try {
      dispatch(showLoadingPage());
      const response = await sendEmailCode();
      if (response.data.verify)
        setVerificationDetails({ ...verificationDetails, emailCodeSend: true });
      else {
        errorMessage(response.data.message);
      }
    } catch (ex) {
      errorMessage("مشکلی پیش آمده! دوباره تلاش کنید");
    } finally {
      dispatch(hiddenLoadingPage());
    }
  };
  const handleSubmitEmailCode = async () => {
    if (!verificationDetails.emailCode) return;
    const code = verificationDetails.emailCode;
    try {
      setDisableFetch(true);
      const res = await checkEmailCode(code);
      if (res.data.verify) {
        successMessage("ایمیل شما تایید شد");
        setVerificationDetails({
          ...verificationDetails,
          emailCodeSend: false,
        });
        setUserInfoDetails({
          ...userInfoDetails,
          ...(userInfoDetails.user.emailStatus = true),
        });
      }
    } catch (ex) {
      errorMessage("کد وارد شده اشتباه است");
    } finally {
      setDisableFetch(false);
    }
  };

  const handleChangeEmailAddress = async () => {
    const __isEmail__ = checkEmail(verificationDetails.newEmail);
    if (!__isEmail__) return errorMessage("ایمیل وارد شده معتبر نمیباشد!");
    if (verificationDetails.newEmail === userInfoDetails.user.email)
      return errorMessage("ایمیل وارد شده معتبر نمیباشد!");
    dispatch(showLoadingPage());
    try {
      const { data } = await userEditProfile({
        email: verificationDetails.newEmail,
      });

      if (data.error) {
        errorMessage(data.message ?? "مشکلی در تغییر وحود داشت");
      } else {
        await dispatch(userProfile());
        setVerificationDetails((prev) => ({
          ...prev,
          newEmail: "",
          changeEmailAddress: false,
        }));
      }
    } catch (error) {
      errorMessage("مشکلی در تغییر وجود دارد!");
    } finally {
      dispatch(hiddenLoadingPage());
    }
  };

  const handleChangeMobileNumber = async () => {
    const __is_phone__ = checkMobileNum(verificationDetails.newPhone);
    if (!__is_phone__) return errorMessage("شماره وارد شده معتبر نمیباشد!");
    if (verificationDetails.newPhone === userInfoDetails.user.phoneNumber)
      return errorMessage("شماره وارد شده معتبر نمیباشد!");
    dispatch(showLoadingPage());
    try {
      const { data } = await userEditProfile({
        mobileNumber: verificationDetails.newPhone,
      });

      if (data.error) {
        errorMessage(data.message ?? "مشکلی در تغییر وحود داشت");
      } else {
        await dispatch(userProfile());
        setVerificationDetails((prev) => ({
          ...prev,
          newPhone: "",
          changePhoneNumber: false,
        }));
      }
    } catch (error) {
      errorMessage("مشکلی در تغییر وجود دارد!");
    } finally {
      dispatch(hiddenLoadingPage());
    }
  };
  return (
    <Stack className={styles.AuthenticationFields_container} mt={2}>
      <Stack
        className={`${styles.part_of_info}`}
        bgcolor="var(--third-color)"
        px={1}
        py={2}
        boxSizing="border-box"
        borderRadius={2}
      >
        <Stack
          className={`accordion-for-user-details`}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <Stack fontSize={27} pb={0.3}>
              <PersonIcon
                className="no-svg-change"
                htmlColor="var(--secondary-color)"
                fontSize="inherit"
              />
            </Stack>
            <Stack className={`font_color_white ${styles.title}`}>
              اطلاعات فردی
            </Stack>
          </Stack>
          <Stack className={styles.ArrowIconContainer}>
            <img
              src={ArrowRightIcon}
              alt="arrow-icon"
              width={17}
              height={17}
              className="ArrowImgPersonalInfo convertPicToGreen"
            />
          </Stack>
        </Stack>
        <div className="panel-buttons">
          {/* In Code Zamani Ke karbar data nadashte bashe baz mishe PERSONAL INFO
          Dar Gheyre In surate Field Ba'adi Baz Mishe */}
          {(userInfoDetails?.user?.verified !== true &&
            userInfoDetails?.user?.docsLack !== true) ||
          (userInfoDetails?.user?.docsLack === true &&
            userInfoDetails?.user?.verified === true) ||
          (userInfoDetails?.user?.docsLack === true &&
            userInfoDetails?.user?.verified !== true) ? (
            <div className={styles.item_status}>
              <div>وضعیت: </div>
              <div
                className={`changePolygonColorOfTransactionInDarkMode ${styles.polygon_icon}`}
              ></div>
              <Link to="/my-profile/authentication/personal-info">
                <div
                  className={`${styles.status_overview} ${styles.button_to_fill_form}`}
                  style={{ minWidth: "120px" }}
                >
                  تکمیل اطلاعات
                </div>
              </Link>
            </div>
          ) : (
            ""
          )}
          <Stack className={styles.item_status} fontSize={13.5}>
            <Stack>نام و نام خانوادگی:</Stack>
            <Stack textAlign="left">
              {userInfoDetails?.user?.fullname ?? "--"}
            </Stack>
          </Stack>
          <Stack className={styles.item_status}>
            <Stack className={`font_color_white`}>
              <Stack>کد ملی: </Stack>
              <Stack>{/*PLACE OF ID NUMBER*/}</Stack>
              <Stack
                className={`changePolygonColorOfTransactionInDarkMode ${styles.polygon_icon}`}
              ></Stack>
            </Stack>
            <Stack className={styles.status_with_img}>
              <img
                src={docInfo?.idNumber ? Confirmed : NotConfirmed}
                alt="check-status-icon"
                width={userInfoDetails?.user?.smsStatus ? 20 : 22}
                height={userInfoDetails?.user?.smsStatus ? 20 : 22}
              />
            </Stack>
          </Stack>
          <Stack className={styles.item_status} gap={1}>
            <Stack flexDirection="column" gap={0.3}>
              <Typography fontSize={14}>شماره موبایل</Typography>
              <Typography fontSize={16}>
                {userInfoDetails?.user?.phoneNumber ?? "--"}
              </Typography>
            </Stack>
            <Stack flexDirection="row-reverse" gap={1} alignItems="center">
              {!userInfoDetails?.user?.smsStatus &&
                userInfoDetails?.user?.phoneNumber &&
                !verificationDetails?.phoneCodeSend &&
                !verificationDetails.changePhoneNumber && (
                  <Stack flexDirection="column" gap={1} flexWrap="wrap">
                    <button
                      className={`button_send_code_darkMode ${styles.button_to_fill_form2}`}
                      disabled={verificationDetails.phoneCodeSend}
                      style={{
                        minWidth: "60px",
                        color: "var(--danger-color)",
                        borderColor: "var(--danger-color)",
                      }}
                      onClick={() =>
                        setVerificationDetails((prev) => ({
                          ...prev,
                          changePhoneNumber: true,
                        }))
                      }
                    >
                      تغییر
                    </button>{" "}
                    <button
                      className={`button_send_code_darkMode ${styles.button_to_fill_form2}`}
                      style={{ minWidth: "110px" }}
                      disabled={verificationDetails.phoneCodeSend}
                      onClick={() => handleSendPhoneCodeVerify()}
                    >
                      ارسال کد تایید
                    </button>
                  </Stack>
                )}
            </Stack>
            {userInfoDetails?.user?.smsStatus && (
              <img src={Confirmed} alt="active-icon" width={18} height={18} />
            )}
            {verificationDetails?.phoneCodeSend && (
              <div className={styles.input_verification}>
                <input
                  type="text"
                  placeholder="کد ارسالی*"
                  onChange={(e) =>
                    setVerificationDetails({
                      ...verificationDetails,
                      phoneCode: e.target.value,
                    })
                  }
                  value={verificationDetails.phoneCode}
                />
                <button
                  onClick={() => handleSubmitPhoneCode()}
                  disabled={disableFetch}
                >
                  {disableFetch ? "بررسی...." : "ثبت"}
                </button>
              </div>
            )}
            {verificationDetails.changePhoneNumber && (
              <Stack flexDirection="column">
                <Stack>
                  <div
                    className={styles.input_verification}
                    style={{ minWidth: "200px" }}
                  >
                    <input
                      placeholder="شماره جدید"
                      onChange={(e) =>
                        setVerificationDetails({
                          ...verificationDetails,
                          newPhone: e.target.value,
                        })
                      }
                      value={verificationDetails.newPhone}
                    />
                  </div>
                </Stack>
                <Stack
                  flexDirection="row-reverse"
                  alignItems="center"
                  mt={1}
                  gap={0.5}
                >
                  <button
                    className={`button_send_code_darkMode ${styles.button_to_fill_form2}`}
                    style={{
                      minWidth: "100px",
                      color: "var(--danger-color)",
                      borderColor: "var(--danger-color)",
                    }}
                    onClick={() =>
                      setVerificationDetails((prev) => ({
                        ...prev,
                        changePhoneNumber: false,
                        newPhone: "",
                      }))
                    }
                  >
                    لغو
                  </button>{" "}
                  <button
                    className={`button_send_code_darkMode ${styles.button_to_fill_form2}`}
                    style={{ minWidth: "100px" }}
                    onClick={handleChangeMobileNumber}
                  >
                    تغییر
                  </button>
                </Stack>
              </Stack>
            )}
          </Stack>
          <Stack className={styles.item_status}>
            <Stack className={`font_color_white`}>
              <Stack>شماره ثابت</Stack>
              <Stack>{userInfoDetails?.user?.telephone ?? "--"}</Stack>
              <Stack
                className={`changePolygonColorOfTransactionInDarkMode ${styles.polygon_icon}`}
              ></Stack>
            </Stack>
            <Stack className={styles.status_with_img}>
              <img
                src={NotConfirmed}
                alt="not-active-icon"
                width={20}
                height={20}
              />
            </Stack>
          </Stack>
          <Stack className={styles.item_status} gap={1}>
            <Stack
              flexDirection="column"
              sx={{ wordBreak: "break-all" }}
              gap={0.3}
            >
              <Typography fontSize={14}>آدرس ایمیل</Typography>
              <Typography fontSize={16}>
                {userInfoDetails?.user?.email ?? "--"}
              </Typography>
            </Stack>
            <Stack flexDirection="row-reverse" gap={1} alignItems="center">
              {!userInfoDetails?.user?.emailStatus &&
                !!userInfoDetails?.user?.email &&
                !verificationDetails?.emailCodeSend &&
                !verificationDetails.changeEmailAddress && (
                  <Stack flexDirection="column" gap={1} flexWrap="wrap">
                    <button
                      className={`button_send_code_darkMode ${styles.button_to_fill_form2}`}
                      disabled={verificationDetails.emailCodeSend}
                      style={{
                        minWidth: "60px",
                        color: "var(--danger-color)",
                        borderColor: "var(--danger-color)",
                      }}
                      onClick={() =>
                        setVerificationDetails((prev) => ({
                          ...prev,
                          changeEmailAddress: true,
                        }))
                      }
                    >
                      تغییر
                    </button>{" "}
                    <button
                      className={`button_send_code_darkMode ${styles.button_to_fill_form2}`}
                      style={{ minWidth: "110px" }}
                      disabled={verificationDetails.emailCodeSend}
                      onClick={() => handleSendEmailCodeVerify()}
                    >
                      ارسال کد تایید
                    </button>
                  </Stack>
                )}
            </Stack>
            {userInfoDetails?.user?.emailStatus && (
              <img src={Confirmed} alt="active-icon" width={18} height={18} />
            )}
            {verificationDetails?.emailCodeSend && (
              <Stack>
                <label htmlFor="accept_rules_in_email">
                  <Stack flexDirection="row" gap={1} mb={1} alignItems="center">
                    <input
                      type="checkbox"
                      hidden
                      id="accept_rules_in_email"
                      className={styles.read_email_checkbox}
                      value={checkEmailRead}
                      onChange={(e) => setCheckEmailRead(e.target.checked)}
                    />
                    <Stack className={styles.checkbox}></Stack>
                    <Stack
                      className={`font_color_white ${styles.text_accept}`}
                      fontSize={13}
                      textAlign="justify"
                    >
                      قوانین ارسال شده در ایمیل را مطالعه کرده و میپذیرم.
                    </Stack>
                  </Stack>
                </label>
                <div className={styles.input_verification}>
                  <input
                    type="text"
                    placeholder="کد ارسالی*"
                    onChange={(e) =>
                      setVerificationDetails({
                        ...verificationDetails,
                        emailCode: e.target.value,
                      })
                    }
                    value={verificationDetails.emailCode}
                  />
                  <button
                    disabled={!checkEmailRead || disableFetch}
                    onClick={() => handleSubmitEmailCode()}
                  >
                    {disableFetch ? "بررسی...." : "ثبت"}
                  </button>
                </div>
              </Stack>
            )}
            {verificationDetails.changeEmailAddress && (
              <Stack flexDirection="column">
                <Stack>
                  <div
                    className={styles.input_verification}
                    style={{ minWidth: "200px" }}
                  >
                    <input
                      type="email"
                      placeholder="ایمیل جدید"
                      onChange={(e) =>
                        setVerificationDetails({
                          ...verificationDetails,
                          newEmail: e.target.value,
                        })
                      }
                      value={verificationDetails.newEmail}
                    />
                  </div>
                </Stack>
                <Stack
                  flexDirection="row-reverse"
                  alignItems="center"
                  mt={1}
                  gap={0.5}
                >
                  <button
                    className={`button_send_code_darkMode ${styles.button_to_fill_form2}`}
                    style={{
                      minWidth: "100px",
                      color: "var(--danger-color)",
                      borderColor: "var(--danger-color)",
                    }}
                    onClick={() =>
                      setVerificationDetails((prev) => ({
                        ...prev,
                        changeEmailAddress: false,
                        newEmail: "",
                      }))
                    }
                  >
                    لغو
                  </button>{" "}
                  <button
                    className={`button_send_code_darkMode ${styles.button_to_fill_form2}`}
                    style={{ minWidth: "100px" }}
                    onClick={handleChangeEmailAddress}
                  >
                    تغییر
                  </button>
                </Stack>
              </Stack>
            )}
          </Stack>
        </div>
      </Stack>
      <Stack
        className={` ${styles.part_of_info}`}
        mt={2}
        bgcolor="var(--third-color)"
        px={1}
        py={2}
        borderRadius={2}
        boxSizing="border-box"
      >
        <Stack
          className={`accordion-for-user-details`}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <Stack fontSize={26} pb={0.3}>
              <FlipIcon
                className="no-svg-change"
                htmlColor="var(--secondary-color)"
                fontSize="inherit"
              />
            </Stack>
            <Stack className={`font_color_white ${styles.title}`}>
              احراز هویت
            </Stack>
          </Stack>
          <Stack className={styles.ArrowIconContainer}>
            <img
              src={ArrowRightIcon}
              alt="arrow-icon"
              width={17}
              height={17}
              className="ArrowImgPersonalInfo convertPicToGreen"
            />
          </Stack>
        </Stack>
        <div className="panel-buttons">
          <div className={styles.item_status}>
            <Typography fontSize={14}>تصویر کارت ملی: </Typography>
            <div
              className={`changePolygonColorOfTransactionInDarkMode ${styles.polygon_icon}`}
            ></div>
            {docInfo.IdCardPic === "APPROVED" && (
              <img
                src={Confirmed}
                alt="check-status-icon"
                width={22}
                height={22}
              />
            )}
            {docInfo.IdCardPic !== "APPROVED" &&
              docInfo.IdCardPic !== "UNCHECKED" && (
                <button
                  className={`button_send_code_darkMode ${styles.button_to_fill_form}`}
                  style={{ minWidth: "110px" }}
                >
                  <Link to="/my-profile/authentication/id-card">
                    تکمیل اطلاعات
                  </Link>
                </button>
              )}
          </div>
          <div className={styles.item_status}>
            <Typography fontSize={14}>تصویر احراز هویت: </Typography>
            <div
              className={`changePolygonColorOfTransactionInDarkMode ${styles.polygon_icon}`}
            ></div>
            {docInfo.personPic === "APPROVED" && (
              <img
                src={Confirmed}
                alt="check-status-icon"
                width={22}
                height={22}
              />
            )}
            {docInfo.personPic !== "APPROVED" &&
              docInfo.personPic !== "UNCHECKED" && (
                <button
                  className={`button_send_code_darkMode ${styles.button_to_fill_form}`}
                  style={{ minWidth: "110px" }}
                >
                  <Link to="/my-profile/authentication/person-pic">
                    تکمیل اطلاعات
                  </Link>
                </button>
              )}
          </div>
        </div>
      </Stack>
    </Stack>
  );
};
export default function Authentication() {
  const history = useHistory();
  const [userRulesAccept, setUserRulesAccept] = useState(true);
  const dispatch = useDispatch();
  const loadingBar = useSelector((state) => state.loadingBar.loading);
  const [docInfo, setDocInfo] = useState({
    personPic: "",
    IdCardPic: "",
  });
  useEffect(() => {
    let mounted = true;
    const controller = new AbortController();
    if (mounted) {
      const getUserDetails = async () => {
        try {
          dispatch(showLoadingPage());
          dispatch(userProfile());
          const verifyInfo = await usersVerifyInfo(controller.signal);
          setDocInfo({
            ...docInfo,
            personPic: verifyInfo.data.user.userSelfie,
            IdCardPic: verifyInfo.data.user.userIdCard,
            idNumber: verifyInfo.data.user.idNumber,
          });
          if (verifyInfo.data.user.userIdCard) {
            setUserRulesAccept(true);
          } else {
            setUserRulesAccept(false);
          }
        } catch (ex) {
        } finally {
          dispatch(hiddenLoadingPage());
        }
      };
      getUserDetails();
    }
    return () => {
      mounted = false;
      controller.abort();
    };
  }, []);

  return (
    <>
      {loadingBar && <LoadingPage />}
      <section className={styles.container}>
        <header>
          <div className={styles.back}>
            <img
              src={ArrowBackIcon}
              alt="BACK"
              width={15}
              height={15}
              onClick={() => history.push("/my-profile/info")}
              className="convertPicToWhite"
            />
          </div>
          <div className={`font_color_white ${styles.header_title}`}>
            احراز هویت
          </div>
        </header>
        <main>
          {userRulesAccept ? (
            <AuthenticationFileds docInfo={docInfo} />
          ) : (
            <UserAcceptRules
              SetAcceptRules={setUserRulesAccept}
              UserRulesInfo={userRulesAccept}
            />
          )}
        </main>
      </section>
    </>
  );
}
