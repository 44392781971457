import { isEmpty } from "lodash";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userProfile } from "../../../../actions/user";
import { errorMessage, successMessage } from "../../../../utils/messages";
import { usersAddNewBankCard } from "../../../../services/userServices";

//Styles and Icons
import styles from "./CardsAccountInfo.module.scss";
//* --> Utils
import { convertNumbers2English } from "../../../../utils/priceFunc";
import { detectBanks } from "../../../../utils/detectBanks";
import { Stack } from "@mui/system";
import PrimaryHeader from "../../../Headers/PrimaryHeader";
import CardsSlider from "./CardsSlider";

export default function CardsAccountInfo() {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.fullUserInfo.data_profile);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (!userInfo) {
        dispatch(userProfile());
      }
    }
    return () => (mounted = false);
  }, []);

  const [addCardDetails, setAddCardDetails] = useState({
    cardNumber: "",
    ibanNumber: "",
    error: "",
    deActive: false,
  });

  const handleAddUserCard = async (e) => {
    e.preventDefault();
    let pattern = /^(?=.{24}$)[0-9]*$/;
    if (addCardDetails.cardNumber.trim().length < 15)
      return setAddCardDetails({
        ...addCardDetails,
        error: "شماره کارت وارد شده صحیح نمی باشد",
      });
    if (!pattern.test(addCardDetails.ibanNumber.split("IR")[1]))
      return setAddCardDetails({
        ...addCardDetails,
        error: (
          <>
            شماره شبا به درستی وارد نشده, مثال صحیح: 270610000001001000652764
            <br />
            شماره شبا باید بدون IR وارد شود
          </>
        ),
      });

    try {
      setAddCardDetails({ ...addCardDetails, deActive: true });
      let { cardNumber, ibanNumber } = addCardDetails;
      ibanNumber = `${ibanNumber}`;
      const cardInfo = { cardNumber, ibanNumber };
      const { data } = await usersAddNewBankCard(cardInfo);
      if (data.error) {
        return errorMessage(data.message);
      }
      setAddCardDetails({ ...addCardDetails, cardNumber: "", ibanNumber: "" });
      dispatch(userProfile());
      successMessage(data.message);
    } catch (ex) {
      errorMessage("مشکلی پیش امده دوباره تلاش کنید");
    } finally {
      const timer = setTimeout(() => {
        setAddCardDetails({ ...addCardDetails, deActive: false });
        clearTimeout(timer);
      }, 2000);
    }
  };

  const handleSetIban = (iban) => {
    iban = "IR" + convertNumbers2English(iban).replace(/\D/g, "");
    setAddCardDetails({ ...addCardDetails, ibanNumber: iban });
  };

  return (
    <div className={styles.container}>
      <Stack marginX={1.5}>
        <PrimaryHeader title="کارت های بانکی" />
      </Stack>

      <Stack width={1} mt={5} mb={6}>
        <CardsSlider userInfo={userInfo} />
      </Stack>

      <div className={styles.addCardContainer}>
        <p>اضافه کردن کارت بانکی</p>
        <div className={styles.inputs_container}>
          <form onSubmit={(e) => handleAddUserCard(e)}>
            <input
              className="changeBackgroundColorToHardBlack font_color_white"
              type="number"
              placeholder="شماره کارت*"
              onChange={(e) =>
                setAddCardDetails({
                  ...addCardDetails,
                  cardNumber: e.target.value,
                })
              }
              value={addCardDetails.cardNumber}
            />
            <input
              className="changeBackgroundColorToHardBlack font_color_white"
              placeholder="شماره شبا*"
              onChange={(e) => handleSetIban(e.target.value)}
              value={addCardDetails.ibanNumber}
            />
            <div className={styles.errors_place}>{addCardDetails.error}</div>
            <div className={styles.btn_container}>
              <button
                type="submit"
                disabled={addCardDetails.deActive}
                className="changeBackgroundToGreen"
              >
                ثبت
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
