import WarningIcon from "../../styles/icon/warning.svg";
export default function NotificationError({
  bgColor,
  fontColor,
  text,
  bgBorder,
  fontColor2,
}) {
  const imgPlace = {
    top: "8px",
    width: "16px",
    height: "16px",
    position: "absolute",
    right: "6px",
  };
  return (
    <div
      style={{
        backgroundColor: `${bgColor}`,
        color: `${fontColor}`,
        position: "relative",
        direction: "rtl",
        paddingRight: "30px",
        paddingLeft: "10px",
        paddingTop: "10px",
        paddingBottom: "10px",
        borderRadius: "3px",
        border: `1px solid ${bgBorder}`,
        fontSize: "11.7px",
      }}
      className="create_border_red_darkMode"
    >
      <div style={imgPlace}>
        <img src={WarningIcon} alt="" width={20} height={20} />
      </div>
      <span className="font_color_change_to_red">توجه: </span>
      <span className="font_color_change_to_red" style={{ lineHeight: "15px" }}>
        {text}
      </span>
    </div>
  );
}
