import { Stack } from "@mui/material";
import styles from "./InputViaButton.module.scss";

export default function InputViaButton(props) {
  const { value, onChange, onClickButton, buttonTitle = "", ...rest } = props;
  return (
    <div className={styles.container}>
      <input onChange={onChange} value={value} {...rest} />
      <button onClick={onClickButton}>{buttonTitle}</button>
    </div>
  );
}
