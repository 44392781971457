import React, { useLayoutEffect, useMemo, useReducer } from "react";
import ShowTickets from "../pageComponents/TicketsPage/ShowTickets";
import RecordTicket from "../pageComponents/TicketsPage/RecordTicket";
import { Box, Button, Container, Stack } from "@mui/material";
import {
  getTicketsList,
  initialStates,
  ticketsReducer,
} from "../pageComponents/TicketsPage/state";
import PrimaryHeader from "../components/Headers/PrimaryHeader";
import styles from "../pageComponents/TicketsPage/TicketsPage.module.scss";
const ButtonTabs = [
  { id: "tickets", name: "تیکت ها" },
  { id: "record", name: "ثبت تیکت" },
];
const ticketDeps = [
  { id: "TECH", title: "احراز هویت", icon: "IdentityIcon" },
  { id: "TRANSACTION", title: "تراکنش", icon: "transactionIcon" },
  { id: "SALES", title: "سفارش گذاری", icon: "orderIcon" },
  { id: "REGISTER", title: "سایر", icon: "waysIcon" },
  { id: "CRITICS", title: "انتقادات", icon: "reportIcon" },
];
function Tickets() {
  const [state, dispatch] = useReducer(ticketsReducer, initialStates);
  const URL = new URLSearchParams(window.location.search);

  useLayoutEffect(() => {
    const hasQuery = URL.get("view");
    if (hasQuery) {
      const isExist = ButtonTabs.filter((c) => c.id === hasQuery)?.[0];
      if (isExist) dispatch({ type: "VIEW", payload: isExist.id });
    }
  }, []);
  const ShowComponent = useMemo(() => {
    const CMPS = {
      tickets: (
        <ShowTickets
          dispatch={dispatch}
          state={state}
          departments={ticketDeps}
        />
      ),
      record: <RecordTicket dispatch={dispatch} TicketSubjects={ticketDeps} />,
    }[state["view"]];
    return CMPS ?? "";
  }, [state]);

  useLayoutEffect(() => {
    const controller = new AbortController();
    (async () => {
      const { error, list } = await getTicketsList(controller.signal);
      if (!error) {
        dispatch({ type: "INIT_SUCCESS", payload: list });
      } else {
        dispatch({ type: "INIT_FAILED" });
      }
    })();
    return () => {
      controller.abort();
    };
  }, [state["refershPageCount"]]);
  return (
    <Container disableGutters sx={{ paddingX: 2 }}>
      <PrimaryHeader title="بخش تیکت" fixButton />
      <Box component="div" sx={{ direction: "rtl" }}>
        <Stack direction="row" borderBottom="1px solid var(--border-color)">
          {ButtonTabs.map((ctx) => {
            return (
              <Button
                variant="text"
                color="inherit"
                key={ctx["id"]}
                data-selected={ctx["id"] === state["view"]}
                className={styles.buttonItems}
                onClick={() => dispatch({ type: "VIEW", payload: ctx["id"] })}
                size="small"
              >
                {ctx["name"]}
              </Button>
            );
          })}
        </Stack>
      </Box>
      {ShowComponent}
    </Container>
  );
}

export default Tickets;
