import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useContext, memo } from "react";
import styles from "./HeaderMT.module.scss";
//* --> Icon's
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ChartBarIcon from "../../../styles/icon/chart_bar.svg";
import ChartBarLightIcon from "../../../styles/icon/chart_bar_light.svg";
//* <-- Icon's

//* --> Redux
import { useDispatch, useSelector } from "react-redux";
import { tradesCTX } from "../../../context/tradesCTX";
//* <-- Redux

//* --> Action's
import { addFavoriteCoins } from "../../../actions/favoriteCoins";
//* --> Utils
import { Helmet } from "react-helmet";
import { isEmpty } from "lodash";
//* --> Components
import PopoverMT from "./PopoverMT";
import appIcons from "../../../utils/appIcons";

function HeaderMT(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const MarketContext = useContext(tradesCTX);
  const favUserCoins = useSelector((state) => state.favCoins);
  const assetsMarket = useSelector((state) => state.market?.data);
  const appTheme = useSelector((state) => state.appTheme);
  const { lastOffer, currentTrade, marketWSInfo, setAsideStatus, asideStatus } =
    MarketContext;
  const market_24h_percent =
    assetsMarket?.filter((c) => c.symbol === currentTrade.asset)[0]
      ?.percent_change_24h ?? 0;

  const handleChangeRoot = () => {
    history.go(-1);
  };
  const handleToggleChart = () => {
    props.setShowChart((prev) => !prev);
  };
  return (
    <>
      {!isEmpty(marketWSInfo) && !isEmpty(currentTrade) ? (
        <Helmet
          title={`خرید سریع و فروش سریع ${currentTrade?.asset_title_fa} - بازار ${currentTrade.market} - قیمت لحظه ای :‌${lastOffer}`}
        >
          <meta charSet="utf-8" />
          <meta
            name="description"
            content={`خرید و فروش ${currentTrade?.asset_title_fa}
            - ${currentTrade?.asset_title} , ${currentTrade.market} معامله در
            لحظه و معامله اسپات قیمت لحظه ای ${currentTrade?.asset_title_fa} 
            ${lastOffer}
            تبدیل سریع و مطمئن (${currentTrade?.asset}) با بهترین صرافی
            ایران ${currentTrade.asset_title_fa}/${currentTrade.area_title_fa}
            ${currentTrade.market} نمودار قیمت `}
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="Arzfi.com" />
          <meta name="twitter:creator" content="@ArzfiEx" />
          <meta property="og:type" content="website" />
          <meta
            name="keywords"
            content={`ارز دیجیتال, کیف پول, صرافی ایرانی, بهترین صرافی, معامله اسپات, مارکت حرفه ای, بیتکوین,اتریوم,خرید ارز دیجیتال, فروش ارز پیجیتال, ${
              currentTrade?.market ?? "BTCUSDT"
            }, ${
              currentTrade?.asset_title_fa ?? "بیت کوین"
            }, "معامله اسپات SPOT"`}
          />
        </Helmet>
      ) : null}

      <section
        className={`changeBackgroundColorToHardBlack ${styles.container}`}
      >
        <div
          className={styles.market}
          onClick={() => setAsideStatus(!asideStatus)}
        >
          <div className={styles.arrow}>
            <KeyboardArrowDownIcon fontSize="inherit" />
          </div>
          <div className={styles.name}>
            <h1 className="change_color_to_E1E2E4">
              {currentTrade?.asset_title_fa}/{currentTrade?.area_title_fa}
            </h1>
            <strong className="change_color_to_9D9EA8">
              {currentTrade?.asset}/{currentTrade?.area_title}
            </strong>
          </div>
        </div>
        <div className={styles.details}>
          <div className={styles.percent}>
            <div
              className={`${+market_24h_percent >= 0 ? "bg_green" : "bg_red"} ${
                styles.box
              }`}
            >
              {market_24h_percent}
              <span style={{ fontSize: "8px" }}>%</span>
            </div>
          </div>
          <div className={styles.more}>
            <div className={styles.isFav}>
              {favUserCoins.includes(currentTrade?.asset_title) ? (
                <StarIcon
                  fontSize="inherit"
                  className={styles.Liked}
                  onClick={() =>
                    dispatch(addFavoriteCoins(currentTrade.asset_title))
                  }
                />
              ) : (
                <span className="no-svg-change ">
                  <StarBorderIcon
                    fontSize="inherit"
                    className={`${styles.NoLike} change_color_to_E1E2E4`}
                    onClick={() =>
                      dispatch(addFavoriteCoins(currentTrade.asset_title))
                    }
                  />
                </span>
              )}
            </div>
            <div className={`${styles.chart_bar}`} onClick={handleToggleChart}>
              {appTheme === "dark" ? (
                <img
                  src={ChartBarLightIcon}
                  width={23}
                  height={23}
                  alt="CHART"
                />
              ) : (
                <img src={ChartBarIcon} width={23} height={23} alt="CHART" />
              )}
            </div>
            <div
              className={`${styles.more_actions} change_color_to_E1E2E4 poperInTradeMarket`}
            >
              <PopoverMT
                asset={currentTrade?.asset}
                faAssetName={currentTrade?.asset_title_fa}
                market={currentTrade?.market}
              >
                <MoreHorizIcon fontSize="inherit" className="no-svg-change" />
              </PopoverMT>
            </div>
            <div className={styles.back}>
              <span onClick={handleChangeRoot}>
                {appIcons("ArrowBackIcon")}
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default memo(HeaderMT);
