import { memo, useContext, useEffect, useMemo, useState } from "react";
import styles from "./AsideT.module.scss";
import { tradesCTX } from "../../../context/tradesCTX";
import { useSelector } from "react-redux";
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router";
//* --> Utils
import { isEmpty } from "lodash";
import RTL from "../../../helpers/direction/RTL";
import { showBetterPrice } from "../../../utils/showBetterPrice";
import { addCommaWithRialPrice } from "../../../utils/addComma";

//# Images For Attacked To Buttons ##
import IranIcon from "../../../styles/icon/iran.png";
import TetherIcon from "../../../styles/icon/tether.png";
import FavoriteIcon from "../../../styles/icon/favorite.png";

const ButtonImages = {
  IRT: IranIcon,
  USDT: TetherIcon,
  favorite: FavoriteIcon,
};
const centerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "12px",
};
const useStyles = makeStyles((theme) => ({
  searchBarStyle: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#00c194",
      },
    },
  },
}));
function AsideT(props) {
  const history = useHistory();
  const [filter, setFilter] = useState({
    priceBy: "IRT",
    name: "",
  });
  const classes = useStyles();
  const {
    xs,
    clinetHeightPOS,
    basic,
    openMarketList,
    setOpenMarketList,
    setSearchMarket,
    marketInFilter,
  } = props;
  const [filteredList, setFilteredList] = useState([]);
  const [numberOfShow, setNumberOfShow] = useState(30); //
  const [hasMoreItem, setHasMoreItem] = useState(true); //
  const TredeContenxt = useContext(tradesCTX);
  const { asideStatus, setAsideStatus, currentTrade, marketWSInfo } =
    TredeContenxt;
  const tradesMarketList = useSelector((state) => state.tradesList);
  const favUserCoins = useSelector((state) => state.favCoins);
  const coinAssets = useSelector((state) => state.market?.data);
  function handleNext() {
    if (!isEmpty(tradesMarketList)) {
      if (filteredList.slice(0, numberOfShow).length >= filteredList.length) {
        setHasMoreItem(false);
        return;
      }
      setNumberOfShow(numberOfShow + 30);
    }
  }

  useEffect(() => {
    if (!isEmpty(tradesMarketList)) {
      if (filter.priceBy !== "favorite") {
        setFilteredList(
          tradesMarketList.list
            .filter(
              (coin) =>
                coin.asset_title_fa
                  .toLowerCase()
                  .includes(filter.name.toLowerCase()) ||
                coin.asset_title
                  .toLowerCase()
                  .includes(filter.name.toLowerCase()) ||
                coin.market.toLowerCase().includes(filter.name.toLowerCase()) ||
                coin.asset.toLowerCase().includes(filter.name.toLowerCase())
            )
            .filter((c) =>
              tradesMarketList.favorite ? filter.includes(c.asset) : c
            )
            .filter((coin) => coin.area_title === filter.priceBy)
        );
      } else {
        setFilteredList(
          tradesMarketList.list.filter((c) =>
            favUserCoins.includes(c.asset_title)
          )
        );
      }
    }
  }, [tradesMarketList, filter]);

  const handleChangeRoot = (market) => {
    if (!openMarketList) {
      if (basic) {
        history.push(`/market/trade?market=${market}`);
        setAsideStatus(false);
        return;
      }
      setAsideStatus(false);
      return history.push(`/market/trade/${market}`);
    } else {
      setSearchMarket(market);
      setOpenMarketList(false);
    }
  };

  const showNumbers = (num, isIRT) => {
    if (isIRT) {
      return addCommaWithRialPrice(num);
    }
    return num;
  };
  return (
    <aside
      className={`${styles.container} ${
        !openMarketList
          ? asideStatus
            ? styles.show_aside
            : styles.hidden_aside
          : openMarketList
          ? styles.show_aside
          : styles.hidden_aside
      } ${clinetHeightPOS > 9 ? styles.full_view : styles.mini_view}`}
      style={xs}
    >
      {!openMarketList ? (
        asideStatus ? (
          <div
            className={styles.bg}
            onClick={() => setAsideStatus(false)}
          ></div>
        ) : null
      ) : openMarketList ? (
        <div
          className={styles.bg}
          onClick={() => setOpenMarketList(false)}
        ></div>
      ) : null}
      <div
        className={`changeBackgroundColorToHardBlack ${styles.aside} ${
          !openMarketList
            ? asideStatus
              ? styles.show_aside
              : styles.hidden_aside
            : openMarketList
            ? styles.show_aside
            : styles.hidden_aside
        }`}
      >
        <div className={styles.top_box}>
          <div className={`changeSearchFieldBoxDarkMode ${styles.box_search}`}>
            <RTL>
              <TextField
                className={classes.searchBarStyle}
                fullWidth
                size="small"
                sx={{ fontFamily: "IranSansWeb" }}
                value={filter.name}
                onChange={(e) => setFilter({ ...filter, name: e.target.value })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </RTL>
          </div>
          <div className={styles.market_filter}>
            <div className={styles.btns}>
              {!isEmpty(tradesMarketList) &&
                tradesMarketList.trading_areas.map((area) => (
                  <button
                    key={area}
                    disabled={isEmpty(
                      tradesMarketList.list.filter(
                        (c) => c.area_title === area.toUpperCase()
                      )
                    )}
                    className={`${styles.item} ${
                      filter.priceBy === area.toUpperCase()
                        ? styles.item_active
                        : "change_color_to_9D9EA8"
                    } `}
                    onClick={() =>
                      setFilter({
                        ...filter,
                        priceBy: area.toUpperCase(),
                      })
                    }
                  >
                    <span>
                      <img
                        alt={`${area}-icon`}
                        src={ButtonImages[area.toUpperCase()]}
                        width={18}
                        height={18}
                      />
                    </span>
                    {tradesMarketList.list.filter(
                      (c) => c.area_title === area.toUpperCase()
                    )[0]?.area_title_fa ?? "-"}
                  </button>
                ))}
              <button
                className={` ${styles.item} ${
                  filter.priceBy === "favorite"
                    ? styles.item_active
                    : "change_color_to_9D9EA8"
                } `}
                onClick={() =>
                  setFilter({
                    ...filter,
                    priceBy: "favorite",
                  })
                }
              >
                <span>
                  <img
                    alt={`favorite-icon`}
                    src={ButtonImages["favorite"]}
                    width={18}
                    height={18}
                  />
                </span>
                مورد علاقه
              </button>
            </div>
          </div>
        </div>
        <section
          className={styles.market_table}
          style={{ height: clinetHeightPOS > 9 ? "80%" : "69%" }}
        >
          <div className={`change_color_to_9D9EA8 ${styles.header}`}>
            <div className={styles.left}>نام ارز</div>
            <div className={styles.center}>آخرین قیمت</div>
            <div className={styles.right}>تغییر ۲۴ ساعته</div>
          </div>
          <div className={styles.table_one}>
            <InfiniteScroll
              dataLength={filteredList.slice(0, numberOfShow).length}
              height="80vh"
              endMessage={
                <p className="change_color_to_9D9EA8" style={centerStyle}>
                  اتمام نمایش لیست
                </p>
              }
              next={handleNext}
              hasMore={hasMoreItem}
            >
              {filteredList.slice(0, numberOfShow).map((coin, index) => {
                let percent =
                  coinAssets?.filter((c) => c?.symbol === coin.asset)[0]
                    ?.percent_change_24h ?? 0;
                return (
                  <div
                    key={index}
                    className={`${styles.list} ${
                      !openMarketList
                        ? currentTrade?.asset
                        : marketInFilter?.asset === coin.asset
                        ? "active-list-aside"
                        : ""
                    }`}
                    onClick={() => handleChangeRoot(coin.market)}
                  >
                    <div className={styles.left}>
                      <div className={styles.name_coin}>
                        <div className={styles.market_name}>
                          <span className={styles.asset_symbol}>
                            {coin.asset}{" "}
                          </span>
                          /
                          <span className={styles.area}>
                            {" "}
                            {coin.area_title}
                          </span>
                        </div>
                        <div className={`${styles.fa_coin}`}>
                          {coin.asset_title_fa}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`change_color_to_9D9EA8 eng-number-font ${styles.center}`}
                    >
                      {!openMarketList
                        ? showNumbers(
                            marketWSInfo[coin.market]?.last ?? 0,
                            coin.area_title === "IRT"
                          )
                        : showBetterPrice(coin.price)}
                    </div>
                    <div className={styles.right}>
                      <div
                        className={`eng-number-font ${
                          percent >= 0 ? "bg_green" : "bg_red"
                        } ${styles.box}`}
                      >
                        {percent}%
                      </div>
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        </section>
      </div>
    </aside>
  );
}
export default memo(AsideT);
