export const favoriteCoinsReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_FAV_COINS":
      return action.payload;
    case "ADD_FAV_COINS":
      return action.payload;
    case "REMOVE_FAV_COINS":
      return action.payload;

    default:
      return state;
  }
};
