const getThemeUsed = () => {
  const data = JSON.parse(localStorage.getItem("appThemeUsed")) || "dark";
  return data;
};
export const applicationTheme = (state = getThemeUsed(), action) => {
  switch (action.type) {
    case "CHOICE_DARK_MODE":
      return action.payload;
    case "CHOICE_LIGHT_MODE":
      return action.payload;
    default:
      return state;
  }
};
