import { Box, Stack, Typography } from "@mui/material";
import appIcons from "../../utils/appIcons";
import styles from "./AccountDetailsPage.module.scss";
import { Link } from "react-router-dom";

export default function DetailsBox(props) {
  const {
    title = "",
    value = "",
    fwdBtn = false,
    status = true,
    fwdLink,
  } = props;

  return (
    <Stack
      className={styles.container}
      flexDirection="row-reverse"
      alignItems="center"
      justifyContent="space-between"
      gap={1}
    >
      <Stack className={styles.subject} textAlign="right">
        <Typography fontSize={13} sx={{opacity:0.65}} mb={0.4}>{title}</Typography>
        <Typography fontSize={13.5}>{value}</Typography>
      </Stack>
      <Stack
        className={styles.status}
        flexDirection="row-reverse"
        alignItems="center"
        gap={1}
      >
        {fwdBtn && (
          <Link to={fwdLink}>
            <button>{fwdBtn}</button>
          </Link>
        )}
        <Box component="span" mt={0.8} data-statusnum={status}>
          {!status ? appIcons("closeIcon") : appIcons("confirmIcon")}
        </Box>
      </Stack>
    </Stack>
  );
}
