import { useContext, useState } from "react";
import { context } from "../../../context/context";
import { Link } from "react-router-dom";
//Components
import RTL from "../../../helpers/direction/RTL";
import TextField from "@mui/material/TextField";
//Styles and Icons
import styles from "./Login.module.scss";
import EyesIcon from "../../../styles/icon/eyes.svg";
import UserIcon from "../../../styles/icon/person_outline.svg";
import LockIcon from "../../../styles/icon/lock.svg";

export default function LoginTwo() {
  const [checkFocuse, setCheckFocuse] = useState("");
  const [invisiblePassword, setInvisiblePassword] = useState(true);
  const userLogin = useContext(context);
  const {
    setUserEmail,
    userEmail,
    userPassword,
    setUserPassword,
    handleLoginEmail,
    validatorLogin,
    setValidatorLogin,
    buttonDisabled,
  } = userLogin;

  return (
    <>
      {" "}
      <form onSubmit={(e) => handleLoginEmail(e)}>
        <section className={styles.inputs_place}>
          <div
            className={`changeTextFieldBackgroundToWBlack ${styles.email_place}`}
          >
            <RTL>
              <TextField
                className={styles.number_login_input}
                label="ایمیل"
                name="userEmail"
                type="email"
                color="success"
                onFocus={() => setCheckFocuse("email")}
                onBlur={() => setCheckFocuse("")}
                value={userEmail}
                onChange={(e) => {
                  setUserEmail(e.target.value);
                  setValidatorLogin({ ...validatorLogin, email: "" });
                }}
              />

              <div
                className={`${styles.icon_place} ${styles.first_input_icon} ${
                  checkFocuse === "email" ? styles.icon_place_active : ""
                }`}
              >
                <img src={UserIcon} alt="" className="convertPicToGreen" />
              </div>
            </RTL>
            <div className={styles.errorMessage}>{validatorLogin?.email}</div>
          </div>

          <div
            className={`changeTextFieldBackgroundToWBlack ${styles.pass_place}`}
          >
            <RTL>
              <TextField
                label="رمز عبور"
                color="success"
                type={invisiblePassword ? "password" : "text"}
                onFocus={() => setCheckFocuse("password")}
                onBlur={() => setCheckFocuse("")}
                value={userPassword}
                onChange={(e) => {
                  setUserPassword(e.target.value);
                }}
              />

              <div
                className={`${styles.icon_place} ${
                  checkFocuse === "password" ? styles.icon_place_active : ""
                }`}
              >
                <img src={LockIcon} alt="" className="convertPicToGreen" />
              </div>
              <div
                className={styles.icon_place_2}
                onClick={() => setInvisiblePassword(!invisiblePassword)}
              >
                <img src={EyesIcon} alt="" />
              </div>
            </RTL>
            <div className={styles.errorMessage}></div>
            <div className={styles.forget_password_text}>
              <Link to="/authorization/recovery-pass">
                <div className="font_color_white">فراموشی رمز عبور</div>
              </Link>
            </div>
          </div>
        </section>
        <div className={styles.button_container}>
          <button type="submit" disabled={buttonDisabled}>
            ورود
          </button>
          <Link to="/authorization/register">
            <div className={`font_color_white ${styles.register_div}`}>
              حساب کاربری ندارید؟ثبت نام کنید
            </div>
          </Link>
        </div>
        <div className={styles.place_of_checkbox_rules}>
          <Link to="/terms">
            <span>قوانین و مقررات</span>
          </Link>
        </div>
      </form>
    </>
  );
}
