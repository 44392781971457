import styles from "./OnlineIncrease.module.scss";
import { useState } from "react";
import { convertNumbers2English } from "../../../../utils/priceFunc";
import { isEmpty } from "lodash";
import { errorMessage } from "../../../../utils/messages";
import { onlinePaymentIncrease } from "../../../../services/adminServices";
import ArrowIcon from "../../../../styles/icon/arrowdown.svg";
import Num2persian from "num2persian";
import { showBetterBankCard } from "../../../../utils/addComma";
import WarningBox from "../../../Boxs/WarningBox";
import { SuccessMuiBtn } from "../../../MuiButtons";
import OpenCards from "./OpenCards";
import appIcons from "../../../../utils/appIcons";
export default function OnlineIncrease({ cards }) {
  const textNotification =
    "مبالغ واریزی فقط برای خرید از سایت میباشد و امکان برداشت مبلغ بدون خرید ارز وجود ندارد.";
  //
  const [userCart, setUserCart] = useState("");
  const [price, setPrice] = useState("");
  const [cardsList, openCardsList] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const handleOnlinePayment = async (e) => {
    e.preventDefault();
    if (!userCart) return errorMessage("کارت مورد نظر را انتخاب کنید");
    if (!price) return errorMessage("مبلغ را مشخص کنید");
    if (Number(price) > 25_000_000) {
      return errorMessage("سقف واریز روزانه 25,000,000 تومان میباشد.");
    }
    setDisableBtn(true);
    try {
      const body = { cardNumber: userCart, amount: price, app: true };
      const data = await onlinePaymentIncrease(body);
      if (data.data.message === "ok") {
        window.location.href = data.data.url;
      } else {
        errorMessage(data.data.message);
      }
    } catch (ex) {
      errorMessage("خطایی رخ داده مجددا تلاش کنید");
    } finally {
      setTimeout(() => {
        setDisableBtn(false);
      }, 500);
    }
  };

  const handleChangePrice = (e) => {
    let amount = e.target.value;
    if (e.target.value < 0) amount = Math.abs(amount);
    setPrice(convertNumbers2English(String(amount)));
  };
  const handleToggleCardsList = () => {
    openCardsList((prev) => !prev);
  };
  const onChangeCardSelected = (cardNumber) => {
    handleToggleCardsList();
    setUserCart(cardNumber);
  };
  return (
    <>
      <div className={styles.container}>
        <div>
          <WarningBox>{textNotification}</WarningBox>
        </div>
        <form onSubmit={(e) => handleOnlinePayment(e)}>
          <div className={styles.inputs_container}>
            <p className="font_color_white">
              کارتی که قصد خرید با آن را دارید انتخاب کنید:
            </p>
            <div
              className={styles.userCardLabel}
              onClick={handleToggleCardsList}
            >
              <p>
                {userCart ? showBetterBankCard(userCart) : "انتخاب کارت بانکی"}
              </p>
              <div>{appIcons("ArrowDownIcon")}</div>
            </div>
            <input
              className={`changeBackgroundColorToHardBlack font_color_white ${styles.amount}`}
              type="number"
              inputMode="numeric"
              placeholder="مبلغ واریز(تومان)"
              onChange={handleChangePrice}
              value={price}
            />
            <div className={styles.persian_price}>
              {price
                ? Num2persian(convertNumbers2English(price)) + " تومان"
                : ""}
            </div>
            <SuccessMuiBtn text="پرداخت" type="submit" disabled={disableBtn} />
          </div>
        </form>
      </div>
      <OpenCards
        visible={cardsList}
        items={cards}
        onRequestClose={handleToggleCardsList}
        onChange={onChangeCardSelected}
        active={userCart}
      />
    </>
  );
}
