import styled from "@emotion/styled";
import { InputAdornment, TextField } from "@mui/material";
import styles from "./MuiInput.module.scss";
const EditedTextField = styled(TextField)({
  // "& label.Mui-focused": {
  //   color: "green"
  // },
  "& .MuiInput-underline:after": {
    // borderBottomColor: "green"
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "var(--main-color)",
      background:"var(--temp-color)",
    },
    "&:hover fieldset": {
      borderColor: "var(--main-color)"
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--primary-color)"
    }
  }
});

export default function MuiInput(props) {
  return (
    <EditedTextField
      label={props.label}
      placeholder={props.placeholder}
      id={props.id}
      className={styles.mui_container}
      fullWidth={props.fullWidth}
      value={props.value}
      readOnly={props.readOnly}
      onChange={props.onChange}
      disabled={props.disabled}
      inputMode={props.inputMode}
      size={props.size}
      InputProps={{
        type: props.type,
        startAdornment: props.startAd ? (
          <InputAdornment position="start">
            <span className={styles.ads}>{props.startAd}</span>
          </InputAdornment>
        ) : null,
        endAdornment: props.endAd ? (
          <InputAdornment position="start">
            <span className={styles.ads}>{props.endAd}</span>
          </InputAdornment>
        ) : null
      }}
    />
  );
}
