import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { DangerMuiBtn, SuccessMuiBtn } from "../../components/MuiButtons";
import { Alert, Checkbox, FormControlLabel, Stack } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "var(--main2-color)",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  borderRadius: 2,
};

export default function BankReportModal(props) {
  const [acceptRules, setAcceptRules] = React.useState(false);
  const { children, onAccept, onRequestClose, isOpen } = props;

  const handleAcceptRules = (e) => {
    setAcceptRules(e.target.checked);
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        closeAfterTransition
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <Alert
              severity="warning"
              sx={{ direction: "rtl", fontFamily: "IranSansWeb" }}
            >
              اخطار: نیاز به تایید برای ادامه فعالیت
            </Alert>
            {children}
            <Stack>
              <FormControlLabel
                sx={{ direction: "rtl", padding: 0, margin: 0 }}
                control={
                  <Checkbox
                    size="small"
                    onChange={handleAcceptRules}
                    checked={acceptRules}
                  />
                }
                label={
                  <span style={{ userSelect: "none", fontSize: "13px",color:"var(--secondary-color)" }}>
                    متن بالا را مطالعه کرده و میپذیرم
                  </span>
                }
              />
            </Stack>
            <Stack direction="row" spacing={1} marginTop={1}>
              <DangerMuiBtn text="لغو" onClick={onRequestClose} />
              <SuccessMuiBtn
                text="تایید"
                onClick={onAccept}
                disabled={!acceptRules}
              />
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
