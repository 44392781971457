import {  Container, Stack, Typography } from "@mui/material";
import React from "react";
import PrimaryHeader from "../components/Headers/PrimaryHeader";
import NotFoundPic from "../styles/pics/404.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PaperButton from "../components/Buttons/PaperButton";

function Page404() {
  const history = useHistory();
  return (
    <Container
      disableGutters
      sx={{ paddingInline: 2, boxSizing: "border-box" }}
    >
      <PrimaryHeader title="بازگشت" fixButton />
      <Stack mb={14}>
        <Typography
          textAlign="center"
          sx={{ color: "var(--secondary-color)" }}
          component="p"
          fontSize={20}
          mb={2}
          mt={1}
        >
          404
        </Typography>
        <Typography
          textAlign="center"
          sx={{ color: "var(--secondary-color)" }}
          component="p"
        >
          اوه! ببخشید
        </Typography>
        <Typography
          textAlign="center"
          sx={{ color: "var(--secondary-color)" }}
          component="p"
        >
          چیزی که دنبالش میگردی اینجا نیست
        </Typography>

        <div>
          <img
            src={NotFoundPic}
            alt="404-Page"
            width="100%"
            style={{ maxHeight: "350px" }}
          />
        </div>
        <PaperButton
          onClick={() => history.replace("/")}
          style={{ zIndex: "1" }}
        >
          بازگشت به صحفه اصلی
        </PaperButton>
      </Stack>
    </Container>
  );
}

export default Page404;
