const PassVisibility = (InputId) => {
  const currentInput = document.getElementById(InputId);
  switch (currentInput.type) {
    case "text":
      currentInput.type = "password";
      break;
    case "password":
      currentInput.type = "text";
      break;
    default:
      break;
  }
};
export default PassVisibility;
