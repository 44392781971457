import { Container, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import { Link } from "react-router-dom";
import HeadRoutes from "../pageComponents/HomePage/HeadRoutes";
import HomeBanners from "../pageComponents/HomePage/HomeBanners";
import HomeTable from "../pageComponents/HomePage/HomeTable";
import MarketRoute from "../pageComponents/HomePage/MarketRoute";
import TopCoins from "../pageComponents/HomePage/TopCoins";
import TopHome from "../pageComponents/HomePage/TopHome";
import RandomCoin from "../pageComponents/HomePage/RandomCoin";
import Advertising from "../components/Advertising";

export default function HomeV2() {
  return (
    <Container disableGutters sx={{boxSizing:"border-box",paddingX:0.3}}>
      <Stack mb={14}>
        <Stack
          marginTop={1.5}
          marginBottom={0.5}
          sx={{ boxSizing: "border-box" }}
        >
          <TopHome />
        </Stack>
        <Stack marginTop={0.5}>
          <HeadRoutes />
        </Stack>
        <Stack mt={1}>
          <TopCoins />
        </Stack>
        <Grid container mt={1}>
          <Grid item xs={5.9}>
            <HomeBanners />
          </Grid>
          <Grid item xs={0.2}></Grid>
          <Grid item xs={5.9}>
            <Stack>
              <RandomCoin />
            </Stack>
            <Stack mt={1}>
              <Link to="/buy/USDT?type=IRT">
                <MarketRoute />
              </Link>
            </Stack>
          </Grid>
        </Grid>
        <Stack mt={1}>
          <HomeTable />
        </Stack>
      </Stack>
      {/** This component will show Advertising  -> */}
      <Advertising />
      {/** This component will show Advertising  <- */}
    </Container>
  );
}
