export const CopyWithInputID = (inputId) => {
  let copyThisText = document.getElementById(inputId);
  copyThisText.select();
  copyThisText.setSelectionRange(0, 99999);
  navigator.clipboard.writeText(copyThisText.value);
};

export const CopyThisText = (value) => {
  let copyThisText = value;
  navigator.clipboard.writeText(copyThisText);
};
