import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import styles from "./WalletPage.module.scss";
import { Link } from "react-router-dom";
import { addComma } from "../../utils/addComma";

function HeaderWallet({ total, daily }) {
  return (
    <Stack
      sx={{ backgroundColor: "var(--third2-color)", direction: "rtl" }}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      minHeight={100}
      pt={2}
      pb={4}
      px={2}
    >
      <Stack flex={1}>
        <Typography
          component="span"
          color="var(--secondary-color)"
          fontSize={13}
          mb={1}
        >
          کل موجودی
        </Typography>
        <Box
          borderRadius={1}
          maxWidth={150}
          minWidth={60}
          width="max-content"
          sx={{ backgroundColor: "var(--main2-color)" }}
          mt={0.5}
          paddingY={0.8}
          paddingX={1}
        >
          <span className={styles.boxTitle}>
            {total["USDTTotal"]} <span className={styles.tagTitle}>USDT</span>
          </span>
        </Box>
        <Box
          borderRadius={1}
          maxWidth={150}
          minWidth={60}
          width="max-content"
          sx={{ backgroundColor: "var(--main2-color)" }}
          mt={0.5}
          paddingY={0.8}
          paddingX={1}
        >
          <span className={styles.boxTitle}>
            {addComma(total["IRTTotal"] ?? 0)}{" "}
            <span className={styles.tagTitle}>IRT</span>
          </span>
        </Box>
      </Stack>
      <Stack flex={0.09}></Stack>
      <Stack flex={1} alignItems="flex-end">
        <span className={styles.wthTitle}>
          میزان برداشت <span className={styles.titleLeft}>(24H)</span>
        </span>
        <Box
          minWidth={120}
          borderRadius={1}
          sx={{ backgroundColor: "var(--main2-color)" }}
          mt={0.5}
          paddingY={0.8}
          paddingX={1}
        >
          <span
            className={styles.boxTitle}
            style={{
              textAlign: "center",
              direction: "ltr",
              color: "var(--danger-color)",
            }}
          >
            {daily ?? "0.00"} <span className={styles.tagTitle}>IRT</span>
          </span>
        </Box>
        <Link to="/my-profile/transactions_two">
          <Box
            borderRadius={1}
            minWidth={120}
            sx={{ backgroundColor: "var(--primary-color)" }}
            mt={0.5}
            paddingY={0.4}
            paddingX={1}
            textAlign="center"
          >
            <Typography component="span" color="#fff" fontSize={11}>
              تاریخچه واریز و برداشت
            </Typography>
          </Box>
        </Link>
      </Stack>
    </Stack>
  );
}

export default HeaderWallet;
