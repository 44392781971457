import { useContext, useEffect } from "react";
import MuiInputOne from "../../../../market/MuiInput/MuiInputOne";
import { tradesCTX } from "../../../../../context/tradesCTX";
import MuiInputTwo from "../../../../market/MuiInput/MuiInputTwo";
import PercentBalance from "../../../../PercentBalance";
import HelperText2 from "../HelperTexts/HelperText2";

export default function MarketPrice({ type }) {
  const marketTradeContext = useContext(tradesCTX);
  const {
    //* --> State's Of Price And Amount
    orderMarketAmount,
    orderMarketPrice,
    setOrderMarketAmount,
    setOrderMarketPrice,
    areaBalance,
    assetBalance,
    //* --> State's Of Price And Amount
    //* --> Market Name
    currentTrade,
    //* <-- Market Name
    buyMarketSlider,
    setBuyMarketSlider,
    sellMarketSlider,
    setSellMarketSlider,
  } = marketTradeContext;

  useEffect(() => {
    if (buyMarketSlider) {
      let percent = +buyMarketSlider * 0.01;
      let amount = percent * areaBalance;
      setOrderMarketPrice(amount);
    }
  }, [buyMarketSlider]);
  useEffect(() => {
    if (sellMarketSlider) {
      let percent = +sellMarketSlider * 0.01;
      let amount = percent * assetBalance;
      setOrderMarketAmount(amount);
    }
  }, [sellMarketSlider]);

  return (
    <div>
      {type === "buy" ? (
        <>
          <div className="box-text-markets">
            <p>خرید به ترتیب بهترین قیمت بازار</p>
          </div>
          <div className="mt-15">
            <MuiInputOne
              amount={orderMarketPrice}
              setAmount={setOrderMarketPrice}
              decimalScale={currentTrade?.buy_places}
              adorment={currentTrade?.area_title}
              placeholder={`(${currentTrade?.area_title_fa ?? "-"})مقدار`}
            />
          </div>
          <div className="mt-15">
            <PercentBalance
              value={buyMarketSlider}
              setValue={setBuyMarketSlider}
            />
          </div>
           <div className="mt-30">
            <HelperText2 symbol={currentTrade?.area_title} balance={areaBalance} />
          </div>
        </>
      ) : (
        <>
          <div className="box-text-markets">
            <p>فروش به ترتیب بهترین قیمت بازار</p>
          </div>
          <div className="mt-15">
            <MuiInputOne
              amount={orderMarketAmount}
              setAmount={setOrderMarketAmount}
              adorment={currentTrade?.asset}
              decimalScale={currentTrade?.sell_places}
              placeholder={`(${currentTrade?.asset_title_fa ?? "-"})مقدار`}
            />
          </div>
          <div className="mt-15">
            <PercentBalance
              value={sellMarketSlider}
              setValue={setSellMarketSlider}
            />
          </div>
          <div className="mt-30">
            <HelperText2 symbol={currentTrade?.asset} balance={assetBalance} />
          </div>
        </>
      )}
    </div>
  );
}
