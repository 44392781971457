import { memo } from "react";
import styles from "./LastWithdrawalsTable.module.scss";
import { Box, Container, Typography } from "@mui/material";
import moment from "jalali-moment";
import { addCommaWithRialPrice } from "../../../../utils/addComma";
import { _bankStatus } from "../../../../utils/_appStatus";
import { useState } from "react";
import { FetchCancelBanWithdrawal } from "../../../../services/userServices";
import { errorMessage, successMessage } from "../../../../utils/messages";
import { isEmpty } from "lodash";
import { ShowJalaliMoment } from "../../../../utils/convertMoment";

function LastWithdrawalsTable(props) {
  const { list = [], setList } = props;
  const [disabledButtons, setDisabledButtons] = useState([]);

  const handleCancelWithdrawal = async (id) => {
    setDisabledButtons((prev) => [...prev, id]);
    try {
      const body = JSON.stringify({ id });
      const { data, status } = await FetchCancelBanWithdrawal(body);
      if (status === 200) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          successMessage(data?.message ?? "Success!");
          setList((prev) => {
            const updateList = prev.map((c) => {
              if (c.id === id) {
                return { ...c, status: "CANCELED" };
              }
              return c;
            });
            return updateList;
          });
        }
      }
    } catch {
      errorMessage("Network/Server Error!");
    } finally {
      setDisabledButtons((prev) => prev.filter((c) => c !== id));
    }
  };
  return (
    <Container disableGutters sx={{ direction: "rtl" }}>
      <Typography
        sx={{ color: "var(--secondary2-color)" }}
        fontSize={13}
        borderBottom="0.5px solid"
        paddingBottom={0.5}
      >
        لیست 10 برداشت آخر
      </Typography>
      <Box>
        {!isEmpty(list) ? (
          list.map((ctx) => {
            const status = _bankStatus[ctx["status"]] ?? {};
            return (
              <div key={ctx["id"]} className={styles.item_container}>
                <div className={styles.item_group}>
                  <Typography component="span">تاریخ</Typography>
                  <Typography component="p">
                    {ShowJalaliMoment(ctx["date"])}
                  </Typography>
                </div>
                <div className={styles.item_group}>
                  <Typography component="span">مقدار (تومان)</Typography>
                  <Typography component="p" data-color="danger">
                    {addCommaWithRialPrice(ctx.amount)}-
                  </Typography>
                </div>
                <div className={styles.item_group}>
                  <Typography component="span">شبای مقصد</Typography>
                  <Typography component="p">{ctx.ibanNumber}</Typography>
                </div>
                <div className={styles.item_group}>
                  <Typography component="span">وضعیت</Typography>
                  <Typography component="p" data-color={status["type"]}>
                    {status["name"]}
                  </Typography>
                </div>
                <div className={styles.item_group}>
                  <Typography component="span">کارمزد (تومان)</Typography>
                  <Typography component="p" data-color="danger">
                    {ctx["fee"]}
                  </Typography>
                </div>
                <div className={styles.item_group}>
                  <Typography component="span">کد تراکنش</Typography>
                  <Typography component="p">
                    {ctx["details"] ? ctx["details"] : "-"}
                  </Typography>
                </div>
                {ctx["status"] === "WAITING" && (
                  <div className={styles.item_group}>
                    <Typography component="span">عملیات</Typography>
                    <button
                      className={styles.cancel}
                      onClick={() => handleCancelWithdrawal(ctx["id"])}
                      disabled={disabledButtons.includes(ctx["id"])}
                    >
                      لغو برداشت
                    </button>
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <Typography component="p" className={styles.noWthFound}>
            برداشت ریالی یافت نشد
          </Typography>
        )}
      </Box>
    </Container>
  );
}

export default memo(LastWithdrawalsTable);