const getAppUsePrice = () => {
  const data =
    JSON.parse(localStorage.getItem("application_price")) || "IRT";
  return data;
};
export const applicationUserPrice = (state = getAppUsePrice(), action) => {
  switch (action.type) {
    case "CHOICE_USDT_PRICE":
      return action.payload;
    case "CHOICE_IRT_PRICE":
      return action.payload;
    default:
      return state;
  }
};
