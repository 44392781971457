import { memo, useContext } from "react";
import { tradesCTX } from "../../../context/tradesCTX";
import styles from "./MenuMT.module.scss";

const radioButtons = [
  {
    name: "market-list-choice",
    value: "",
    title: "لیست پیشفرض",
    id: "default-list",
  },
  {
    name: "market-list-choice",
    value: "buy",
    title: "لیست خرید",
    id: "buy-list",
  },
  {
    name: "market-list-choice",
    value: "sell",
    title: "لیست فروش",
    id: "sell-list",
  },
];
function DetailsMenuMT() {
  const TradesContext = useContext(tradesCTX);
  const { showSortListMenu, setShowSortListMenu, setSortListBy, sortListBy } =
    TradesContext;
  const handleClose = () => {
    setShowSortListMenu(false);
  };
  const handleChangeMarket = (e) => {
    setSortListBy(e.target.value);
    handleClose();
  };
  return (
    <>
      {showSortListMenu ? (
        <div className={styles.bg} onClick={handleClose}></div>
      ) : null}
      <div
        className={`${styles.menu}  change_to_151C30 ${
          showSortListMenu ? styles.visible : styles.hidden
        }`}
      >
        <h5 className="change_color_to_E1E2E4">نمایش لیست</h5>
        <div className={styles.items}>
          {radioButtons.map((c, i) => (
            <div className={`change_color_to_E1E2E4 ${styles.item}`} key={i}>
              <input
                className={styles.radio_market}
                type="radio"
                id={c.id}
                name={c.name}
                value={c.value}
                hidden
                checked={sortListBy === c.value}
                onChange={handleChangeMarket}
              />
              <label htmlFor={c.id}>
                <div
                  className={`${styles.label} ${
                    sortListBy === c.value ? "active-menu-market-type" : ""
                  }`}
                >
                  <div className={styles.checkbox}></div>
                  <div className={styles.title}>{c.title}</div>
                </div>
              </label>
            </div>
          ))}
        </div>
        <div className={styles.btn}>
          <button
            onClick={handleClose}
            className="active-menu-market-type change_color_to_E1E2E4"
          >
            لغو
          </button>
        </div>
      </div>
    </>
  );
}
export default memo(DetailsMenuMT);
