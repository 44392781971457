import {  useEffect, useState } from "react";
import { useLocation } from "react-router";
//* --> (Redux) - Actions, Functions, State's
import { useDispatch, useSelector } from "react-redux";
import {
  hiddenAppMenu,
  showAppMenu,
} from "../../../../actions/visibiltyOfMenu";
//* --> Components
import FooterT from "../../../TradeLayout/FooterT";
import HeaderT from "../../../TradeLayout/HeaderT";
import OrdersT from "../../../TradeLayout/OrdersT";
//* --> Context
import { ArzfiChart } from "../../../../utils/ArzfiChart";
import OrdersContext from "../../../../context/OrdersContext";
import { tradesCTX } from "../../../../context/tradesCTX";
//* --> Image
import chartBackground from "../../../../styles/pics/chart_default.jpg";
import AsideT from "../../../TradeLayout/AsideT";

export const TRADE_TITLE = [
  { title: "limit", label: "لیمیت" },
  { title: "market", label: "مارکت" },
  { title: "stop-limit", label: "استاپ-لیمیت" },
  { title: "otc", label: "معمولی" },
];
export const TRADE_TYPE = [
  { title: "BUY", label: "خرید", color: "color_green" },
  { title: "SELL", label: "فروش", color: "color_red" },
];

export default function Trade() {
  const appTheme = useSelector((state) => state.appTheme);
  useEffect(() => {
    const handleShowPOS = () => {
      let top = window.pageYOffset || document.documentElement.scrollTop;
      setClientHeightPOS(top);
    };

    document.addEventListener("scroll", handleShowPOS);
    return () => document.removeEventListener("scroll", handleShowPOS);
  }, []);

  const location = useLocation();
  //* --> Access URL QUERY PARAMS
  const [market, setMarket] = useState("");
  const [clinetHeightPOS, setClientHeightPOS] = useState(0);
  useEffect(() => {
    let marketName = "";
    if (location?.search?.split("=")[1]) {
      marketName = location?.search?.split("=")[1];
    }
    setMarket(marketName);
  }, [location]);
  //* <-- Access URL QUERY PARAMS

  //* --> Access Dispatch For Redux
  const dispatch = useDispatch();
  //* <-- Access Dispatch For Redux

  //* --> Hidden Menu-App
  useEffect(() => {
    dispatch(hiddenAppMenu());
    return () => dispatch(showAppMenu());
  }, [dispatch]);
  //* <-- Hidden Menu-App

  //* --> RETURN THE COMPONENTS

  return (
    <>
      <OrdersContext marketName={market}>
        <section>
          <header>
            <HeaderT />
          </header>
          <AsideT clinetHeightPOS={clinetHeightPOS} basic />
          <main>
            <div
              style={{
                width: "100%",
                height: "310px",
                backgroundImage: `url(${chartBackground})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <tradesCTX.Consumer>
                {(value) => {
                  let symbol = value.currentTrade.asset;
                  let area = value.currentTrade.trading_area;
                  let allow = false;
                  let ArzfiSymbol = "";
                  if (symbol && area) {
                    ArzfiSymbol = `ArzFi:${symbol}/${area}`;
                    allow = true;
                  }
                  return (
                    allow && (
                      <ArzfiChart
                        symbol={ArzfiSymbol}
                        isDarkMode={appTheme === "dark"}
                      />
                    )
                  );
                }}
              </tradesCTX.Consumer>
            </div>
            <div style={{ marginBottom: "70px", marginTop: "5px" }}>
              <OrdersT />
            </div>
          </main>
          <footer style={{ width: "100%", position: "fixed", bottom: "0" }}>
            <FooterT />
          </footer>
        </section>
      </OrdersContext>
    </>
  );
}
