import { Stack } from "@mui/system";
import axios from "axios";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { memo, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddAppMiniBanners } from "../../../actions/AddAppBannerAction";
import ProgressLoading from "../../../components/Loadings/ProgressLoading";
import PrimarySwiper from "../../../components/Swipers/PrimarySwiper";
import configService from "../../../services/config.json";
//# STYLES
import styles from "./HomeBanners.module.scss";

function HomeBanners() {
  const dispatch = useDispatch();
  const MiniBanners = useSelector((state) => state.miniBanners);

  useLayoutEffect(() => {
    const Controller = new AbortController();
    if (isEmpty(MiniBanners)) {
      (async () => {
        const { data, status } = await axios.get(
          `${configService.blog_url}minibanners`,
          {
            headers: [],
            signal: Controller.signal,
          }
        );

        //#
        if (status === 200) {
          dispatch(AddAppMiniBanners(data));
        }
      })();
    }
    return () => {
      Controller.abort();
    };
  }, [dispatch]);
  const handleChangeChangeWindow = (url) => {
    if (url) {
      return window.open(url);
    }
  };

  const SwiperItems = useMemo(() => {
    if (!isEmpty(MiniBanners)) {
      return MiniBanners.map((ctx, idx) => {
        const ORG_IMAGE = ctx["image"][0]?.["url"];
        const URL = ctx["url"];
        return (
          <div
            className={styles.banner_container}
            key={idx}
            onClick={() => handleChangeChangeWindow(URL)}
          >
            <div className={styles.logo}>
              <img
                src="/images/logo.png"
                alt="ArzFi-Crypto-Logo"
                width={45}
                height={30}
              />
            </div>
            <div className={styles.title}>
              <strong>{ctx["title"]}</strong>
            </div>
            <div className={styles.desc}>
              <strong>{ctx["description"]}</strong>
            </div>
            <div className={styles.pic_container}>
              <div className={styles.pic}>
                {ORG_IMAGE && (
                  <img
                    src={configService["blog_url"] + ORG_IMAGE}
                    alt={ctx["title"]}
                    width="100%"
                    height="100%"
                  />
                )}
              </div>
            </div>
          </div>
        );
      });
    }
    return [];
  }, [MiniBanners]);
  return (
    <Stack className={styles.container}>
      {!isEmpty(MiniBanners) ? (
        <PrimarySwiper items={SwiperItems} inerval={5000} disableDot />
      ) : (
        <Stack height="100%" justifyContent="center">
          <ProgressLoading />
        </Stack>
      )}
    </Stack>
  );
}
export default memo(HomeBanners);
