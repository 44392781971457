import { memo } from "react";
import styles from "./HelperText.module.scss";

function HelperText2(props) {
  const { symbol, balance } = props;
  return (
    <div className={`${styles.container} change_color_to_9D9EA8`}>
      <div className={styles.sections}>
        <div className={styles.title}>موجودی:</div>
        <div className={styles.desc}>
          <span className="eng-number-font">{balance}</span>
          <span className={styles.symbols}>{symbol}</span>
        </div>
      </div>
    </div>
  );
}
export default memo(HelperText2);
