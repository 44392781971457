import { useEffect } from "react";
import { useHistory } from "react-router";

//Styles and Icons
import styles from "./FAQ.module.scss";
import ArrowBackIcon from "../../../styles/icon/arrow_back_fill.svg";
import ArrowRightIcon from "../../../styles/icon/arrow_wallet.svg";
export default function FAQ() {
  const history = useHistory();
  useEffect(() => {
    let acc = document.getElementsByClassName("accordion-for-messages");
    let panel = document.getElementsByClassName("panel-buttons");

    for (var i = 0; i < acc.length; i++) {
      acc[i].onclick = function () {
        let setClasses = !this.classList.contains("active-faq");
        setClass(acc, "active-faq", "remove");
        setClass(panel, "show", "remove");

        if (setClasses) {
          this.classList.toggle("active-faq");
          this.nextElementSibling.classList.toggle("show");
        }
      };
    }

    function setClass(els, className, fnName) {
      for (let i = 0; i < els.length; i++) {
        els[i].classList[fnName](className);
      }
    }
  }, []);
  const DATA_OF_FAQS = [
    {
      title: "ارزفی؟",
      description:
        "ارزفی جامع ترین سیستم مبادلات ارز دیجیتال در ایران است که با همت جمعی از جوانان با انگیزه راه اندازی شده است",
    },
    {
      title: "ارز دیجیتال چیست؟",
      description:
        "ارز دیجیتال ارزهایی هستند که به صورت الکترونیکی ذخیره و منتقل می‌شوند و مبنای آن‌ها صفر و یک است.   ",
    },
    {
      title: "چگونه در صرافی ارزفی ثبت نام کنیم؟",
      description:
        "شما میتوانید با ورود به سایت ارزفی روی لینک ثبت نام کلیک کرده و مراحل ثبت نام را کامل کنید",
    },
    {
      title: "چرا باید احراز هویت انجام دهیم؟",
      description:
        "با توجه به کلاهبرداری های اخیر در زمینه انتقال دارایی در دنیای مجازی برای اینکه صرافی ارزفی از صحت اطلاعات وارد شده(اطلاعات هویتی و بانکی) اطمینان حاصل نماید نیاز هست که احراز هویت شما تایید شود.",
    },
    {
      title: "چگونه احراز هویت کنیم؟",
      description:
        "برای احراز هویت لازم است کارت ملی یا ( شناسنامه جدید یا قدیمی یا پاسپورت با اعتبار بیش از یک سال ) همراه با متنی که با دست خط خوانای شخص کاربر نوشته شده باشد به صورت فرمتی که در سایت وجود دارد در محل مذبور ارسال نمایید تا هویت شما تأیید گردد . لازم به ذکر است که تصویر ارسالی از سوی شما باید به صورتی باشد که ، چهره شخص متقاضی و تمامی اطلاعات مندرج در کارت ملی (شناسنامه یا پاسپورت) و متن خواسته شده کاملا واضح و خوانا باشد .",
    },
    {
      title: "چگونه خرید و فروش ارز دیجیتال را انجام دهیم؟",
      description:
        "خرید و فروش ارز دیجیتال در صرافی ارزفی کاملا راحت و ساده میباشد.بعد از ثبت نام در صرافی و انجام مراحل احراز هویت شما میتوانید با ورود به بخش خرید یا فروش سفارش خودتان را ثبت نمایید.",
    },
    {
      title: "خرید و فروش ارز در ارزفی چقدر زمان میبرد؟",
      description:
        "خرید و فروش در ارزفی به صورت لحظه ای انجام میشود و هر زمان که ارزی را خریداری کنید در همان لحظه به کیف پول شما واریز میشود . هر زمان که ارز خود را بفروشید ریال آن به کیف پول شما اضافه خواهد شد .",
    },
    {
      title: "خرید و فروش ارز با جه دارایی هایی ممکن است؟",
      description: "در سایت ارزفی خرید و فروش بر بستر ریال ایران انجام میشود",
    },
    {
      title: "کیف پول چیست؟",
      description:
        "شما برای نگه داری ارز های خریداری شده نیاز به کیف پول الکترونیک دارید که سایت ارزفی توانسته امن ترین و مطمئن ترین بستر را برای کیف پول اختصاصی خود فراهم آورد.",
    },
    {
      title: "میزان کارمزد شبکه ارزفی چقدر است؟",
      description:
        "صرافی ارزفی توانسته است بستری را فراهم آورد که کاربران این سایت بتوانند با کمترین میزان کارمزد خرید و فروش ارز های خود را انجام دهند.",
    },
    {
      title: "اعتبار کاریری چیست؟",
      description:
        "به میزان مبلغ ریالی که شما در صرافی ارزفی نگه میدارید اعتبار کاربری گفته میشود که هر موقع از شبانه روز میتوانید با آن از خدمات صرافی استفاده کرده یا درخواست واریز به حساب بانکی خود را بدهید",
    },
    {
      title: "هزینه انتقال شبکه چیست؟",
      description:
        "هر یک از ارز های دیجیتال بر پایه یکی از شبکه های موجود در بازار ارز دیجیتال فعالیت میکند و هر کدام از این شبکه ها برای هر انتقال مبلغی را به عنوان کارمزد شبکه از شما میگیرد که این رقم برای هر ارز متغیر میباشد که در هنگام خرید یا فروش ارز این مبلغ به شما نمایش داده میشود.",
    },
  ];
  return (
    <div className={styles.container}>
      <div className={styles.BackArrow}>
        <img
          src={ArrowBackIcon}
          alt="BACK"
          width={15}
          height={15}
          onClick={() => history.goBack()}
          className="convertPicToWhite"
        />
      </div>
      <div className={`font_color_white ${styles.title_component}`}>
        سوالات متداول
      </div>
      <main className={styles.container_of_FAQs}>
        {DATA_OF_FAQS?.map((data) => (
          <div
            className={`create_border_green_darkMode ${styles.item_of_FAQ_container}`}
            key={data.title}
          >
            <div
              className={`accordion-for-messages ${styles.subject_message_container}`}
            >
              <div className={`font_color_white ${styles.title_of_meesage}`}>
                {data.title}
              </div>
              <div className={styles.ArrowIconContainer}>
                <img
                  src={ArrowRightIcon}
                  alt=""
                  width={15}
                  height={15}
                  className="ArrowImgFaq convertPicToGreen"
                />
              </div>
            </div>
            <div className="panel-buttons">
              <div className={styles.text_message_container}>
                <div
                  className={`changeBackgroundToGreen ${styles.line_1}`}
                ></div>
                <div className={`font_color_white ${styles.textOfMessage}`}>
                  {data.description}
                </div>
              </div>
            </div>
          </div>
        ))}
      </main>
    </div>
  );
}
