import { useContext, useState, useEffect } from "react";
import { context } from "../../../context/context";
import { Link } from "react-router-dom";
import styles from "./Register.module.scss";
import UserIcon from "../../../styles/icon/person_outline.svg";
import ReferralIcon from "../../../styles/icon/referralcode.svg";
import TextField from "@mui/material/TextField";
import RTL from "../../../helpers/direction/RTL";
// import "./Login_global.scss";
export default function RegisterMail({ refer }) {
  const registerCTX = useContext(context);
  const [rulesAccept, setRulesAccept] = useState(false);
  const {
    newReferral,
    setNewReferral,
    newUserEmail,
    setNewUserEmail,
    handleRegisterEmail,
    validatorRegister,
    setValidatorRegister,
    disableNewRef,
  } = registerCTX;
  const [checkFocuse, setCheckFocuse] = useState("");
  //* Check --> Referral Code's

  return (
    <>
      <form onSubmit={(e) => handleRegisterEmail(e)}>
        <section className={styles.inputs_place}>
          <div
            className={`changeTextFieldBackgroundToWBlack ${styles.email_place}`}
          >
            <RTL>
              <TextField
                className={styles.number_login_input}
                name="newMail"
                label="ایمیل"
                color="success"
                onFocus={() => setCheckFocuse("email")}
                onBlur={() => setCheckFocuse("")}
                value={newUserEmail}
                onChange={(e) => {
                  setNewUserEmail(e.target.value);
                  setValidatorRegister({ ...validatorRegister, email: "" });
                }}
              />
              <div
                className={`${styles.icon_place} ${styles.first_input_icon} ${
                  checkFocuse === "email" ? styles.icon_place_active : ""
                }`}
              >
                <img src={UserIcon} alt="" className="convertPicToGreen" />
              </div>
            </RTL>
            <div className={styles.errorMessage}>
              {validatorRegister?.email}
            </div>
          </div>

          <div
            className={`changeTextFieldBackgroundToWBlack ${styles.pass_place}`}
          >
            <RTL>
              <TextField
                name="newUserPass"
                label="کد معرف(اختیاری)"
                color="success"
                onFocus={() => setCheckFocuse("referral")}
                onBlur={() => setCheckFocuse("")}
                value={newReferral}
                onChange={(e) => {
                  setNewReferral(e.target.value);
                }}
                disabled={disableNewRef}
              />

              <div
                className={`${styles.icon_place} ${
                  checkFocuse === "referral" ? styles.icon_place_active : ""
                }`}
              >
                <img src={ReferralIcon} alt="" className="convertPicToGreen" />
              </div>
            </RTL>
          </div>
          <div className={`font_color_white ${styles.place_of_checkbox_rules}`}>
            <input
              type="checkbox"
              id="checkBox_accept_rules_for_register"
              value={rulesAccept}
              onChange={(e) => setRulesAccept(e.target.checked)}
            />
            <label htmlFor="checkBox_accept_rules_for_register">
              قوانین سایت را میپذیرم
            </label>{" "}
            <span className={styles.blue_font_color}>
              <Link to="/terms">(قوانین و مقررات)</Link>
            </span>
          </div>
        </section>
        <div className={styles.button_container}>
          <button type="submit" disabled={!rulesAccept}>
            ادامه
          </button>
          <Link to="/authorization/login">
            <div className={`font_color_white ${styles.register_div}`}>
              قبلا ثبت نام کردید؟ وارد شوید
            </div>
          </Link>
        </div>
      </form>
    </>
  );
}
