import { Stack } from "@mui/system";
import { useState } from "react";
import { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { coinUsersBalance } from "../../../services/userServices";
import { numberWith3Commas } from "../../../utils/addComma";
import appIcons from "../../../utils/appIcons";
import { showPrice } from "../../../utils/priceFunc";

//## STYLES
import styles from "./TopHome.module.scss";

function TopHome() {
  const history = useHistory();
  const userId = useSelector((state) => state.userId);
  const [userIRTBalance, setUserIRTBalance] = useState(0);

  useEffect(() => {
    const Controller = new AbortController();
    if (userId.hasOwnProperty("userHashCode")) {
      (async () => {
        const { data, status } = await coinUsersBalance(
          "IRT",
          Controller.signal
        );
        if (status === 200) {
          if (data.hasOwnProperty("asset")) {
            const AvalaibleAmount = showPrice(
              data.asset.balance - data.asset.locked
            )
              .toString()
              .split(".")[0];

            //# Set Balance IRT
            setUserIRTBalance(numberWith3Commas(AvalaibleAmount));
          }
        }
      })();
    }
    return () => {
      Controller.abort();
    };
  }, [userId]);
  const handlePushUserToIncreaseBalance = () => {
    return history.push("/my-profile/increase-balance");
  };
  return (
    <Stack className={styles.container}>
      <Stack className={styles.logo}>
        <img
          src="/images/logo.png"
          alt="ArzFi-crypto-Logo"
          width={85}
          height={55}
        />
      </Stack>
      <Stack className={styles.user_details}>
        <Stack className={styles.profile}>
          <Link to="/my-profile">
            <span>{appIcons("UserProfileIcon")}</span>
          </Link>
        </Stack>
        {userId.hasOwnProperty("userHashCode") && (
          <Stack className={styles.card_container}>
            <Stack
              className={styles.card}
              onClick={handlePushUserToIncreaseBalance}
            >
              {appIcons("CardTwoToneIcon")}
            </Stack>
            <Stack
              className={styles.balance}
              onClick={handlePushUserToIncreaseBalance}
            >
              <p data-num="true">{userIRTBalance}</p> <span>تومان</span>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
export default memo(TopHome);
