import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { reducers } from "../reducers";
import { marketData } from "../actions/market";
import { favoriteCoins } from "../actions/favoriteCoins";
import { addUserHashCode, clearUserHashCode } from "../actions/user";
import { getTradeMarketList } from "../actions/getMarketList";

import axios from "axios";
import { errorMessage } from "../utils/messages";

const deleteAxiosToken = () => {
  axios.defaults.headers.common["x-access-token"] = "";
};
//Reducer ha ro combine mikonim va midim be createStore
export const store = createStore(
  reducers,
  compose(
    applyMiddleware(thunk)
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);
//* REMOVE FOR SECURITY -->
axios.interceptors.response.use(null, (error) => {
  if (
    error.response?.status === 401 &&
    !window.location.pathname.includes("/authorization")
  ) {
    deleteAxiosToken();
    store.dispatch(clearUserHashCode());
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    errorMessage("مشکلی در احراز هویت رخ داده! مجددا وارد شوید.");
  }
  if (error.response?.status === 403) {
    deleteAxiosToken();
    store.dispatch(clearUserHashCode());
  }
  return Promise.reject(error);
});
// ---->

// store.subscribe(() => console.log(store.getState()));
// store.dispatch(coinList());
store.dispatch(marketData());
store.dispatch(addUserHashCode());
store.dispatch(favoriteCoins());
setTimeout(() => {
  store.dispatch(getTradeMarketList());
}, 1000);
setInterval(() => {
  store.dispatch(marketData());
}, 25000);
