export const showLoadingPage = () => {
  return (dispatch) => {
    dispatch({ type: "SET_LOADING_TRUE", payload: { loading: true } });
  };
};
export const hiddenLoadingPage = () => {
  return (dispatch) => {
    dispatch({ type: "SET_LOADING_FALSE", payload: { loading: false } });
  };
};
