import { Box, Stack } from "@mui/material";
import React from "react";
import configs from "../../services/config.json";
import styles from "./ReferralPage.module.scss";
import { profitCalc } from "../../pages/Referral";
import { copyTextViaNavigator } from "../../utils/UI_utilities";
import BottomModal from "../../components/Modals/BottomModal";
import { useState } from "react";
import MuiCheckbox from "../../components/MuiCheckbox";
import PaperButton from "../../components/Buttons/PaperButton";
import {
  usersCreateReferralCodes,
  usersEditReferralCodes,
} from "../../services/adminServices";
import { getReferralDetails } from "./state";
import { errorMessage, successMessage } from "../../utils/messages";

function CodeReferral({ state, dispatch }) {
  const [editCodeInfo, setEditCodeInfo] = useState({
    isOpen: false,
    code: {},
    newPercent: "0",
    isDefaultCode: false,
    canChangeDefault: false,
    oldPercent: "0",
  });
  const [createNewCodeInfo, setCreateNewCodeInfo] = useState({
    percent: 0,
    isDefault: false,
    isOpen: false,
  });
  const handleOpenEditModal = (ctx) => {
    setEditCodeInfo({
      isOpen: true,
      code: ctx,
      oldPercent: ctx["referralPercent"],
      newPercent: ctx["referralPercent"],
      isDefaultCode: ctx["isDefault"],
      canChangeDefault: !ctx["isDefault"],
    });
  };
  const handleCloseEditCode = () => {
    setEditCodeInfo((prev) => ({ ...prev, isOpen: false }));
  };
  const handleEditNewPercent = (e) => {
    const value = +e.target.value;
    if (value < 0 || value > 100) return;
    setEditCodeInfo((prev) => ({ ...prev, newPercent: e.target.value }));
  };
  const handleChangeDefaultCode = () => {
    if (editCodeInfo["canChangeDefault"]) {
      setEditCodeInfo((prev) => ({
        ...prev,
        isDefaultCode: !prev.isDefaultCode,
      }));
    }
  };

  const handleDoChange = async () => {
    if (
      editCodeInfo["oldPercent"] !== editCodeInfo["newPercent"] ||
      (editCodeInfo["canChangeDefault"] && editCodeInfo["isDefaultCode"])
    ) {
      dispatch({ type: "SHOW_LOADING" });
      const body = JSON.stringify({
        currentPercent: editCodeInfo.newPercent,
        isDefault: editCodeInfo.isDefaultCode,
        referCode: editCodeInfo.code.referralCode,
      });
      const controller = new AbortController();
      const { data: changesIsValid } = await usersEditReferralCodes(body);
      if (changesIsValid) {
        const { error, data } = await getReferralDetails(controller.signal);
        if (error) return dispatch({ type: "HIDE_LOADING" });
        handleCloseEditCode();
        successMessage(changesIsValid.message);
        return dispatch({ type: "INIT_SUCCESS", payload: data });
      }
    }
  };
  const handleOpenCreateNewCode = () => {
    setCreateNewCodeInfo((prev) => ({ ...prev, isOpen: true }));
  };
  const handleCloseCreateCode = () => {
    setCreateNewCodeInfo((prev) => ({ ...prev, isOpen: false }));
  };

  const handleEditCreateNewPercent = (e) => {
    const value = +e.target.value;
    if (value < 0 || value > 100) return;
    setCreateNewCodeInfo((prev) => ({ ...prev, percent: e.target.value }));
  };
  const handleChangeDefaultNewCode = () => {
    setCreateNewCodeInfo((prev) => ({
      ...prev,
      isDefault: !prev.isDefault,
    }));
  };
  const handleDoCreate = async () => {
    dispatch({ type: "SHOW_LOADING" });
    try {
      const body = JSON.stringify({
        currentPercent: createNewCodeInfo.percent,
        isDefault: createNewCodeInfo.isDefault,
      });
      const controller = new AbortController();
      const { data: changesIsValid } = await usersCreateReferralCodes(body);
      if (changesIsValid) {
        const { error, data } = await getReferralDetails(controller.signal);
        if (error) return dispatch({ type: "HIDE_LOADING" });
        handleCloseEditCode();
        successMessage(changesIsValid.message);
        return dispatch({ type: "INIT_SUCCESS", payload: data });
      }
    } catch (error) {
      dispatch({ type: "HIDE_LOADING" });
      errorMessage("مشکل در ساخت کد جدید");
    }
  };
  return (
    <>
      <Box mt={1} mb={13}>
        {state["referralCodes"].map((ctx, idx) => {
          return (
            <div className={styles.codeReferralItem} key={idx}>
              <div className={styles.itemContainer}>
                <span>کد معرف</span>
                <span>{ctx["referralCode"]}</span>
              </div>
              <div className={styles.itemContainer}>
                <span>لینک</span>
                <span
                  onClick={() =>
                    copyTextViaNavigator(
                      configs["referral_url"] + ctx["referralCode"],
                      "لینک کپی شد"
                    )
                  }
                  style={{
                    borderBottom: "1px solid var(--primary-color)",
                    color: "var(--primary-color)",
                  }}
                >
                  {configs["referral_url"] + ctx["referralCode"]}
                </span>
              </div>
              <div className={styles.itemContainer}>
                <span>سهم شما</span>
                <span>
                  {profitCalc(
                    state["vipStatus"].percent,
                    ctx["referrerPercent"]
                  )}
                  %
                </span>
              </div>
              <div className={styles.itemContainer}>
                <span>سهم دوستان</span>
                <span>
                  {profitCalc(
                    state["vipStatus"].percent,
                    ctx["referralPercent"]
                  )}
                  %
                </span>
              </div>
              <div className={styles.itemContainer}>
                <span>تعداد استفاده</span>
                <span>{ctx["totalNumber"]}</span>
              </div>
              <Box mt={1} flexDirection="row-reverse" display="flex">
                <button
                  className={styles.editBtn}
                  onClick={() => handleOpenEditModal(ctx)}
                >
                  ویرایش
                </button>
              </Box>
            </div>
          );
        })}
        {state["referralCodes"].length < 20 && (
          <Box>
            <PaperButton onClick={handleOpenCreateNewCode} style={{zIndex:1}}>
              ساخت کد جدید
            </PaperButton>
          </Box>
        )}
      </Box>
      <BottomModal
        visible={editCodeInfo["isOpen"]}
        onRequestClose={handleCloseEditCode}
      >
        <div className={styles.editContainer}>
          <strong>ویرایش کد {editCodeInfo["code"]["referralCode"]}</strong>
          <p>
            درصد کارمزد دریافتی دوستان شما از کارمزد خودتان را وارد کنید (0 الی
            100)
          </p>
          <Box mb={1}>
            <input
              type="number"
              className={styles.numberInput}
              value={editCodeInfo["newPercent"] ?? 0}
              onChange={handleEditNewPercent}
            />
          </Box>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            columnGap={1}
          >
            <div className={styles.boxPercent}>
              <span>سهم شما</span>
              <span>
                {profitCalc(
                  state["vipStatus"].percent,
                  100 - Number(editCodeInfo["newPercent"] ?? 0)
                )}
                %
              </span>
            </div>
            <div className={styles.boxPercent}>
              <span>سهم دوستان</span>
              <span>
                {profitCalc(
                  state["vipStatus"].percent,
                  Number(editCodeInfo["newPercent"] ?? 0)
                )}
                %
              </span>
            </div>
          </Stack>
          <Stack flexDirection="row-reverse" mt={1} mb={0.5}>
            <Box flex={1.2}>
              <PaperButton onClick={handleDoChange}>ویرایش</PaperButton>
            </Box>
            <Box flex={1} mt={0.5}>
              <MuiCheckbox
                label="کد پیشفرض"
                disabled={!editCodeInfo["canChangeDefault"]}
                checked={editCodeInfo["isDefaultCode"]}
                onChange={handleChangeDefaultCode}
              />
            </Box>
          </Stack>
        </div>
      </BottomModal>

      {/** Create New Code Bottom Modal */}
      <BottomModal
        visible={createNewCodeInfo["isOpen"]}
        onRequestClose={handleCloseCreateCode}
      >
        <div className={styles.editContainer}>
          <strong>ساخت کد</strong>
          <p>
            درصد کارمزد دریافتی دوستان شما از کارمزد خودتان را وارد کنید (0 الی
            100)
          </p>
          <Box mb={1}>
            <input
              type="number"
              className={styles.numberInput}
              value={createNewCodeInfo["percent"] ?? 0}
              onChange={handleEditCreateNewPercent}
            />
          </Box>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            columnGap={1}
          >
            <div className={styles.boxPercent}>
              <span>سهم شما</span>
              <span>
                {profitCalc(
                  state["vipStatus"].percent,
                  100 - Number(createNewCodeInfo["percent"] ?? 0)
                )}
                %
              </span>
            </div>
            <div className={styles.boxPercent}>
              <span>سهم دوستان</span>
              <span>
                {profitCalc(
                  state["vipStatus"].percent,
                  Number(createNewCodeInfo["percent"] ?? 0)
                )}
                %
              </span>
            </div>
          </Stack>
          <Stack flexDirection="row-reverse" mt={1} mb={0.5}>
            <Box flex={1.2}>
              <PaperButton onClick={handleDoCreate}>ساخت</PaperButton>
            </Box>
            <Box flex={1} mt={0.5}>
              <MuiCheckbox
                label="کد پیشفرض"
                checked={createNewCodeInfo["isDefault"]}
                onChange={handleChangeDefaultNewCode}
              />
            </Box>
          </Stack>
        </div>
      </BottomModal>
    </>
  );
}

export default CodeReferral;
