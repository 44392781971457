const DEFAULT_STATE = {
  marketPage: "markets",
};
export const routeStateCashReducer = (state = { ...DEFAULT_STATE }, action) => {
  switch (action.type) {
    case "CHANGE_MARKET_ROUTE":
      return { ...state, marketPage: action.payload };
    default:
      return state;
  }
};
