export const applicationMenuConfig = (state = false, action) => {
  switch (action.type) {
    case "HIDDEN_BOTTOM_APP_MENU":
      return action.payload;
    case "SHOW_BOTTOM_APP_MENU":
      return action.payload;
    default:
      return state;
  }
};
