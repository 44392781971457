import { useHistory } from "react-router";
import NotificationError from "../../../../helpers/errors_notifications/NotificationError";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_USER_TRANSACTIONS,
  userCoinBalance,
  userProfile,
} from "../../../../actions/user";
import {
  FetchUserLastBankWithdrawal,
  getUsersCards,
} from "../../../../services/userServices";
import { convertNumbers2English, showPrice } from "../../../../utils/priceFunc";
import Num2persian from "num2persian";
import { errorMessage, successMessage } from "../../../../utils/messages";
import {
  hiddenLoadingPage,
  showLoadingPage,
} from "../../../../actions/loadingPage";
import { LoadingPage } from "../../../LoadingPage/LoadingPage";
//Styles and Icons
import styles from "./DecreaseBalance.module.scss";
import { userBankRialWithdraw } from "../../../../services/adminServices";
//* --> utils
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { Container, Stack, Typography } from "@mui/material";
import PrimaryHeader from "../../../Headers/PrimaryHeader";
import UserBalanceInfoBox from "../../../Boxs/UserBalanceInfoBox";
import InputViaButton from "../../MuiInput/InputViaButton";
import PaperButton from "../../../Buttons/PaperButton";
import OpenCards from "../IncreaseBalance/OpenCards";
import appIcons from "../../../../utils/appIcons";
import LastWithdrawalsTable from "./LastWithdrawalsTable";
import { numberWith3Commas } from "../../../../utils/addComma";
import PrimaryModal from "../../../Modals/PrimaryModal";

export default function DecreaseUserBalance() {
  const dispatch = useDispatch();
  const history = useHistory();
  const loadingBar = useSelector((state) => state.loadingBar.loading);
  const UserIRT = useSelector((state) => state.fullUserInfo.coinBalance);
  const USER_TRANSACTIONS = useSelector(
    (state) => state.userTransactionsReducer
  );
  const userInfo = useSelector((state) => state.fullUserInfo.data_profile);

  //List of user cards
  const [cards, setCards] = useState([]);
  const [deactivatedButton, setDeactivatedButton] = useState(false);
  const [lastWithdrawals, setLastWithdrawals] = useState([]);
  const [withdrawalsRules, setWithdrawalsRules] = useState({
    min: "300000",
    fee: "5000",
    fetched: false,
  });
  const [showCardsModal, setShowCardsModal] = useState(false);
  const [selectedList, setSelectedList] = useState({
    cardNum: "",
    ibanNum: "",
  });
  const [amount, setAmount] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  //
  //GET USER DATA
  useEffect(() => {
    dispatch(userCoinBalance("irt"));
    if (isEmpty(USER_TRANSACTIONS)) {
      dispatch(GET_USER_TRANSACTIONS());
    }
    if (isEmpty(userInfo)) {
      dispatch(userProfile());
    }
    const getUserCards = async () => {
      try {
        dispatch(showLoadingPage());
        const data = await getUsersCards();
        setCards(data.data.cards);
      } catch (ex) {
      } finally {
        dispatch(hiddenLoadingPage());
      }
    };
    getUserLastWithdrawals();
    getUserCards();
  }, []);
  //End GET User DATA

  const getUserLastWithdrawals = async () => {
    const { data, status } = await FetchUserLastBankWithdrawal();
    if (status === 200) {
      if (data.hasOwnProperty("withdrawals")) {
        setLastWithdrawals(data.withdrawals);
        if (!withdrawalsRules.fetched) {
          setWithdrawalsRules({
            min: data.amountLimits?.min ?? 300000,
            fee: data?.fee ?? 5000,
          });
        }
      }
    }
  };

  //Details for withdraw
  const handleDecreaseBalance = async (e) => {
    e.preventDefault();
    let maximumUserBalance = UserIRT?.asset?.balance - UserIRT?.asset?.locked;
    if (!selectedList["ibanNum"]) return errorMessage("شماره شبا را وارد کنید");
    if (amount > maximumUserBalance)
      return errorMessage("موجودی حساب شما کافی نمی باشد");
    if (amount < Number(withdrawalsRules["min"]) ?? 100000)
      return errorMessage("حداقل مقدار برداشت رعایت نشده است");
    try {
      setDeactivatedButton(true);
      const currentAccount = cards.filter(
        (c) => c.ibanNumber === selectedList["ibanNum"]
      )[0].id;
      const body = { amount, currentAccount };
      const { data } = await userBankRialWithdraw(body);
      if (data) {
        if (!data.error) {
          dispatch(userCoinBalance("irt"));
          setAmount("");
          setSelectedList({ cardNum: "", ibanNum: "" });
          successMessage(data.message);
          getUserLastWithdrawals();
          setShowSuccessMessage(true);
        } else if (data.error) {
          errorMessage(data.message);
        }
      }
    } catch (ex) {
    } finally {
      setDeactivatedButton(false);
    }
  };

  //Function Handle Balance
  const handleMaximumBalance = (amount) => {
    let maximumUserBalance = UserIRT?.asset?.balance - UserIRT?.asset?.locked;
    if (maximumUserBalance < 0) {
      setAmount(0);
    } else {
      setAmount(amount.toString().split(".")[0]);
    }
  };

  const USER_CAN_WTH = useMemo(() => {
    if (!isEmpty(userInfo)) {
      const hasDeposit = USER_TRANSACTIONS.some((ctx) => {
        return ctx.coinName === "IRT" && ctx.operation === "BUY";
      });
      if (hasDeposit) {
        if (userInfo.user.documentStatus) {
          return true;
        }
        return false;
      }
    }

    return true;
  }, [USER_TRANSACTIONS, userInfo]);

  const sendUserToVerifyPage = () => {
    return history.push("/my-profile/authentication");
  };

  const handleChangeAmount = (e) => {
    setAmount(convertNumbers2English(e.target.value));
  };
  const onClickAllAmount = () => {
    return handleMaximumBalance(UserIRT.asset.balance - UserIRT.asset.locked);
  };
  const handleToggleListCards = () => {
    setShowCardsModal((prev) => !prev);
  };
  const handleChangeCardNumberSelected = (card, iban) => {
    setSelectedList({ cardNum: card, ibanNum: iban });
    handleToggleListCards();
  };
  return (
    <>
      {loadingBar && <LoadingPage />}
      <Container disableGutters>
        <Stack marginX={1.5} mb={1}>
          <PrimaryHeader title="برداشت از اعتبار" />
        </Stack>
        <Stack margin={2} mt={1}>
          <UserBalanceInfoBox
            locked={UserIRT?.asset?.locked}
            balance={UserIRT?.asset?.balance}
          />
        </Stack>
        <Stack mt={2} marginX={2}>
          <Stack>
            <InputViaButton
              placeholder="مبلغ برداشت(تومان)"
              onChange={handleChangeAmount}
              value={amount}
              buttonTitle="کل موجودی"
              onClickButton={onClickAllAmount}
            />
          </Stack>
          <Stack
            sx={{
              fontSize: 12,
              direction: "rtl",
              marginBottom: 0.5,
              marginTop: 0.1,
              color: "var(--secondary2-color)",
            }}
          >
            {amount ? Num2persian(amount) + " تومان" : ""}
          </Stack>
          <Typography
            sx={{
              direction: "rtl",
              fontSize: 12,
              color: "var(--secondary2-color)",
              marginBottom: 0.3,
              marginTop: 1,
            }}
          >
            شماره شبایی که قصد واریز به آن را دارید را انتخاب کنید
          </Typography>
          <div className={styles.userCardLabel} onClick={handleToggleListCards}>
            <p>
              {selectedList["ibanNum"]
                ? selectedList["ibanNum"]
                : "انتخاب کارت بانکی"}
            </p>
            <div>{appIcons("ArrowDownIcon")}</div>
          </div>
          <div style={{ marginTop: "10px" }} className="eng-number-font">
            <NotificationError
              bgBorder="rgba(246, 85, 85, 1)"
              bgColor="rgba(246, 85, 85, 0.16)"
              fontColor="rgba(246, 85, 85, 1)"
              text={`کارمزد برداشت ریالی ${numberWith3Commas(
                withdrawalsRules["fee"]
              )} تومان می باشد.`}
            />
          </div>
          <div style={{ marginTop: "10px" }} className="eng-number-font">
            <NotificationError
              bgBorder="rgba(246, 85, 85, 1)"
              bgColor="rgba(246, 85, 85, 0.16)"
              fontColor="rgba(246, 85, 85, 1)"
              text={`حداقل مبلغ برداشت ${numberWith3Commas(
                withdrawalsRules["min"]
              )} تومان می باشد.`}
            />
          </div>
          {!USER_CAN_WTH && (
            <div style={{ marginTop: "10px" }}>
              <NotificationError
                bgBorder="rgba(246, 85, 85, 1)"
                bgColor="rgba(246, 85, 85, 0.16)"
                fontColor="rgba(246, 85, 85, 1)"
                text="طبق قوانین برای برداشت نیازمند تکمیل مدارک می باشید."
              />
            </div>
          )}
          <Stack mt={2}>
            {USER_CAN_WTH ? (
              <PaperButton
                disabled={deactivatedButton}
                onClick={handleDecreaseBalance}
              >
                ثبت درخواست
              </PaperButton>
            ) : (
              <PaperButton
                disabled={deactivatedButton}
                onClick={sendUserToVerifyPage}
              >
                احراز هویت
              </PaperButton>
            )}
          </Stack>
        </Stack>
        <Stack mt={2} mx={2} mb={13}>
          <LastWithdrawalsTable
            list={lastWithdrawals}
            setList={setLastWithdrawals}
          />
        </Stack>
      </Container>
      <OpenCards
        visible={showCardsModal}
        items={cards}
        active={selectedList["cardNum"]}
        onRequestClose={handleToggleListCards}
        onChange={handleChangeCardNumberSelected}
        show="iban"
      />
      <PrimaryModal
        message={
          <Typography style={{ color: "var(--secondary-color)" }}>
            برداشت شما در صف پرداخت بانکی بوده و طبق ساعت پایا به حساب شما واریز
            میشود
          </Typography>
        }
        open={showSuccessMessage}
        showTitle={false}
        onClose={() => setShowSuccessMessage(false)}
      />
    </>
  );
}
