import React, { useReducer } from "react";
import LoadingModal from "../components/Modals/LoadingModal";
import { Box, Container, Stack } from "@mui/material";
import PrimaryHeader from "../components/Headers/PrimaryHeader";
import appIcons from "../utils/appIcons";
import { useLayoutEffect } from "react";
import {
  fetchUserDetails,
  initialStates,
  reducer,
} from "../pageComponents/AccountDetailsPage/state";
import DetailsBox from "../pageComponents/AccountDetailsPage/DetailsBox";
import WarningBox from "../components/Boxs/WarningBox";

function AccountDetails() {
  const [state, dispatch] = useReducer(reducer, initialStates);

  useLayoutEffect(() => {
    const controller = new AbortController();
    (async () => {
      const { data, error } = await fetchUserDetails(controller.signal);
      if (error) {
        return dispatch({ type: "INIT_FAILED" });
      }
      return dispatch({ type: "INIT_SUCCESS", payload: data });
    })();
    return () => {
      controller.abort();
    };
  }, []);

  console.log(state);

  const need_acc_verify = [
    state.details["verified"] === false,
    state.details["docsLack"] === true,
  ].some((c) => c === true);
  return (
    <>
      <Container
        disableGutters
        sx={{ paddingInline: 2, boxSizing: "border-box", mb: 14 }}
      >
        <PrimaryHeader title="اطلاعات حساب کاربری" fixButton />
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ fontSize: 70 }}
          mb={-2}
        >
          <Box>{appIcons("personAccIcon", { fontSize: "inherit" })}</Box>
        </Stack>
        <Box mb={1.5}>
          <DetailsBox
            title="نام کامل"
            value={state.details["fullname"] ?? "-"}
            status={!!state["details"]["fullname"]}
            fwdBtn={!state["details"]["fullname"] && "تکمیل"}
            fwdLink="/my-profile/authentication"
          />
        </Box>
        <Box mb={1.5}>
          <DetailsBox
            title="احراز هویت"
            value={need_acc_verify ? "احراز نشده" : "احراز شده"}
            status={!need_acc_verify}
            fwdBtn={need_acc_verify && "تکمیل مشخصات/مدارک"}
            fwdLink="/my-profile/authentication"
          />
        </Box>
        <Stack mb={1.5}>
          <WarningBox>
            کاربر گرامی جهت واریز یا برداشت ریال نیازمند احراز هویت در سایت
            ارزفی می باشید جهت تبدیل ارزها(چنج) و استفاده از کیف پول ارزفی نیازی
            به احراز هویت نمی باشد
          </WarningBox>
        </Stack>
        <Box mb={1.5}>
          <DetailsBox
            title="ایمیل"
            value={state["details"]["email"] ?? "-"}
            status={state["details"]["emailStatus"]}
            fwdBtn={!state.details["emailStatus"] && "تکمیل"}
            fwdLink="/my-profile/authentication"
          />
        </Box>
        <Box mb={1.5}>
          <DetailsBox
            title="شماره موبایل"
            value={state["details"]["phoneNumber"] ?? "-"}
            status={state["details"]["smsStatus"]}
            fwdBtn={!state.details["smsStatus"] && "تکمیل"}
            fwdLink="/my-profile/authentication"
          />
        </Box>
        <Box mb={1.5}>
          <DetailsBox
            title="کد شناسایی دوعاملی"
            value={state["details"]["totpStatus"] ? "فعال" : "غیر فعال"}
            status={state["details"]["totpStatus"]}
            fwdBtn={!state.details["totpStatus"] && "فعال سازی"}
            fwdLink="/my-profile/authentication/2fa"
          />
        </Box>
      </Container>
      <LoadingModal visible={state["isLoading"]} />
    </>
  );
}

export default AccountDetails;
