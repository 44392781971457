import http from "./httpServices";
import config from "./config.json";
import axios from "axios";

//Record's Ticket Options and Replies Option
export const recordsTicket = (data, file) => {
  if (file) {
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "multipart/form-data",
      "x-access-token": token,
    };
    return axios.post(`${config.arzfi}/users/new-ticket-attached`, data, {
      headers: headers,
    });
  }
  return http.post(`${config.arzfi}/users/new-ticket`, data);
};

export const repliesToTicket = (data, file) => {
  if (file) {
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "multipart/form-data",
      "x-access-token": token,
    };
    return axios.post(`${config.arzfi}/users/ticket-reply-attached`, data, {
      headers: headers,
    });
  }
  return http.post(`${config.arzfi}/users/ticket-reply`, data);
};
//End Record's Ticket Options and Replies Option

export const usersTextTicket = (id, signal) => {
  const options = signal ? { signal } : {};
  return http.get(`${config.arzfi}/users/ticket-details/${id}`, options);
};

export const usersProfile = (signal) => {
  const options = signal ? { signal } : {};
  return http.get(`${config.arzfi}/users/profile`, options);
};

export const usersTickets = (signal) => {
  const options = signal ? { signal } : {};
  return http.get(`${config.arzfi}/users/tickets`, options);
};

export const usersBalance = () => {
  return http.get(`${config.arzfi}/users/balance`);
};

export const coinUsersBalance = (coin, signal) => {
  const options = signal ? { signal } : {};
  return http.get(`${config.arzfi}/users/balance/${coin}`, options);
};

export const addUsersNetworkWallet = (coin, network) => {
  return http.get(`${config.arzfi}/users/wallet/${coin}/${network}`);
};

export const usersTransactionsHistory = (signal) => {
  const options = signal ? { signal } : {};
  return http.get(`${config.arzfi}/users/transactions`, options);
};

export const usersOrdersHistory = (signal) => {
  const options = signal ? { signal } : {};
  return http.get(`${config.arzfi}/users/orders`, options);
};

export const usersWithdrawalHistory = (signal) => {
  const options = signal ? { signal } : {};
  return http.get(`${config.arzfi}/users/withdrawal-list`, options);
};

export const usersDepositHistory = (signal) => {
  const options = signal ? { signal } : {};
  return http.get(`${config.arzfi}/users/deposit-list`, options);
};

export const usersCommisions = () => {
  return http.get(`${config.arzfi}/users/commissions`);
};

export const usersReferrals = () => {
  return http.get(`${config.arzfi}/users/refer`);
};

export const usersVerifyInfo = (signal) => {
  const options = signal ? { signal } : {};
  return http.get(`${config.arzfi}/users/verify-data`, options);
};

///Create Two Fac Code and Check
export const usersCreateTwoFacCode = (body) => {
  return http.post(`${config.arzfi}/users/two-factor`, body);
};
//* --> Check Two Factor Code User
export const usersCheckTwoFacCode = (body) => {
  return http.post(`${config.arzfi}/users/check-gacode`, body);
};
///End Create Two Fac Code and Check

export const updateUsersDeposits = (item) => {
  return http.get(`${config.arzfi}/users/deposit/${item}`);
};
export const usersAddNewBankCard = (data) => {
  const body = JSON.stringify(data);
  return http.post(`${config.arzfi}/users/add_card`, body);
};
export const getUsersCards = () => {
  return http.get(`${config.arzfi}/users/cards`);
};
export const FetchUserLastBankWithdrawal = () => {
  return http.get(`${config.arzfi}/users/bank-withdrawal-last`);
};
export const FetchUserLastAssetsWithdrawal = () => {
  return http.get(`${config.arzfi}/users/withdrawal-last`);
};

/////
///// LOGIN - REGISTER - EDIT PASS - RECOVERY
/////
export const userLoginToApp = (
  emailOrPhone,
  password,
  byPhone,
  remember,
  token
) => {
  let body = null;
  if (byPhone) {
    body = JSON.stringify({
      mobileNumber: emailOrPhone,
      password,
      byPhone,
      remember,
      captchaToken: token,
    });
  } else {
    body = JSON.stringify({ email: emailOrPhone, password, byPhone, remember });
  }
  return http.post(`${config.arzfi}/auth/signin`, body);
};

export const confirmCodeForRegister = (data, phone) => {
  let body = JSON.stringify(data);
  if (phone) {
    return http.post(`${config.arzfi}/auth/2fasms`, body);
  } else {
    return http.post(`${config.arzfi}/auth/2faemail`, body);
  }
};

export const userRegisterToApp = (data, email) => {
  let body = JSON.stringify(data);
  if (email) {
    return http
      .post(`${config.arzfi}/auth/register-email`, body)
      .then((r) => r);
  } else {
    return http.post(`${config.arzfi}/auth/register`, body);
  }
};

export const userEditProfile = (data) => {
  let body = JSON.stringify(data);
  return http.post(`${config.arzfi}/auth/edit-profile`, body);
};

export const userRecoveryPass = (data, sms) => {
  let body = JSON.stringify(data);
  if (sms) {
    return http.post(`${config.arzfi}/auth/forgot-sms`, body);
  }
  return http.post(`${config.arzfi}/auth/forgot-email`, body);
};

export const userEditPassWithRecoveryHashCode = (data, sms) => {
  let body = JSON.stringify(data);
  if (sms) {
    return http.post(`${config.arzfi}/auth/change-password`, body);
  }
  return http.post(`${config.arzfi}/auth/change-password-email`, body);
};

export const userEditPasswordWithToken = (data) => {
  let body = JSON.stringify(data);
  return http.post(`${config.arzfi}/auth/edit-password`, body);
};

//* --> Make-Limit-Order (TRADE MARKET)
export const FetchMakeLimitOrder = (body) => {
  return http.post(`${config.dev}/market/make-limit-order`, body);
};
//* --> Make-Stop-Order (TRADE MARKET)
export const FetchMakeStopLimitOrder = (body) => {
  return http.post(`${config.dev}/market/make-stop-order`, body);
};
//* --> Get User Orders-List In Market
export const FetchGetUserOrdersList = (market) => {
  if (market)
    return http.get(`${config.dev}/users/done-orders?market=${market}`);
  return http.get(`${config.dev}/users/done-orders`);
};
//* --> Get Un-Executed-List
export const FetchUnexecutedOrdersList = (market) => {
  if (market)
    return http.get(`${config.dev}/users/undone-orders?market=${market}`);
  return http.get(`${config.dev}/users/undone-orders`);
};
//* --> Cancel Order-Market
export const FetchCancelUserOrder = (id) => {
  return http.get(`${config.dev}/market/cancel-order?orderId=${id}`);
};
//* --> Get Status - Market
export const FetchGetOrderStatus = (id) => {
  return http.get(`${config.dev}/market/order-status?orderId=${id}`);
};
//* --> Market Order
export const FetchMakeMarketOrder = (body) => {
  return http.post(`${config.dev}/market/make-market-order`, body);
};
//* --> Cancel stop Order's
export const FetchCancelUserStopOrder = (id) => {
  return http.get(`${config.dev}/market/cancel-stop-order?orderId=${id}`);
};
//* --> Make Stop Order
export const FetchMakeStopMarketOrder = (body) => {
  return http.post(`${config.dev}/market/make-stop-market-order`, body);
};
//* --> Stop Orders-List
export const FetchStopOrdersList = (market) => {
  if (market)
    return http.get(`${config.dev}/users/stop-orders?market=${market}`);
  return http.get(`${config.dev}/users/stop-orders`);
};

export const FetchCancelBanWithdrawal = (body) => {
  return http.post(`${config.dev}/users/cancel-bank-withdrawal`, body);
};
export const FetchCancelAssetWithdrawal = (body) => {
  return http.post(`${config.dev}/users/cancel-withdrawal`, body);
};
