import { useContext, useEffect, useMemo, useState } from "react";
import styles from "./OrdersMT.module.scss";
import { tradesCTX } from "../../../../context/tradesCTX";
import { isEmpty } from "lodash";
import { addCommaWithRialPrice } from "../../../../utils/addComma";
import { Stack } from "@mui/system";
import { ThreeDots } from "react-loader-spinner";

function showWithLessDecimal(number) {
  if (typeof number === "number") {
    let Str = number.toString();
    const SplitStr = Str.split(".");
    let hasDecimal = !!SplitStr[1];
    if (hasDecimal) {
      if (number > 1_000_000) {
        return SplitStr[0];
      } else if (number < 1_000_000 && number > 100) {
        return number.toFixed(2);
      } else {
        return parseFloat(number.toFixed(4));
      }
    }
    return number;
  }
  return 0;
}
export default function OrdersMT(params) {
  const marketContext = useContext(tradesCTX);
  const {
    sellOffers,
    buyOffers,
    lastOffer,
    handleReplaceTradesAmount,
    sortListBy,
    currentTrade,
  } = marketContext;
  const [checkLastPrice, setCheckLastPrice] = useState(0);
  const [removeFromList] = useState({
    IRT: 6,
    USDT: 0,
  });
  const [lastPriceCss, setLastPriceCss] = useState("");

  useEffect(() => {
    if (lastOffer) {
      if (+checkLastPrice >= +lastOffer) {
        setLastPriceCss("color_red");
      } else {
        setLastPriceCss("color_green");
      }
      setCheckLastPrice(lastOffer);
    }
  }, [lastOffer]);

  let percentBuyOffer = buyOffers[13]?.s;
  let percentSellOffer = sellOffers[13]?.s;

  let percentAllBuyOffer = buyOffers[buyOffers?.length - 1]?.s;
  let percentAllSellOffer = sellOffers[sellOffers?.length - 1]?.s;

  const isIRT = useMemo(() => {
    return currentTrade?.area_title === "IRT";
  }, [currentTrade]);
  const showNumber = (num) => {
    if (isIRT) return addCommaWithRialPrice(num);
    return num;
  };
  return (
    <div className={`dir-rtl ${styles.container}`}>
      <div
        className={`${styles.sell_container}
      ${sortListBy === "buy" ? "hidden" : ""}
      `}
      >
        <div className={`change_color_to_9D9EA8 ${styles.head}`}>
          <div>قیمت ({currentTrade?.area_title_fa})</div>
          <div>مقدار</div>
        </div>
        <div
          className={`${styles.body} ${
            sortListBy === "buy"
              ? "hidden"
              : sortListBy === "sell"
              ? styles.full_view_list
              : ""
          }`}
        >
          {!isEmpty(sellOffers) ? (
            sellOffers
              .slice(
                removeFromList[currentTrade?.["area_title"]] ?? 4,
                sortListBy === "sell"
                  ? sellOffers.length - 1
                  : 7 + removeFromList[currentTrade?.["area_title"]]
              )
              .reverse()
              .map((c, i) => {
                let percent = +c.s * 100;
                if (sortListBy === "sell") {
                  percent = percent / percentAllSellOffer;
                } else {
                  percent = percent / percentSellOffer;
                }
                return (
                  <div
                    className={styles.item}
                    key={i}
                    onClick={() => handleReplaceTradesAmount(c)}
                  >
                    <div className={`eng-number-font ${styles.price}`}>
                      {showNumber(c.i)}
                    </div>
                    <div
                      className={`${styles.amount} eng-number-font font_color_white`}
                    >
                      {showWithLessDecimal(Number(c?.a))}
                    </div>
                    <div
                      className={`${styles.colorize} ${styles.colorize_red}`}
                      style={{ width: percent + "%" }}
                    ></div>
                  </div>
                );
              })
          ) : (
            <Stack alignItems="center" height="100%" justifyContent="center">
              <ThreeDots
                height="40"
                width="40"
                radius="9"
                color="#10D078"
                visible={true}
              />
            </Stack>
          )}
        </div>
      </div>
      {sortListBy !== "buy" && (
        <div
          className={`${styles.last_price} border_color_272E40`}
          onClick={() => handleReplaceTradesAmount(lastOffer, true)}
        >
          <div className={`eng-number-font ${lastPriceCss}`}>
            {showNumber(lastOffer)}
          </div>
        </div>
      )}
      <div
        className={`${styles.buy_container} ${
          sortListBy === "sell" ? "hidden" : ""
        }`}
      >
        <div className={`change_color_to_9D9EA8 ${styles.head}`}>
          <div>قیمت ({currentTrade?.area_title_fa})</div>
          <div>مقدار</div>
        </div>
        <div
          className={`${styles.body} ${
            sortListBy === "sell"
              ? "hidden"
              : sortListBy === "buy"
              ? styles.full_view_list
              : ""
          }`}
        >
          {!isEmpty(buyOffers) ? (
            buyOffers
              .slice(
                removeFromList[currentTrade?.["area_title"]] ?? 4,
                sortListBy === "buy" ? buyOffers.length - 1 : removeFromList[currentTrade?.["area_title"]] + 7
              )
              .map((c, i) => {
                let percent = +c.s * 100;
                if (sortListBy === "buy") {
                  percent = percent / percentAllBuyOffer;
                } else {
                  percent = percent / percentBuyOffer;
                }
                return (
                  <div
                    className={styles.item}
                    key={i}
                    onClick={() => handleReplaceTradesAmount(c)}
                  >
                    <div className={`eng-number-font ${styles.price}`}>
                      {showNumber(c.i)}
                    </div>
                    <div
                      className={`${styles.amount} font_color_white eng-number-font`}
                    >
                      {showWithLessDecimal(Number(c?.a))}
                    </div>
                    <div
                      className={`${styles.colorize} ${styles.colorize_green}`}
                      style={{ width: percent + "%" }}
                    ></div>
                  </div>
                );
              })
          ) : (
            <Stack alignItems="center" height="100%" justifyContent="center">
              <ThreeDots
                height="40"
                width="40"
                radius="9"
                color="#10D078"
                visible={true}
              />
            </Stack>
          )}
        </div>
      </div>
      {sortListBy === "buy" && (
        <div
          className={`${styles.last_price} border_color_272E40`}
          onClick={() => handleReplaceTradesAmount(lastOffer, true)}
        >
          <div
            className={`eng-number-font ${lastPriceCss}`}
            style={{ fontSize: "15px" }}
          >
            {showNumber(lastOffer)}
          </div>
        </div>
      )}
    </div>
  );
}
