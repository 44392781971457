import { Stack, Typography } from "@mui/material";
import { memo } from "react";

import styles from "./UserBalanceInfoBox.module.scss";
import { numberWith3Commas } from "../../utils/addComma";
function UserBalanceInfoBox(props) {
  const { balance = 0, locked = 0 } = props;

  return (
    <Stack
      paddingX={2}
      paddingY={0.8}
      sx={{
        backgroundColor: "var(--third-color)",
        minHeight: 10,
        borderRadius: 2,
        direction: "rtl",
      }}
    >
      <Stack
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
        paddingY={0.5}
        paddingBottom={0.8}
        sx={{ borderBottom: "1px solid var(--secondary4-color)" }}
      >
        <p className={styles.headerTexts}>موجودی کل</p>
        <p className={styles.headerTexts} data-theme="error">قفل شده</p>
        <p className={styles.headerTexts} data-theme="success">قابل برداشت</p>
      </Stack>
      <Stack
        paddingY={0.5}
        alignItems="center"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between"
        mt={0.8}
      >
        <p className={styles.descText}>
          {numberWith3Commas(balance.toString().split(".")[0])}
          <span className={styles.label}>تومان</span>
        </p>
        <p className={styles.descText} data-theme="error">
          {numberWith3Commas(locked.toString().split(".")[0])}
          <span className={styles.label}>تومان</span>
        </p>
        <p className={styles.descText} data-theme="success">
          {numberWith3Commas((balance - locked).toString().split(".")[0])}{" "}
          <span className={styles.label}>تومان</span>
        </p>
      </Stack>
    </Stack>
  );
}

export default memo(UserBalanceInfoBox);
