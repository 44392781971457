//Components -->
import { memo, useState, useEffect, useRef } from "react";
import { isEmpty } from "lodash";
import TextField from "@mui/material/TextField";
import RTL from "../../../helpers/direction/RTL";
import ImagePreview from "../../../utils/ImagePreview";
//Styles and Icons -->
import styles from "./ChoiceCoinFilter.module.scss";
import SearchIcon from "../../../styles/icon/Search_light.svg";
import ArrowRight from "../../../styles/icon/arrow_alt_right.svg";
//* Store, Actions -->
import { useDispatch, useSelector } from "react-redux";
import { coinList } from "../../../actions/market";
import { getTradeMarketList } from "../../../actions/getMarketList";
import { hiddenAppMenu, showAppMenu } from "../../../actions/visibiltyOfMenu";
// import InfiniteScrollList from "../../../utils/ViewScroll";
import InfiniteScroll from "react-infinite-scroll-component";

function GetCoinList({
  visible,
  setVisible,
  currentCoin,
  setCurrentCoin,
  currentTwoCoin,
  showMarketList,
  setCurrentMarket,
  currentMarket,
  filterList = [],
}) {
  let coinsList = useSelector((state) => state.coins);
  let tradesMarketList = useSelector((state) => state.tradesList);
  const [coinData, setCoinData] = useState([]);
  const [numberOfShow, setNumberOfShow] = useState(20);
  const [hasMoreItem, setHasMoreItem] = useState(true);
  useEffect(() => {
    if (!isEmpty(coinsList)) {
      if (currentTwoCoin?.symbol) {
        let filtered = coinsList.data.filter(
          (c) => c.symbol !== currentTwoCoin.symbol
        );
        for (const symbol of filterList) {
          filtered = filtered.filter((c) => c.symbol !== symbol);
        }
        setCoinData(filtered);
      } else {
        let coinData = coinsList.data;
        for (const symbol of filterList) {
          coinData = coinData.filter((c) => c.symbol !== symbol);
        }
        setCoinData(coinData);
      }
    }
  }, [coinsList]);
  const ref = useRef(null);
  const [filterOptions, setFilterOptions] = useState({
    searchItem: "",
  });
  const TAKE_DATA =
    !isEmpty(coinData) &&
    coinData.filter(
      (coin) =>
        coin.faName.includes(filterOptions.searchItem) ||
        coin.name
          .toLowerCase()
          .includes(filterOptions.searchItem.toLowerCase()) ||
        coin.symbol
          .toLowerCase()
          .includes(filterOptions.searchItem.toLowerCase())
    );
  const TRADES_MARKET_FILTER =
    !isEmpty(tradesMarketList) &&
    tradesMarketList.list.filter(
      (coin) =>
        coin.asset_title_fa.includes(filterOptions.searchItem) ||
        coin.asset
          .toLowerCase()
          .includes(filterOptions.searchItem.toLowerCase()) ||
        coin.market
          .toLowerCase()
          .includes(filterOptions.searchItem.toLowerCase()) ||
        coin.asset_title
          .toLowerCase()
          .includes(filterOptions.searchItem.toLowerCase())
    );
  const dispatch = useDispatch();

  useEffect(() => {
    if (showMarketList) {
      if (isEmpty(tradesMarketList)) dispatch(getTradeMarketList());
    } else {
      if (isEmpty(coinsList)) dispatch(coinList());
    }
    if (visible) {
      dispatch(hiddenAppMenu());
    }
    return () => dispatch(showAppMenu());
  }, []);

  const [checkFocuse, setCheckFocuse] = useState("");
  let RealItems = [];
  if (showMarketList) {
    RealItems = TRADES_MARKET_FILTER;
  } else {
    RealItems = TAKE_DATA;
  }
  function handleNext() {
    if (RealItems.slice(0, numberOfShow).length >= RealItems.length) {
      setHasMoreItem(false);
      return;
    }
    setNumberOfShow(numberOfShow + 20);
  }

  return (
    <div
      className={`changeBackgroundColorToBlack ${styles.ChoiceCoin_container} ${
        visible ? "" : styles.invisible_container
      }`}
    >
      <header
        className={`changeBackgroundColorToHardBlack ${styles.title_and_close_container}`}
      >
        <div className={styles.container_for_width}>
          <div className={styles.title_choiceCoin}>انتخاب ارز</div>
          <div onClick={() => setVisible(false)}>
            <img src={ArrowRight} alt="back" width={20} height={14} />
          </div>
        </div>
      </header>
      <main
        className={`changeBackgroundColorToBlack ${styles.Main_ChoiceCoin}`}
      >
        <div className={styles.search_choiceCoin}>
          <div
            className={`changeSearchFieldBoxDarkMode ${styles.search_input_place}`}
          >
            <RTL>
              <TextField
                className={styles.search_coin_list}
                name="phoneNumber"
                label="جستجو"
                color="success"
                onFocus={() => setCheckFocuse("search")}
                onBlur={() => setCheckFocuse("")}
                autoComplete="off"
                onChange={(e) =>
                  setFilterOptions({
                    ...filterOptions,
                    searchItem: e.target.value,
                  })
                }
              />
              <div
                className={`${styles.icon_place} ${styles.first_input_icon} ${
                  checkFocuse === "search" ? styles.icon_place_active : ""
                }`}
              >
                <img
                  src={SearchIcon}
                  alt="find"
                  className="convertPicToGreen"
                />
              </div>
            </RTL>
          </div>
        </div>
        <div
          className={`changeBackgroundColorToBlack font_color_white ${styles.lists_of_coins_container}`}
        >
          <ul className={`changeBackgroundColorToBlack ${styles.ul_of_list}`}>
            <div className="scroll-container" ref={ref}>
              {!isEmpty(RealItems) ? (
                <InfiniteScroll
                  dataLength={RealItems.slice(0, numberOfShow).length}
                  loader={<h4>Loading...</h4>}
                  next={handleNext}
                  hasMore={hasMoreItem}
                >
                  {RealItems.slice(0, numberOfShow).map((coin, index) => (
                    <li
                      ket={index}
                      className={styles.list_coin}
                      key={coin?.name + index}
                      onClick={
                        !showMarketList
                          ? () => {
                              setCurrentCoin({
                                ...currentCoin,
                                symbol: coin.symbol,
                                faName: coin.faName,
                                name: coin.name,
                              });
                              setVisible(false);
                            }
                          : () => {
                              setCurrentMarket(coin);
                              setVisible(false);
                            }
                      }
                    >
                      <div className={styles.coin_persian_name}>
                        {showMarketList ? coin.market : coin.faName}
                      </div>
                      <div className={styles.coin_icon}>
                        <ImagePreview
                          image={showMarketList ? coin.asset : coin.symbol}
                          width={30}
                          height={30}
                        />
                      </div>
                      <div className={styles.coin_symbol_name_history}>
                        <div className={styles.coin_eng_name}>
                          <span>
                            {showMarketList ? coin.asset_title_fa : coin.name}
                          </span>
                          <span> - </span>
                          <span>
                            {showMarketList ? coin.area_title_fa : coin.symbol}
                          </span>
                        </div>
                      </div>
                      <div className={styles.line_after_li}></div>
                    </li>
                  ))}
                </InfiniteScroll>
              ) : (
                <div className={` ${styles.loadingBar} font_color_white`}>
                  {/* <Triangle color="#10D078" height={80} width={80} /> */}
                  {showMarketList
                    ? isEmpty(tradesMarketList)
                      ? "در حال برروزرسانی..."
                      : "موردی یافت نشد"
                    : isEmpty(coinsList)
                    ? "در حال برروزرسانی..."
                    : "موردی یافت نشد"}
                </div>
              )}
            </div>
          </ul>
        </div>
      </main>
    </div>
  );
}
export default memo(GetCoinList);
