import { memo } from "react";
import styles from "./PercentBalance.module.scss";

const percentNums = [25, 50, 75, 100];
function PercentBalance(props) {
  const { value, setValue } = props;

  const handleChangePercnet = (e) => {
    setValue(e.target.value);
  };
  return (
    <section className={styles.container}>
      {percentNums.map((c, i) => (
        <div className={styles.item} key={i}>
          <input
            className={styles.slider_input}
            hidden
            type="radio"
            name="percent_nums"
            id={c}
            value={c}
            checked={value}
            onChange={handleChangePercnet}
          />
          <label htmlFor={c}>
            <div
              className={`${
                +c <= +value
                  ? "bg_green  color_white_light_mode"
                  : "changeBackgroundColorToHardBlack change_color_to_9D9EA8"
              } ${styles.label}`}
            >
              {c}
              <span style={{ fontSize: "10px" }}>%</span>
            </div>
          </label>
        </div>
      ))}
    </section>
  );
}
export default memo(PercentBalance);
