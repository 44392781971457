import { useHistory } from "react-router";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  choiceDarkMode,
  choiceLightMode,
} from "../../../actions/choiceAppTheme";
import {choiceRialPrice,choiceUSDTPrice} from "../../../actions/choiceAppPrice";
//Styles
import styles from "./ApplicationSetting.module.scss";
//Icons
import ArrowBackIcon from "../../../styles/icon/arrow_back_fill.svg";
import ArrowRightIcon from "../../../styles/icon/ArrowGray.svg";
import { Link } from "react-router-dom";
export default function ApplicationSetting({ version }) {
  const VALUE_OPTIONS = {
    langVisible: 0,
    themeVisible: 0,
    priceVisible: 0,
  };
  const appTheme = useSelector((state) => state.appTheme);
  const appPrice = useSelector((state) => state.appPrice);
  const dispatch = useDispatch();
  const [settingOptions, setSettingOptions] = useState(VALUE_OPTIONS);
  const history = useHistory();
  return (
    <>
      <div>
        <div
          className={`${styles.bg_of_menu} ${
            settingOptions.langVisible || settingOptions.themeVisible || settingOptions.priceVisible
              ? styles.block
              : styles.none
          }`}
          onClick={() => setSettingOptions({})}
        ></div>
        <div
          className={`${
            styles.menu_choice_app_language_setting_container
          }  darkModeSettingGlobal ${
            settingOptions.langVisible ? styles.goToUp : styles.goToDown
          }`}
        >
          <div className={styles.setting_title}>انتخاب زبان</div>
          <div className={styles.setting_input_container}>
            <label htmlFor="choice-persian-lang">
              <input
                type="radio"
                id="choice-persian-lang"
                name="language_choice"
                defaultChecked
              />
              <div className={` choiceTheTheme ${styles.persian_c_container}`}>
                <div>فارسی</div>
                <div></div>
              </div>
            </label>
            <label htmlFor="choice-english-lang">
              <input
                type="radio"
                id="choice-english-lang"
                name="language_choice"
                disabled
              />
              <div className={`choiceTheTheme ${styles.persian_c_container}`}>
                <div>English</div>
                <div></div>
              </div>
            </label>
          </div>
        </div>
        <div
          className={`${
            styles.menu_choice_app_language_setting_container
          }  darkModeSettingGlobal ${
            settingOptions.langVisible ? styles.goToUp : styles.goToDown
          }`}
        >
          <div className={styles.setting_title}>انتخاب زبان</div>
          <div className={styles.setting_input_container}>
            <label htmlFor="choice-persian-lang">
              <input
                type="radio"
                id="choice-persian-lang"
                name="language_choice"
                defaultChecked
              />
              <div className={` choiceTheTheme ${styles.persian_c_container}`}>
                <div>فارسی</div>
                <div></div>
              </div>
            </label>
            <label htmlFor="choice-english-lang">
              <input
                type="radio"
                id="choice-english-lang"
                name="language_choice"
                disabled
              />
              <div className={`choiceTheTheme ${styles.persian_c_container}`}>
                <div>English</div>
                <div></div>
              </div>
            </label>
          </div>
        </div>
        {/* PRICE */}
        <div
          className={`${
            styles.menu_choice_app_language_setting_container
          }  darkModeSettingGlobal ${
            settingOptions.priceVisible ? styles.goToUp : styles.goToDown
          }`}
        >
          <div className={styles.setting_title}>نمایش قیمت بر اساس</div>
          <div className={styles.setting_input_container}>
            <label htmlFor="choice-price-IRT">
              <input
                type="radio"
                id="choice-price-IRT"
                name="price_choice"
                value="IRT"
                checked={appPrice === "IRT"}
                onChange={() => dispatch(choiceRialPrice())}
              />
              <div
                className={` changeBackgroundColorToBlack font_color_white ${styles.persian_c_container}`}
              >
                <div>تومان(IRT)</div>
                <div></div>
              </div>
            </label>
            <label htmlFor="choice-price-USDT">
              <input
                type="radio"
                id="choice-price-USDT"
                name="price_choice"
                value="USDT"
                checked={appPrice === "USDT"}
                onChange={() => dispatch(choiceUSDTPrice())}
              />
              <div
                className={`changeBackgroundColorToBlack  font_color_white ${styles.persian_c_container}`}
              >
                <div>تتر(USDT)</div>
                <div></div>
              </div>
            </label>
          </div>
        </div>
        {/*Choice THEME DIVISION */}
        <div
          className={`${
            styles.menu_choice_app_language_setting_container
          } darkModeSettingGlobal ${
            settingOptions.themeVisible ? styles.goToUp : styles.goToDown
          }`}
        >
          <div className={styles.setting_title}>انتخاب حالت نمایش</div>
          <div className={styles.setting_input_container}>
            <label htmlFor="choice-theme-white">
              <input
                type="radio"
                id="choice-theme-white"
                name="theme_choice"
                value="light"
                checked={appTheme === "light"}
                onChange={() => dispatch(choiceLightMode())}
              />
              <div
                className={` changeBackgroundColorToBlack font_color_white ${styles.persian_c_container}`}
              >
                <div>روشن</div>
                <div></div>
              </div>
            </label>
            <label htmlFor="choice-theme-dark">
              <input
                type="radio"
                id="choice-theme-dark"
                name="theme_choice"
                value="dark"
                checked={appTheme === "dark"}
                onChange={() => dispatch(choiceDarkMode())}
              />
              <div
                className={`changeBackgroundColorToBlack  font_color_white ${styles.persian_c_container}`}
              >
                <div>تاریک</div>
                <div></div>
              </div>
            </label>
          </div>
        </div>
      </div>
      {/*Top code write for pop-up menu for choice app settings */}
      <div className={`${styles.container} darkModeSettingGlobalContainer`}>
        <div className={styles.BackArrow}>
          <img
            src={ArrowBackIcon}
            alt="BACK"
            width={15}
            height={15}
            onClick={() => history.goBack()}
            className="convertPicToWhite"
          />
        </div>
        <div className={`${styles.title_component} font_color_white`}>
          تنظیمات
        </div>
        <div
          className={`changeBackgroundColorToHardBlack font_color_white ${styles.section_of_setting} `}
          onClick={() => setSettingOptions({ langVisible: 1 })}
        >
          <div className={styles.part_of_setting}>
            <div>زبان</div>
            <div>فارسی</div>
            <div className={styles.iconOpenCheckbox}>
              <img src={ArrowRightIcon} alt="" width={15} height={15} />
            </div>
          </div>
        </div>
        <div
          className={`changeBackgroundColorToHardBlack font_color_white ${styles.section_of_setting}`}
          onClick={() => setSettingOptions({ themeVisible: 1 })}
        >
          <div className={styles.part_of_setting}>
            <div>حالت نمایش</div>
            <div>{appTheme === "dark" ? "تاریک" : "روشن"}</div>
            <div className={styles.iconOpenCheckbox}>
              <img src={ArrowRightIcon} alt="" width={15} height={15} />
            </div>
          </div>
        </div>
        <div
          className={`changeBackgroundColorToHardBlack font_color_white ${styles.section_of_setting}`}
          onClick={() => setSettingOptions({ priceVisible: 1 })}
        >
          <div className={styles.part_of_setting}>
            <div>نمایش قیمت</div>
            <div>{appPrice === "IRT" ? "تومان" : "تتر"}</div>
            <div className={styles.iconOpenCheckbox}>
              <img src={ArrowRightIcon} alt="" width={15} height={15} />
            </div>
          </div>
        </div>
        <Link to={`/privacy`}>
          <div
            className={`changeBackgroundColorToHardBlack font_color_white ${styles.section_of_setting} `}
          >
            <div className={styles.part_of_setting}>
              <div>Privacy Policy</div>
              {/* <div>فارسی</div> */}
              <div className={styles.iconOpenCheckbox}>
                <img src={ArrowRightIcon} alt="" width={15} height={15} />
              </div>
            </div>
          </div>
        </Link>

        <div
          className={`changeBackgroundColorToHardBlack font_color_white ${styles.section_of_setting}`}
        >
          <div className={styles.part_of_setting}>
            <div>ورژن</div>
            <div>{version}</div>
          </div>
        </div>
      </div>
    </>
  );
}
