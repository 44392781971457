import { memo, useMemo } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import styles from "./MarketCoins.module.scss";
import appIcons from "../../utils/appIcons";
import { Stack } from "@mui/system";
import { addCommaWithRialPrice } from "../../utils/addComma";
import { addFavoriteCoins } from "../../actions/favoriteCoins";
import InfiniteScroll from "react-infinite-scroll-component";
import serviceConfig from "../../services/config.json";

function MarketCoins(props) {
  const { search, showBy, marketWSInfo } = props;
  const ref = useRef(null);
  const dispatch = useDispatch();
  const marketListReducer = useSelector((state) => state.tradesList);
  const favUserCoins = useSelector((state) => state.favCoins);
  const [marketList, setMarketList] = useState([]);
  //## State For View Port
  const [numberOfShow, setNumberOfShow] = useState(20);
  const [hasMoreItem, setHasMoreItem] = useState(true);
  //##
  useEffect(() => {
    if (marketListReducer.hasOwnProperty("list")) {
      const filteredList = marketListReducer.list.filter(
        (c) => c.area_title === showBy
      );
      setMarketList(filteredList);
    }
  }, [marketListReducer]);

  useEffect(() => {
    const userSearch = search.toLowerCase().trim();
    if (!isEmpty(marketListReducer)) {
      const filteredList = marketListReducer.list
        .filter(
          (c) =>
            c.asset.toLowerCase().includes(userSearch) ||
            c.asset_title.toLowerCase().includes(userSearch) ||
            c.asset_title_fa.toLowerCase().includes(userSearch)
        )
        .filter((c) => c.area_title === showBy);
      setMarketList(filteredList);
    }
  }, [showBy, search]);

  const handleToggleFavoriteCoin = (e, item) => {
    e.preventDefault();
    dispatch(addFavoriteCoins(item));
  };

  //## -->
  const CurrentSelectedItems = useMemo(() => {
    setHasMoreItem(true);
    if (isEmpty(marketList)) return [];
    return marketList.slice(0, numberOfShow);
  }, [marketList, numberOfShow]);
  //# Handle Function for View-Port
  function handleNext() {
    if (CurrentSelectedItems.length >= marketList.length) {
      setHasMoreItem(false);
      return;
    }
    setNumberOfShow((prev) => prev + 20);
  }
  return (
    <div className="scroll-container" ref={ref}>
      {!isEmpty(CurrentSelectedItems) && (
        <InfiniteScroll
          dataLength={CurrentSelectedItems}
          // loader={<h4>Loading...</h4>}
          height="calc(100vh - 320px)"
          next={handleNext}
          hasMore={hasMoreItem}
        >
          {CurrentSelectedItems.map((item, index) => (
            <Link
              to={`/market/trade?market=${item.market}`}
              key={item.market}
              className={styles.a}
            >
              <div className={styles._item}>
                <div className={styles.star}>
                  {favUserCoins.includes(item.asset_title) ? (
                    <span
                      className={styles.fill_start}
                      onClick={(e) =>
                        handleToggleFavoriteCoin(e, item.asset_title)
                      }
                    >
                      {appIcons("StarFillIcon", { fontSize: "inherit" })}
                    </span>
                  ) : (
                    <span
                      onClick={(e) =>
                        handleToggleFavoriteCoin(e, item.asset_title)
                      }
                    >
                      {appIcons("StarEmptyIcon", { fontSize: "inherit" })}
                    </span>
                  )}
                </div>
                <div className={styles.pic}>
                  <img
                    src={`${serviceConfig.image_url}/${item.asset}.png`}
                    width={28}
                    height={28}
                    alt={item.asset}
                    loading="lazy"
                  />
                </div>
                <div className={styles.name}>
                  <div>
                    <strong>
                      {item.asset} / {item.area_title}
                    </strong>
                  </div>
                </div>
                <div className={`eng-number-font ${styles.changes}`}>
                  {item.area_title === "IRT"
                    ? addCommaWithRialPrice(marketWSInfo[item.market]?.last)
                    : marketWSInfo[item.market]?.last}
                </div>
              </div>
            </Link>
          ))}
        </InfiniteScroll>
      )}
      {marketListReducer.hasOwnProperty("list") && isEmpty(marketList) && (
        <Stack className={styles.not_found}>موردی یافت نشد</Stack>
      )}
    </div>
  );
}
export default memo(MarketCoins);
