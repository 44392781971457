//RRD
import { Link, useLocation } from "react-router-dom";
import styles from "./BottomCrypto.module.scss";
//* Store, Actions -->
import { useSelector } from "react-redux";
import { memo } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import appIcons from "../../../utils/appIcons";

const BottomLinks = [
  { title: "خانه", href: "/", icon: "HomeOutIcon", fillIcon: "HomeFillIcon" },
  {
    title: "تبدیل",
    href: "/convert",
    icon: "ChartOutIcon",
    fillIcon: "ChartFillIcon"
  },
  { title: "بازار ها", href: "/market", icon: "Chart2Icon" },
  {
    title: "کیف پول",
    href: "/my-wallet",
    icon: "WalletOutIcon",
    fillIcon: "WalletFillIcon"
  },
  {
    title: "پروفایل",
    href: "/my-profile",
    icon: "PersonOutIcon",
    fillIcon: "PersonFillIcon"
  }
];
function BottomCrypto() {
  const showMenuWhenCoinListIsOpen = useSelector((state) => state.menuConfig);
  const { pathname } = useLocation();
  return (
    <div
      className={`${styles.BottomMenu} ${
        showMenuWhenCoinListIsOpen ? styles.hiddenMenu : ""
      }`}
    >
      {BottomLinks.map((ctx, idx) => {
        if (idx === 2)
          return (
            <Link key={idx} className={styles.markets_link} to={ctx.href}>
              <Stack key={idx} className={styles.markets}>
                {appIcons(ctx.icon)}
              </Stack>
              <Typography component="p">{ctx.title}</Typography>
            </Link>
          );
        return (
          <Link to={ctx.href} key={idx} className={styles.item_link}>
            <Stack
              className={styles.item_box}
              data-active={pathname === ctx.href}
            >
              <IconButton>
                {appIcons(pathname === ctx.href ? ctx.fillIcon : ctx.icon)}
              </IconButton>
              {pathname !== ctx.href ? (
                <Typography component="p">{ctx.title}</Typography>
              ) : (
                <div className={styles.active_cirlce}></div>
              )}
            </Stack>
          </Link>
        );
      })}
    </div>
  );
}
export default memo(BottomCrypto);
