import React, { useRef } from "react";

import styles from "./ImagePicker.module.scss";
import appIcons from "../../utils/appIcons";
import { Box, Stack } from "@mui/material";

function ImagePicker(props) {
  const { id = "test", onChange, file, handleDeletePicture, ...rest } = props;
  const imageRef = useRef(null);

  return (
    <div className={styles.container}>
      <form>
        <label htmlFor={id}>
          <div className={styles.label} data-visiblity={!!file}>
            <i>{appIcons("addPhotoIcon")}</i>
            <span>تصویر مورد نظر را ضمیمه کنید</span>
          </div>
        </label>
        <input
          id={id}
          name={id}
          key={id}
          type="file"
          multiple={false}
          onChange={onChange}
          value=""
          ref={imageRef}
          {...rest}
        />
        {file && (
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              backgroundColor: "var(--third-color)",
              paddingX: 1,
              paddingY: 0.5,
            }}
          >
            <img
              src={URL.createObjectURL(file)}
              onLoad={URL.revokeObjectURL(file)}
              width={70}
              height={70}
              style={{ objectFit: "contain" }}
            />
            <Box width={120}>
              <button type="reset" onClick={handleDeletePicture}>
                پاک کردن
              </button>
            </Box>
          </Stack>
        )}
      </form>
    </div>
  );
}

export default ImagePicker;
