import { createContext } from "react";

export const context = createContext({
  userEmail: "",
  userNumber: "",
  userPassword: "",
  newUserEmail: "",
  newUserNumber: "",
  changePassword: "",
  newReferral: "",
  numRecovery: "",
  emailRecovery: "",
  setEmailRecovery: "",
  verifyCodeRecovery: "",
  recoveryAccSteps: {},
  repeatChangePassword: "",
  registerAccSteps: {},
  newUserPassword: "",
  repeatNewUserPassword: "",
  verifyCodeRegister: "",
  hashCodeRecovery: "",
  buttonDisabled: "",
  disableNewRef: "",
  percentChanges: {},
  setDisableNewRef: () => {},
  setButtonDisabled: () => {},
  setHashCodeRecovery: () => {},
  handleChangeUserPassword: () => {},
  handleRegisterCodeVerify: () => {},
  setVerifyCodeRegister: () => {},
  setRepeatNewUserPassword: () => {},
  setNewUserPassword: () => {},
  setRegisterAccSteps: () => {},
  setRepeatChangePassword: () => {},
  setVerifyCodeRecovery: () => {},
  setNumRecovery: () => {},
  setNewReferral: () => {},
  setUserEmail: () => {},
  setUserNumber: () => {},
  setUserPassword: () => {},
  setNewUserEmail: () => {},
  setNewUserNumber: () => {},
  setChangePassword: () => {},
  handleLoginNumber: () => {},
  handleLoginEmail: () => {},
  handleRegisterNum: () => {},
  handleRegisterEmail: () => {},
  handleRecoveryNum: () => {},
  handleRecoveryEmail: () => {},
  handleVerifyCodeRecovery: () => {},
  setRecoveryAccSteps: () => {},
  handleNewUserCompleteRegistration: () => {},
  //bottom props for handle error''
  validatorLogin: "",
  setValidatorLogin: () => {},
  validatorRegister: "",
  setValidatorRegister: () => {},
  validatorRecovery: "",
  setValidatorRecovery: () => {},
  captchaDetails: {},
  setCaptchaDetails: () => {},
});
