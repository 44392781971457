import { useState, useContext } from "react";
import { context } from "../../../context/context";
//Components
import RecoveryMobile from "./RecoveryMobile";
import RecoveryEmail from "./RecoveryEmail";
import CodeVerify from "./codeVerify/CodeVerify";
import NewPassword from "./newPassword/NewPassword";
//Styles and Icons
import styles from "./RecoveryPass.module.scss";
function RecoveryPass() {
  const [swapButton, setSwapButton] = useState("with-mobile");
  const { recoveryAccSteps } = useContext(context);
  if (recoveryAccSteps.showVerifyCode) return <CodeVerify />;
  if (recoveryAccSteps.showNewPassword) return <NewPassword />;
  return (
    <>
      <div className={styles.container}>
        <div className={` font_color_change_to_green ${styles.recovery_title}`}>
          بازیابی رمزعبور
        </div>
        <div className={styles.login_buttons}>
          <div
            className={`font_color_white ${styles.with_email}`}
            onClick={() => setSwapButton("with-email")}
          >
            ایمیل
          </div>
          <div
            className={`font_color_white ${styles.with_mobile}`}
            onClick={() => setSwapButton("with-mobile")}
          >
            موبایل
          </div>
        </div>
        <div className={styles.lines_under_button}>
          <div
            className={`${styles.line_button} ${
              swapButton === "with-email" ? styles.line_button_active : ""
            }`}
          ></div>
          <div className={styles.line_button}></div>
          <div
            className={`${styles.line_button} ${
              swapButton === "with-mobile" ? styles.line_button_active : ""
            }`}
          ></div>
        </div>
        <main>
          {swapButton === "with-mobile" ? (
            <RecoveryMobile />
          ) : (
            <RecoveryEmail />
          )}
        </main>
      </div>
    </>
  );
}
export default RecoveryPass;
