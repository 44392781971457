import { Container, Stack } from "@mui/material";
import { useReducer, useLayoutEffect } from "react";
import {
  fetchPageList,
  initialStates,
  reducer,
} from "../pageComponents/OrdersHistoryPage/states";
import ViewList from "../pageComponents/OrdersHistoryPage/ViewList";
import PrimaryHeader from "../components/Headers/PrimaryHeader";
import ButtonTitle from "../components/Buttons/ButtonTitle";
import AssetsListModal from "../pageComponents/Common/AssetsListModal";
import DatePickerJalali from "../pageComponents/Common/DatePickerJalali";
import ModalViewSelectItem from "../pageComponents/Common/ModalViewSelectItem";

const PageItems = [
  { id: "BUY", title: "خرید", icon: "addIcon" },
  { id: "SELL", title: "فروش", icon: "removeOutlinedIcon" },
  { id: "CHANGE", title: "تبدیل", icon: "changeIcon" },
];
//# Deposits And Withdrawals History Page
export default function OrdersHistory() {
  const [state, dispatch] = useReducer(reducer, initialStates);
  const params = new URLSearchParams(window.location.search);

  useLayoutEffect(() => {
    const typeFilter = params.get("f");
    if (typeof typeFilter === "string") {
      const validType = PageItems.filter(
        (c) => c.id === typeFilter.toUpperCase()
      )[0];
      if (validType?.id) {
        dispatch({
          type: "FILTER",
          payload: { type: "type", value: validType.id },
        });
      }
    }
  }, []);

  useLayoutEffect(() => {
    const controller = new AbortController();
    (async () => {
      const { error, list } = await fetchPageList(controller.signal);
      if (error) {
        dispatch({ type: "INIT_FAILED" });
      } else {
        dispatch({ type: "INIT_SUCCESS", payload: list });
      }
    })();
    return () => {
      controller.abort();
    };
  }, [state["refreshPageCount"]]);

  //# Handlere's!!
  const handleToggleAssetsView = () => {
    dispatch({ type: "TOGGLE_ASSETS" });
  };
  const handleToggleTypes = () => {
    dispatch({ type: "TOGGLE_SHOW_TYPES" });
  };
  const onChangeDate = (e) => {
    dispatch({ type: "FILTER", payload: { type: "date", value: e } });
  };
  const handleChangeType = (e) => {
    dispatch({ type: "FILTER", payload: { type: "type", value: e } });
  };
  const onClickResetTime = () => {
    dispatch({ type: "RESET_TIME" });
  };
  const handleResestSymbol = (e) => {
    e.stopPropagation();
    dispatch({ type: "RESET_SYMBOL" });
  };
  const handleResetType = (e) => {
    e.stopPropagation();
    dispatch({ type: "RESET_TYPE" });
  };
  const handleShowNextItems = () => {
    dispatch({ type: "INCREASE_VIEW" });
  };
  const onChangeSymbolSearch = (item) => {
    dispatch({
      type: "FILTER",
      payload: { type: "symbol", value: item["symbol"] },
    });
  };
  const onClickRefreshDetails = () => {
    dispatch({ type: "REFRESH" });
  };
  return (
    <>
      <Container>
        <PrimaryHeader title="تاریخچه سفارشات" fixButton />
        <Stack>
          <Stack
            direction="row-reverse"
            justifyContent="space-between"
            spacing={1}
          >
            <Stack flex={1}>
              <ButtonTitle
                title={
                  state["filter"]["name"]
                    ? state["filter"]["name"]
                    : "انتخاب ارز"
                }
                onClick={handleToggleAssetsView}
                showReset={!!state["filter"]["name"]}
                onClickReset={handleResestSymbol}
              />
            </Stack>
            <Stack flex={1}>
              <ButtonTitle
                title={
                  PageItems.filter((c) => c.id === state["filter"]["type"])[0]
                    ?.title ?? "نوع"
                }
                onClick={handleToggleTypes}
                showReset={!!state["filter"]["type"]}
                onClickReset={handleResetType}
              />
            </Stack>
          </Stack>
          <Stack mt={1}>
            <DatePickerJalali
              startDate={state["filter"]["startDate"]}
              endDate={state["filter"]["endDate"]}
              onChangeDate={onChangeDate}
              onClickResetTime={onClickResetTime}
            />
          </Stack>
        </Stack>
        <Stack pb={12} mt={1}>
          <ViewList
            state={state}
            handleShowNextItems={handleShowNextItems}
            PageItems={PageItems}
            onClickRefreshDetails={onClickRefreshDetails}
          />
        </Stack>
      </Container>
      <AssetsListModal
        visible={state["showAssets"]}
        onRequestClose={handleToggleAssetsView}
        onSelectSymbol={onChangeSymbolSearch}
        activeSymbol={state["filter"]["name"]}
      />
      <ModalViewSelectItem
        visible={state["showTypes"]}
        onRequestClose={handleToggleTypes}
        items={PageItems}
        selected={state["filter"]["type"]}
        onChange={handleChangeType}
      />
    </>
  );
}
