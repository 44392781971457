import { useHistory } from "react-router";
import styles from "./Authentication.module.scss";
//Icon
import ArrowBackIcon from "../../../../styles/icon/arrow_back_fill.svg";
import IdCardIcon from "../../../../styles/icon/idcardgray.svg";
import AttachIcon from "../../../../styles/icon/AttachmentIcon.svg";
import GuidPic from "../../../../styles/pics/authenticationGuidPic.png";
import { checkFileTypeImage } from "../../../Validator/Validator";
import { errorMessage, successMessage } from "../../../../utils/messages";
import { usersVerifyInfo } from "../../../../services/userServices";
import { useEffect, useState } from "react";
import { uploadAuthUserFiles } from "../../../../services/adminServices";
import { ERROR_CODE9 } from "../../../Validator/ErrorCodes";
export default function PersonPic() {
  const history = useHistory();

  const [docInfo, setDocInfo] = useState("");
  const [selfieInfo, setSelfieInfo] = useState({
    file: "",
    deactivateButton: false,
  });
  useEffect(() => {
    const getVerifyInformation = async () => {
      const verifyInfo = await usersVerifyInfo();
      if (verifyInfo.data.message) setDocInfo(verifyInfo.data.user);
    };
    getVerifyInformation();
  }, []);
  const handleSendUserSelfie = async (e) => {
    e.preventDefault();
    if (!selfieInfo.file) return errorMessage("فایلی انتخاب نشده");
    let validFileType = checkFileTypeImage(selfieInfo.file.name);
    if (!validFileType) return errorMessage(ERROR_CODE9);
    try {
      setSelfieInfo({ ...selfieInfo, deactivateButton: true });
      const data = new FormData();
      data.append("fileType", "SELFIE");
      data.append("file", selfieInfo.file);
      const info = await uploadAuthUserFiles(data);
      if (info.status === 200) {
        successMessage(info.data.message);
        setSelfieInfo({ ...selfieInfo, file: "" });
        history.replace("/my-profile/authentication");
      }
    } catch (ex) {
      errorMessage("مشکلی در آپلود رخ داده! دوباره تلاش کنید");
    } finally {
      setSelfieInfo({
        ...selfieInfo,
        deactivateButton: false,
      });
    }
  };
  if (docInfo.PersonPic === "UNCHECKED" || docInfo.PersonPic === "APPROVED") {
    history.replace("/my-profile/authentication");
    return null;
  }

  return (
    <>
      <section className={styles.container_of_person_pic}>
        <header>
          <div className={styles.back}>
            <img
              src={ArrowBackIcon}
              alt="BACK"
              width={15}
              height={15}
              onClick={() => history.push("/my-profile/authentication")}
              className="convertPicToWhite"
            />
          </div>
          <div className={`font_color_white ${styles.header_title}`}>
            مدارک احراز هویت
          </div>
        </header>
        <main>
          <div className={`font_color_white ${styles.header_of_main_title}`}>
            آپلود تصویر احراز هویت
          </div>
          <div className={` font_color_white ${styles.guide_to_send_files}`}>
            عکس از چهره خودتان را مانند نمونه (یعنی روی کاغذ یادداشت می نمایید:
            برای ارزفی اینجانب قوانین سایت ارزفی را مطالعه و قبول می نمایم و
            همراه با اصل کارت ملی یا اصل شناسنامه یا اصل پاسپورت با حداقل اعتبار
            یک ساله همراه با چهره خود به صورت واضج و خوانا گرفته) و ارسال می
            نمایید.
          </div>
          <div className={styles.guid_pic_container}>
            <img src={"/images/auth_guid.jpg"} alt="GUID" width="100%" />
          </div>
          <form onSubmit={(e) => handleSendUserSelfie(e)}>
            <div className={styles.section_of_input}>
              <label htmlFor="uploadIdCardAuthentication">
                <div className={` ${styles.label_for_input_file}`}>
                  <div className={styles.icon_card}>
                    <img src={IdCardIcon} alt="" width={40} height={40} />
                  </div>
                  <div className={styles.text_icon_card}>
                    <div className="font_color_white">
                      انتخاب تصویر احراز هویت
                    </div>
                    <div>
                      <img
                        src={AttachIcon}
                        alt=""
                        width={18}
                        height={18}
                        className="convertPicToGreen"
                      />
                    </div>
                  </div>
                </div>
                <input
                  type="file"
                  id="uploadIdCardAuthentication"
                  onChange={(e) =>
                    setSelfieInfo({ ...selfieInfo, file: e.target.files[0] })
                  }
                />
              </label>
            </div>
            <div className={styles.pic_uploaded_info}>
              {selfieInfo.file && (
                <>
                  <div>{selfieInfo.file.name}</div>
                  {!selfieInfo.deactivateButton && (
                    <div
                      onClick={() => setSelfieInfo({ ...selfieInfo, file: "" })}
                    >
                      پاک کردن عکس
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={styles.button_container}>
              <button
                type="submit"
                className="changeBackgroundToGreen"
                disabled={selfieInfo.deactivateButton}
              >
                ارسال
              </button>
            </div>
          </form>
        </main>
      </section>
    </>
  );
}
