export const AddSocketBuyDepth = (data) => {
  return (dispatch) => {
    dispatch({ type: "SET_DEPTH_BUY_PRICE", payload: data });
  };
};
export const AddSocketSellDepth = (data) => {
  return (dispatch) => {
    dispatch({ type: "SET_DEPTH_SELL_PRICE", payload: data });
  };
};
