import React, { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./WalletPage.module.scss";
import { Box, Checkbox, Divider, Stack, Typography } from "@mui/material";
import useDebounce from "../../hooks/useDebounce";
import InfiniteScroll from "react-infinite-scroll-component";
import { isEmpty } from "lodash";
import BottomModal from "../../components/Modals/BottomModal";
import ImagePreview from "../../utils/ImagePreview";
import { addComma } from "../../utils/addComma";
import { Link } from "react-router-dom";
import { updateUserAssetsItem } from "./states";
import { errorMessage, successMessage } from "../../utils/messages";
import { ThreeDots } from "react-loader-spinner";
import PaperButton from "../../components/Buttons/PaperButton";

const userCurrectAsset = (item, price) => {
  const res = { coin: "0.00", price: "0.00" };
  if (item) {
    const numFixed = parseFloat(item["amount"] - item["lockedAmount"])
    if (numFixed < 1) {
      res.coin = numFixed
    }else{
      res.coin = addComma(numFixed);
    }
    const irt = addComma(
      parseFloat((item["amount"] - item["lockedAmount"]) * price)
    );
    res.price = irt.toString()?.split(".")?.[0];
    return res;
  }
  return res;
};
function UserWallets({ state, dispatch }) {
  const marketAssets = useSelector((state) => state.market.data);
  const [showJustBalance, setShowJustBalance] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [showLength, setShowLength] = useState(10);
  const searchValue = useDebounce(searchInput.toUpperCase(), 400);
  const [modalStatus, setModalStatus] = useState({
    visibility: false,
    item: {},
  });

  const ref = useRef(null);

  const handleChangeshowBalance = () => {
    setShowJustBalance((prev) => !prev);
  };
  const handleShowNextItems = () => {
    setShowLength((prev) => prev + 10);
  };
  const handleOpenModal = (item) => {
    setModalStatus({ visibility: true, item });
  };

  const handleCloseModal = () => {
    setModalStatus((prev) => ({ ...prev, visibility: false }));
  };

  const handleRequestRefresh = async () => {
    const controller = new AbortController();
    setDisableButton(true);
    const { error, message } = await updateUserAssetsItem(
      modalStatus["item"]["symbol"],
      controller.signal
    );
    if (error) {
      errorMessage(message);
    } else {
      successMessage(message);
      dispatch({ type: "REFRESH" });
      handleCloseModal();
    }
    setDisableButton(false);
  };

  const handleRefreshWithLoading = () => {
    return dispatch({ type: "REFRESH_VIA_LOADING" });
  };

  const showAssetsList = useMemo(() => {
    const result = { items: [], hasNext: false };
    const ExistsBalance = Object.keys(state["userWallets"]);
    if (marketAssets) {
      result.items = marketAssets
        .filter((c) => {
          return (
            c["symbol"].includes(searchValue) ||
            c["faName"].includes(searchValue)
          );
        })
        .filter((c) => {
          if (showJustBalance) {
            return ExistsBalance.includes(c.symbol);
          }
          return c;
        })
        .slice(0, showLength);
      result.hasNext = result.items.length < marketAssets.length;
    }
    return result;
  }, [searchValue, marketAssets, showLength, showJustBalance]);

  return (
    <>
      <Stack mt={1} height={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={0.5}
        >
          <Box flex={1}>
            <input
              placeholder="جست و جو"
              className={styles.searchBox}
              onChange={(e) => setSearchInput(e.target.value)}
              value={searchInput}
            />
          </Box>
          <Box flex={0.09}></Box>
          <Stack
            flex={1}
            flexDirection="row"
            alignItems="center"
            sx={{ direction: "ltr" }}
          >
            <Checkbox
              size="small"
              checked={showJustBalance}
              onChange={handleChangeshowBalance}
            />
            <Typography
              fontSize={14}
              color="var(--secondary-color)"
              onClick={handleChangeshowBalance}
            >
              موجودی
            </Typography>
          </Stack>
        </Stack>
        <Stack height="calc(100% - 65px)">
          {!state["isLoading"] && !state["isError"] ? (
            <div ref={ref} className="scroll-container">
              <InfiniteScroll
                dataLength={showAssetsList["items"].length}
                next={handleShowNextItems}
                hasMore={showAssetsList["hasNext"]}
                height="calc(100vh - 300px)"
              >
                {!isEmpty(showAssetsList["items"]) ? (
                  showAssetsList.items.map((ctx, idx) => {
                    const { coin, price } = userCurrectAsset(
                      state["userWallets"][ctx["symbol"]],
                      ctx["arzfi_buy_price"]
                    );
                    const itemViaDetails = {
                      ...ctx,
                      userCoin: coin,
                      userCalcIRT: price,
                    };
                    return (
                      <div
                        className={styles.item_wallet}
                        key={ctx["faName"]}
                        onClick={() => handleOpenModal(itemViaDetails)}
                      >
                        <div className={styles.imageSide}>
                          <div className={styles.imagePre}>
                            <ImagePreview
                              image={ctx.symbol}
                              width={30}
                              height={30}
                            />
                            <span>{ctx["faName"]}</span>
                          </div>
                          <strong>
                            {ctx["name"]} / {ctx["symbol"]}
                          </strong>
                        </div>
                        <div className={styles.priceSide}>
                          <div>
                            <span className={styles.number}>{coin}</span>
                            <span className={styles.symbol}>
                              {ctx["symbol"]}
                            </span>
                          </div>
                          <div>
                            <span className={styles.number}>{price}</span>
                            <span className={styles.symbol}>IRT</span>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <Stack height={1} alignItems="center" justifyContent="center">
                    <Typography
                      textAlign="center"
                      color="var(--secondary-color)"
                      sx={{ backgroundColor: "rgba(0,0,0,0.2)" }}
                      width={1}
                      py={1.5}
                    >
                      موردی یافت نشد
                    </Typography>
                  </Stack>
                )}
              </InfiniteScroll>
            </div>
          ) : state["isError"] ? (
            <Stack
              alignItems="center"
              justifyContent="center"
              height="calc(100vh - 300px)"
            >
              <Typography color="var(--danger-color)" fontSize={14}>
                مشکلی در دریافت اطلاعات رخ داده است
              </Typography>
              <Stack mt={2}>
                <PaperButton onClick={handleRefreshWithLoading}>
                  تلاش مجدد
                </PaperButton>
              </Stack>
            </Stack>
          ) : (
            <Stack alignItems="center" justifyContent="center" height={1}>
              <ThreeDots color="var(--primary-color)" width={40} />
            </Stack>
          )}
        </Stack>
      </Stack>
      <BottomModal
        visible={modalStatus["visibility"]}
        onRequestClose={handleCloseModal}
      >
        <Stack
          sx={{ backgroundColor: "var(--main2-color)" }}
          minHeight={180}
          borderRadius="10px 10px 0 0"
          padding={2}
        >
          <Stack
            direction="row"
            mb={0.4}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography color="var(--primary-color)">
              {modalStatus["item"]["symbol"]}
            </Typography>
            <button
              className={styles.update}
              disabled={disableButton}
              onClick={handleRequestRefresh}
            >
              بروز رسانی
            </button>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            paddingY={0.3}
            sx={{ backgroundColor: "rgba(0,0,0,0.03)" }}
            marginY={0.2}
            paddingX={0.2}
            boxSizing="border-box"
          >
            <span className={styles.titleModalPrice}>مقدار ارز:</span>
            <span className={styles.priceModal}>
              {modalStatus["item"]["userCoin"]}
            </span>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            paddingY={0.3}
            sx={{ backgroundColor: "rgba(0,0,0,0.03)" }}
            marginY={0.2}
            paddingX={0.2}
            boxSizing="border-box"
          >
            <span className={styles.titleModalPrice}>ارزش تومانی:</span>
            <span className={styles.priceModal}>
              {modalStatus["item"]["userCalcIRT"]}
            </span>
          </Stack>
          <Typography mt={1} textAlign="center" color="var(--secondary-color)">
            عملیات ها
          </Typography>
          <Divider
            sx={{ background: "var(--border-color)", marginBottom: 1 }}
          />
          <Stack direction="row" justifyContent="space-between">
            <Box flex={1} mb={1}>
              <Link to={`/withdraw/${modalStatus["item"]["symbol"]}`}>
                <button className={styles.button_group} data-color="danger">
                  برداشت
                </button>
              </Link>
            </Box>
            <Box flex={0.04}></Box>
            <Box flex={1}>
              <Link
                to={`/withdraw/${modalStatus["item"]["symbol"]}?wth=internal`}
              >
                <button className={styles.button_group} data-color="warning">
                  انتقال
                </button>
              </Link>
            </Box>
          </Stack>
          <Stack mb={2}>
            <Link to={`/deposit/${modalStatus["item"]["symbol"]}`}>
              <button className={styles.button_group} data-color="success">
                واریز
              </button>
            </Link>
          </Stack>
        </Stack>
      </BottomModal>
    </>
  );
}

export default UserWallets;
