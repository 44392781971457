import { getNetworkInfo } from "../services/marketServices";

export const coinNetworks = (name) => {
  return async (dispatch) => {
    const { data: networkInfo } = await getNetworkInfo(name);
    await dispatch({ type: "SET_NETWORK_INFO", payload: networkInfo });
  };
};
export const clearCoinNetworks = () => {
  return (dispatch) => {
    dispatch({ type: "CLEAR_NETWORK_INFO", payload: {} });
  };
};
