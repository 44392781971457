import React from "react";
import styles from "./MessageModal.module.scss";
import appIcons from "../../utils/appIcons";
//Message Modal Contain Picture of Success / Warning / Error
//# Bottom of Modal Contain Message or Anything like Button, attention or ...!
function MessageModal(props) {
  const { type = "error", title = "", children } = props;

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.messageIconContainer} data-type={type}>
          <div>{type === "error" ? appIcons('sadSmileIcon') : appIcons("attentionIcon")}</div>
          <p className={styles.title}>{title}</p>
        </div>
        <div className={styles.messageContext}>
            {children}
        </div>
      </div>
    </div>
  );
}

export default MessageModal;
