import React from "react";

import styles from "./ReferralPage.module.scss";
import { useMemo } from "react";
import { Box, Stack } from "@mui/material";
import configs from "../../services/config.json";
import { copyTextViaNavigator } from "../../utils/UI_utilities";
import { profitCalc } from "../../pages/Referral";
import { numberSeprator } from "../../utils/addComma";

function OverviewReferral({ state }) {
  const handleChangeTextToCopied = (e, text) => {
    copyTextViaNavigator(text);
    e.target.innerText = "کپی شد!";
    setTimeout(() => {
      e.target.innerText = "کپی";
    }, 400);
  };

  const defaultCode = useMemo(() => {
    if (state["referralCodes"][0]) {
      return state["referralCodes"].filter((c) => c.isDefault)?.[0] ?? {};
    }
    return {};
  }, [state]);

  return (
    <div className={styles.overviewContainer}>
      <strong className={styles.upper_notification}>
        با معرفی دوستان خود و فعالیت آنان در ارزفی{" "}
        {state.vipStatus["percent"] ?? "20"} درصد پورسانت بگیرید
      </strong>
      <strong className={styles.default_text}>کد پیشفرض شما:</strong>
      <Box>
        <div className={styles.textViaCopy}>
          <div
            className={styles.btnForCopy}
            onClick={(e) =>
              handleChangeTextToCopied(e, defaultCode["referralCode"])
            }
          >
            کپی
          </div>
          <div className={styles.textForCopy}>
            {defaultCode["referralCode"] ?? "مشکل در پردازش"}
          </div>
        </div>
      </Box>
      <Box mt={1.5}>
        <div className={styles.textViaCopy}>
          <div
            className={styles.btnForCopy}
            onClick={(e) =>
              handleChangeTextToCopied(
                e,
                configs.referral_url + defaultCode["referralCode"]
              )
            }
          >
            کپی
          </div>
          <div className={styles.textForCopy}>
            {defaultCode["referralCode"]
              ? configs.referral_url + defaultCode["referralCode"]
              : "مشکل در پردازش"}
          </div>
        </div>
      </Box>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        columnGap={1}
        mt={1}
      >
        <div className={styles.miniPercentBox}>
          <span>سهم شما</span>
          <span>
            {profitCalc(
              state["vipStatus"]?.["percent"] ?? 0,
              defaultCode["referrerPercent"] ?? 0
            )}
            %
          </span>
        </div>
        <div className={styles.miniPercentBox}>
          <span>سهم دوستان</span>
          <span>
            {profitCalc(
              state["vipStatus"]?.["percent"] ?? 0,
              100 - defaultCode["referrerPercent"] ?? 0
            )}
            %
          </span>
        </div>
      </Stack>
      <Stack
        alignItems="center"
        justifyContent="center"
        flexDirection="row"
        columnGap={1}
        mt={2}
      >
        <div className={styles.largeRefBox}>
          <span>تعداد دوستان</span>
          <span>{state["friends"].length ?? 0}</span>
        </div>
        <div className={styles.largeRefBox}>
          <span>تعداد کدهای شما</span>
          <span>{state["referralCodes"].length ?? 0}</span>
        </div>
      </Stack>
      <Stack
        alignItems="center"
        justifyContent="center"
        flexDirection="row"
        columnGap={1}
        mt={2}
      >
        <div className={styles.largeRefBox}>
          <span>مقدار سود شما(تومان)</span>
          <span>
            {numberSeprator(
              state["friends"].reduce((prev, next) => {
                return prev + next["totalPorsant"];
              }, 0)
            )}
          </span>
        </div>
      </Stack>
    </div>
  );
}

export default OverviewReferral;
