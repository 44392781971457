import React from "react";
import styles from "./InputViaTakePic.module.scss";
import appIcons from "../../utils/appIcons";

function InputViaTakePic(props) {
  const { onClickPaste, onClickTakePic, ...rest } = props;
  return (
    <div className={styles.container}>
      <input aria-multiline {...rest} />
      <button onClick={onClickTakePic} className={styles.cameraIcon}>
        {appIcons("scanIcon")}
      </button>
      <span className={styles.divider}></span>
      <button className={styles.pasteBtn} onClick={onClickPaste}>
        چسباندن
      </button>
    </div>
  );
}

export default InputViaTakePic;
