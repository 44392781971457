import { Stack } from "@mui/material";
import React from "react";
import { DatePicker } from "zaman";

import "./DatePickerJalali.scss";
import PaperButton from "../../components/Buttons/PaperButton";
export default function DatePickerJalali(props) {
  const { onChangeDate, startDate, endDate, onClickResetTime } = props;

  return (
    <Stack
      direction="row-reverse"
      justifyContent="space-between"
      alignItems="center"
      sx={{ direction: "rtl" }}
    >
      {!!startDate && (
        <Stack mr={1}>
          <PaperButton
            type="error"
            style={{ minHeight: 40 }}
            onClick={onClickResetTime}
          >
            ریست
          </PaperButton>
        </Stack>
      )}
      <Stack flex={1}>
        <DatePicker
          onChange={onChangeDate}
          round="x2"
          direction="ltr"
          inputAttributes={{
            placeholder: "انتخاب بازه زمانی (شروع - پایان)",
          }}
          range
          className="override-zaman"
          inputClass="input-zaman-override"
          defaultValue={new Date()}
          from={startDate}
          to={endDate}
        />
      </Stack>
    </Stack>
  );
}
