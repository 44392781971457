import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import BankIncrease from "./BankIncrease";
import styles from "./IncreaseBalance.module.scss";
import OnlineIncrease from "./OnlineIncrease";
import ArrowBackIcon from "../../../../styles/icon/arrow_back_fill.svg";
import { useDispatch, useSelector } from "react-redux";
import { userCoinBalance } from "../../../../actions/user";
import { showPrice } from "../../../../utils/priceFunc";
import { getUsersCards } from "../../../../services/userServices";
import PrimaryHeader from "../../../Headers/PrimaryHeader";
import { Stack } from "@mui/material";
import UserBalanceInfoBox from "../../../Boxs/UserBalanceInfoBox";
export default function IncreaseBalance() {
  const [swapButton, setSwapButton] = useState("online-payment");
  const UserIRT = useSelector((state) => state.fullUserInfo.coinBalance);
  const [cards, setCards] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(userCoinBalance("irt"));
      const getUserCards = async () => {
        const data = await getUsersCards();
        setCards(data.data.cards);
      };
      getUserCards();
    }
    return () => (mounted = false);
  }, []);
  return (
    <div className={styles.container}>
      <Stack marginX={1.5} mb={1}>
        <PrimaryHeader title="افزایش اعتبار" />
      </Stack>
      <div className={styles.button_for_swap}>
        <div
          className={` font_color_white ${styles.button_swap} ${
            swapButton === "bank-payment"
              ? `font_color_change_to_green ${styles.button_swap_active}`
              : ""
          }`}
          onClick={() => setSwapButton("bank-payment")}
        >
          واریز بانکی
        </div>
        <div
          className={`font_color_white ${styles.button_swap} ${
            swapButton === "online-payment"
              ? `font_color_change_to_green ${styles.button_swap_active}`
              : ""
          }`}
          onClick={() => setSwapButton("online-payment")}
        >
          پرداخت آنلاین
        </div>
      </div>
      <Stack margin={2} mt={4}>
        <UserBalanceInfoBox
          locked={UserIRT?.asset?.locked}
          balance={UserIRT?.asset?.balance}
        />
      </Stack>
      <main>
        {swapButton === "online-payment" ? (
          <OnlineIncrease cards={cards} />
        ) : swapButton === "bank-payment" ? (
          <BankIncrease setSwapButton={setSwapButton} />
        ) : (
          "No Content To Show - Reload Page Or Contact Support"
        )}
      </main>
    </div>
  );
}
