import { useContext, useEffect } from "react";
import MuiInputOne from "../../../../market/MuiInput/MuiInputOne";
import { tradesCTX } from "../../../../../context/tradesCTX";
import MuiInputTwo from "../../../../market/MuiInput/MuiInputTwo";
import PercentBalance from "../../../../PercentBalance";
import HelperText2 from "../HelperTexts/HelperText2";

export default function StopMarket({ type }) {
  const marketTradeContext = useContext(tradesCTX);
  const {
    //* --> State's Of Price And Amount
    orderBuyPriceStopMarket,
    orderSellPriceStopMarket,
    setOrderBuyPriceStopMarket,
    setOrderSellPriceStopMarket,
    orderBuyAmountStopMarket,
    setOrderBuyAmountStopMarket,
    orderSellAmountStopMarket,
    setOrderSellAmountStopMarket,
    areaBalance,
    assetBalance,
    //* --> State's Of Price And Amount
    //* --> Market Name
    currentTrade,
    lastTrades,
    //* <-- Market Name
    buyStopMarketSlider,
    setBuyStopMarketSlider,
    sellStopMarketSlider,
    setSellStopMarketSlider,
  } = marketTradeContext;

  useEffect(() => {
    if (buyStopMarketSlider) {
      let percent = +buyStopMarketSlider * 0.01;
      let amount = percent * areaBalance;
      setOrderBuyAmountStopMarket(amount);
    }
  }, [buyStopMarketSlider]);
  useEffect(() => {
    if (sellStopMarketSlider) {
      let percent = +sellStopMarketSlider * 0.01;
      let amount = percent * assetBalance;
      setOrderSellAmountStopMarket(amount);
    }
  }, [sellStopMarketSlider]);
  return (
    <div>
      {type === "buy" ? (
        <>
          <div className="mt-15">
            <MuiInputTwo
              amount={orderBuyPriceStopMarket}
              setAmount={setOrderBuyPriceStopMarket}
              decimalScale={currentTrade?.buy_places}
              placeholder={`(${currentTrade?.area_title_fa ?? "-"})استاپ`}
              xs={{ direction: "rtl" }}
              market={lastTrades}
            />
          </div>
          <div className="box-text-markets mt-15">
            <p>خرید به ترتیب بهترین قیمت بازار</p>
          </div>
          <div className="mt-15">
            <MuiInputOne
              amount={orderBuyAmountStopMarket}
              setAmount={setOrderBuyAmountStopMarket}
              decimalScale={currentTrade?.buy_places}
              adorment={currentTrade?.area_title}
              placeholder={`(${currentTrade?.area_title_fa ?? "-"})مقدار`}
            />
          </div>
          <div className="mt-15">
            <PercentBalance
              value={buyStopMarketSlider}
              setValue={setBuyStopMarketSlider}
            />
          </div>
          <div className="mt-30">
            <HelperText2
              symbol={currentTrade?.area_title}
              balance={areaBalance}
            />
          </div>
        </>
      ) : (
        <>
          <div className="mt-15">
            <MuiInputTwo
              amount={orderSellPriceStopMarket}
              setAmount={setOrderSellPriceStopMarket}
              decimalScale={currentTrade?.buy_places}
              placeholder={`(${currentTrade?.area_title_fa ?? "-"})استاپ`}
              xs={{ direction: "rtl" }}
              market={lastTrades}
            />
          </div>
          <div className="box-text-markets">
            <p>فروش به ترتیب بهترین قیمت بازار</p>
          </div>
          <div className="mt-15">
            <MuiInputOne
              amount={orderSellAmountStopMarket}
              setAmount={setOrderSellAmountStopMarket}
              adorment={currentTrade?.asset}
              decimalScale={currentTrade?.sell_places}
              placeholder={`(${currentTrade?.asset_title_fa ?? "-"})مقدار`}
            />
          </div>
          <div className="mt-15">
            <PercentBalance
              value={sellStopMarketSlider}
              setValue={setSellStopMarketSlider}
            />
          </div>
          <div className="mt-30">
            <HelperText2 symbol={currentTrade?.asset} balance={assetBalance} />
          </div>
        </>
      )}
    </div>
  );
}
