import { memo } from "react";
import styles from "./BackButton.module.scss";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
//* --> Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

function BackButton(props) {
  const history = useHistory();

  const goBackLocation = () => {
    history.go(-1);
  };
  return (
    <div className={styles.container}>
      <div className={styles.home}>
        <Link to="/">
          <HomeOutlinedIcon
            fontSize="inherit"
            className="change_color_to_E1E2E4"
          />
        </Link>
        <span style={{marginLeft:"6px",fontSize:"32px"}}>
          <Link to="/setting">
            <SettingsOutlinedIcon
              fontSize="inherit"
              className="change_color_to_E1E2E4"
            />
          </Link>
        </span>
      </div>
      <div className={styles.back}>
        <ArrowBackIcon
          fontSize="inherit"
          className="change_color_to_E1E2E4"
          onClick={goBackLocation}
        />
      </div>
    </div>
  );
}
export default memo(BackButton);
