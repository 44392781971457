import React, { useMemo, useRef, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import styles from "./TicketsPage.module.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import useDebounce from "../../hooks/useDebounce";
import { ShowJalaliMoment } from "../../utils/convertMoment";
import { ticketsStauts } from "../../utils/_appStatus";
import PaperButton from "../../components/Buttons/PaperButton";
import { ThreeDots } from "react-loader-spinner";
import { getSingleTicket } from "./state";
import DialogTicket from "./DialogTicket";

function ShowTickets({ state, dispatch, departments }) {
  const ref = useRef(null);
  const [searchInput, setSearchInput] = useState("");
  const [showCount, setShowCount] = useState(7);
  const searchValue = useDebounce(searchInput, 500);
  const [showSingleMessage, setShowSingleMessage] = useState({
    visiblity: false,
    isLoading: true,
    isError: false,
    message: {},
    replies: [],
  });

  const handleShowNext = () => {
    setShowCount((prev) => prev + 7);
  };
  const ShowTicketList = useMemo(() => {
    const result = {
      items: [],
      hasNext: false,
    };
    if (!isEmpty(state["ticketsList"])) {
      result.items = state["ticketsList"]
        .filter((c) => c.title.includes(searchValue))
        .slice(0, showCount);
      if (result.items < state["ticketsList"]) result.hasNext = true;
    }
    return result;
  }, [state["ticketsList"], searchValue, showCount]);

  const handleRefershViaLoading = () => {
    dispatch({ type: "REFRESH" });
  };

  const handleShowSingleMessage = async (id) => {
    const controller = new AbortController();
    setShowSingleMessage((prev) => ({
      ...prev,
      visiblity: true,
      isLoading: true,
    }));
    try {
      const { error, message, replies } = await getSingleTicket(
        id,
        controller.signal
      );
      if (!error) {
        setShowSingleMessage((prev) => ({
          ...prev,
          isLoading: false,
          isError: false,
          message,
          replies,
        }));
      } else {
        setShowSingleMessage((prev) => ({
          ...prev,
          isLoading: false,
          isError: true,
        }));
      }
    } catch {
      setShowSingleMessage((prev) => ({
        ...prev,
        isLoading: false,
        isError: true,
      }));
    }
  };

  const handleCloseSingleMessage = () => {
    setShowSingleMessage((prev) => ({ ...prev, visiblity: false }));
  };
  return (
    <>
      <Box sx={{ direction: "rtl" }}>
        <Box mt={2} mb={1}>
          <input
            type="text"
            className={styles.search}
            placeholder="جست و جو"
            onChange={(e) => setSearchInput(e.target.value)}
            value={searchInput}
          />
        </Box>
        {!state["isLoading"] && !state["isError"] ? (
          !isEmpty(ShowTicketList["items"]) ? (
            <div ref={ref} className="scroll-container">
              <InfiniteScroll
                dataLength={ShowTicketList["items"].length}
                next={handleShowNext}
                hasMore={ShowTicketList["hasNext"]}
                height="calc(100vh - 255px)"
              >
                {ShowTicketList.items.map((ctx) => {
                  const curDep =
                    departments.filter((c) => c.id === ctx["department"])?.[0]
                      ?.title ?? "--";
                  const currStatus =
                    ticketsStauts[ctx["status"]] ?? ticketsStauts["CLOSED"];
                  return (
                    <div key={ctx["id"]} className={styles.item}>
                      <div className={styles.flexItem}>
                        <span data-number="true">No.{ctx["id"]}</span>
                        <span data-number="true">
                          {ShowJalaliMoment(ctx["date"])}
                        </span>
                      </div>
                      <div className={styles.flexItem}>
                        <span>عنوان</span>
                        <span className={styles.title}>{ctx["title"]}</span>
                      </div>
                      <div className={styles.flexItem}>
                        <span>بخش</span>
                        <span>{curDep}</span>
                      </div>
                      <div
                        className={styles.flexItem}
                        data-status={currStatus["type"]}
                        style={{ paddingInline: "5px" }}
                      >
                        <span>وضعیت</span>
                        <span>{currStatus["name"]}</span>
                      </div>
                      <div className={styles.flexItem}>
                        <span>عملیات</span>
                        <Box>
                          <button
                            className={styles.button_submit}
                            data-size="small"
                            onClick={() => handleShowSingleMessage(ctx["id"])}
                          >
                            متن تیکت
                          </button>
                        </Box>
                      </div>
                    </div>
                  );
                })}
              </InfiniteScroll>
            </div>
          ) : (
            <Stack
              height="calc(100vh - 300px)"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                textAlign="center"
                color="var(--secondary-color)"
                sx={{ backgroundColor: "rgba(0,0,0,0.2)" }}
                width={1}
                py={1.5}
              >
                موردی یافت نشد
              </Typography>
            </Stack>
          )
        ) : state["isError"] ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            height="calc(100vh - 300px)"
          >
            <Typography color="var(--danger-color)" fontSize={14}>
              مشکلی در دریافت اطلاعات رخ داده است
            </Typography>
            <Stack mt={2}>
              <PaperButton onClick={handleRefershViaLoading}>
                تلاش مجدد
              </PaperButton>
            </Stack>
          </Stack>
        ) : (
          <Stack
            alignItems="center"
            justifyContent="center"
            height="calc(100vh - 300px)"
          >
            <ThreeDots color="var(--primary-color)" width={40} />
          </Stack>
        )}
      </Box>
      <DialogTicket
        showSingleMessage={showSingleMessage}
        handleCloseSingleMessage={handleCloseSingleMessage}
        dispatch={dispatch}
      />
    </>
  );
}

export default ShowTickets;
