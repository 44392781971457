import { decodeToken } from "../utils/decodeToken";
import {
  coinUsersBalance,
  usersBalance,
  usersDepositHistory,
  usersOrdersHistory,
  usersProfile,
  usersTickets,
  usersTransactionsHistory,
  usersVerifyInfo,
  usersWithdrawalHistory,
} from "../services/userServices";
import { hiddenLoadingPage, showLoadingPage } from "./loadingPage";
const checkUserExist = () => {
  const token = localStorage.getItem("token") ?? null;
  if (token) {
    let checkToken = token;
    const decodedToken = decodeToken(checkToken);
    const dateNow = Date.now() / 1000;
    if (decodedToken?.payload?.exp < dateNow) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      checkToken = null;
      window.location.assign("/login");
    }
    return checkToken;
  }
};
export const addUserHashCode = () => {
  return async (dispatch) => {
    const token = await checkUserExist();
    if (token) {
      await dispatch({
        type: "SET_USER_HASH_CODE",
        payload: { userHashCode: token },
      });
    } else {
      await dispatch(clearUserHashCode());
    }
  };
};
export const clearUserHashCode = () => {
  return async (dispatch) => {
    await dispatch({ type: "CLEAR_USER", payload: {} });
  };
};

export const clearUserFullInfo = () => {
  return async (dispatch) => {
    await dispatch({ type: "CLEAR_USER_FULL_INFO", payload: {} });
  };
};

/////// ---- //////
/////// ---- //////
/////// ---- //////
/////// ---- //////
/////// ---- //////
//-Full-User-Data-//

export const userProfile = () => {
  return async (dispatch, getState) => {
    const previousData = getState().fullUserInfo;
    try {
      const { data, status } = await usersProfile();
      if (status === 200) {
        const data_profile = data;
        await dispatch({
          type: "SET_USER_PROFILE_INFO",
          payload: {
            ...previousData,
            data_profile,
          },
        });
      }
    } catch (ex) {}
  };
};

///End get User Profile Info

export const userTickets = () => {
  return async (dispatch, getState) => {
    dispatch(showLoadingPage());
    try {
      const previousData = getState().fullUserInfo;
      const { data, status } = await usersTickets();
      if (status === 200) {
        const data_tickets = data?.data;
        await dispatch({
          type: "SET_USER_TICKETS_INFO",
          payload: {
            ...previousData,
            data_tickets,
          },
        });
      }
    } catch (ex) {}
    dispatch(hiddenLoadingPage());
  };
};
//End get user Tickets Info

export const userBalance = () => {
  return async (dispatch, getState) => {
    const previousData = getState().fullUserInfo;
    try {
      const { data: balances, status } = await usersBalance();
      if (status === 200)
        await dispatch({
          type: "SET_USER_BALANCE_INFO",
          payload: {
            ...previousData,
            balances,
          },
        });
    } catch (ex) {}
  };
};
export const userCoinBalance = (coin) => {
  return async (dispatch, getState) => {
    const previousData = getState().fullUserInfo;
    try {
      const { data: coinBalance, status } = await coinUsersBalance(coin);
      if (status === 200)
        await dispatch({
          type: "SET_USER_COIN_BALANCE_INFO",
          payload: {
            ...previousData,
            coinBalance,
          },
        });
    } catch (ex) {}
  };
};

export const getUserTransactions = () => {
  return async (dispatch) => {
    const trans = await usersTransactionsHistory();
    const orders = await usersOrdersHistory();
    //
    let transX = trans?.data?.data;
    let ordersX = orders?.data?.data;
    //Get just Convert's
    let userOrders = await ordersX?.filter((ord) => ord.type === "CHANGE");
    //
    const options = userOrders?.map(function (ord) {
      const { type: operation, ...rest } = ord;
      return { operation, ...rest };
    });
    let userTransactions = [...transX, ...options];

    await dispatch({
      type: "SET_USER_TRANSACTIONS_INFO",
      payload: { userTransactions },
    });
  };
};
export const getUsersOrders = () => {
  return async (dispatch) => {
    const { data } = await usersOrdersHistory();
    await dispatch({
      type: "SET_USER_ORDERS_INFO",
      payload: { userOrders: data.data },
    });
  };
};

export const usersDepositAndWithdrawal = () => {
  return async (dispatch) => {
    const DEP = await usersDepositHistory();
    const WTH = await usersWithdrawalHistory();
    //Merge Deposit and Withdrawal
    let deposits = await DEP?.data?.deposits;
    let withdraws = await WTH?.data?.withdrawals;
    const dep = deposits?.map(function (ord) {
      const { date: createdAt, ...rest } = ord;
      return { type: "DEPOSIT", createdAt, ...rest };
    });
    const wth = withdraws?.map(function (ord) {
      return { type: "WITHDRAW", ...ord };
    });
    let userDepAndWth = [...dep, ...wth];
    //End of Merge
    await dispatch({
      type: "SET_USER_DEP_WTH_INFO",
      payload: { userDepAndWth },
    });
  };
};

export const userReferrals = () => {
  return async (dispatch) => {
    await dispatch({ type: "SET_USER_REFERRALS_INFO", payload: {} });
  };
};
export const userCommission = () => {
  return async (dispatch) => {
    await dispatch({ type: "SET_USER_COMMISSION_INFO", payload: {} });
  };
};
export const userTwoFactor = () => {
  return async (dispatch) => {
    await dispatch({ type: "SET_USER_TWO_FAC_INFO", payload: {} });
  };
};

export const GET_USER_TRANSACTIONS = () => {
  return async (dispatch) => {
    let LIST = [];
    const TRANSACTIONS = await usersTransactionsHistory();
    if (TRANSACTIONS.hasOwnProperty("data")) {
      LIST = TRANSACTIONS.data.data;
    }
    await dispatch({ type: "GET_USER_TRANSACTIONS", payload: LIST });
  };
};

export const GET_USER_VERIFY = () => {
  return async (dispatch) => {
    let LIST = {};
    const { data } = await usersVerifyInfo();
    if (data.hasOwnProperty("user")) {
      LIST = data.user;
    }
    await dispatch({ type: "GET_USER_VERIFY", payload: LIST });
  };
};
