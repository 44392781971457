import { Stack, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

function InProgress() {
  const [time, setTime] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <Stack
      sx={{
        minHeight: "calc(100vh - 250px)",
        direction: "rtl",
        justifyContent: "center",
      }}
    >
      <Typography
        textAlign="center"
        mb={4}
        color="var(--secondary-color)"
        fontSize={13}
        paddingY={1}
        typography="p"
        sx={{ backgroundColor: "rgba(0,0,0,0.2)" }}
      >
        درخواست شما در حال پردازش میباشد
      </Typography>
      <span
        className="eng-number-font"
        style={{
          textAlign: "center",
          fontSize: 20,
          color: "var(--secondary-color)",
          letterSpacing: 2,
        }}
      >
        {formatTime(time)}
      </span>
    </Stack>
  );
}

export default InProgress;
