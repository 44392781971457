import { useContext, useState } from "react";
import { context } from "../../../context/context";
import { useHistory } from "react-router";
//Components
import TextField from "@mui/material/TextField";
import RTL from "../../../helpers/direction/RTL";
//Styles and Icons
import styles from "./RecoveryPass.module.scss";
import UserIcon from "../../../styles/icon/person_outline.svg";
export default function RecoveryEmail() {
  const [checkFocuse, setCheckFocuse] = useState("");
  const registerCTX = useContext(context);
  const {
    emailRecovery,
    setEmailRecovery,
    handleRecoveryEmail,
    validatorRecovery,
    setValidatorRecovery,
    buttonDisabled,
  } = registerCTX;
  const history = useHistory();
  return (
    <>
      <form onSubmit={(e) => handleRecoveryEmail(e)}>
        <section className={styles.inputs_place}>
          <div
            className={`changeTextFieldBackgroundToWBlack ${styles.mobile_place}`}
          >
            <RTL>
              <TextField
                className={styles.number_login_input}
                name="emailRecovery"
                label="ایمیل"
                color="success"
                onFocus={() => setCheckFocuse("email")}
                onBlur={() => setCheckFocuse("")}
                value={emailRecovery}
                onChange={(e) => {
                  setEmailRecovery(e.target.value);
                  setValidatorRecovery({ ...validatorRecovery, email: "" });
                }}
              />

              <div
                className={`${styles.icon_place} ${styles.first_input_icon} ${
                  checkFocuse === "email" ? styles.icon_place_active : ""
                }`}
              >
                <img src={UserIcon} alt="" className="convertPicToGreen" />
              </div>
              <div className={styles.errorMessage}>
                {validatorRecovery?.email}
              </div>
            </RTL>
          </div>
        </section>

        <div className={styles.button_container}>
          <button
            type="submit"
            className={styles.continue_btn}
            disabled={buttonDisabled}
          >
            ادامه
          </button>
        </div>
      </form>
      <div className={styles.button_container}>
        <button
          className={styles.back_btn}
          disabled={buttonDisabled}
          onClick={() => history.push("/authorization/login")}
        >
          بازگشت
        </button>
      </div>
    </>
  );
}
