import { memo } from "react";
import { useRef } from "react";
import { ViewportList } from "react-viewport-list";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import styles from "./MarketCoins.module.scss";
import appIcons from "../../utils/appIcons";
import { Stack } from "@mui/system";
import { addFavoriteCoins } from "../../actions/favoriteCoins";
import { Collapse, Typography } from "@mui/material";
import serviceConfig from "../../services/config.json";

function FavoriteCoins(props) {
  const { search } = props;
  const ref = useRef(null);
  const dispatch = useDispatch();
  const OTCListReducer = useSelector((state) => state.market);
  const marketListReducer = useSelector((state) => state.tradesList);
  const favUserCoins = useSelector((state) => state.favCoins);
  const [openSubMenu, setOpenSubMenu] = useState("");
  const [marketList, setMarketList] = useState([]);
  useEffect(() => {
    const userSearch = search.toLowerCase().trim();
    if (OTCListReducer.hasOwnProperty("data")) {
      const filteredList = OTCListReducer.data
        .filter(
          (c) =>
            c.name.toLowerCase().includes(userSearch) ||
            c.symbol.toLowerCase().includes(userSearch) ||
            c.faName.toLowerCase().includes(userSearch)
        )
        .filter((c) => favUserCoins.includes(c.name));
      setMarketList(filteredList);
    }
  }, [OTCListReducer, search]);

  const handleToggleFavoriteCoin = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(addFavoriteCoins(item));
  };
  return (
    <div className="scroll-container" ref={ref}>
      <ViewportList viewportRef={ref} items={marketList} itemMinSize={60}>
        {(item) => {
          let OwnCoinMarkets = [];
          if (marketListReducer.hasOwnProperty("list")) {
            OwnCoinMarkets = marketListReducer.list.filter((c) => {
              return c.asset_title === item.name;
            });
          }
          return (
            <div
              key={item.name + item.symbol}
              className={styles.item_container2}
              onClick={() =>
                setOpenSubMenu((prev) => (prev !== item.name ? item.name : ""))
              }
            >
              <div className={styles._item2}>
                <div className={styles.star}>
                  {favUserCoins.includes(item.name) ? (
                    <span
                      className={styles.fill_start}
                      onClick={(e) => handleToggleFavoriteCoin(e, item.name)}
                    >
                      {appIcons("StarFillIcon", { fontSize: "inherit" })}
                    </span>
                  ) : (
                    <span
                      onClick={(e) => handleToggleFavoriteCoin(e, item.name)}
                    >
                      {appIcons("StarEmptyIcon", { fontSize: "inherit" })}
                    </span>
                  )}
                </div>
                <div className={styles.pic}>
                  <img
                    src={`${serviceConfig.image_url}/${item.symbol}.png`}
                    width={28}
                    height={28}
                    alt={item.symbol}
                  />
                </div>
                <div className={styles.name}>
                  <div>
                    <strong>{item.faName}</strong>
                  </div>
                  <div>
                    <strong>
                      {item.symbol} / {item.name}
                    </strong>
                  </div>
                </div>
                <div
                  className={`eng-number-font ${styles.changes} ${styles.arrow}`}
                  data-active={openSubMenu === item.name}
                >
                  {appIcons("ArrowDownIcon")}
                </div>
              </div>
              <Collapse in={openSubMenu === item.name}>
                {OwnCoinMarkets.map((ctx, idx) => {
                  return (
                    <Stack key={idx} className={styles.markets}>
                      <Stack>
                        <div>
                          {ctx.asset} / {ctx.area_title}
                        </div>
                      </Stack>
                      <Stack className={styles.trade_btn}>
                        <Link to={`/market/trade?market=${ctx.market}`}>
                          <button>معامله</button>
                        </Link>
                      </Stack>
                    </Stack>
                  );
                })}
                <Stack className={styles.bottom_fav_static}>
                  <Typography component="p" className={styles.p}>
                    معامله سریع
                  </Typography>
                  <Stack direction="column" columnGap={1} rowGap={1.5}>
                    {OwnCoinMarkets.map((ctx, idx) => {
                      let persianName = {
                        IRT: "تومان",
                        USDT: "تتر",
                      };
                      return (
                        <Stack key={idx} direction="row" columnGap={1}>
                          <Stack className={styles.trade_btn}>
                            <Link
                              to={`/buy/${ctx.asset}?type=${ctx.area_title}`}
                            >
                              <button>
                                خرید با {persianName[ctx.area_title]}
                              </button>
                            </Link>
                          </Stack>
                          <Stack className={styles.trade_btn}>
                            <Link
                              to={`/sell/${ctx.asset}?type=${ctx.area_title}`}
                            >
                              <button data-status="error">
                                فروش به {persianName[ctx.area_title]}
                              </button>
                            </Link>
                          </Stack>
                        </Stack>
                      );
                    })}
                  </Stack>
                </Stack>
              </Collapse>
            </div>
          );
        }}
      </ViewportList>
      {OTCListReducer.hasOwnProperty("data") && isEmpty(marketList) && (
        <Stack className={styles.not_found}>موردی یافت نشد</Stack>
      )}
    </div>
  );
}
export default memo(FavoriteCoins);
