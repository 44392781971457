import styles from "./HeaderMH.module.scss";
//* --> Icon's
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { useHistory } from "react-router";
export default function HeaderMH({ setOpen }) {
  const history = useHistory();

  const handleBack = () => {
    history.go(-1);
  };
  const handleToggleBox = () => {
    setOpen((current) => !current);
  };
  return (
    <div className={`dir-rtl ${styles.container}`}>
      <div className={styles.title}>
        <span onClick={handleBack}>
          <ArrowForwardOutlinedIcon
            fontSize="inherit"
            className="no-svg-change"
            htmlColor="#00C194"
          />
        </span>
        <strong className="change_color_to_E1E2E4">معاملات</strong>
      </div>
      <div className={styles.filterButton}>
        <span className={`change_color_to_E1E2E4 ${styles.button}`} onClick={handleToggleBox}>
          <span className={styles.name}>فیلتر</span>
          <span className={styles.icon}>
            <ArrowDropDownOutlinedIcon fontSize="inherit" className='no-svg-change' />
          </span>
        </span>
      </div>
    </div>
  );
}
