import { memo } from "react";
import { useRef } from "react";
import { ViewportList } from "react-viewport-list";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import styles from "./MarketCoins.module.scss";
import appIcons from "../../utils/appIcons";
import { Stack } from "@mui/system";
import { addCommaWithRialPrice } from "../../utils/addComma";
import { addFavoriteCoins } from "../../actions/favoriteCoins";
import { Collapse, Typography } from "@mui/material";
import serviceConfig from "../../services/config.json";

function OrderCoins(props) {
  const { search, handleButtonsClicked, marketWSInfo } = props;
  const ref = useRef(null);
  const dispatch = useDispatch();
  const OTCListReducer = useSelector((state) => state.market);
  const favUserCoins = useSelector((state) => state.favCoins);
  const marketListReducer = useSelector((state) => state.tradesList);
  const [openSubMenu, setOpenSubMenu] = useState("");
  const [marketList, setMarketList] = useState([]);
  useEffect(() => {
    const userSearch = search.toLowerCase().trim();
    if (OTCListReducer.hasOwnProperty("data")) {
      const filteredList = OTCListReducer.data.filter(
        (c) =>
          c.name.toLowerCase().includes(userSearch) ||
          c.symbol.toLowerCase().includes(userSearch) ||
          c.faName.toLowerCase().includes(userSearch)
      );
      setMarketList(filteredList);
    }
  }, [OTCListReducer, search]);

  const handleToggleFavoriteCoin = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(addFavoriteCoins(item));
  };
  return (
    <div className="scroll-container" ref={ref}>
      <ViewportList viewportRef={ref} items={marketList} itemMinSize={80}>
        {(item) => {
          let OwnCoinMarkets = [];
          if (marketListReducer.hasOwnProperty("list")) {
            OwnCoinMarkets = marketListReducer.list.filter((c) => {
              return c.asset_title === item.name;
            });
          }
          OwnCoinMarkets = OwnCoinMarkets.filter(
            (c) => c.trading_area !== "IRT"
          );
          return (
            <div
              key={item.name + item.symbol}
              className={styles.item_container}
              onClick={() =>
                setOpenSubMenu((prev) => (prev !== item.name ? item.name : ""))
              }
            >
              <div className={styles._item2}>
                <div className={styles.star}>
                  {favUserCoins.includes(item.name) ? (
                    <span
                      className={styles.fill_start}
                      onClick={(e) => handleToggleFavoriteCoin(e, item.name)}
                    >
                      {appIcons("StarFillIcon", { fontSize: "inherit" })}
                    </span>
                  ) : (
                    <span
                      onClick={(e) => handleToggleFavoriteCoin(e, item.name)}
                    >
                      {appIcons("StarEmptyIcon", { fontSize: "inherit" })}
                    </span>
                  )}
                </div>
                <div className={styles.pic}>
                  <img
                    src={`${serviceConfig.image_url}/${item.symbol}.png`}
                    width={28}
                    height={28}
                    loading="lazy"
                    alt={item.symbol}
                  />
                </div>
                <div className={styles.name}>
                  <div>
                    <strong>{item.faName}</strong>
                  </div>
                  <div>
                    <strong>{item.name}</strong>
                  </div>
                </div>
                <div
                  className={`eng-number-font ${styles.changes} ${styles.order_coins_changes}`}
                >
                  <div>
                    <span className={styles.buy}>
                      {addCommaWithRialPrice(item.arzfi_price)} <span>IRT</span>
                    </span>
                  </div>
                  {OwnCoinMarkets.map((ctx, idx) => {
                    return (
                      <div key={idx}>
                        <span className={styles.buy}>
                          {marketWSInfo && marketWSInfo[ctx.market]?.last}{" "}
                          <span>{ctx.trading_area}</span>
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <Collapse in={openSubMenu === item.name}>
                <Stack className={styles.subMenu_orders} direction="row">
                  <div className={styles.order_buttons}>
                    <Link to={`/sell/${item.symbol}?type=IRT`}>
                      <button data-type="sell" onClick={handleButtonsClicked}>
                        فروش
                      </button>
                    </Link>
                    <Link to={`/buy/${item.symbol}?type=IRT`}>
                      <button data-type="buy" onClick={handleButtonsClicked}>
                        خرید
                      </button>
                    </Link>
                  </div>
                  <Typography component="strong">
                    {item.symbol} / IRT
                  </Typography>
                </Stack>
                {OwnCoinMarkets.map((ctx, idx) => {
                  return (
                    <Stack
                      className={styles.subMenu_orders}
                      direction="row"
                      key={idx}
                    >
                      <div className={styles.order_buttons}>
                        <Link
                          to={`/sell/${item.symbol}?type=${ctx.trading_area}`}
                        >
                          <button
                            data-type="sell"
                            onClick={handleButtonsClicked}
                          >
                            فروش
                          </button>
                        </Link>
                        <Link
                          to={`/buy/${item.symbol}?type=${ctx.trading_area}`}
                        >
                          <button
                            data-type="buy"
                            onClick={handleButtonsClicked}
                          >
                            خرید
                          </button>
                        </Link>
                      </div>
                      <Typography component="strong">
                        {ctx.asset} / {ctx.trading_area}
                      </Typography>
                    </Stack>
                  );
                })}
              </Collapse>
            </div>
          );
        }}
      </ViewportList>
      {OTCListReducer.hasOwnProperty("data") && isEmpty(marketList) && (
        <Stack className={styles.not_found}>موردی یافت نشد</Stack>
      )}
    </div>
  );
}
export default memo(OrderCoins);
