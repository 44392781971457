import { memo, useContext } from "react";
import { tradesCTX } from "../../../context/tradesCTX";
import styles from "./MenuMT.module.scss";

const radioButtons = [
  {
    name: "market_type_choice",
    value: "limit",
    title: "قیمت لیمیت",
    id: "limit_id",
  },
  {
    name: "market_type_choice",
    value: "market",
    title: "قیمت بازار",
    id: "market_id",
  },
  {
    name: "market_type_choice",
    value: "stop-limit",
    title: "استاپ-لیمیت",
    id: "stop_limit_id",
  },
  {
    name: "market_type_choice",
    value: "stop-market",
    title: "استاپ-مارکت",
    id: "stop_market_id",
  },
];
function MenuMT() {
  const TradesContext = useContext(tradesCTX);
  const {
    menuStatusMarketType,
    setMenuStatusMarketType,
    setMarketType,
    marketType,
  } = TradesContext;
  const handleClose = () => {
    setMenuStatusMarketType(false);
  };
  const handleChangeMarket = (e) => {
    setMarketType(e.target.value);
    handleClose();
  };
  return (
    <>
      {menuStatusMarketType ? (
        <div className={styles.bg} onClick={handleClose}></div>
      ) : null}
      <div
        className={`${styles.menu}  changeBackgroundColorToBlack ${
          menuStatusMarketType ? styles.visible : styles.hidden
        }`}
      >
        <h5 className="change_color_to_E1E2E4">نوع سفارش</h5>
        <div className={styles.items}>
          {radioButtons.map((c, i) => (
            <div className={`change_color_to_E1E2E4 ${styles.item}`} key={i}>
              <input
                className={styles.radio_market}
                type="radio"
                id={c.id}
                name={c.name}
                value={c.value}
                hidden
                checked={marketType === c.value}
                onChange={handleChangeMarket}
              />
              <label htmlFor={c.id}>
                <div
                  className={`${styles.label} ${
                    marketType === c.value ? "active-menu-market-type" : ""
                  }`}
                >
                  <div className={styles.checkbox}></div>
                  <div className={styles.title}>{c.title}</div>
                </div>
              </label>
            </div>
          ))}
        </div>
        <div className={styles.btn}>
            <button onClick={handleClose} className='active-menu-market-type change_color_to_E1E2E4'>لغو</button>
        </div>
      </div>
    </>
  );
}
export default memo(MenuMT);
