import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { DangerMuiBtn, SuccessMuiBtn } from "../../MuiButtons";
import { Alert, Checkbox, FormControlLabel, Stack } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ConfirmWithMessage(props) {
  const [acceptRules, setAcceptRules] = React.useState(false);
  const { message, open, setOpen, handleConfirm } = props;
  const handleClose = () => {
    setOpen(false);
    setAcceptRules(false);
  };
  const handleAcceptRules = (e) => {
    setAcceptRules(e.target.checked);
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Alert
              severity="warning"
              sx={{ direction: "rtl", fontFamily: "IranSansWeb" }}
            >
              اخطار: نیاز به تایید برای ادامه فعالیت
            </Alert>
            {message && (
              <Typography
                component="p"
                id="transition-modal-description"
                sx={{ mt: 2, direction: "rtl" }}
              >
                {message}
              </Typography>
            )}
            <Stack>
              <FormControlLabel
                sx={{ direction: "rtl", padding: 0, margin: 0 }}
                control={
                  <Checkbox
                    size="small"
                    onChange={handleAcceptRules}
                    checked={acceptRules}
                  />
                }
                label={
                  <span style={{ userSelect: "none", fontSize: "12px" }}>
                    متوجه شدم و معامله را انجام میدهم
                  </span>
                }
              />
            </Stack>
            <Stack direction="row" spacing={1} marginTop={0}>
              <DangerMuiBtn text="لغو" onClick={handleClose} />
              <SuccessMuiBtn
                text="تایید"
                onClick={handleConfirm}
                disabled={!acceptRules}
              />
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
