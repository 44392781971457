import { FetchGetUserOrdersList } from "../services/userServices";

export const getUserMarketOrdersList = (market) => {
  return async (dispatch) => {
    const { data } = await FetchGetUserOrdersList(market);
    dispatch({ type: "GET_USER_MARKET_ORDERS" , payload: data });
  };
};


