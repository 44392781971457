import BottomCrypto from "./BottomCryptoApp";
export default function MarketLayout({ children }) {
  return (
    <>
      <main>{children}</main>
        <footer>
          <BottomCrypto />
        </footer>
    </>
  );
}
