import { Stack } from "@mui/system";
import { useHistory } from "react-router";

import appIcons from "../../utils/appIcons";
//#
import styles from "./PreviousPage.module.scss";
export default function PreviousPage() {
  const history = useHistory();

  const handleBackToPreviousPage = () => {
    history.goBack();
  };
  return (
    <Stack className={styles.container} direction="row">
      <Stack>
        <span onClick={handleBackToPreviousPage}>
          {appIcons("ArrowBackIcon")}
        </span>
      </Stack>
    </Stack>
  );
}
