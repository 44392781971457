export const userId = (state = { id: "default" }, action) => {
  switch (action.type) {
    case "SET_USER_HASH_CODE":
      return { ...action.payload }; //spread Operator
    case "CLEAR_USER":
      return { ...action.payload };
    default:
      return state;
  }
};
