import { Container } from "@mui/material";
import { Stack } from "@mui/system";
import moment from "jalali-moment";
import Cookies from "js-cookie";
import { memo, useLayoutEffect, useState } from "react";
import PaperButton from "../components/Buttons/PaperButton";
import PrimaryHeader from "../components/Headers/PrimaryHeader";
import ProgressLoading from "../components/Loadings/ProgressLoading";
import MuiCheckbox from "../components/MuiCheckbox";
import { FetchNotifications } from "../services/adminServices";

import styles from "../styles/pageStyles/Notifications.module.scss";
import appIcons from "../utils/appIcons";
const Notifications = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(0);
  const [showUnreadMessage, setShowUnreadMessage] = useState(false);
  const [openMessageDescId, setOpenMessageDescId] = useState(null);
  //# Loading options => true,false,'error'
  useLayoutEffect(() => {
    const controller = new AbortController();
    const getNotifications = async () => {
      const _inCookie = Cookies.get("Notifications");
      //Cookie =["title+id"]
      try {
        const { status, data } = await FetchNotifications(controller.signal);
        if (status === 200) {
          if (_inCookie && typeof _inCookie === "string") {
            const _cookieParsed = JSON.parse(_inCookie);
            if (typeof _cookieParsed === "object") {
              const updatedMessage = data.map((ctx) => {
                let seen = false;
                if (_cookieParsed.includes(ctx["id"])) {
                  seen = true;
                }
                return { ...ctx, seen };
              });
              setMessages(updatedMessage);
            } else {
              const updatedMessage = data.map((ctx) => {
                return { ...ctx, seen: false };
              });
              setMessages(updatedMessage);
            }
          } else {
            const updatedMessage = data.map((ctx) => {
              return { ...ctx, seen: false };
            });
            setMessages(updatedMessage);
          }
          setIsLoading(false);
        } else {
          setIsLoading("error");
        }
      } catch (ex) {
        if (ex.message !== "canceled") {
          setIsLoading("error");
        }
      }
    };
    getNotifications();
    return () => {
      controller.abort();
    };
  }, [refresh]);

  const handleRefreshPage = () => {
    setIsLoading(true);
    setRefresh((prev) => prev + 1);
  };
  const toggleShowUnreadMessage = () => {
    setShowUnreadMessage((prev) => !prev);
  };

  const handleOpenMessageDetails = (idx) => {
    if (idx === openMessageDescId) {
      setOpenMessageDescId(null);
    } else {
      setOpenMessageDescId(idx);
      const upMess = messages.map((ctx) => {
        if (ctx["id"] === idx) {
          return { ...ctx, seen: true };
        }
        return ctx;
      });
      const newCookie = upMess
        .filter((c) => c["seen"] === true)
        .map((c) => {
          return c["id"];
        });

      Cookies.set("Notifications", JSON.stringify(newCookie), { expires: 365 });
      setMessages(upMess);
    }
  };

  const handleChangeReadAll = () => {
    const upMess = messages.map((ctx) => {
      return { ...ctx, seen: true };
    });
    const newCookie = upMess
      .filter((c) => c["seen"] === true)
      .map((c) => {
        return c["id"];
      });
    Cookies.set("Notifications", JSON.stringify(newCookie));
    setMessages(upMess);
  };
  return (
    <Container>
      <Stack>
        <PrimaryHeader title="پیام ها" />
      </Stack>
      {loading === "error" && (
        <Stack
          sx={{
            height: "calc(100vh - 150px)",
            alignItems: "center",
            justifyContent: "center",
            direction: "rtl",
            textAlign: "center",
          }}
        >
          <Stack sx={{ maxWidth: 400, fontSize: 14 }}>
            <p style={{ color: "var(--secondary-color)" }}>
              اتصال ناموفق -{" "}
              <span style={{ color: "var(--danger-color)" }}>
                Network/Server
              </span>
            </p>
            <PaperButton onClick={handleRefreshPage}>تلاش مجدد</PaperButton>
          </Stack>
        </Stack>
      )}
      {loading === true && (
        <Stack
          sx={{
            height: "calc(100vh - 140px)",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ProgressLoading />
        </Stack>
      )}
      {loading === false ? (
        <Stack>
          <div className={styles.headerDataContainer}>
            <div className={styles.sumDetails}>
              <p>
                خوانده شده:‌{" "}
                <span>{messages.filter((c) => c["seen"]).length}</span>
              </p>
              <p>
                خوانده نشده:‌{" "}
                <span data-color="danger">
                  {messages.filter((c) => !c["seen"]).length}
                </span>
              </p>
            </div>
            <div style={{ direction: "ltr" }}>
              {messages.filter((c) => c["seen"]).length !== messages.length && (
                <div style={{ marginBottom: 5 }}>
                  <button
                    className={styles.readAllBtn}
                    onClick={handleChangeReadAll}
                  >
                    تغییر همه به خوانده
                  </button>
                </div>
              )}
              <MuiCheckbox
                label="خوانده نشده ها"
                checked={showUnreadMessage}
                onChange={toggleShowUnreadMessage}
              />
            </div>
          </div>
          <div className={styles.messagesContainer}>
            {messages.map((msg, idx) => {
              if (
                showUnreadMessage &&
                msg["seen"] &&
                openMessageDescId !== msg["id"]
              ) {
                return null;
              }
              return (
                <div
                  className={styles.messageItem}
                  onClick={() => handleOpenMessageDetails(msg["id"])}
                  key={msg["id"]}
                >
                  <div className={styles.headItem}>
                    <div data-seen={msg["seen"]} className={styles.seenIcon}>
                      {appIcons("infoIcon")}
                    </div>
                    <div className={styles.title_date}>
                      <strong>{msg["title"]}</strong>
                      <time>
                        {msg["published_at"] &&
                          moment(msg.published_at, "YYYY-MM-DDThh:mm:ss.000Z")
                            .locale("fa")
                            .format("YYYY/MM/DD")}
                      </time>
                    </div>
                    <span>{appIcons("ArrowDownIcon")}</span>
                  </div>
                  <div
                    className={styles.descItem}
                    data-show={msg["id"] === openMessageDescId}
                  >
                    {msg["description"]}
                  </div>
                </div>
              );
            })}
          </div>
        </Stack>
      ) : null}
    </Container>
  );
};

export default memo(Notifications);
