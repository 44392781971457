import React, { useState } from "react";
import BottomModal from "../../components/Modals/BottomModal";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import styles from "./TicketsPage.module.scss";
import { isEmpty } from "lodash";
import { ShowJalaliMoment } from "../../utils/convertMoment";
import ImagePicker from "../../components/ImagePicker";
import PaperButton from "../../components/Buttons/PaperButton";
import { ThreeDots } from "react-loader-spinner";
import config from "../../services/config.json";
import appIcons from "../../utils/appIcons";
import { checkFileTypeImage } from "../../components/Validator/Validator";
import { repliesToTicket } from "../../services/userServices";
import { errorMessage, successMessage } from "../../utils/messages";

const handleOpenPicture = (type, name) => {
  if (type === "ADMIN") {
    return window.open(config.admin_uploads + name, "_blank");
  }
  return window.open(config.user_uploads + name, "_blank");
};
function DialogTicket(props) {
  const { showSingleMessage = {}, handleCloseSingleMessage, dispatch } = props;
  const [replyMessage, setReplayMessage] = useState({
    desc: "",
    pic: null,
  });
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState([]);

  const handleChangePic = (e) => {
    setReplayMessage((prev) => ({ ...prev, pic: e.target.files[0] }));
  };
  const handleDeletePicture = () => {
    setReplayMessage((prev) => ({ ...prev, pic: null }));
  };

  const handleSubmitReplay = async () => {
    const currErrors = [];
    if (replyMessage["desc"].length < 5 || replyMessage["desc"].length > 400)
      currErrors.push(
        "توضیحات نباید از 5 کاراکتر کمتر و از 400 کاراکتر بیشتر باشد"
      );
    if (replyMessage["pic"]) {
      const valid = checkFileTypeImage(replyMessage.pic.name);
      if (!valid) currErrors.push("فرمت عکس باید jpg, png و یا jpeg باشد.");
    }
    if (currErrors[0]) {
      return setError(currErrors);
    }
    try {
      setDisabled(true);
      const formData = new FormData();
      formData.append("ticketId", showSingleMessage["message"]["id"]);
      formData.append("body", replyMessage.desc);
      if (replyMessage.pic) {
        formData.append("file", replyMessage.pic);
      }
      const { status, data } = await repliesToTicket(
        formData,
        !!replyMessage.pic
      );
      console.log(status, data);
      if (status === 200) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          successMessage(data.message);
          setReplayMessage({ pic: null, desc: "" });
          dispatch({ type: "REFERSH_NO_LOADING" });
          handleCloseSingleMessage();
        }
      }
    } catch {
    } finally {
      setDisabled(false);
      setError([]);
    }
  };
  return (
    <BottomModal
      visible={showSingleMessage["visiblity"]}
      onRequestClose={handleCloseSingleMessage}
    >
      <Box
        sx={{ backgroundColor: "var(--third-color)" }}
        height="calc(100vh - 60px)"
        borderRadius="15px 15px 0px 0px"
        padding={2}
        boxSizing="border-box"
      >
        <Stack
          direction="row-reverse"
          alignItems="center"
          justifyContent="space-between"
          className={styles.modalContainer}
        >
          <span className={styles.noSingle}>
            No.
            {!showSingleMessage["isLoading"] &&
              showSingleMessage["message"]["id"]}
          </span>
          <IconButton
            color="inherit"
            onClick={handleCloseSingleMessage}
            className={styles.close}
          >
            {appIcons("closeIcon")}
          </IconButton>
        </Stack>
        <Box>
          {!showSingleMessage["isLoading"] && !showSingleMessage["isError"] ? (
            <Box height="calc(100vh - 160px)" overflow="auto">
              <div
                className={styles.message_container}
                data-type={showSingleMessage["message"]["type"]}
              >
                <span>{showSingleMessage["message"]["title"]}</span>
                <p>{showSingleMessage["message"]["text"]}</p>
                {showSingleMessage["message"]["details"] && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <span>فایل ضمیمه</span>
                    <span
                      style={{
                        borderBottom: "1px solid",
                        color: "var(--primary-color)",
                      }}
                      onClick={() =>
                        handleOpenPicture(
                          showSingleMessage["message"]["type"],
                          showSingleMessage["message"]["details"]
                        )
                      }
                    >
                      مشاهده
                    </span>
                  </Stack>
                )}
                <div className={styles.footer}>
                  <b>
                    {showSingleMessage["message"]["type"] === "ADMIN"
                      ? "پاسخ کارشناس"
                      : "پاسخ شما"}
                  </b>
                  <time>
                    {ShowJalaliMoment(
                      showSingleMessage["message"]["createdAt"]
                    )}
                  </time>
                </div>
              </div>
              {!isEmpty(showSingleMessage["replies"]) &&
                showSingleMessage["replies"].map((ctx, idx) => {
                  return (
                    <div
                      key={idx}
                      className={styles.message_container}
                      data-type={ctx["type"]}
                    >
                      <p>{ctx["text"]}</p>
                      {ctx["atached"]?.[0]?.file && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <span>فایل ضمیمه</span>
                          <span
                            style={{
                              borderBottom: "1px solid",
                              color: "var(--primary-color)",
                            }}
                            onClick={() =>
                              handleOpenPicture(
                                ctx["type"],
                                ctx["atached"]?.[0]?.file
                              )
                            }
                          >
                            مشاهده
                          </span>
                        </Stack>
                      )}
                      <div className={styles.footer}>
                        <b>
                          {ctx["type"] === "ADMIN"
                            ? "پاسخ کارشناس"
                            : "پاسخ شما"}
                        </b>
                        <time>{ShowJalaliMoment(ctx["date"])}</time>
                      </div>
                    </div>
                  );
                })}
              {showSingleMessage["message"]["status"] !== "CLOSED" && (
                <Box mt={4} className={styles.answer}>
                  <textarea
                    placeholder="پاسخ شما"
                    value={replyMessage["desc"]}
                    onChange={(e) =>
                      setReplayMessage((prev) => ({
                        ...prev,
                        desc: e.target.value,
                      }))
                    }
                  />
                  <Stack mb={1}>
                    <ImagePicker
                      accept="image/*"
                      id="replay"
                      onChange={handleChangePic}
                      handleDeletePicture={handleDeletePicture}
                      file={replyMessage["pic"]}
                    />
                  </Stack>
                  {error[0] && (
                    <Box mb={1} sx={{ direction: "rtl" }}>
                      {error.map((ctx) => {
                        return (
                          <Typography
                            color="var(--danger-color)"
                            key={ctx}
                            fontSize={13}
                          >
                            - {ctx}
                          </Typography>
                        );
                      })}
                    </Box>
                  )}
                  <PaperButton disabled={disabled} onClick={handleSubmitReplay}>
                    ثبت پاسخ
                  </PaperButton>
                </Box>
              )}
            </Box>
          ) : showSingleMessage["isError"] ? (
            <Stack
              alignItems="center"
              justifyContent="center"
              height="calc(100vh - 300px)"
            >
              <Typography color="var(--danger-color)" fontSize={14}>
                مشکلی در دریافت اطلاعات رخ داده است
              </Typography>
            </Stack>
          ) : (
            <Stack
              alignItems="center"
              justifyContent="center"
              height="calc(100vh - 300px)"
            >
              <ThreeDots color="var(--primary-color)" width={40} />
            </Stack>
          )}
        </Box>
      </Box>
    </BottomModal>
  );
}

export default DialogTicket;
