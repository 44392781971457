import { useEffect, useState } from "react";
import { useParams } from "react-router";
//* --> (Redux) - Actions, Functions, State's
//* --> Components
import AsideT from "../../../TradeLayout/AsideT";
import HeaderMT from "../../../MarketTradeLayout/HeaderMT";
//* --> Context
import OrdersContext from "../../../../context/OrdersContext";
// import TradeType from "../../../MarketTradeLayout/TradeType";
import MenuMT from "../../../MarketTradeLayout/MenuMT";
import MainMT from "../../../MarketTradeLayout/MainMT";
import HistoryMT from "../../../MarketTradeLayout/HistoryMT";
import DetailsMT from "../../../MarketTradeLayout/DetailsMT";
import DetailsMenuMT from "../../../MarketTradeLayout/DetailsMenuMT";
import { useDispatch } from "react-redux";
//* --> Image
import {
  hiddenAppMenu,
  showAppMenu,
} from "../../../../actions/visibiltyOfMenu";
import BackButton from "../../../MarketTradeLayout/BackButton";

export const TRADE_TITLE = [
  { title: "limit", label: "لیمیت" },
  { title: "market", label: "مارکت" },
  { title: "stop-limit", label: "استاپ-لیمیت" },
  { title: "otc", label: "معمولی" },
];
export const TRADE_TYPE = [
  { title: "BUY", label: "خرید", color: "color_green" },
  { title: "SELL", label: "فروش", color: "color_red" },
];

export default function CoinMarket() {
  const dispatch = useDispatch();
  const [showChart, setShowChart] = useState(false);
  useEffect(() => {
    const handleShowPOS = () => {
      let top = window.pageYOffset || document.documentElement.scrollTop;
      setClientHeightPOS(top);
    };

    document.addEventListener("scroll", handleShowPOS);
    return () => document.removeEventListener("scroll", handleShowPOS);
  }, []);

  const params = useParams();
  //* --> Access URL QUERY PARAMS
  const [market, setMarket] = useState("");
  const [clinetHeightPOS, setClientHeightPOS] = useState(0);
  useEffect(() => {
    if (params.coin) {
      setMarket(params.coin);
    }
  }, [params]);

  useEffect(() => {
    dispatch(hiddenAppMenu());
    return () => dispatch(showAppMenu());
  }, [dispatch]);
  //* <-- Access URL QUERY PARAMS

  //* --> Access Dispatch For Redux
  //* <-- Access Dispatch For Redux

  //* --> RETURN THE COMPONENTS
  return (
    <>
      <OrdersContext marketName={market}>
        <section>
          <header>
            <HeaderMT setShowChart={setShowChart} />
          </header>
          <AsideT clinetHeightPOS={clinetHeightPOS} />
          <DetailsMT showChart={showChart} />
          {/* <TradeType /> */}
          <MainMT />
        </section>
        <footer style={{ marginBottom: "60px" }} className="mt-10 dir-rtl">
          <HistoryMT />
        </footer>
        <MenuMT />
        <DetailsMenuMT />
      </OrdersContext>
      <BackButton />
    </>
  );
}
