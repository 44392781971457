import { isEmpty } from "lodash";
import { memo, useMemo } from "react";
import { Helmet } from "react-helmet";
import serviceConfig from "../../services/config.json";
const FeesSEO = (props) => {
  const { list } = props;

  const SEOItemsList = useMemo(() => {
    if (!isEmpty(list)) {
      return list.map((ctx, idx) => {
        return {
          "@type": "ListItem",
          position: ++idx,
          item: {
            "@type": "Product",
            name: `${ctx["asset"]} (${ctx["asset_title_fa"]})`,
            description: `خرید ارز ${ctx["asset_title_fa"]} به صورت لحظه ای`,
            image: `${serviceConfig.image_url}/${ctx["asset"]}.png`,
            url: `https://arzfi.com/buy/${ctx["asset"]}?type=IRT`,
            offers: {
              "@type": "Offer",
              price: `${ctx["price"]}`,
              priceCurrency: "USD",
              description: `ارز ${ctx["asset_title_fa"]} را با کمترین کارمزد ${ctx["fee_rate"]} معامله کنید`,
            },
          },
        };
      });
    }
    return {};
  }, [list]);

  return (
    <Helmet>
      <title>کارمزد های معاملات و انتقال ارز های دیجیتال - ارزفی (ArzFi)</title>
      <meta
        name="description"
        content="کارمزد های معاملاتی ارزهای دیجیتال در سطح های مختلف کاربری ارائه شده
    است تا با کمترین میزان کارمزد معاملات خود را انجام دهید و ارزهای خود
    را انتقال دهید و دریافت کنید.,"
      />
      <meta
        name="keywords"
        content="کارمزد ارز دیجیتال, خرید ارز دیجیتال با کارمزد پایین, انتقال ارز دیجیتال, کیف پول ارز دیجیتال, Crypto curreny, crypto fee, معامله ارز , واریز و برداشت ارز, BTC, USDT, ETH, LTC, BABYDOGE, خرید ارز با تومان"
      />
      {!isEmpty(SEOItemsList) && (
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ItemList",
            itemListElement: SEOItemsList,
          })}
        </script>
      )}
    </Helmet>
  );
};

export default memo(FeesSEO);
