import { memo } from "react";

import styles from "./OpenCards.module.scss";
import appIcons from "../../../../utils/appIcons";
import { isEmpty } from "lodash";
import { showBetterBankCard } from "../../../../utils/addComma";
import { detectBanks } from "../../../../utils/detectBanks";
function OpenCards(props) {
  const {
    items = [],
    onChange,
    active = "",
    visible = false,
    onRequestClose,
    show = "card",
  } = props;

  const handleBackgroundTouched = () => {
    if (typeof onRequestClose === "function") {
      onRequestClose();
    }
  };
  const handleContentTouched = (e) => {
    e.stopPropagation();
  };
  return (
    <div
      className={styles.container}
      data-visibility={visible}
      onClick={handleBackgroundTouched}
    >
      <div
        className={styles.content}
        onClick={handleContentTouched}
        data-visibility={visible}
      >
        <div className={styles.headContent}>
          <p>انتخاب کارت</p>
          <div onClick={onRequestClose}>{appIcons("closeIcon")}</div>
        </div>
        <div className={styles.itemsContainer}>
          {!isEmpty(items) ? (
            items.map((ctx, idx) => {
              const cardNumber = ctx["cardNumber"];
              let thisBank = {};
              const bank = detectBanks.filter(
                (c) => String(c.card_no) === String(cardNumber).slice(0, 6)
              )[0];
              if (bank !== undefined) {
                thisBank = bank;
              }
              return (
                <div
                  className={styles.item}
                  data-active={cardNumber === active}
                  key={idx}
                  onClick={() => onChange(cardNumber, ctx["ibanNumber"])}
                >
                  <p>
                    {show === "card"
                      ? showBetterBankCard(cardNumber)
                      : ctx["ibanNumber"]}
                  </p>
                  {!isEmpty(thisBank) && (
                    <div>
                      <img
                        src={thisBank["bank_logo"]}
                        alt={thisBank["bank_title"]}
                        width={30}
                        height={30}
                      />
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <div className={styles.emptyContainer}>
              <p>کارت بانکی ثبت نشده است</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(OpenCards);
