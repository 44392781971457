import {
  FetchUserLastAssetsWithdrawal,
  updateUsersDeposits,
  usersBalance,
} from "../../services/userServices";

export const initialStates = {
  view: "wallet",
  wthList: [],
  userWallets: {},
  isLoading: true,
  isError: false,
  refreshPage: 0,
  dailyAmount: 0,
  total: {
    IRTTotal: 0,
    USDTTotal: 0,
  },
};

export const walletReducer = (state, action) => {
  switch (action.type) {
    case "VIEW":
      return { ...state, view: action.payload };
    case "SUCCESS_INIT":
      return {
        ...state,
        isLoading: false,
        isError: false,
        dailyAmount: action.payload.dailyAmount,
        wthList: action.payload.withdrawals,
        userWallets: action.payload.wallets,
        total: {
          IRTTotal: action.payload.IRTTotal,
          USDTTotal: action.payload.USDTTotal,
        },
      };
    case "FAILD_INIT":
      return { ...state, isError: true, isLoading: false };

    case "REFRESH":
      return { ...state, refreshPage: state.refreshPage + 1 };
    case "REFRESH_VIA_LOADING":
      return { ...state, isLoading: true, refreshPage: state.refreshPage + 1,isError: false };
    case "WTH_CANCELED":
      const filteredList = [...state.wthList].map((c) => {
        if (c.id === action.payload) {
          return { ...c, status: "CANCELED" };
        }
        return c;
      });
      return { ...state, wthList: filteredList };
    default:
      return state;
  }
};

export async function getPageData(signal) {
  const result = {
    wallets: {},
    lasts: [],
    dailyAmount: 0,
    total: {},
    error: false,
  };
  try {
    const [Balances, LastWTH] = await Promise.all([
      usersBalance(signal),
      FetchUserLastAssetsWithdrawal(signal),
    ]);
    if (Balances["status"] === 200 && LastWTH["status"] === 200) {
      result.lasts = LastWTH["data"]["withdrawals"];
      result.wallets = Balances["data"]["balance"];
      result.dailyAmount = Balances["data"]["dailyAmount"];
      result.total = Balances["data"]["total"];
    } else {
      result.error = true;
    }
  } catch (ex) {
    result.error = true;
  } finally {
    return result;
  }
}

export const updateUserAssetsItem = async (item, signal) => {
  const result = {
    error: false,
    message: "",
  };
  try {
    const { data, status } = await updateUsersDeposits(item, signal);
    console.log(data);
    if (status === 200) {
      if (!data["error"]) {
        result.message = data["message"];
      } else {
        result.error = true;
        result.message = data["message"];
      }
    } else {
      result.error = true;
    }
    return result;
  } catch {
    result.error = true;
  } finally {
    return result;
  }
};
