import {
  usersProfile,
  usersTransactionsHistory,
} from "../services/userServices";

export function ValidationUserDocsAction() {
  return async (dispatch) => {
    const { data: userTransactions } = await usersTransactionsHistory();
    const { data: userProfile } = await usersProfile();
    if (userTransactions && userProfile) {
      const hasRialDeposit = userTransactions.data.some((ctx) => {
        return ctx.coinName === "IRT" && ctx.operation === "BUY";
      });
      return dispatch({
        type: "SET_USER_DOCS_VALIDATION",
        payload: {
          documentStatus: userProfile.user.documentStatus,
          rialPayment: hasRialDeposit,
          isFetched: true,
        },
      });
    }
  };
  //   return async (dispatch) => {
  //     const { data } = await FetchTradesMarketList();
  //     await dispatch({ type: "GET_TRADE_MARKET_LIST", payload: data });
  //   };
  // }
}
