export function AddAppBanners(data) {
  return (dispatch) => {
    dispatch({ type: "SET_APP_BANNER", payload: data });
  };
}

export function AddAppMiniBanners(data) {
  return (dispatch) => {
    dispatch({ type: "ADD_APP_MINI_BANNERS", payload: data });
  };
}
