import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GradeOutlinedIcon from "@mui/icons-material/GradeOutlined";
import GradeIcon from "@mui/icons-material/Grade";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

//#
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import HomeIcon from "@mui/icons-material/Home";

import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import InsertChartIcon from "@mui/icons-material/InsertChart";

import EqualizerOutlinedIcon from "@mui/icons-material/EqualizerOutlined";

import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PersonIcon from "@mui/icons-material/Person";

import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import AllInboxOutlinedIcon from "@mui/icons-material/AllInboxOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import HelpCenterOutlinedIcon from "@mui/icons-material/HelpCenterOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";

import FingerprintIcon from "@mui/icons-material/Fingerprint";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PriceChangeOutlinedIcon from "@mui/icons-material/PriceChangeOutlined";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";

import IosShareIcon from "@mui/icons-material/IosShare";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";

//# Two-Tone
import AccountBalanceTwoToneIcon from "@mui/icons-material/AccountBalanceTwoTone";
import DisplaySettingsTwoToneIcon from "@mui/icons-material/DisplaySettingsTwoTone";
import QuizTwoToneIcon from "@mui/icons-material/QuizTwoTone";
import DirectionsTwoToneIcon from "@mui/icons-material/DirectionsTwoTone";
import UpcomingTwoToneIcon from "@mui/icons-material/UpcomingTwoTone";
import AccountBalanceWalletTwoToneIcon from "@mui/icons-material/AccountBalanceWalletTwoTone";
import HeadsetMicTwoToneIcon from "@mui/icons-material/HeadsetMicTwoTone";
import InsertChartTwoToneIcon from "@mui/icons-material/InsertChartTwoTone";
import PaymentTwoToneIcon from "@mui/icons-material/PaymentTwoTone";
import AddCardTwoToneIcon from "@mui/icons-material/AddCardTwoTone";
import TollTwoToneIcon from "@mui/icons-material/TollTwoTone";
import NavigationTwoToneIcon from "@mui/icons-material/NavigationTwoTone";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";

import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import StreamSharpIcon from "@mui/icons-material/StreamSharp";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import NotificationsActiveTwoToneIcon from "@mui/icons-material/NotificationsActiveTwoTone";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import VerticalAlignBottomOutlinedIcon from "@mui/icons-material/VerticalAlignBottomOutlined";
import VerticalAlignTopOutlinedIcon from "@mui/icons-material/VerticalAlignTopOutlined";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import AssistantPhotoOutlinedIcon from "@mui/icons-material/AssistantPhotoOutlined";
import AltRouteOutlinedIcon from "@mui/icons-material/AltRouteOutlined";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CheckIcon from "@mui/icons-material/Check";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import GppMaybeOutlinedIcon from "@mui/icons-material/GppMaybeOutlined";

export default function appIcons(name, props) {
  let icon = {
    ArrowBackIcon: <ArrowBackIcon {...props} />,
    StarEmptyIcon: <GradeOutlinedIcon {...props} />,
    StarFillIcon: <GradeIcon {...props} />,
    ArrowDownIcon: <KeyboardArrowDownIcon {...props} />,

    HomeOutIcon: <HomeOutlinedIcon {...props} />,
    HomeFillIcon: <HomeIcon {...props} />,
    ChartOutIcon: <InsertChartOutlinedIcon {...props} />,
    ChartFillIcon: <InsertChartIcon {...props} />,
    Chart2Icon: <EqualizerOutlinedIcon {...props} />,
    WalletOutIcon: <AccountBalanceWalletOutlinedIcon {...props} />,
    WalletFillIcon: <AccountBalanceWalletIcon {...props} />,
    PersonOutIcon: <PersonOutlineIcon {...props} />,
    PersonFillIcon: <PersonIcon {...props} />,
    PersonAccountIcon: <BadgeOutlinedIcon {...props} />,
    FinanceIcon: <LocalAtmOutlinedIcon {...props} />,
    BankAccountIcon: <AccountBalanceOutlinedIcon {...props} />,
    MessageBoxIcon: <AllInboxOutlinedIcon {...props} />,
    AddPersonIcon: <GroupAddOutlinedIcon {...props} />,
    SupportIcon: <SupportAgentOutlinedIcon {...props} />,
    HelperIcon: <HelpCenterOutlinedIcon {...props} />,
    FaqIcon: <QuestionAnswerOutlinedIcon {...props} />,
    MessageIcon: <MessageOutlinedIcon {...props} />,
    ExitIcon: <ExitToAppOutlinedIcon {...props} />,
    IdentityIcon: <FingerprintIcon {...props} />,
    SettingIcon: <SettingsOutlinedIcon {...props} />,
    IncreaseBalanceIcon: <PriceChangeOutlinedIcon {...props} />,
    AddMessageIcon: <ForwardToInboxOutlinedIcon {...props} />,
    IosShareIcon: <IosShareIcon {...props} />,
    ArrowMoreIcon: <UnfoldMoreIcon {...props} />,
    UserProfileIcon: <AccountCircleTwoToneIcon {...props} />,

    //#
    BankTwoToneIcon: <AccountBalanceTwoToneIcon {...props} />,
    SettingTwoToneIcon: <DisplaySettingsTwoToneIcon {...props} />,
    FaqTwoToneIcon: <QuizTwoToneIcon {...props} />,
    GuidTwoToneIcon: <DirectionsTwoToneIcon {...props} />,
    TicketTwoToneIcon: <UpcomingTwoToneIcon {...props} />,
    WalletTwoToneIcon: <AccountBalanceWalletTwoToneIcon {...props} />,
    SupportTwoToneIcon: <HeadsetMicTwoToneIcon {...props} />,
    ConvertTwoToneIcon: <InsertChartTwoToneIcon {...props} />,
    CardTwoToneIcon: <AddCardTwoToneIcon {...props} />,
    CoinTwoToneIcon: <TollTwoToneIcon {...props} />,
    ArrowTwoToneIcon: <NavigationTwoToneIcon {...props} />,

    //#
    GridIcon: <GridViewOutlinedIcon {...props} />,
    removeIcon: <RemoveCircleOutlineOutlinedIcon {...props} />,
    acceptIcon: <CheckCircleOutlineOutlinedIcon {...props} />,
    FeesIcon: <StreamSharpIcon {...props} />,
    infoIcon: <InfoOutlinedIcon {...props} />,
    notificationIcon: <NotificationsActiveTwoToneIcon {...props} />,
    closeIcon: <CloseOutlinedIcon {...props} />,
    arrowDownLineIcon: <VerticalAlignBottomOutlinedIcon {...props} />,
    arrowUpLineIcon: <VerticalAlignTopOutlinedIcon {...props} />,
    copyIcon: <CopyAllIcon {...props} />,
    addIcon: <AddOutlinedIcon {...props} />,
    removeOutlinedIcon: <RemoveOutlinedIcon {...props} />,
    changeIcon: <ChangeCircleOutlinedIcon {...props} />,
    cupIcon: <EmojiEventsOutlinedIcon {...props} />,
    transactionIcon: <ReceiptLongOutlinedIcon {...props} />,
    orderIcon: <ShoppingCartOutlinedIcon {...props} />,
    reportIcon: <AssistantPhotoOutlinedIcon {...props} />,
    waysIcon: <AltRouteOutlinedIcon {...props} />,
    addPhotoIcon: <AddPhotoAlternateOutlinedIcon {...props} />,
    personAccIcon: <AccountCircleIcon {...props} />,
    confirmIcon: <CheckIcon {...props} />,
    scanIcon: <CenterFocusWeakIcon {...props} />,
    sadSmileIcon: <SentimentVeryDissatisfiedIcon {...props} />,
    attentionIcon: <GppMaybeOutlinedIcon {...props} />,
  }[name];
  return icon || "";
}
