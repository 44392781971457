import OrdersMT from "./OrdersMT";
import InputsMT from "./InputsMT";

const MainStyles = {
    display:"grid",
    gridTemplateColumns:"49% 49%",
    gridColumnGap: "2%",
}
export default function MainMT() {
  return (
    <section style={MainStyles}>
      <OrdersMT />
      <InputsMT />
    </section>
  );
}
