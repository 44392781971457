import axios from "axios";
axios.defaults.headers.post["Content-Type"] = "application/json";
const token = localStorage.getItem("token");

if (token) axios.defaults.headers.common["x-access-token"] = `${token}`;
axios.interceptors.response.use(null, (error) => {
  const expectedErrors =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;
  if (!expectedErrors) {
    // Can Show Error
  }
  return Promise.reject(error);
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
