import styles from "./InputsMT.module.scss";
import { memo, useContext, useEffect } from "react";
import { tradesCTX } from "../../../../context/tradesCTX";
//* --> Components
import { SuccessMuiBtn, DangerMuiBtn } from "../../../MuiButtons";
import Limit from "./Limit";
import MarketPrice from "./MarketPrice";
import StopLimit from "./StopLimit";
import StopMarket from "./StopMarket";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import LoginRegister from "./LoginRegister";
import TradeType from "../../TradeType";

function InputsMT() {
  const isUserLogin = useSelector((state) => state.userId?.userHashCode);
  const marketTradeContext = useContext(tradesCTX);
  const {
    setTypeOfTrade,
    typeOfTrade,
    currentTrade,
    marketType,
    handleBuyLimit,
    handleSellLimit,
    handleBuyMarket,
    handleSellMarket,
    handleBuyStopLimit,
    handleSellStopLimit,
    handleBuyStopMarket,
    handleSellStopMarket,
    disableDangerBtn,
    disableSuccessBtn,
  } = marketTradeContext;
  const urlParams = new URLSearchParams(window.location.search);
  const queryParams = urlParams.get("type");
  useEffect(() => {
    switch (queryParams) {
      case "buy":
        return setTypeBuy();
      case "sell":
        return setTypeSell();
      default:
        break;
    }
  }, [queryParams]);
  const setTypeBuy = () => {
    setTypeOfTrade("buy");
  };
  const setTypeSell = () => {
    setTypeOfTrade("sell");
  };

  const handleBuy = () => {
    switch (marketType) {
      case "limit":
        return handleBuyLimit();
      case "market":
        return handleBuyMarket();
      case "stop-limit":
        return handleBuyStopLimit();
      case "stop-market":
        return handleBuyStopMarket();
      default:
        break;
    }
  };
  const handleSell = () => {
    switch (marketType) {
      case "limit":
        return handleSellLimit();
      case "market":
        return handleSellMarket();
      case "stop-limit":
        return handleSellStopLimit();
      case "stop-market":
        return handleSellStopMarket();
      default:
        break;
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.header_btns}>
        <div className={styles.just_btn}>
          <button
            className={`${styles.typeBtn} ${
              typeOfTrade === "sell"
                ? "bg_red color_white_light_mode"
                : "changeBackgroundColorToHardBlack"
            }`}
            onClick={setTypeSell}
          >
            فروش
          </button>
          <button
            className={`${styles.typeBtn} ${
              typeOfTrade === "buy"
                ? "bg_green color_white_light_mode"
                : "changeBackgroundColorToHardBlack"
            }`}
            onClick={setTypeBuy}
          >
            خرید
          </button>
        </div>
        {/* <SuccessMuiBtn text="خرید" onClick={setTypeBuy} /> */}
        <div className={`mt-7 ${styles.type_trade}`}>
          <TradeType />
        </div>
      </div>

      <main>
        {marketType === "limit" ? (
          <Limit type={typeOfTrade} />
        ) : marketType === "market" ? (
          <MarketPrice type={typeOfTrade} />
        ) : marketType === "stop-limit" ? (
          <StopLimit type={typeOfTrade} />
        ) : marketType === "stop-market" ? (
          <StopMarket type={typeOfTrade} />
        ) : (
          "Reload the application"
        )}
      </main>
      <div className={`dir-rtl ${styles.finally_btn}`}>
        {isEmpty(isUserLogin) ? (
          <LoginRegister />
        ) : (
          <>
            {typeOfTrade === "buy" ? (
              <SuccessMuiBtn
                text={`خرید ${currentTrade?.asset_title_fa}`}
                onClick={handleBuy}
                disabled={disableSuccessBtn}
              />
            ) : (
              <DangerMuiBtn
                text={`فروش ${currentTrade?.asset_title_fa}`}
                onClick={handleSell}
                disabled={disableDangerBtn}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}
export default memo(InputsMT);
