import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { ClickAwayListener } from "@mui/material";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(240, 240, 240,0.9)",
    color: "#151C30",
    // boxShadow: theme.shadows[1],
    // border: "none",
    fontSize: 11,
    maxWidth: 270,
    fontFamily: "IranSansWeb",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(240, 240, 240,0.9)",
  },
}));
const DarkToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#202328",
    color: "#FFF",
    // boxShadow: theme.shadows[1],
    // border: "none",
    fontSize: 11,
    maxWidth: 270,
    fontFamily: "IranSansWeb",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#202328",
  },
}));

const marketDesc = [
  {
    name: "limit",
    desc: "لیمیت سفارش خرید و فروش در قیمت دلخواه ",
  },
  { name: "market", desc: "خرید و فروش ارز به ترتیب بهترین قیمت بازار" },
  { name: "stop-limit", desc: "ثبت سفارش لیمیت پس از رسیدن به قیمت استاپ" },
  { name: "stop-market", desc: "ثبت سفارش قیمت بازار پس از رسیدن به قیمت استاپ" },
];
function ToolltipMt({ children, marketType }) {
  const appTheme = useSelector((state) => state.appTheme);
  React.useEffect(() => {
    let innerTimer;
    let outerTimer;
    outerTimer = setTimeout(() => {
      handleTooltipOpen();
      innerTimer = setTimeout(() => {
        handleTooltipClose();
        clearTimeout(innerTimer);
      }, 2000);
      clearTimeout(outerTimer);
    }, 400);
    return () => {
      clearTimeout(innerTimer);
      clearTimeout(outerTimer);
    };
  }, [marketType]);
  const [open, setOpen] = React.useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <span>
          {appTheme === "dark" ? (
            <DarkToolTip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                <>
                  <span className="font_color_white">
                    {marketDesc.filter((c) => c.name === marketType)[0]?.desc ??
                      "-"}
                  </span>{" "}
                  {/* <span
                    style={{ paddingRight: "20px" }}
                    className="color_green"
                  >
                    اطلاعات بیشتر
                  </span> */}
                </>
              }
              arrow
            >
              <span onClick={handleTooltipOpen}>{children}</span>
            </DarkToolTip>
          ) : (
            <LightTooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                <>
                  <span className="font_color_white">
                    {marketDesc.filter((c) => c.name === marketType)[0]?.desc ??
                      "-"}
                  </span>{" "}
                  {/* <span
                    style={{ paddingRight: "20px" }}
                    className="color_green"
                  >
                    اطلاعات بیشتر
                  </span> */}
                </>
              }
              arrow
            >
              <span onClick={handleTooltipOpen}>{children}</span>
            </LightTooltip>
          )}
        </span>
      </ClickAwayListener>
    </>
  );
}
export default React.memo(ToolltipMt);
