import http from "./httpServices";
import config from "./config.json";
export const getMarketData = () => {
  return http.get(`${config.arzfi}/market/assets`);
};
export const getCoinList = () => {
  return http.get(`${config.arzfi}/market/list`);
};
export const getCoinInfo = async (coin) => {
  try {
    return await http.get(`${config.arzfi}/market/asset/${coin}`);
  } catch (err) {
    if (err.response.status === 404) {
      window.location.replace("/404");
    }
  }
};
export const getNetworkInfo = async (coin, signal) => {
  const options = signal ? { signal } : {};
  return http.get(`${config.arzfi}/market/chains/${coin}`, options);
};

export const getRateOfCoins = (first, second) => {
  return http.get(`${config.arzfi}/market/rate/${first}-${second}`);
};
export const FetchTradesMarketList = () => {
  return http.get(`${config.dev}/market/market-list`);
};
export const FetchPairs = () => {
  return http.get(`${config.arzfi}/market/get-pairs`);
};
export const FetchMarketsFees = (signal) => {
  return http.get(`${config.arzfi}/market/fee`, { signal });
};
