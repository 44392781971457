export const loadingPageReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_LOADING_TRUE":
      return { ...action.payload };

    case "SET_LOADING_FALSE":
      return { ...action.payload };
    default:
      return state;
  }
};
