import { Container, Stack } from "@mui/material";
import React, { useLayoutEffect } from "react";
import HeaderWallet from "../pageComponents/WalletPage/HeaderWallet";
import WalletsBody from "../pageComponents/WalletPage/WalletsBody";
import { useReducer } from "react";
import {
  getPageData,
  initialStates,
  walletReducer,
} from "../pageComponents/WalletPage/states";

export default function Wallet() {
  const [state, dispatch] = useReducer(walletReducer, initialStates);
  useLayoutEffect(() => {
    const controller = new AbortController();
    (async () => {
      const { error, wallets, lasts, dailyAmount, total } = await getPageData(
        controller.signal
      );
      if (!error) {
        return dispatch({
          type: "SUCCESS_INIT",
          payload: {
            dailyAmount,
            withdrawals: lasts,
            wallets,
            IRTTotal: total["totalPrice"],
            USDTTotal: total["totalUsdt"],
          },
        });
      }
      return dispatch({ type: "FAILD_INIT" });
    })();

    return () => {
      controller.abort();
    };
  }, [state["refreshPage"]]);

  return (
    <Container disableGutters>
      <HeaderWallet total={state["total"]} daily={state["dailyAmount"]} />
      <Stack
        sx={{ flex: 1, background: "var(--main2-color)" }}
        height="calc(100vh - 200px)"
        position="relative"
        top={-20}
        borderRadius="20px 20px 0 0"
        paddingX={2}
        paddingTop={1}
      >
        <WalletsBody state={state} dispatch={dispatch} />
      </Stack>
    </Container>
  );
}
