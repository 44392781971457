import { Fab } from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import { useHistory } from "react-router";
import appIcons from "../../../utils/appIcons";
//Css
import styles from "./Login.module.scss";
//Components
import LoginOne from "./LoginOne";
import LoginTwo from "./LoginTwo";
import "./Login_global.scss";
export default function Login() {
  const router = useHistory();
  const [swapButton, setSwapButton] = useState("with-mobile");


  const handleChangeRoute = () => {
    router.push("/")
  }
  return (
    <>
      <div className={styles.container}>
        <div className={`changeTextColorToGreen ${styles.login_title}`}>
          ورود به حساب کاربری
        </div>
        <div className={styles.login_buttons}>
          <div
            className={`font_color_white ${styles.with_email}`}
            onClick={() => setSwapButton("with-email")}
          >
            ورود با ایمیل
          </div>
          <div
            className={`font_color_white ${styles.with_mobile}`}
            onClick={() => setSwapButton("with-mobile")}
          >
            ورود با موبایل
          </div>
        </div>
        <div className={styles.lines_under_button}>
          <div
            className={`${styles.line_button} ${
              swapButton === "with-email"
                ? `changeTextColorToGreen ${styles.line_button_active}`
                : ""
            }`}
          ></div>
          <div className={styles.line_button}></div>
          <div
            className={`${styles.line_button} ${
              swapButton === "with-mobile"
                ? `changeTextColorToGreen ${styles.line_button_active}`
                : ""
            }`}
          ></div>
        </div>
        <main>
          {swapButton === "with-mobile" ? <LoginOne /> : <LoginTwo />}
        </main>
        <Stack className={styles.FooterContainer}>
          <Fab
            size="medium"
            color="inherit"
            style={{ background: "var(--primary-color)" }}
            onClick={handleChangeRoute}
          >
            {appIcons("GridIcon")}
          </Fab>
        </Stack>
      </div>
    </>
  );
}
