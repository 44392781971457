import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { memo } from "react";
import serviceConfig from "../../../services/config.json";
//# STYLES
import styles from "./MarketRoute.module.scss";
function MarketRoute() {
  return (
    <Stack className={styles.container}>
      <Stack className={styles.image}>
        <span>
          <img
            src={`${serviceConfig.image_url}/USDT.png`}
            alt="USDT/IRT-Order"
            width={35}
            height={35}
          />
        </span>
      </Stack>
      <Stack className={styles.text}>
        <Typography component="h2">معامله تتر</Typography>
        <Typography component="h3">سفارش سریع تتر</Typography>
      </Stack>
    </Stack>
  );
}
export default memo(MarketRoute);
