import { useContext, useState, useRef } from "react";
import { context } from "../../../context/context";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";

//Styles And Icons
import styles from "./Login.module.scss";
import RTL from "../../../helpers/direction/RTL";
import EyesIcon from "../../../styles/icon/eyes.svg";
import UserIcon from "../../../styles/icon/person_outline.svg";
import LockIcon from "../../../styles/icon/lock.svg";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

export default function LoginOne() {
  const [checkFocuse, setCheckFocuse] = useState("");
  const [invisiblePassword, setInvisiblePassword] = useState(true);
  const userLogin = useContext(context);
  const {
    userNumber,
    setUserNumber,
    userPassword,
    setUserPassword,
    handleLoginNumber,
    validatorLogin,
    setValidatorLogin,
    buttonDisabled,
    setCaptchaDetails,
    captchaDetails,
  } = userLogin;

  return (
    <>
      <form onSubmit={(e) => handleLoginNumber(e)}>
        <section className={styles.inputs_place}>
          <div
            className={`changeTextFieldBackgroundToWBlack ${styles.mobile_place} `}
          >
            <RTL>
              <TextField
                className={` ${styles.number_login_input}`}
                name="phoneNumber"
                label="شماره موبایل"
                inputProps={{ inputMode: "decimal" }}
                color="success"
                onFocus={() => setCheckFocuse("mobile")}
                onBlur={() => setCheckFocuse("")}
                value={userNumber}
                onChange={(e) => {
                  setUserNumber(e.target.value);
                  setValidatorLogin({ ...validatorLogin, number: "" });
                }}
              />
              <div
                className={`${styles.icon_place} ${styles.first_input_icon} ${
                  checkFocuse === "mobile" ? styles.icon_place_active : ""
                }`}
              >
                <img src={UserIcon} alt="" className="convertPicToGreen" />
              </div>
            </RTL>
            <div className={styles.errorMessage}>{validatorLogin?.number}</div>
          </div>

          <div
            className={`changeTextFieldBackgroundToWBlack ${styles.pass_place}`}
          >
            <RTL>
              <TextField
                name="userPass"
                label="رمز عبور"
                color="success"
                type={invisiblePassword ? "password" : "text"}
                onFocus={() => setCheckFocuse("password")}
                onBlur={() => setCheckFocuse("")}
                value={userPassword}
                onChange={(e) => {
                  setUserPassword(e.target.value);
                }}
              />

              <div
                className={`${styles.icon_place} ${
                  checkFocuse === "password" ? styles.icon_place_active : ""
                }`}
              >
                <img src={LockIcon} alt="" className="convertPicToGreen" />
              </div>
              <div
                className={styles.icon_place_2}
                onClick={() => setInvisiblePassword(!invisiblePassword)}
              >
                <img src={EyesIcon} alt="" />
              </div>
            </RTL>
            <div>
              <GoogleReCaptcha
                className="google-recaptcha-custom-class"
                onVerify={(t) =>
                  setCaptchaDetails((prev) => ({ ...prev, token: t }))
                }
                refreshReCaptcha={captchaDetails.refresh}
              />
            </div>
            <div className={styles.errorMessage}></div>
            <div className={styles.forget_password_text}>
              <Link to="/authorization/recovery-pass">
                <div className="font_color_white">فراموشی رمز عبور</div>
              </Link>
            </div>
          </div>
        </section>
        <div className={styles.button_container}>
          <button type="submit" disabled={buttonDisabled}>
            ورود
          </button>
          <Link to="/authorization/register">
            <div className={`font_color_white ${styles.register_div}`}>
              حساب کاربری ندارید؟ثبت نام کنید
            </div>
          </Link>
        </div>
        <div className={styles.place_of_checkbox_rules}>
          <Link to="/terms">
            <span>قوانین و مقررات</span>
          </Link>
        </div>
      </form>
    </>
  );
}
