import { useState, useContext } from "react";
import { context } from "../../../context/context";
import ReactCodeInput from "react-verification-code-input";
import styles from "./FinallyRegister.module.scss";
import TextField from "@mui/material/TextField";
import RTL from "../../../helpers/direction/RTL";
import LockIcon from "../../../styles/icon/lock.svg";
export default function FinallyRegister() {
  const [checkFocuse, setCheckFocuse] = useState("");
  const FinallyRegisterCTX = useContext(context);
  const {
    registerAccSteps,
    setRegisterAccSteps,
    handleNewUserCompleteRegistration,
    newUserPassword,
    setNewUserPassword,
    repeatNewUserPassword,
    setRepeatNewUserPassword,
    setVerifyCodeRegister,
    handleRegisterCodeVerify,
    validatorRegister,
    setValidatorRegister,
  } = FinallyRegisterCTX;

  return (
    <div className={styles.container}>
      <form onSubmit={(e) => handleNewUserCompleteRegistration(e)}>
        <div className={styles.input_confirm_code}>
          <div className={`font_color_change_to_green ${styles.title_confirm}`}>
            کد دریافتی
          </div>
          <ReactCodeInput
            fields={5}
            className={styles.input_places}
            fieldWidth={43}
            fieldHeight={43}
            placeholder={["-", "-", "-", "-", "-", "-"]}
            autoFocus={true}
            onChange={setVerifyCodeRegister}
            onComplete={handleRegisterCodeVerify}
          />
          {!registerAccSteps.activeSetPassword ? (
            <div className={styles.text_guid_verify}>
              *ابندا کد فعال سازی را وارد کنید سپس رمز مورد نظر خود را وارد کنید
            </div>
          ) : (
            ""
          )}
        </div>
        <div className={styles.password_container}>
          <div
            className={`changeTextFieldBackgroundToWBlack ${styles.pass_place}`}
          >
            <RTL>
              <TextField
                type="password"
                label="رمز عبور"
                color="success"
                onFocus={() => setCheckFocuse("mobile")}
                onBlur={() => setCheckFocuse("")}
                value={newUserPassword}
                onChange={(e) => {
                  setNewUserPassword(e.target.value);
                  setValidatorRegister({ ...validatorRegister, password: "" });
                }}
                disabled={!registerAccSteps.activeSetPassword}
              />

              <div
                className={`${styles.icon_place}  ${
                  checkFocuse === "mobile" ? styles.icon_place_active : ""
                }`}
              >
                <img src={LockIcon} alt="" className="convertPicToGreen" />
              </div>
              <div className={styles.errorMessage}>
                {validatorRegister?.password}
              </div>
            </RTL>
          </div>
          <div
            className={`changeTextFieldBackgroundToWBlack ${styles.pass_place}`}
          >
            <RTL>
              <TextField
                type="password"
                label="تکرار رمز عبور"
                color="success"
                onFocus={() => setCheckFocuse("repeat")}
                onBlur={() => setCheckFocuse("")}
                value={repeatNewUserPassword}
                onChange={(e) => {
                  setRepeatNewUserPassword(e.target.value);
                  setValidatorRegister({
                    ...validatorRegister,
                    repeatPassword: "",
                  });
                }}
                disabled={!registerAccSteps.activeSetPassword}
              />

              <div
                className={`${styles.icon_place}  ${
                  checkFocuse === "repeat" ? styles.icon_place_active : ""
                }`}
              >
                <img src={LockIcon} alt="" className="convertPicToGreen" />
              </div>
              <div className={styles.errorMessage}>
                {validatorRegister?.repeatPassword}
              </div>
            </RTL>
          </div>
        </div>
        <div className={styles.button_container}>
          <button
            className={styles.continue_button}
            type="submit"
            disabled={!registerAccSteps.activeSetPassword}
          >
            ورود
          </button>
        </div>
      </form>
      <div className={styles.button_container}>
        <button
          className={styles.back_button}
          onClick={() =>
            setRegisterAccSteps({ ...registerAccSteps, showVerifyCode: false })
          }
        >
          برگشت
        </button>
      </div>
    </div>
  );
}
