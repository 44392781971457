const HOME_ROUTES = [
  {
    id: "notifications",
    title: "پیام ها",
    icon: "notificationIcon",
    link: "/Notifications",
  },
  {
    id: "guid",
    title: "راهنما",
    icon: "GuidTwoToneIcon",
    link: "/app-guid",
  },
  {
    id: "support",
    title: "پشتیبانی",
    icon: "SupportTwoToneIcon",
    link: null,
    onClick: "openImber",
  },
  {
    id: "fees",
    title: "کارمزد ها",
    icon: "FeesIcon",
    link: "/fees",
  },
  {
    id: "ticket",
    title: "تیکت",
    icon: "TicketTwoToneIcon",
    link: "/tickets",
  },
  {
    id: "faq",
    title: "سوالات متداول",
    icon: "FaqTwoToneIcon",
    link: "/faq",
  },
  {
    id: "verify",
    title: "احراز هویت",
    icon: "IdentityIcon",
    link: "/my-profile/authentication",
  },
  {
    id: "markets",
    title: "بازارها",
    icon: "BankTwoToneIcon",
    link: "/market",
  },
  {
    id: "setting",
    title: "تنظیمات",
    icon: "SettingTwoToneIcon",
    link: "/setting",
  },
];

export { HOME_ROUTES };
