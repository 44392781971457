import React from "react";

import styles from "./BottomModal.module.scss";
function BottomModal(props) {
  const { visible = false, children, onRequestClose } = props;
  return (
    <div className={styles.container} data-visible={visible}>
      {visible && <div className={styles.bg} onClick={onRequestClose}></div>}
      <div className={styles.content} data-visible={visible}>{children}</div>
    </div>
  );
}

export default BottomModal;
