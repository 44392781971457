import { Checkbox, FormControlLabel } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import styles from "../HistoryMT.module.scss";
import { tradesCTX } from "../../../../context/tradesCTX";
import NoOrders from "../NoOrders";
import { useSelector } from "react-redux";
//* --> Utils
import { isEmpty } from "lodash";
import moment from "jalali-moment";
import { MARKET_TRADE_TYPES, statusTrade, TYPE_TRADE } from "../index";
//* --> Material UI
//* --> Icon's
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

export default function OrdersHistory() {
  const MarketContext = useContext(tradesCTX);
  const {
    currentTrade,
    showThisMarketTable,
    setShowThisMarketTable,
    disabledCheckBoxTable,
  } = MarketContext;
  const [ordersList, setOrdersList] = useState([]);
  const userOrdersRedux = useSelector((state) => state.marketOrdersList?.data);
  const isUserLogin = useSelector((state) => state.userId?.userHashCode);

  useEffect(() => {
    if (!isEmpty(isUserLogin)) {
      setOrdersList(userOrdersRedux);
    }
  }, [isUserLogin, userOrdersRedux]);
  return (
    <div className={styles.open_orders_section}>
      <div className={styles.check}>
        <FormControlLabel
          sx={{
            padding: 0,
            margin: 0,
            height: "30px",
          }}
          label={
            <span
              style={{
                fontFamily: "IranSansWeb",
                fontSize: "12px",
                paddingRight: "5px",
                color: "#9D9EA8",
              }}
            >
              فقط نمایش {currentTrade?.asset_title_fa}/
              {currentTrade?.area_title_fa}
            </span>
          }
          control={
            <Checkbox
              disabled={disabledCheckBoxTable}
              checked={showThisMarketTable}
              onChange={() => setShowThisMarketTable(!showThisMarketTable)}
              size="small"
              sx={{
                color: "#9D9EA8",
                padding: 0,
                "&.Mui-checked": {
                  color: "#00c194",
                },
              }}
            />
          }
        />
      </div>
      <div>
        {!isEmpty(isUserLogin) ? (
          !isEmpty(ordersList) ? (
            ordersList.slice(0, 3).map((ord, i) => {
              return (
                <section
                  key={i}
                  className={`${styles.normal_orders} border_color_272E40`}
                >
                  <div className={styles.one}>
                    <div className={styles.name}>
                      {ord.type === "BUY" ? (
                        <span
                          style={{ color: "#00C194" }}
                          className={styles.icon_buy_sell}
                        >
                          <FileDownloadOutlinedIcon
                            color="inherit"
                            fontSize="inherit"
                          />
                        </span>
                      ) : (
                        <span
                          style={{ color: "#ed6e72" }}
                          className={styles.icon_buy_sell}
                        >
                          <FileUploadOutlinedIcon
                            color="inherit"
                            fontSize="inherit"
                          />
                        </span>
                      )}
                      <span>{ord.faName}</span>
                      <span>‌({ord.market})</span>
                    </div>
                  </div>
                  <div className={`${styles.second} change_color_to_9D9EA8`}>
                    <div className={`eng-number-font ${styles.date}`}>
                      {moment(ord.createdAt, "YYYY-MM-DDThh:mm:ss.000Z")
                        .locale("fa")
                        .format("YYYY/MM/DD HH:mm:ss")}
                    </div>
                    <div className={styles.market_type}>
                      <span>
                        {MARKET_TRADE_TYPES.filter(
                          (c) => c.title === ord.tradeType
                        )[0]?.label ?? "-"}
                      </span>
                    </div>
                    <div className={styles.area_price}>
                      <div style={{ fontSize: "10px" }} className="dir-rtl">
                        مبلغ کل ({currentTrade?.area_title_fa})
                      </div>
                      <div>
                        <span className="eng-number-font">
                          {Number(ord?.totalPrice ?? 0) % 1
                            ? Number(ord?.totalPrice ?? 0)?.toFixed(2)
                            : Number(ord?.totalPrice ?? 0)}
                        </span>
                        <span style={{ paddingLeft: "3px" }}>
                          {currentTrade?.area_title}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.three}>
                    <div className={styles.amount}>
                      <div
                        className={`${styles.titles} change_color_to_9D9EA8`}
                      >
                        مقدار
                      </div>
                      <div className="change_color_to_E1E2E4 eng-number-font">
                        <span>{+ord.amount}</span>
                      </div>
                    </div>
                    <div className={styles.dones}>
                      <div
                        className={`${styles.titles} change_color_to_9D9EA8`}
                      >
                        انجام شده
                      </div>
                      <div className="change_color_to_E1E2E4 eng-number-font">
                        {+ord.executed}
                      </div>
                    </div>
                    <div className={styles.undones}>
                      <div
                        className={`${styles.titles} change_color_to_9D9EA8`}
                      >
                        انجام نشده
                      </div>
                      <div className="change_color_to_E1E2E4 eng-number-font">
                        {+ord.amount - +ord.executed}
                      </div>
                    </div>
                  </div>
                  <div className={styles.four}>
                    <div>
                      <div
                        className={`${styles.titles} change_color_to_9D9EA8`}
                      >
                        لیمیت
                      </div>
                      <div className="change_color_to_E1E2E4 eng-number-font">
                        {ord.price % 1 ? ord.price : +ord.price}
                      </div>
                    </div>
                    <div className={styles.trade_type}>
                      <div
                        className={`${styles.titles} change_color_to_9D9EA8`}
                      >
                        نوع
                      </div>
                      <div
                        className={
                          TYPE_TRADE.filter((c) => c.title === ord.type)[0]
                            ?.color ?? "-"
                        }
                      >
                        {TYPE_TRADE.filter((c) => c.title === ord.type)[0]
                          ?.label ?? "-"}
                      </div>
                    </div>
                    <div className={styles.status}>
                      <div
                        className={`${styles.titles} change_color_to_9D9EA8`}
                      >وضعیت</div>
                      <div className="change_color_to_E1E2E4">
                        {statusTrade.filter((c) => c.title === ord.status)[0]
                          ?.label ?? "-"}
                      </div>
                    </div>
                  </div>
                </section>
              );
            })
          ) : (
            <NoOrders text="سفارش فعالی یافت نشد" />
          )
        ) : (
          <NoOrders text="برای مشاهده سفارشات وارد حساب خود شوید" />
        )}
      </div>
    </div>
  );
}
