import React, { useMemo } from "react";
import { Button, Stack } from "@mui/material";
import styles from "./WalletPage.module.scss";
import UserWallets from "./UserWallets";
import UsersWithdrawals from "./UsersWithdrawals";

const ButtonTabs = [
  { name: "کیف پول", id: "wallet" },
  { name: "برداشت های اخیر", id: "withdrawals" },
];
function WalletsBody({ state, dispatch }) {
  //# function's
  const handleChangeView = (id) => {
    dispatch({ type: "VIEW", payload: id });
  };

  const ShowComponent = useMemo(() => {
    const CMP = {
      wallet: <UserWallets state={state} dispatch={dispatch} />,
      withdrawals: <UsersWithdrawals state={state} dispatch={dispatch} />,
    }[state["view"]];
    return CMP ?? "";
  }, [state]);
  
  return (
    <Stack sx={{ direction: "rtl" }} height={1}>
      <Stack direction="row" borderBottom="1px solid var(--border-color)">
        {ButtonTabs.map((ctx) => {
          return (
            <Button
              variant="text"
              color="inherit"
              key={ctx["id"]}
              data-selected={ctx["id"] === state["view"]}
              className={styles.buttonItems}
              onClick={() => handleChangeView(ctx["id"])}
              size="small"
            >
              {ctx["name"]}
            </Button>
          );
        })}
      </Stack>
      <Stack flex={1}>{ShowComponent}</Stack>
    </Stack>
  );
}

export default WalletsBody;
