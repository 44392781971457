import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { getTradeMarketList } from "../../../../actions/getMarketList";
import { getUserMarketOrdersList } from "../../../../actions/UsersMarketOrders";
import { GetUserStopOrdersList } from "../../../../actions/UsersStopOrdersList";
import { GetUnexecutedOrders } from "../../../../actions/UsersUnexecutedOrders";
import {
  hiddenAppMenu,
  showAppMenu,
} from "../../../../actions/visibiltyOfMenu";
import { formatDateToMiladi } from "../../../../utils/timeConvertor";
import FilterMH from "../../../MarketHistoryLayout/FilterMH";
import HeaderMH from "../../../MarketHistoryLayout/HeaderMH";
import MainMH from "../../../MarketHistoryLayout/MainMH";
import AsideT from "../../../TradeLayout/AsideT";

export default function MarketOrders() {
  //* --> Redux State's
  const tradesMarketList = useSelector((state) => state.tradesList);
  const isUserLogin = useSelector((state) => state.userId?.userHashCode);

  //* --> Access Dispatch For Redux
  const dispatch = useDispatch();
  //* --> Access Locations Params & Querys
  const location = useLocation();
  //* --> State's
  const [searchMarket, setSearchMarket] = useState("");
  //* --> Filter State's
  const [marketData, setMarketData] = useState({});
  const [typeTrade, setTypeTrade] = useState("");
  const [tradeMarketType, setTradeMarketType] = useState("");
  const [date, setDate] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [customDate, setCustomDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [openFilterBox, setOpenFilterBox] = useState(false);
  const [openMarketList, setOpenMarketList] = useState(false);
  //* <-- Filter State's
  const [showThisMarketDoneOrders, setShowThisMarketDoneOrders] =
    useState(false);
  const [showThisMarketOpenOrders, setShowThisMarkOpenOrders] = useState(false);
  const [disabledCheckBoxTable, setDisabledCheckBoxTable] = useState(false);
  const [disabledCheckBoxOpen, setDisabledCheckBoxOpen] = useState(false);
  const [ordersType, setOrdersType] = useState(0);

  useEffect(() => {
    let marketName = "";
    if (location?.search?.split("=")[1]) {
      marketName = location?.search?.split("=")[1];
    }
    setSearchMarket(marketName);
  }, [location]);
  //* --> Get Data Of The ALL Trades-Market
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (isEmpty(tradesMarketList)) {
        dispatch(getTradeMarketList());
      }
    }
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (!isEmpty(tradesMarketList)) {
      if (searchMarket) {
        const filterMarket = tradesMarketList.list.filter(
          (c) => c.market === searchMarket
        )[0];
        if (filterMarket) {
          setMarketData(filterMarket);
        } else {
          setMarketData(tradesMarketList.list[0]);
        }
      } else {
        setMarketData(tradesMarketList.list[0]);
      }
    }
  }, [searchMarket, tradesMarketList]);

  //* --> Get Details Of User History
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (!isEmpty(isUserLogin) && !isEmpty(marketData)) {
        if (showThisMarketOpenOrders) {
          try {
            setDisabledCheckBoxOpen(true);
            dispatch(GetUserStopOrdersList(marketData.market));
            dispatch(GetUnexecutedOrders(marketData.market));
          } catch (ex) {
          } finally {
            setTimeout(() => {
              setDisabledCheckBoxOpen(false);
            }, 5000);
          }
        } else {
          try {
            setDisabledCheckBoxOpen(true);
            dispatch(GetUserStopOrdersList());
            dispatch(GetUnexecutedOrders());
          } catch (ex) {
          } finally {
            setTimeout(() => {
              setDisabledCheckBoxOpen(false);
            }, 5000);
          }
        }
      }
    }
    return () => (mounted = false);
  }, [marketData, isUserLogin, showThisMarketOpenOrders]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (!isEmpty(marketData)) {
        if (!isEmpty(isUserLogin)) {
          if (showThisMarketDoneOrders) {
            try {
              setDisabledCheckBoxTable(true);
              dispatch(getUserMarketOrdersList(marketData.market));
            } catch (ex) {
            } finally {
              setTimeout(() => {
                setDisabledCheckBoxTable(false);
              }, 5000);
            }
          } else {
            try {
              setDisabledCheckBoxTable(true);
              dispatch(getUserMarketOrdersList());
            } catch (ex) {
            } finally {
              setTimeout(() => {
                setDisabledCheckBoxTable(false);
              }, 5000);
            }
          }
        }
      }
    }
    return () => (mounted = false);
  }, [marketData, isUserLogin, showThisMarketDoneOrders]);
  //* --> Hidden Menu-App
  useEffect(() => {
    dispatch(hiddenAppMenu());
    return () => dispatch(showAppMenu());
  }, [dispatch]);
  //* <-- Hidden Menu-App

  //* -->
  useEffect(() => {
    let dateM = new Date();
    let lastWeek = new Date(dateM.getTime() - 7 * 24 * 60 * 60 * 1000);
    let lastMonth = new Date(dateM.getTime() - 30 * 24 * 60 * 60 * 1000);
    lastWeek = formatDateToMiladi(lastWeek);
    lastMonth = formatDateToMiladi(lastMonth);
    if (date !== "c-date") {
      setCustomDate({ startDate: "", endDate: "" });
      //* -->
      switch (date) {
        case "":
          setDateTime("");
          break;
        case "week":
          setDateTime(lastWeek);
          break;
        case "month":
          setDateTime(lastMonth);
          break;
        default:
          break;
      }
    }
  }, [date]);
  //   useEffect(() => {
  //
  //     setLastMonth(lastMonth);
  //     setLastWeek(lastWeek);
  //   }, []);
  return (
    <section>
      <header>
        <HeaderMH setOpen={setOpenFilterBox} />
      </header>
      <main className="mt-20">
        <MainMH
          ordersType={ordersType}
          setOrdersType={setOrdersType}
          marketData={marketData}
          showThisMarketOpenOrders={showThisMarketOpenOrders}
          setShowThisMarkOpenOrders={setShowThisMarkOpenOrders}
          disabledCheckBoxOpen={disabledCheckBoxOpen}
          //* -->
          disabledCheckBoxTable={disabledCheckBoxTable}
          showThisMarketDoneOrders={showThisMarketDoneOrders}
          setShowThisMarketDoneOrders={setShowThisMarketDoneOrders}
          //* --> Filters
          typeTrade={typeTrade}
          dateTime={dateTime}
          customDate={customDate}
          tradeMarketType={tradeMarketType}
        />
      </main>
      <aside>
        <AsideT
          clinetHeightPOS={20}
          openMarketList={openMarketList}
          setOpenMarketList={setOpenMarketList}
          setSearchMarket={setSearchMarket}
          marketInFilter={marketData}
        />
      </aside>
      <footer>
        <FilterMH
          setOpen={setOpenFilterBox}
          open={openFilterBox}
          searchMarket={searchMarket}
          setSearchMarket={setSearchMarket}
          typeTrade={typeTrade}
          setTypeTrade={setTypeTrade}
          tradeMarketType={tradeMarketType}
          setTradeMarketType={setTradeMarketType}
          date={date}
          setDate={setDate}
          marketData={marketData}
          openMarketList={openMarketList}
          setOpenMarketList={setOpenMarketList}
          customDate={customDate}
          setCustomDate={setCustomDate}
        />
      </footer>
    </section>
  );
}
