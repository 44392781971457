import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getCoinDetail } from "../../../actions/getCoinDetail";
import { isEmpty } from "lodash";
import ArrowBackIcon from "../../../styles/icon/arrow_back_fill.svg";
import Helmet from "react-helmet";
//Styles and Icons
import styles from "./DescCoin.module.scss";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Highcharts from "highcharts/highstock";
import PriceChart from "./PriceChart";
import { Link } from "react-router-dom";
import serviceConfig from "../../../services/config.json";
//

require("highcharts/indicators/indicators")(Highcharts);
require("highcharts/indicators/pivot-points")(Highcharts);
require("highcharts/indicators/macd")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
export default function DescCoin() {
  const history = useHistory();
  const { name } = useParams();
  const dispatch = useDispatch();
  const COIN = useSelector((state) => state.coinInfo.data);
  const [descriptionStatus, setDescriptionStatus] = useState({
    length: 0,
    visible: false,
  });
  const [coinDescription, setDescription] = useState("");
  useEffect(() => {
    (async () => {
      if (!COIN) {
        await dispatch(getCoinDetail(name));
      }
      setDescription(
        COIN?.description?.replace(/(<([^>]+)>)/gi, "")?.replace(/&nbsp;/g, " ")
      );
    })();
  }, [name, COIN]);
  useEffect(() => {
    setDescriptionStatus({
      ...descriptionStatus,
      length: coinDescription.length,
    });
  }, [coinDescription]);
  useEffect(() => {
    if (descriptionStatus.length > 200) {
      setDescriptionStatus({ ...descriptionStatus, visible: true });
    }
  }, [descriptionStatus.length]);
  let stockOptions = {};
  if (!isEmpty(COIN)) {
    const stockData = COIN.klines;
    const data = stockData.map((stock) => {
      return [stock[0] * 1000, parseFloat(stock[1])];
    });
    stockOptions = {
      navigation: {
        bindingsClassName: "tools-container", // informs Stock Tools where to look for HTML elements for adding technical indicators, annotations etc.
      },

      stockTools: {
        gui: {
          enabled: false, // disable the built-in toolbar
        },
      },

      series: [
        {
          id: name,
          name: name + "USDT",
          data: data,
          tooltip: {
            valueDecimals: 5,
          },
        },
      ],
    };
  }
  return (
    <>
      {!isEmpty(COIN) && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            ارزفی - {COIN.name} - خرید {COIN.faName} , فروش {COIN.faName}, تبدیل{" "}
            {COIN.faName}
          </title>
          <link
            rel="canonical"
            href={`https://arzfi.com/market/about/${COIN.symbol}`}
          />
          <meta
            name="description"
            content={`خرید و فروش و تبدیل ارز ${COIN.faName} به بیش از ۵۰۰ ارز دیچیتال
            قیمت ${COIN.faName} 
            نمودار قیمت ${COIN.faName}
            ${COIN.name}
            `}
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="Arzfi.com" />
          <meta name="twitter:creator" content="@ArzfiEx" />
          <meta property="og:type" content="website" />
          <meta
            name="keywords"
            content={`خرید ${COIN.faName}, فروش ${COIN.faName}, تبدیل ${COIN.faName}, ${COIN.name}, کیف پول`}
          />
        </Helmet>
      )}
      <div className={styles.container}>
        <div className={styles.back_icon}>
          <img
            src={ArrowBackIcon}
            alt="BACK"
            className="convertPicToWhite"
            onClick={() => history.goBack()}
          />
        </div>
        <div className={styles.coin_pic}>
          <img
            src={`${serviceConfig.image_url}/${COIN?.symbol}.png`}
            alt={COIN?.symbol}
            width={45}
            height={45}
          />
        </div>
        {!isEmpty(COIN) && (
          <section className={`highChartDark ${styles.place_of_chart}`}>
            <PriceChart
              options={stockOptions}
              highcharts={Highcharts}
              style={{ maxWidth: "100%" }}
            />
          </section>
        )}
        <section className={styles.select_buy_or_sell}>
          <Link to={`/buy/${COIN?.symbol}`}>
            <button className={styles.buy_btn}>
              <strong>خرید {COIN?.faName}</strong>
            </button>
          </Link>
          <Link to={`/sell/${COIN?.symbol}`}>
            <button className={styles.sell_btn}>
              <strong>فروش {COIN?.faName}</strong>
            </button>
          </Link>
        </section>
        <h1 itemProp="name" className={`font_color_white ${styles.coinNameFa}`}>
          {COIN?.faName}
        </h1>
        <div className={`font_color_white ${styles.coin_description}`}>
          <div
            className={
              descriptionStatus.visible ? styles.hidden_other_text : ""
            }
            dangerouslySetInnerHTML={{ __html: coinDescription }}
          />
        </div>
        {descriptionStatus.visible && (
          <div className={styles.blur_read_more}>
            <div className={styles.blur}></div>
            <div
              itemProp="description"
              className={styles.readFullContent}
              onClick={() =>
                setDescriptionStatus({ ...descriptionStatus, visible: false })
              }
            >
              ادامه ی مطلب
            </div>
          </div>
        )}
      </div>
    </>
  );
}
