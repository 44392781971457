import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
//# PIC
import WaningIcon from "../../styles/icon/warning.svg";
//# STYLES
import styles from "./Box.module.scss";
export default function WarningBox(props) {
  const { children } = props;
  return (
    <Stack className={styles.warning_container}>
      <Typography component="span" className={styles.icon}>
        <img src={WaningIcon} alt="" width={20} height={20} />
      </Typography>
      <Typography component="p" className={styles.title}>
        {children}
      </Typography>
    </Stack>
  );
}
