import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { purple } from "@mui/material/colors";

const GreenButtonMui = styled(Button)(({ theme }) => ({
  fontFamily: "IranSansWeb",
  color: theme.palette.getContrastText(purple[500]),
  boxShadow: "none",
  backgroundColor: "#00c194",
  borderRadius: "3px",
  "&:hover": {
    backgroundColor: "#0aab6d",
  },
  "&:disabled": {
    backgroundColor: "rgba(190, 190, 190, 0.2)",
  },
}));
const RedButtonMui = styled(Button)(({ theme }) => ({
  fontFamily: "IranSansWeb",
  color: theme.palette.getContrastText(purple[500]),
  boxShadow: "none",
  borderRadius: "3px",
  backgroundColor: "#fd5353",
  "&:hover": {
    backgroundColor: "rgba(253, 65, 65, 1)",
  },
  "&:disabled": {
    backgroundColor: "rgba(190, 190, 190, 0.2)",
  },
}));
export function SuccessMuiBtn(props) {
  return (
    <GreenButtonMui
      variant="contained"
      fullWidth
      onClick={props.onClick}
      disabled={props.disabled}
      sx={props.sx}
      type={props.type}
    >
      {props.text}
    </GreenButtonMui>
  );
}
export function DangerMuiBtn(props) {
  return (
    <RedButtonMui
      variant="contained"
      fullWidth
      onClick={props.onClick}
      disabled={props.disabled}
      type={props.type}
      sx={props.sx}
    >
      {props.text}
    </RedButtonMui>
  );
}
