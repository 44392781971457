export default function registerValidSW(params) {
  navigator.serviceWorker
    .getRegistration(window.location.origin)
    .then((swReg) => {
      if (swReg) {
        navigator.serviceWorker.addEventListener("controllerchange", () => {
          window.swUpdateReady = true;
        });
      }
    });
}
