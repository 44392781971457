import { Stack } from "@mui/system";
import { memo } from "react";
import { ProgressBar } from "react-loader-spinner";

function ProgressLoading() {
  return (
    <Stack
     alignItems="center" width={1}>
      <ProgressBar
        height="60"
        width="60"
        ariaLabel="progress-bar-loading"
        wrapperClass="progress-bar-wrapper"
        borderColor="var(--secondary-color)"
        barColor="var(--primary-color)"
      />
    </Stack>
  );
}
export default memo(ProgressLoading);
