import { Stack } from "@mui/system";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { memo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PrimarySwiper from "../../../components/Swipers/PrimarySwiper";

//# STYLES
import styles from "./TopCoins.module.scss";
function TopCoins() {
  const MarketFromAPI = useSelector((state) => state.market.data);
  const socketReducer = useSelector((state) => state.appSocket);

  //# Function To Calulate W/WebSocket
  const handleShowPrice = (symbol) => {
    if (!isEmpty(socketReducer)) {
      if (socketReducer[symbol + "USDT"]) {
        return socketReducer[symbol + "USDT"].last;
      } else {
        return "...";
      }
    } else {
      return "...";
    }
  };

  const ITEMS = useMemo(() => {
    if (!isEmpty(MarketFromAPI)) {
      const DataWithOutStaticCoin = MarketFromAPI.filter(
        (c) => c.symbol !== "USDT"
      );
      const GROUP_ONE = DataWithOutStaticCoin.slice(0, 3);
      const GROUP_TWO = DataWithOutStaticCoin.slice(4, 7);
      const GROUP_THREE = DataWithOutStaticCoin.slice(8, 11);
      return [
        <div className={styles.item_container} data-value="1">
          {GROUP_ONE.map((ctx, idx) => {
            return (
              <Link
                to={`/market/trade?market=${ctx["symbol"] + "USDT"}`}
                key={ctx["symbol"]}
              >
                <div className={styles.item}>
                  <div className={styles.top}>
                    <strong>{`${ctx["symbol"]}/USDT`}</strong>
                    <span data-positive={ctx["percent_change_24h"] >= 0}>
                      {ctx["percent_change_24h"]}%
                    </span>
                  </div>
                  <p>{handleShowPrice(ctx["symbol"])}</p>
                </div>
              </Link>
            );
          })}
        </div>,
        <div className={styles.item_container} data-value="1">
          {GROUP_TWO.map((ctx, idx) => {
            return (
              <Link
                to={`/market/trade?market=${ctx["symbol"] + "USDT"}`}
                key={ctx["symbol"]}
              >
                <div className={styles.item}>
                  <div className={styles.top}>
                    <strong>{`${ctx["symbol"]}/USDT`}</strong>
                    <span data-positive={ctx["percent_change_24h"] >= 0}>
                      {ctx["percent_change_24h"]}%
                    </span>
                  </div>
                  <p>{handleShowPrice(ctx["symbol"])}</p>
                </div>
              </Link>
            );
          })}
        </div>,
        <div className={styles.item_container} data-value="1">
          {GROUP_THREE.map((ctx, idx) => {
            return (
              <Link
                to={`/market/trade?market=${ctx["symbol"] + "USDT"}`}
                key={ctx["symbol"]}
              >
                <div className={styles.item}>
                  <div className={styles.top}>
                    <strong>{`${ctx["symbol"]}/USDT`}</strong>
                    <span data-positive={ctx["percent_change_24h"] >= 0}>
                      {ctx["percent_change_24h"]}%
                    </span>
                  </div>
                  <p>{handleShowPrice(ctx["symbol"])}</p>
                </div>
              </Link>
            );
          })}
        </div>,
      ];
    }
    return [];
  }, [socketReducer, MarketFromAPI]);

  return (
    <Stack className={styles.container}>
      <PrimarySwiper items={ITEMS} inerval={4000} />
    </Stack>
  );
}
export default memo(TopCoins);
