import { commafy } from "./addComma";
export const showBetterPrice = (price) => {
  const number = price?.toString()?.split(".");
  if (number[1]) {
    if (number[0]?.length >= 4) {
      return number[0] + "." + number[1]?.slice(0, 3);
    }
    if (number[0]?.length >= 2) {
      return number[0] + "." + number[1]?.slice(0, 5);
    }
    if (number[0]?.length === 1 && number[0] >= 1) {
      return number[0] + "." + number[1]?.slice(0, 5);
    }
    if (number[0]?.length === 1 && number[0] < 1) {
      return number[0] + "." + number[1]?.slice(0, 9);
    }
    if (number[1]?.slice(0, 2) > 1) {
      return number[0] + "." + number[1]?.slice(0, 7);
    }
    if (number[1]?.slice(0, 4) > 1) {
      return number[0] + "." + number[1]?.slice(0, 9);
    }
  }
  return price;
};

export const showBetterCoinBalance = (price) => {
  const cPrice = price?.toString()?.split(".");
  if (cPrice && cPrice[1] == 0) {
    return cPrice[0];
  }
  return price;
};

export const showBetterUsdtBalance = (price) => {
  const cPrice = price?.toString()?.split(".");
  if (cPrice[1])
    if (cPrice[1]?.length > 9) {
      return cPrice[0] + "." + cPrice[1].slice(0, 8);
    }
  return price;
};

export const showPriceRate = (value) => {
  const _number = Number(value);
  if (isNaN(_number)) return 0;
  if (_number.toString().includes("e")) {
    return 0;
  }
  if (_number > 1) {
    return commafy(parseFloat(_number.toFixed(2)));
  }
  return parseFloat(_number.toFixed(6));
};

export function NumberHasDecimal(n) {
  let result = n - Math.floor(n) !== 0;
  if (result) return true;
  else return false;
}