import styles from "./Button3.module.scss";

export default function Button3(props) {
  const { value, setValue, text, active, sx } = props;
  const handleChangeValue = (e) => {
    setValue(e.target.value);
  };
  return (
    <button
      style={sx}
      className={`${styles.buttnon3} 
      ${
        value !== active
          ? "changeBackgroundColorToHardBlack change_color_to_9D9EA8"
          : ""
      }
      
      `}
      value={value}
      disabled={value === active}
      onClick={handleChangeValue}
    >
      {text}
    </button>
  );
}
