import { IconButton } from "@mui/material";
import React from "react";
import { memo } from "react";
import { useHistory } from "react-router";
import appIcons from "../../utils/appIcons";

import styles from "./Headers.module.scss";
const PrimaryHeader = (props) => {
  const history = useHistory();
  const { title = "", fixButton = false } = props;

  const onPressGoBack = () => {
    history.goBack();
  };
  return (
    <div className={styles.container}>
      <div className={styles.svgPlace} data-fix={fixButton}>
        <IconButton onClick={onPressGoBack} sx={{ fontSize: "22px" }}>
          {appIcons("ArrowBackIcon", { fontSize: "inherit" })}
        </IconButton>
      </div>
      {title && (
        <div>
          <strong>{title}</strong>
        </div>
      )}
    </div>
  );
};

export default memo(PrimaryHeader);
