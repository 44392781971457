import { useContext, useState } from "react";
import { context } from "../../../context/context";
import { useHistory } from "react-router";
//Components
import TextField from "@mui/material/TextField";
import RTL from "../../../helpers/direction/RTL";
//Styles and Icons
import UserIcon from "../../../styles/icon/person_outline.svg";
import styles from "./RecoveryPass.module.scss";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
export default function RecoveryMobile() {
  const [checkFocuse, setCheckFocuse] = useState("");
  const registerCTX = useContext(context);
  const {
    numRecovery,
    setNumRecovery,
    handleRecoveryNum,
    validatorRecovery,
    setValidatorRecovery,
    buttonDisabled,
    setCaptchaDetails,
    captchaDetails,
  } = registerCTX;

  const history = useHistory();
  return (
    <>
      <form onSubmit={(e) => handleRecoveryNum(e)}>
        <section className={styles.inputs_place}>
          <div
            className={`changeTextFieldBackgroundToWBlack ${styles.mobile_place}`}
          >
            <RTL>
              <TextField
                name="numRecovery"
                label="شماره موبایل"
                color="success"
                inputProps={{ inputMode: "decimal" }}
                onFocus={() => setCheckFocuse("mobile")}
                onBlur={() => setCheckFocuse("")}
                value={numRecovery}
                className={styles.number_login_input}
                onChange={(e) => {
                  setNumRecovery(e.target.value);
                  setValidatorRecovery({ ...validatorRecovery, number: "" });
                }}
              />

              <div
                className={`${styles.icon_place} ${styles.first_input_icon} ${
                  checkFocuse === "mobile" ? styles.icon_place_active : ""
                }`}
              >
                <img src={UserIcon} alt="" className="convertPicToGreen" />
              </div>
              <div className={styles.errorMessage}>
                {validatorRecovery?.number}
              </div>
            </RTL>
            <GoogleReCaptcha
              className="google-recaptcha-custom-class"
              onVerify={(t) =>
                setCaptchaDetails((prev) => ({ ...prev, token: t }))
              }
              refreshReCaptcha={captchaDetails.refresh}
            />
          </div>
        </section>

        <div className={styles.button_container}>
          <button
            type="submit"
            className={styles.continue_btn}
            disabled={buttonDisabled}
          >
            ادامه
          </button>
        </div>
      </form>
      <div className={styles.button_container}>
        <button
          disabled={buttonDisabled}
          className={styles.back_btn}
          onClick={() => history.push("/authorization/login")}
        >
          بازگشت
        </button>
      </div>
    </>
  );
}
