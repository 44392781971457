//* --> Styles
import { useContext } from "react";
import { tradesCTX } from "../../../context/tradesCTX";
import styles from "./PercentTrade.module.scss";

export default function PercentTrade() {
  const OrdersContext = useContext(tradesCTX);
  const { percentChanges } = OrdersContext;

  return (
    <section
      className={`border-blue border-radius-3 --bg-trade_w_b ${styles.PercentTrade_container}`}
    >
      <div className={styles.percent_show}>
        <div className={`color_green_STATIC eng-number-font ${styles.percent_buy}`}>
          <div className={styles.percent_buy_show}>{percentChanges.buy}%</div>
        </div>
        <div className={`color_red_STATIC eng-number-font ${styles.percent_sell}`}>
          <div className={styles.percent_sell_show}>{percentChanges.sell}%</div>
        </div>
      </div>
      <div className={styles.line_show}>
        <div
          className={`bg_red_STATIC ${styles.negative}`}
          style={{ width: percentChanges.sell + "%" }}
        ></div>
        <div
          className={`${styles.positive}`}
          style={{ width: percentChanges.buy + "%" }}
        ></div>
      </div>
    </section>
  );
}
