import React from "react";
import { Box, Stack } from "@mui/material";
import InputViaButton from "../Common/InputViaButton";
import styles from "./WithdrawalCoinPage.module.scss";
import { useState } from "react";
import PaperButton from "../../components/Buttons/PaperButton";
import MessageModal from "../Common/MessageModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { isEmpty } from "lodash";
import { EXCEPTEC_ERRORS_WIH } from "./states";
import Confirm2FaCode from "./Confirm2FaCode";
import { useSelector } from "react-redux";

function InternalTransferView({ state, dispatch }) {
  const history = useHistory();
  const [viewStates, setViewStates] = useState({
    amount: "",
    email: "",
  });
  const userPayments = useSelector((state) => state.userPaymentValidity);
  //# A >Excepted Error Via Modal
  //# A >1.Page Load Error, 2.Can't Withdrawal Error
  const [withdrawalErrors, setWithdrawalErrors] = useState([]);
  const [openTwoFacCode, setOpenTwoFacCode] = useState(false);

  const onRequestAllBalance = () => {
    setViewStates((prev) => ({
      ...prev,
      amount: parseFloat(state["asset"]["balance"] - state["asset"]["locked"]),
    }));
  };
  const onUpdateViewStates = (e, target) => {
    setViewStates((prev) => ({ ...prev, [target]: e.target.value }));
  };
  const onRequestPaste = async () => {
    if (navigator) {
      await navigator.clipboard
        .readText()
        .then((res) => {
          setViewStates((prev) => ({ ...prev, email: res }));
        })
        .catch((error) => console.log(error));
    }
  };
  const handleRequestRefershPage = () => {
    return dispatch({ type: "REFRESH" });
  };
  //# Check Details For Withdrawal ##
  const handleCheckWithdrawal = () => {
    const sumErros = [];
    if (!state["gaCode"]) sumErros.push("gaCode");
    if (
      userPayments.isFetched &&
      !userPayments.documentStatus &&
      userPayments.rialPayment
    )
      sumErros.push("document");
    if (!viewStates["amount"]) sumErros.push("amount");
    if (!viewStates["email"]) sumErros.push("email");

    if (
      Number(viewStates["amount"]) >
      parseFloat(state["asset"]["balance"] - state["asset"]["locked"])
    ) {
      sumErros.push("yourBalance");
    }
    if (sumErros[0]) {
      setWithdrawalErrors(
        sumErros.map((c) => {
          return EXCEPTEC_ERRORS_WIH[c];
        })
      );
    } else {
      setOpenTwoFacCode(true);
    }
  };

  const handleResetErrors = () => {
    setWithdrawalErrors([]);
  };

  return (
    <>
      <Stack>
        <Box mb={1.5}>
          <InputViaButton
            buttonTitle="کل موجودی"
            placeholder="مقدار برداشتی"
            type="number"
            onClickButton={onRequestAllBalance}
            onChange={(e) => onUpdateViewStates(e, "amount")}
            value={viewStates["amount"]}
          />
        </Box>
        <Box mb={1.5}>
          <InputViaButton
            buttonTitle="چسباندن"
            placeholder="آدرس ایمیل"
            type="email"
            onClickButton={onRequestPaste}
            onChange={(e) => onUpdateViewStates(e, "email")}
            value={viewStates["email"]}
          />
        </Box>
        <ul className={styles.overNotifContainer}>
          <li>
            با داشتن ایمیل دوستان خود ارزهای خود را در شبکه داخلی ارزفی انتقال
            دهید
          </li>
          <li>
            دقت کنید برداشت ارز به آدرس ایمیل صحیح انجام شود. ارزفی هیچگونه
            مسيولیتی در قبال از دست رفتن سرمایه شما ندارد
          </li>
        </ul>
        <Box mb={3}>
          <PaperButton
            type="success"
            style={{ zIndex: "2", height: "40px" }}
            onClick={handleCheckWithdrawal}
          >
            برداشت
          </PaperButton>
        </Box>
      </Stack>
      {state["isError"] && (
        <MessageModal type="error" title="مشکل در دریافت اطلاعات">
          <Stack sx={{ direction: "rtl" }}>
            <p style={{ textAlign: "center", marginBottom: 15, fontSize: 15 }}>
              مشکلی در دریافت اطلاعات وجود دارد, اینترنت خود را چک کنید یا با
              پشتیبانی ارتباط برقرار کنید.
            </p>
            <Stack flexDirection="row" columnGap={1}>
              <PaperButton onClick={handleRequestRefershPage}>
                تلاش مجدد
              </PaperButton>
              <PaperButton
                type="error"
                onClick={() => history.replace("/my-wallet")}
              >
                بازگشت
              </PaperButton>
            </Stack>
          </Stack>
        </MessageModal>
      )}
      {!isEmpty(withdrawalErrors) && (
        <MessageModal type="error" title="ورودی های نامعتبر">
          <ol className={styles.withdrawErrorContainer}>
            {withdrawalErrors.map((ctx, idx) => {
              return <li key={idx}>{ctx}</li>;
            })}
          </ol>
          <div className={styles.btnResetErrors}>
            <PaperButton onClick={handleResetErrors}>متوجه شدم</PaperButton>
          </div>
        </MessageModal>
      )}
      {openTwoFacCode && (
        <Confirm2FaCode
          state={state}
          dispatch={dispatch}
          viewStates={viewStates}
          setViewStates={setViewStates}
          setOpen={setOpenTwoFacCode}
          external={false}
        />
      )}
    </>
  );
}

export default InternalTransferView;
