import { FetchUnexecutedOrdersList } from "../services/userServices";

export const GetUnexecutedOrders = (market) => {
  return async (dispatch) => {
    const { data } = await FetchUnexecutedOrdersList(market);
    dispatch({ type: "GET_USER_UNEXECUTED_ORDERS", payload: data });
  };
};

export const UpdateUnexecutedOrder = (data) => {
  return (dispatch) => {
    dispatch({ type: "UPDATE_USER_UNEXECUTED_ORDERS", payload: data });
  };
};
