import { memo, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { coinList } from "../../actions/market";
import { useMemo } from "react";
import styles from "./AssetsListModal.module.scss";
import { isEmpty } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import ImagePreview from "../../utils/ImagePreview";
import { IconButton, Stack, Typography } from "@mui/material";
import appIcons from "../../utils/appIcons";
import useDebounce from "../../hooks/useDebounce";

function AssetsListModal(props) {
  const {
    visible = false,
    onSelectSymbol,
    disabledSymbols = [],
    onRequestClose,
    activeSymbol = "",
    hasRial = false,
  } = props;
  const ref = useRef();
  //# Maybe Emtpy, Maybe Not (Must be Check)
  const AssetsListReducer = useSelector((state) => state.coins);
  const [pageProps, setPageProps] = useState({
    search: "",
    showCounts: 20,
    isLoading: true,
  });
  //# Hooks
  const dispatch = useDispatch();
  const searchItems = useDebounce(pageProps["search"].toUpperCase(), 300);

  useLayoutEffect(() => {
    if (!!AssetsListReducer && AssetsListReducer.hasOwnProperty("data")) {
      setPageProps((prev) => ({
        ...prev,
        isLoading: false,
      }));
    } else {
      dispatch(coinList());
    }
  }, []);

  const handleShowNextItems = () => {
    setPageProps((prev) => ({ ...prev, showCounts: prev.showCounts + 20 }));
  };
  const handleChangeSearch = (e) => {
    setPageProps((prev) => ({ ...prev, search: e.target.value }));
  };
  const handleItemClicked = (item) => {
    if (
      item["symbol"] !== activeSymbol &&
      !disabledSymbols.includes(item["symbol"])
    ) {
      return onSelectSymbol(item);
    }
  };
  const SHOW_ITEMS = useMemo(() => {
    const values = { hasNext: false, items: [] };
    if (typeof AssetsListReducer.data === "object") {
      let currList = [...AssetsListReducer.data];
      if (hasRial) {
        if (currList[0].symbol !== "IRT") {
          currList.unshift({
            name: "Iranian Toman",
            symbol: "IRT",
            faName: "تومان",
          });
        }
      }
      values.items = currList
        .filter((ctx) => {
          return (
            ctx["symbol"].includes(searchItems) ||
            ctx["name"].includes(searchItems)
          );
        })
        .slice(0, pageProps["showCounts"]);
      if (values.items.length >= currList) {
        values.hasNext = false;
      } else {
        values.hasNext = true;
      }
    }
    return values;
  }, [AssetsListReducer, searchItems, pageProps["showCounts"], hasRial]);
  return (
    <div className={styles.container} data-visibility={visible}>
      <div className={styles.header}>
        <IconButton
          sx={{ marginLeft: "-8px" }}
          color="inherit"
          onClick={onRequestClose}
        >
          {appIcons("ArrowBackIcon", { htmlColor: "inherit" })}
        </IconButton>
        <input
          type="text"
          autoComplete="off"
          placeholder="جست و جو"
          value={pageProps["search"]}
          onChange={handleChangeSearch}
        />
      </div>
      <div className={styles.content}>
        {!isEmpty(AssetsListReducer) ? (
          !isEmpty(SHOW_ITEMS["items"]) ? (
            <div className="scroll-container" ref={ref}>
              <InfiniteScroll
                dataLength={SHOW_ITEMS["items"].length}
                height="calc(100vh - 85px)"
                next={handleShowNextItems}
                hasMore={SHOW_ITEMS["hasNext"]}
              >
                {SHOW_ITEMS["items"].map((coin, index) => {
                  return (
                    <div
                      key={coin["symbol"]}
                      className={styles.item}
                      data-active={activeSymbol === coin["symbol"]}
                      data-disabled={disabledSymbols.includes(coin["symbol"])}
                      onClick={() => handleItemClicked(coin)}
                    >
                      <div className={styles.pic}>
                        <span>{coin["symbol"]}</span>
                        <ImagePreview
                          image={coin["symbol"]}
                          width={30}
                          height={30}
                        />
                      </div>
                      <strong className={styles.name}>{coin["name"]}</strong>
                    </div>
                  );
                })}
              </InfiniteScroll>
            </div>
          ) : (
            <Stack textAlign="center" height="80%" justifyContent="center">
              <Typography
                sx={{
                  color: "var(--secondary-color)",
                  backgroundColor: "rgba(0,0,0,0.2)",
                }}
                paddingY={2}
                fontSize={14}
              >
                موردی یافت نشد
              </Typography>
            </Stack>
          )
        ) : (
          <Stack textAlign="center" height="80%" justifyContent="center">
            <Typography
              sx={{
                color: "var(--secondary-color)",
                backgroundColor: "rgba(0,0,0,0.2)",
              }}
              paddingY={2}
              fontSize={14}
            >
              در حال برروزرسانی
            </Typography>
          </Stack>
        )}
      </div>
    </div>
  );
}

export default memo(AssetsListModal);
