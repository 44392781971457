const getFavoriteCoins = () => {
  const data = JSON.parse(localStorage.getItem("favoriteCoins")) || [];
  return data;
};
const addCoinToLocalStorage = (array) => {
  localStorage.setItem("favoriteCoins", JSON.stringify(array));
};

export const favoriteCoins = () => {
  return async (dispatch) => {
    const data = await getFavoriteCoins();
    await dispatch({ type: "GET_FAV_COINS", payload: data });
  };
};
export const addFavoriteCoins = (name) => {
  return async (dispatch, getState) => {
    const inLocalCoins = [...getState().favCoins];
    const isAvailable = inLocalCoins.includes(name)
      ? inLocalCoins.filter((coin) => coin !== name)
      : [...inLocalCoins, name];
    await dispatch({ type: "ADD_FAV_COINS", payload: isAvailable });
    addCoinToLocalStorage(isAvailable);
  };
};
