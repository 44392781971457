import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { memo } from "react";

const HeadTabsFees = (props) => {
  const { PageButtons, tableView, setTableView } = props;
  return (
    <Stack
      sx={{
        borderBottom: "1px solid var(--border-color)",
      }}
      flexDirection="row-reverse"
      columnGap={3}
      mt={2}
    >
      {PageButtons.map((ctx, idx) => {
        const isActive = ctx["id"] === tableView;
        return (
          <Typography
            key={idx}
            component="strong"
            fontSize={14}
            borderBottom="2px solid"
            color={
              isActive ? "var(--primary-color)" : "var(--secondary3-color)"
            }
            borderColor={isActive ? "var(--primary-color)" : "transparent"}
            paddingBottom={1}
            sx={{
              cursor: "pointer",
              WebkitTapHighlightColor: "transparent",
            }}
            onClick={() => setTableView(ctx["id"])}
          >
            {ctx["title"]}
          </Typography>
        );
      })}
    </Stack>
  );
};

export default memo(HeadTabsFees);
