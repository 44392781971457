import { memo } from "react";
import { useSelector } from "react-redux";
import styles from "./NoOrders.module.scss";
import NoOrderLight from "./no_order_light.svg";
import NoOrderDark from "./no_order_dark.svg";
function NoOrders({ text }) {
  const appTheme = useSelector((state) => state.appTheme);
  return (
    <section className={styles.container}>
      <div className={styles.image}>
        {appTheme === "dark" ? (
          <img
            src={NoOrderDark}
            width={80}
            height={80}
            alt="not-found-orders"
          />
        ) : (
          <img
            src={NoOrderLight}
            width={80}
            height={80}
            alt="not-found-orders"
          />
        )}
      </div>
      <div className={`${styles.text} change_color_to_9D9EA8`}>{text}</div>
    </section>
  );
}
export default memo(NoOrders);
