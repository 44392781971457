import { memo, useMemo } from "react";
import { Link } from "react-router-dom";
import { addCommaWithRialPrice } from "../../../../../utils/addComma";
import appIcons from "../../../../../utils/appIcons";
import styles from "./HelperText.module.scss";

function HelperText1(props) {
  const { paySymbol, getSymbol, wage, pay, get, balance, wageSymbol } = props;

  const showNumber = (num) => {
    if (paySymbol === "IRT") return addCommaWithRialPrice(num ?? 0);
    return num;
  };

  const showGetPrice = useMemo(() => {
    const toNum = Number(get);
    if (isNaN(toNum)) {
      return 0;
    } else {
      if (String(get).includes("e")) return 0;
      if (get > 1000) {
        return get.toFixed(0);
      } else if (get > 1) {
        return get.toFixed(2);
      } else if (get <= 1 && get > 0.001) {
        return get.toFixed(3);
      } else if (get > 0.00001) {
        return get.toFixed(5);
      } else {
        return 0;
      }
    }
  }, [get]);
  return (
    <div className={`${styles.container} change_color_to_9D9EA8`}>
      <div className={styles.sections}>
        <div className={styles.title}>مقدار پرداختی:</div>
        <div className={styles.desc}>
          <span className="eng-number-font">{showNumber(pay)}</span>
          <span className={styles.symbols}>{paySymbol}</span>
        </div>
      </div>
      <div className={styles.sections}>
        <div className={styles.title}>
          کارمزد:
          <Link to="/fees">
            <span className={styles.infoIcon}>
              {appIcons("infoIcon", { fontSize: "inherit" })}
            </span>
          </Link>
        </div>
        <div className={styles.desc}>
          <span className="eng-number-font">{showNumber(wage)}</span>
          <span className={styles.symbols}>{wageSymbol}</span>
        </div>
      </div>
      <div className={styles.sections}>
        <div className={styles.title}>دریافتی تقریبی:</div>
        <div className={styles.desc}>
          <span className="eng-number-font">{showGetPrice}</span>
          <span className={styles.symbols}>{getSymbol}</span>
        </div>
      </div>
      <div className={styles.sections}>
        <div className={styles.title}>موجودی:</div>
        <div className={styles.desc}>
          <span className="eng-number-font">{showNumber(balance)}</span>
          <span className={styles.symbols}>{paySymbol}</span>
        </div>
      </div>
    </div>
  );
}
export default memo(HelperText1);
