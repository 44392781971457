import { usersReferralCodes } from "../../services/adminServices";

export const initialStates = {
  isLoading: true,
  isError: false,
  view: "overview",
  referralCodes: [],
  friends: [],
  vipStatus: {},
};

export const referralReducer = (state, action) => {
  switch (action.type) {
    case "VIEW":
      return { ...state, view: action.payload };
    case "INIT_SUCCESS":
      return { ...state, ...action.payload, isLoading: false, isError: false };
    case "INIT_FAILED":
      return { ...state, isError: true, isLoading: false };
    case "SHOW_LOADING":
      return { ...state, isLoading: true };
    case "HIDE_LOADING":
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export async function getReferralDetails(signal) {
  const res = {
    error: false,
    data: {},
  };
  try {
    const { data, status } = await usersReferralCodes(signal);
    if (status === 200) {
      res.data.vipStatus = data.level;
      res.data.referralCodes = data.referralAccounts;
      res.data.friends = data.users;
    } else {
      res.error = true;
    }
  } catch {
    res.error = true;
  } finally {
    return res;
  }
}
