import React from "react";
import styles from "./ReferralPage.module.scss";
import { Box, Stack } from "@mui/material";

function FriendsReferral({ state }) {
  console.log(state);
  return (
    <div className={styles.friendsContainer}>
      <p className={styles.title}>
        لیست دوستانی که از طرف شما دعوت شده اند را در این قسمت مشاهده کنید.
      </p>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        textAlign="center"
        columnGap={1}
      >
        <Box
          flex={1}
          color="var(--secondary-color)"
          sx={{ opacity: 0.7 }}
          fontSize={12}
        >
          ایمیل/موبایل
        </Box>
        <Box
          flex={1}
          color="var(--secondary-color)"
          sx={{ opacity: 0.7 }}
          fontSize={12}
        >
          پاداش
        </Box>
      </Stack>
      {state["friends"].map((ctx, idx) => {
        return (
          <Stack
            key={idx}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            textAlign="center"
            columnGap={1}
            fontSize={13.5}
            pt={2}
            pb={2}
            sx={{
              wordBreak: "break-all",
              borderBottom: "1px solid var(--border-color)",
            }}
          >
            <Box flex={1} color="var(--secondary-color)">
              {ctx["email"]}
            </Box>
            <Box
              flex={1}
              color="var(--secondary-color)"
              sx={{ fontFamily: "IranYekanBolds" }}
            >
              {ctx["totalPorsant"]}
            </Box>
          </Stack>
        );
      })}
    </div>
  );
}

export default FriendsReferral;
