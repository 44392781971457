import React from "react";
import { Box, Container, Stack } from "@mui/material";
import PrimaryHeader from "../components/Headers/PrimaryHeader";
import styles from "../pageComponents/WithdrawalCoinPage/WithdrawalCoinPage.module.scss";
import LoadingModal from "../components/Modals/LoadingModal";
import SymbolInfo from "../pageComponents/WithdrawalCoinPage/SymbolInfo";
import {
  TRANSFER_TYPE_BUTTONS,
  WithdrawReducer,
  getWithdrawalSymbolDetails,
  initialStates,
  symbolChainDetails,
} from "../pageComponents/WithdrawalCoinPage/states";
import { useReducer } from "react";
import InternalTransferView from "../pageComponents/WithdrawalCoinPage/InternalTransferView";
import ExternalTransferView from "../pageComponents/WithdrawalCoinPage/ExternalTransferView";
import { useLayoutEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ValidationUserDocsAction } from "../actions/ValidationUserDocsAction";

function WithdrawalCoin() {
  const [state, dispatch] = useReducer(WithdrawReducer, initialStates);
  const dispatchStore = useDispatch();
  const userPayments = useSelector((state) => state.userPaymentValidity);
  const { name: symbolName } = useParams();

  useLayoutEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get("wth") === "internal") {
      dispatch({ type: "CHANGE_VIEW", payload: "internal" });
    }
    if (!userPayments.isFetched) {
      dispatchStore(ValidationUserDocsAction());
    }
  }, []);
  useLayoutEffect(() => {
    const controller = new AbortController();
    (async () => {
      const [walletResponse, chainResponse] = await Promise.all([
        getWithdrawalSymbolDetails(symbolName, controller.signal),
        symbolChainDetails(symbolName, controller.signal),
      ]);
      if (walletResponse["error"] || chainResponse["error"]) {
        return dispatch({ type: "INIT_FAILED" });
      }
      return dispatch({
        type: "INIT_SUCCESS",
        payload: {
          wallet: walletResponse["data"],
          chains: chainResponse["data"],
        },
      });
    })();

    return () => {
      controller.abort();
    };
  }, [symbolName, state["refreshCount"]]);

  //# Function's
  const handleUpdateAsset = async () => {
    dispatch({ type: "LOADING", payload: true });
    const response = await getWithdrawalSymbolDetails(symbolName);
    if (response["error"]) {
      return dispatch({ type: "LOADING", payload: false });
    }
    dispatch({ type: "UPDATE", payload: response["data"] });
    // SetTimeOut for Prevent Referesh Every MiliSecond's
    setTimeout(() => {
      dispatch({ type: "LOADING", payload: false });
    }, 1000);
  };

  return (
    <>
      <Container disableGutters>
        <Box px={1}>
          <PrimaryHeader fixButton title="برداشت ارز" />
        </Box>
        <Stack component="main" className={styles.mainView}>
          <Box sx={{ marginTop: "-30px" }}>
            <SymbolInfo state={state} onRequestUpdate={handleUpdateAsset} />
          </Box>
          <Stack
            className={styles.buttonOfTransfers}
            flexDirection="row-reverse"
            alignItems="flex-end"
            justifyContent="space-between"
            marginY={1}
          >
            <Box>
              {TRANSFER_TYPE_BUTTONS.map((btn) => {
                return (
                  <button
                    key={btn["id"]}
                    data-active={btn["id"] === state["view"]}
                    onClick={() =>
                      dispatch({ type: "CHANGE_VIEW", payload: btn["id"] })
                    }
                  >
                    {btn["name"]}
                  </button>
                );
              })}
            </Box>
          </Stack>
          <Box>
            {state["view"] === "internal" ? (
              <InternalTransferView state={state} dispatch={dispatch} />
            ) : (
              <ExternalTransferView state={state} dispatch={dispatch} />
            )}
          </Box>
        </Stack>
      </Container>
      <LoadingModal visible={state["isLoading"]} />
    </>
  );
}

export default WithdrawalCoin;
