import { combineReducers } from "redux";
import { favoriteCoinsReducer } from "./favoriteCoinsReducer";
import { CoinListReducer, marketReducer } from "./market";
import { userId } from "./userIdReducer";
import { coinReducer } from "./coinReducer";
import { loadingPageReducer } from "./loadingPageReducer";
import { fullUserInfoReducer } from "./fullUserInfoReducer";
import { ticketsTextReducer } from "./ticketsTextReducer";
import { coinNetworkReducer } from "./coinNetworkReducer";
import { applicationTheme } from "./applicationTheme";
import { applicationMenuConfig } from "./applicationMenuConfig";
import { tradesListReducer } from "./tradesListReducer";
import { UsersOrdersListReducer } from "./UsersOrdersListReducer";
import { UsersStopOrdersListReducer } from "./UsersStopOrdersListReducer";
import { UsersUnexecutedOrdersReducer } from "./UsersUnexecutedOrdersReducer";
import { applicationUserPrice } from "./applicationChoicePrice";
import { appSocketPrice } from "./appSocketPrice";
import { appSocketInfo } from "./appSocketInfo";
import { reassignSocketObject } from "./reassignSocketObject";
import { appSocketBuyDepth } from "./appSocketBuyDepth";
import { appSocketSellDepth } from "./appSocketSellDepth";
import { applicationBanners } from "./applicationBanners";
import { userTransactionsReducer } from "./userTransactionsReducer";
import { userVerifyReducer } from "./userVerifyReducer";
import { routeStateCashReducer } from "./routeStateCashReducer";
import { applicationMiniBanners } from "./applicationMiniBanners";
import { appUserDocumentsInfo } from "./appUserDocumentsInfo";
export const reducers = combineReducers({
  userId,
  market: marketReducer,
  coins: CoinListReducer,
  favCoins: favoriteCoinsReducer,
  coinInfo: coinReducer,
  loadingBar: loadingPageReducer,
  fullUserInfo: fullUserInfoReducer,
  ticketText: ticketsTextReducer,
  network: coinNetworkReducer,
  appTheme: applicationTheme,
  menuConfig: applicationMenuConfig,
  tradesList: tradesListReducer,
  unExecutedList: UsersUnexecutedOrdersReducer,
  stopOrdersList: UsersStopOrdersListReducer,
  marketOrdersList: UsersOrdersListReducer,
  appPrice: applicationUserPrice,
  appSocket: appSocketPrice,
  appSocketInfo,
  reassignSocketObject,
  appSocketBuyDepth,
  appSocketSellDepth,
  applicationBanners,
  userTransactionsReducer,
  userVerifyReducer,
  cashRoutes: routeStateCashReducer,
  miniBanners: applicationMiniBanners,
  userPaymentValidity: appUserDocumentsInfo,
});
