import React from "react";
import BottomModal from "../../components/Modals/BottomModal";
import { Stack } from "@mui/material";
import styles from "./ModalViewSelectItem.module.scss";
import appIcons from "../../utils/appIcons";

export default function ModalViewSelectItem(props) {
  const {
    visible,
    onRequestClose,
    items = [],
    selected = "",
    onChange,
  } = props;
  // items includes id, title, icon?

  const handleSelectedItem = (id) => {
    if (id === selected) return onRequestClose();
    return onChange(id);
  };
  return (
    <BottomModal visible={visible} onRequestClose={onRequestClose}>
      <Stack
        paddingY={3}
        sx={{
          maxHeight: "300px",
          overflow: "auto",
          background: "var(--third-color)",
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
        }}
      >
        {items.map((ctx) => {
          return (
            <div
              className={styles.item}
              data-active={selected === ctx["id"]}
              key={ctx["id"]}
              onClick={() => handleSelectedItem(ctx["id"])}
            >
              <span>{ctx["title"]}</span>
              <span data-icon="true">
                {appIcons(ctx["icon"], {
                  color: "inherit",
                  fontSize: "inherit",
                })}
              </span>
            </div>
          );
        })}
      </Stack>
    </BottomModal>
  );
}
