import { isEmpty } from "lodash";
import { useRef } from "react";
import { useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  ShowJalaliMoment,
  convertJalaliToMilad,
} from "../../utils/convertMoment";

import styles from "./ViewList.module.scss";
import { DepWthStatus, transferTypes } from "../../utils/_appStatus";
import { IconButton, Stack, Typography } from "@mui/material";
import appIcons from "../../utils/appIcons";
import { ThreeDots } from "react-loader-spinner";
import PaperButton from "../../components/Buttons/PaperButton";
import { copyTextViaNavigator } from "../../utils/UI_utilities";
function ViewList(props) {
  const { state, handleShowNextItems, PageItems, onClickRefreshDetails } =
    props;
  const ref = useRef();
  //# Controller's
  const SHOW_ITEMS = useMemo(() => {
    const values = { hasNext: false, items: [] };
    if (state["itemsList"][0]) {
      const simpleFiltered = state.itemsList.filter((ctx) => {
        return (
          ctx["coinName"].includes(state["filter"]["name"]) &&
          ctx["type"].includes(state["filter"]["type"])
        );
      });
      if (state["filter"]["startDate"]) {
        const stDate = convertJalaliToMilad(state["filter"]["startDate"]);
        const endDate = convertJalaliToMilad(state["filter"]["endDate"]);
        const ls = simpleFiltered.filter(
          (c) => c.createdAt >= stDate && c.createdAt <= endDate
        );
        values.items = ls.slice(0, state["showCount"]);
      } else {
        values.items = simpleFiltered.slice(0, state["showCount"]);
      }
      values.hasNext = values.items.length < state["itemsList"].length;
      return values;
    }
    return values;
  }, [state["filter"], state["itemsList"], state["showCount"]]);

  const onRequestCopyTxID = (val) => {
    copyTextViaNavigator(val, "TxID Copied");
  };
  return (
    <div>
      {!state["isLoading"] && !state["isError"] ? (
        !isEmpty(SHOW_ITEMS["items"]) ? (
          <div className="scroll-container" ref={ref}>
            <InfiniteScroll
              dataLength={SHOW_ITEMS["items"].length}
              next={handleShowNextItems}
              hasMore={SHOW_ITEMS["hasNext"]}
              height="calc(100vh - 200px)"
              endMessage={<span style={{ marginBlock: "100px" }}></span>}
            >
              {SHOW_ITEMS["items"].map((ctx, index) => {
                const status = DepWthStatus[ctx["status"]];
                const currItem = PageItems.filter(
                  (c) => c.id === ctx["type"]
                )[0];
                return (
                  <div key={ctx["createdAt"]} className={styles.item}>
                    <div className={styles.top_item}>
                      <span className={styles.type} data-type={ctx["type"]}>
                        {currItem?.title}
                      </span>
                      <span
                        className={styles.status}
                        data-status={status?.type ?? "danger"}
                      >
                        {status?.name}
                      </span>
                    </div>
                    <div className={styles.flexItems}>
                      <span>{ShowJalaliMoment(ctx["createdAt"])}</span>
                      <span>{ctx["coinName"]}</span>
                    </div>
                    <div className={styles.flexItems}>
                      <span data-color={ctx["type"]}>
                        {parseFloat(ctx?.amount ?? 0)}
                      </span>
                      <span>مقدار</span>
                    </div>
                    <div className={styles.flexItems}>
                      <span>
                        {ctx.address ? ctx.address : ctx?.walletAddress ?? "-"}
                      </span>
                      <span>آدرس</span>
                    </div>
                    {ctx["txId"] && (
                      <div className={styles.flexItems}>
                        <span>{ctx["txId"]}</span>
                        <span>
                          <IconButton
                            size="small"
                            color="success"
                            onClick={() => onRequestCopyTxID(ctx["txId"])}
                          >
                            {appIcons("copyIcon", { fontSize: "inheirt" })}
                          </IconButton>
                          TxID
                        </span>
                      </div>
                    )}
                    {ctx["transferType"] && (
                      <div className={styles.flexItems}>
                        <span>{transferTypes[ctx["transferType"]] ?? "-"}</span>
                        <span>نوع انتقال</span>
                      </div>
                    )}
                    {ctx["protocol"] && (
                      <div className={styles.flexItems}>
                        <span>{ctx["protocol"]}</span>
                        <span>شبکه</span>
                      </div>
                    )}
                    {ctx["explorer"] && (
                      <div className={styles.flexItems}>
                        <span>
                          <a
                            href={ctx["explorer"]}
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: "var(--primary-color)" }}
                          >
                            {ctx["explorer"]}
                          </a>
                        </span>
                        <span>جست و جو</span>
                      </div>
                    )}
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        ) : (
          <Stack
            alignItems="center"
            justifyContent="center"
            height="calc(100vh - 300px)"
          >
            <Typography
              sx={{ backgroundColor: "var(--dangerOpacity-color)" }}
              width={1}
              color="var(--danger-color)"
              borderRadius={0.5}
              paddingY={1}
              textAlign="center"
              fontSize={14}
            >
              اطلاعاتی یافت نشد
            </Typography>
          </Stack>
        )
      ) : state["isError"] ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          height="calc(100vh - 300px)"
        >
          <Typography color="var(--danger-color)" fontSize={14}>
            مشکلی در دریافت اطلاعات رخ داده است
          </Typography>
          <Stack mt={2}>
            <PaperButton onClick={onClickRefreshDetails}>تلاش مجدد</PaperButton>
          </Stack>
        </Stack>
      ) : (
        <Stack
          alignItems="center"
          justifyContent="center"
          height="calc(100vh - 300px)"
        >
          <ThreeDots color="var(--primary-color)" width={40} />
        </Stack>
      )}
    </div>
  );
}

export default ViewList;
