import { usersProfile } from "../../services/userServices";

export const initialStates = {
  isLoading: true,
  isError: false,
  details: {},
};

export function reducer(state, action) {
  switch (action.type) {
    case "INIT_FAILED":
      return { ...state, isError: true };
    case "INIT_SUCCESS":
      return { ...state, isLoading: false, details: action.payload };
    case "REFRESH":
      return { ...state, isLoading: true, isError: false };

    default:
      return state;
  }
}
export const fetchUserDetails = async (signal) => {
  const result = {
    data: {},
    error: false,
  };
  try {
    const response = await usersProfile(signal);
    if (response.status === 200) {
      result.data = response.data.user;
    } else {
      result.error = true;
    }
  } catch (ex) {
    result.error = true;
  } finally {
    return result;
  }
};
