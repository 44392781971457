import { Link } from "react-router-dom";
import styles from "./LoginRegister.module.scss";

export default function LoginRegister(params) {
  return (
    <section className={`bg_green ${styles.container}`}>
      <span>
        <Link to="/authorization/login"><span>ورود</span></Link>{" "}
      </span>
      /<span> <Link to="/authorization/register"><span>عضویت</span></Link></span>
    </section>
  );
}
