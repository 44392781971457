import { Paper, InputBase, Divider, Slider, Container } from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { isEmpty } from "lodash";
import { userCoinBalance } from "../../../../actions/user";
import { num2English, showPrice } from "../../../../utils/priceFunc";
import { showPriceRate } from "../../../../utils/showBetterPrice";
import { usersMakeChangeOrders } from "../../../../services/adminServices";
import { getCoinDetail } from "../../../../actions/getCoinDetail";
import { LoadingPage } from "../../../LoadingPage/LoadingPage";
import { errorMessage, successMessage } from "../../../../utils/messages";
import ImagePreview from "../../../../utils/ImagePreview";
//Components
import CoinList from "../../CoinList/CoinList";
import {
  getCoinList,
  getRateOfCoins,
} from "../../../../services/marketServices";
import {
  hiddenLoadingPage,
  showLoadingPage,
} from "../../../../actions/loadingPage";
//Styles and Icons
import styles from "./Convert.module.scss";
import CartIcon from "../../../../styles/icon/carticon.svg";
import ArrowFillIcon from "../../../../styles/icon/ArrowFillDown.svg";
import SwitchIcon from "../../../../styles/icon/switchgreen.svg";
import EqualIcon from "../../../../styles/icon/equal.svg";
import WarningRedIcon from "../../../../styles/icon/warningRed.svg";
import copyIcon from "../../../../styles/icon/CopyIcon2.svg";
import ConfirmModal from "../../../Modals/ConfirmModal";
// import STABLE_COINS from "../../../../static/stable_coins";

export default function Convert() {
  const LoadingBar = useSelector((state) => state.loadingBar.loading);
  const marketCoinsList = useSelector((state) => state.market?.data);
  const GET_COIN_INFO = useSelector((state) => state.coinInfo.data);
  const userId = useSelector((state) => state.userId);
  const coinOfUser = useSelector(
    (state) => state.fullUserInfo?.coinBalance?.asset
  );
  const [swapDisabled, setSwapDisabled] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [rateValue, setRateValue] = useState(0);
  const [conListVisible, setCoinListVisible] = useState(false);
  const [forWhich, setForWhich] = useState(0);
  //State's
  const [payCoin, setPayCoin] = useState({});
  const [getCoin, setGetCoin] = useState({});
  let [orderAmount, setOrderAmount] = useState("");
  //End State's
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  let term = queryParams.get("coin");
  //
  const [openConfrimModal, setOpenConfirmModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  //
  useEffect(() => {
    let mounted = true;
    // ----->
    const getListsTerm = (value) => {
      setPayCoin(value.filter((d) => d.symbol === term)[0]);
      setGetCoin(value.filter((d) => d.symbol !== term)[0]);
    };
    const getListsRandom = (value) => {
      const firstCoin = value[0];
      setPayCoin(firstCoin);
      setGetCoin(value.filter((d) => d.symbol !== firstCoin.symbol)[0]);
    };
    // ----->
    if (term && mounted) {
      if (isEmpty(marketCoinsList)) {
        (async () => {
          dispatch(showLoadingPage());
          const { data } = await getCoinList();
          getListsTerm(data.data);
          dispatch(hiddenLoadingPage());
        })();
      } else {
        getListsTerm(marketCoinsList);
      }
    } else {
      if (isEmpty(marketCoinsList)) {
        if (mounted) {
          (async () => {
            dispatch(showLoadingPage());
            const { data } = await getCoinList();
            getListsRandom(data.data);
            dispatch(hiddenLoadingPage());
          })();
        }
      } else {
        getListsRandom(marketCoinsList);
      }
    }
    return () => (mounted = false);
  }, []);
  //
  useEffect(() => {
    let mounted = true;
    if (mounted && payCoin.symbol)
      (async () => {
        dispatch(userCoinBalance(payCoin.symbol));
      })();
    ///////
    if (mounted && payCoin.symbol && getCoin.symbol) {
      (async () => {
        setSwapDisabled(true);
        setDisabledBtn(true);
        const { data } = await getRateOfCoins(payCoin.symbol, getCoin.symbol);
        setRateValue(data.data.rate);
        await dispatch(getCoinDetail(getCoin.symbol));
        setDisabledBtn(false);
        setSwapDisabled(false);
      })();
    }
    return () => (mounted = false);
  }, [payCoin, getCoin]);
  //
  //SWAP
  const changePayGet = () => {
    let pay = { ...payCoin };
    let get = { ...getCoin };
    setPayCoin(get);
    setGetCoin(pay);
    setOrderAmount("");
  };
  const setUserBalanceValue = (e) => {
    let userCoin = 0;
    let percent = e.target.value * 0.01;
    userCoin = Number(coinOfUser?.balance - coinOfUser?.locked) || 0;
    setOrderAmount(userCoin * percent);
  };

  //Handle send data to sever
  const handleMakeOrders = async (isConfirm) => {
    if (!+orderAmount) return errorMessage("تعداد به درستی وارد نشده");
    if (+orderAmount * rateValue < GET_COIN_INFO.minAmount)
      return errorMessage("حداقل مقدار تبدیل رعایت نشده است");
    if (+orderAmount > Number(coinOfUser?.balance - coinOfUser?.locked) || 0) {
      return errorMessage("موجودی حساب شما کافی نمیباشد");
    }
    orderAmount = +orderAmount;
    setDisabledBtn(true);
    let options;
    if (isConfirm) {
      options = {
        market: `${payCoin.symbol}-${getCoin.symbol}`,
        orderAmount,
        orderType: "change",
        confirmed: true,
      };
    } else {
      options = {
        market: `${payCoin.symbol}-${getCoin.symbol}`,
        orderAmount,
        orderType: "change",
      };
    }
    try {
      const { data } = await usersMakeChangeOrders(options);
      if (data.needConfirmation) {
        setOpenConfirmModal(true);
        setModalMessage(data.error);
        return;
      }
      if (data.error) {
        errorMessage(data.error);
      } else {
        setOrderAmount(0);
        dispatch(userCoinBalance(payCoin.symbol));
        successMessage(data.message);
      }
    } catch {
      errorMessage("مشکلی پیش آمده دوباره تلاش کنید");
    } finally {
      setDisabledBtn(false);
    }
  };
  const history = useHistory();

  const showAmountEarned = useMemo(() => {
    if (orderAmount) {
      return showPriceRate(orderAmount * rateValue);
    }
    return 0;
  }, [orderAmount, rateValue]);

  const handleChangeAmount = (e) => {
    if (isNaN(num2English(e.target.value))) {
      return null;
    }
    return setOrderAmount(num2English(e.target.value));
  };
  return (
    <Container disableGutters>
      {LoadingBar && <LoadingPage />}
      {conListVisible && (
        <CoinList
          visible={conListVisible}
          setVisible={setCoinListVisible}
          currentCoin={forWhich ? getCoin : payCoin}
          setCurrentCoin={forWhich ? setGetCoin : setPayCoin}
          currentTwoCoin={forWhich ? payCoin : getCoin}
          // filterList={STABLE_COINS}
        />
      )}
      <div className={`${styles.container} convert_basic`}>
        <main className={styles.information_of_convert_container}>
          <div className={styles.pay_coin_information}>
            <div className={`changeTextColorToGreen ${styles.deposit_coin}`}>
              <div onClick={() => history.push(`/deposit/${payCoin?.symbol}`)}>
                واریز
                <img
                  src={CartIcon}
                  width={22}
                  height={22}
                  alt="cart"
                  className="convertPicToGreen"
                />
              </div>
            </div>
            <div className={`darkModeForConvertInputs ${styles.input_place}`}>
              <Paper
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <InputBase
                  inputProps={{
                    inputMode: "decimal",
                    className: "eng-number-font",
                  }}
                  sx={{ ml: 1, flex: 1 }}
                  value={orderAmount}
                  onChange={handleChangeAmount}
                />
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <div
                  className={styles.box_coin_select}
                  onClick={() => {
                    !disabledBtn && setCoinListVisible(true);
                    setForWhich(0);
                  }}
                >
                  <div className={styles.ArrowPlace}>
                    <img
                      className={`convertPicToGreen ${styles.forAnim_arrow}`}
                      src={ArrowFillIcon}
                      alt="list"
                      width={9}
                      height={9}
                    />
                  </div>
                  <div className={`font_color_white ${styles.coin_symbol}`}>
                    <p>{payCoin?.symbol}</p>
                  </div>
                  <div className={`font_color_white ${styles.coin_name}`}>
                    <span>{payCoin?.faName}</span>
                  </div>
                  <div className={styles.coin_pic}>
                    <ImagePreview
                      image={payCoin?.symbol}
                      width={23}
                      height={23}
                    />
                  </div>
                </div>
              </Paper>
            </div>
            <div className={`font_color_white ${styles.user_coin_info}`}>
              <div
                onClick={() =>
                  coinOfUser &&
                  setOrderAmount(coinOfUser.balance - coinOfUser.locked)
                }
              >
                <span>موجودی: </span>
                <span className="eng-number-font">
                  {coinOfUser && coinOfUser.balance - coinOfUser.locked}{" "}
                </span>
                <span>{payCoin?.faName}</span>
                <span>
                  {" "}
                  <img
                    src={copyIcon}
                    alt=""
                    width={12}
                    height={12}
                    className={styles.greenIcon}
                  />
                </span>
              </div>
              <div>پرداخت میکنید</div>
            </div>
          </div>
          <div className={styles.swap_coins}>
            <button disabled={swapDisabled} onClick={changePayGet}>
              <img src={SwitchIcon} alt="SWAP" width={30} height={30} />
            </button>
          </div>
          <div className={styles.get_coin_information}>
            <div
              className={`font_color_change_to_green ${styles.deposit_coin}`}
            >
              <div onClick={() => history.push(`/deposit/${getCoin?.symbol}`)}>
                واریز
                <img
                  src={CartIcon}
                  width={22}
                  height={22}
                  alt="cart"
                  className="convertPicToGreen"
                />
              </div>
            </div>
            <div className={`darkModeForConvertInputs ${styles.input_place}`}>
              <Paper
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ paddingLeft: "8px" }}>
                  <img
                    src={EqualIcon}
                    alt="equal-icon"
                    width={20}
                    height={17}
                    className="convertPicToGreen"
                  />
                </div>
                <InputBase
                  sx={{ ml: 1, flex: 1, className: "eng-number-font" }}
                  disabled
                  readOnly
                  value={showAmountEarned}
                />
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <div
                  className={styles.box_coin_select}
                  onClick={() => {
                    !disabledBtn && setCoinListVisible(true);
                    setForWhich(1);
                  }}
                >
                  <div className={styles.ArrowPlace}>
                    <img
                      className={`convertPicToGreen ${styles.forAnim_arrow}`}
                      src={ArrowFillIcon}
                      alt="list"
                      width={9}
                      height={9}
                    />
                  </div>
                  <div className={`font_color_white ${styles.coin_symbol}`}>
                    <p>{getCoin?.symbol}</p>
                  </div>
                  <div className={`font_color_white ${styles.coin_name}`}>
                    <span>{getCoin?.faName}</span>
                  </div>
                  <div className={styles.coin_pic}>
                    <ImagePreview
                      image={getCoin?.symbol}
                      width={23}
                      height={23}
                    />
                  </div>
                </div>
              </Paper>
            </div>
            <div className={`font_color_white ${styles.user_coin_info}`}>
              <div></div>
              <div>دریافت میکنید</div>
            </div>
          </div>
        </main>
        <footer className={styles.footer_convert}>
          <div className={`sliderDarkMode ${styles.line_convert_footer}`}>
            <Slider
              aria-label="Temperature"
              valueLabelDisplay="auto"
              step={25}
              marks
              min={25}
              max={100}
              onChange={setUserBalanceValue}
            />
          </div>
          <div className={styles.showErrorNotification}>
            <img src={WarningRedIcon} alt="" width={25} height={25} />
            <div>
              حداقل مقدار تبدیل برای <span>{getCoin?.faName}</span> :‌{" "}
              <span className="eng-number-font">
                {showPrice(GET_COIN_INFO?.minAmount)}
              </span>
            </div>
          </div>
          <button
            type="submit"
            className={`changeBackgroundToGreen ${styles.button_convert}`}
            disabled={isEmpty(userId) ? true : disabledBtn}
            onClick={handleMakeOrders}
          >
            تبدیل
          </button>
          {isEmpty(userId) ? (
            <div className={`font_color_white ${styles.message_for_login}`}>
              ابتدا وارد{" "}
              <span
                onClick={() => history.push("/authorization/login")}
                className="font_color_change_to_green"
              >
                حساب کاربری
              </span>{" "}
              خود شوید
            </div>
          ) : (
            ""
          )}
        </footer>
      </div>
      <ConfirmModal
        open={openConfrimModal}
        setOpen={setOpenConfirmModal}
        message={modalMessage}
        process={handleMakeOrders}
      />
    </Container>
  );
}
