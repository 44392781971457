import { useEffect, useState } from "react";
import QRCode from "qrcode";

const QRCodeMaker = ({ address }) => {
  const [src, setSrc] = useState("");

  useEffect(() => {
    QRCode.toDataURL(address)?.then((data) => {
      setSrc(data);
    });
    return () => setSrc("");
  }, [address]);

  return (
    <div>
      <img src={src} alt="" width={95} height={95} />
    </div>
  );
};

export default QRCodeMaker;
