import * as React from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

const CustomTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    fontFamily: "IranSansWeb",
    direction: "rtl",
    color: "var(--secondary-color)"
  },
  "& label.Mui-focused": {
    color: "var(--primary-color)"
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green"
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "var(--main-color)"
    },
    "&:hover fieldset": {
      borderColor: "var(--main-color)"
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--primary-color)"
    }
  }
});

export default function MuiTextField(props) {
  return (
    <CustomTextField
      label={props.label}
      id={props.id}
      placeholder={props.placeholder}
      onChange={props.onChange}
      value={props.value}
      size={props.size}
      fullWidth={props.fullWidth}
    />
  );
}
