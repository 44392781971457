export const  appSocketInfo = (
  state = {
    OPEN: false,
    CLOSED: true,
    CONNECTION: null,
    RECONNECT: 0,
  },
  action
) => {
  switch (action.type) {
    case "SET_SOCKET_STATUS":
      return action.payload;
    default:
      return state;
  }
};
