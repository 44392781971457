import { Container, Stack, Typography } from "@mui/material";
import PrimaryHeader from "../components/Headers/PrimaryHeader";
export default function About(props) {
  return (
    <Container>
      <PrimaryHeader fixButton title="بازگشت" />
      <Stack
        width={1}
        textAlign="center"
        marginBottom={3}
        className="--color-primary"
      >
        <Typography
          component="h1"
          color="var(--primary-color)"
          sx={{ borderBottom: "1px solid currentColor", paddingBottom: 1 }}
          fontSize="18px"
        >
          درباره ارزفی
        </Typography>
        <Typography
          component="p"
          fontSize="16px"
          marginTop={1}
          paddingX={1}
          lineHeight={1.9}
        >
          صرافی ارزفی با گردآوری افراد با تجربه در زمینه ارزهای دیجیتال و بازار
          سرمایه و همچنین برنامه نویسی و طراحی سایتی قوی از سال ۱۴۰۰ اقدام به
          راه اندازی یکی از جامع ترین صرافی های ارز دیجیتال در ایران کرده است
        </Typography>
      </Stack>
      <Stack
        width={1}
        textAlign="center"
        marginTop={5}
        className="--color-primary"
      >
        <Typography
          component="h2"
          color="var(--primary-color)"
          sx={{ borderBottom: "1px solid currentColor", paddingBottom: 1 }}
          fontSize="18px"
        >
          اهداف مجموعه ارزفی
        </Typography>
        <Typography
          component="p"
          fontSize="16px"
          marginTop={1}
          paddingX={1}
          lineHeight={1.9}
        >
          این مجموعه با هدف راحت نمودن خرید و فروش و تبدیل ارزهای دیجیتال در
          ایران با تاکید بر دانش جوانان با انگیزه و با پشتکار زیاد توانسته است
          که یک صرافی درخور شأن ایران و ایرانی ایجاد نماید
        </Typography>
      </Stack>
      <Stack
        width={1}
        textAlign="center"
        marginTop={5}
        className="--color-primary"
      >
        <Typography
          component="h3"
          color="var(--primary-color)"
          sx={{
            borderBottom: "1px solid currentColor",
            paddingBottom: 1,
          }}
          fontSize="18px"
        >
          تیم ارزفی
        </Typography>
        <Typography
          component="p"
          fontSize="16px"
          marginTop={1}
          paddingX={1}
          lineHeight={1.9}
          marginBottom={20}
        >
          تیم ارزفی در تلاش است تا با شفافیت در انتقال اطلاعات و حفظ حریم شخصی و
          خصوصی کاربران به عنوان امن ترین سامانه تبادل ارزهای دیجیتال در ایران
          رضایت کاربران خود را جلب کند . مدیریت و کارمندان ارزفی معتقد هستند با
          توجه به تحریم هایی که علیه کشورمان وضع شده است تکنولوژی بلاکچین و
          ارزهای دیجیتال می تواند در مسیر تسهیل مراودات مالی به هموطنان عزیزمان
          کمک کرده و راه گشا باشد
        </Typography>
      </Stack>
    </Container>
  );
}
