import { Container, IconButton, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { isEmpty, uniqBy } from "lodash";
import { useLayoutEffect, lazy, useMemo, useState, Suspense } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import FeesSEO from "../pageComponents/FeesPage/FeesSEO";
import HeadTabsFees from "../pageComponents/FeesPage/HeadTabsFees";
import { FetchMarketsFees } from "../services/marketServices";
import appIcons from "../utils/appIcons";

const DepositFees = lazy(() =>
  import("../pageComponents/FeesPage/DepositFees")
);
const MarketsFees = lazy(() =>
  import("../pageComponents/FeesPage/MarketsFees")
);

const PageButtons = [
  { title: "معاملات", id: "spot" },
  { title: "واریز و برداشت", id: "deposit" },
];

const ComponentTabs = {
  spot: MarketsFees,
  deposit: DepositFees,
};
const Fees = () => {
  const router = useHistory();
  //# Get From Store TradesList ---> @Market-List
  const MarketLists = useSelector((state) => state.tradesList);
  //# MarketLists Must be Filter and Resize
  //# Scope State's
  const [tableView, setTableView] = useState(PageButtons[0].id);
  const [marketListFiltered, setMarketListFiltered] = useState([]);
  const [depositWithdrawalFees, setDepositWithdrawalFees] = useState([]);

  //# Define Tab-Views
  const TabView = useMemo(() => {
    return ComponentTabs[tableView];
  }, [tableView]);

  useLayoutEffect(() => {
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);
    for (const [key, value] of searchParams.entries()) {
      if (key === "type") {
        if (PageButtons.some((c) => c.id === value)) {
          if (tableView !== value) {
            setTableView(value);
          }
        }
      }
    }
  }, []);

  useLayoutEffect(() => {
    const controller = new AbortController();
    if (isEmpty(depositWithdrawalFees)) {
      (async () => {
        const { data, status } = await FetchMarketsFees(controller.signal);
        if (status === 200) {
          setDepositWithdrawalFees(data.data);
        }
      })();
    }
    return () => {
      controller.abort();
    };
  }, []);

  useLayoutEffect(() => {
    //# Fetch Data && Filter Data
    if (MarketLists.hasOwnProperty("list") && isEmpty(marketListFiltered)) {
      const filteredList = uniqBy(
        MarketLists.list.filter((c) => c.area_title !== "IRT"),
        "asset"
      );
      setMarketListFiltered(filteredList);
    }
  }, [MarketLists]);

  const handleGoBack = () => {
    router.goBack();
  };
  return (
    <>
      <FeesSEO list={marketListFiltered.slice(0, 4)} />
      <Container component="main">
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <IconButton onClick={handleGoBack}>
            {appIcons("ArrowBackIcon")}
          </IconButton>
          <Typography color="var(--secondary-color)" component="h1">
            کارمزد ها
          </Typography>
        </Stack>
        <Stack>
          <HeadTabsFees
            PageButtons={PageButtons}
            tableView={tableView}
            setTableView={setTableView}
          />
        </Stack>
        <Suspense fallback={<p>Laoding...</p>}>
          <TabView
            markets={marketListFiltered}
            marketList={depositWithdrawalFees}
          />
        </Suspense>
      </Container>
    </>
  );
};

export default Fees;
