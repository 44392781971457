import { Grid, Stack } from "@mui/material";
import { HOME_ROUTES } from "../../../routes/homeRoutes";
import { isEmpty } from "lodash";
import styles from "./HeadRoutes.module.scss";
import appIcons from "../../../utils/appIcons";
import { useSelector } from "react-redux";
import { errorMessage } from "../../../utils/messages";
import { Link } from "react-router-dom";
import { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { userProfile } from "../../../actions/user";
import { useLayoutEffect } from "react";
import {  FetchNotificationsCounts } from "../../../services/adminServices";
import { useState } from "react";
import Cookies from "js-cookie";

const openImber = () => {
  try {
    window.Raychat.open();
    const rayChatEle = document.getElementById("raychat_widget");
    rayChatEle.style.display = "block";
  } catch (ex) {
    if (ex.message.includes("window.$imber.open is not a function")) {
      errorMessage("پشتیبانی از ۹ صبح تا ۱۲ شب فعال میباشد");
    }
  }
};

const handleHomeActions = {
  openImber: openImber,
};
function HeadRoutes() {
  const dispatch = useDispatch();
  const userProf = useSelector((state) => state.fullUserInfo?.data_profile);
  const [hasNewMessage, setHasNewMessage] = useState(0);

  useEffect(() => {
    dispatch(userProfile());
  }, []);

  useLayoutEffect(() => {
    const controller = new AbortController();
    const _cookies = Cookies.get("Notifications");
    (async () => {
      try {
        const { status, data } = await FetchNotificationsCounts(controller.signal);
        if (status === 200) {
          if (_cookies && typeof _cookies === "string") {
            const _parseCookie = JSON.parse(_cookies);
            if (typeof _parseCookie === "object") {
              if (_parseCookie.length < data) {
                setHasNewMessage(data - _parseCookie.length);
              }
            } else {
              if (data >= 1) {
                setHasNewMessage(data);
              }
            }
          } else {
            if (data >= 1) {
              setHasNewMessage(data);
            }
          }
        }
      } catch {}
    })();
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Grid container className={styles.menu_routes_container}>
      {HOME_ROUTES.map((ctx, idx) => {
        let clickActiob = ctx.hasOwnProperty("onClick");
        let userVerified = true;
        if (!isEmpty(userProf)) {
          if (
            userProf?.user?.verified === true &&
            userProf?.user?.documentStatus === true
          ) {
            userVerified = true;
          } else {
            userVerified = false;
          }
        }
        if (userVerified && ctx.id === "verify") return null;
        if (!userVerified && ctx.id === "markets") return null;

        if (clickActiob) {
          return (
            <Grid item xs={3} className={styles.item_route_container} key={idx}>
              <Stack
                className={styles.col}
                onClick={handleHomeActions[ctx.onClick]}
              >
                <div>{appIcons(ctx.icon)}</div>
                <p>{ctx.title}</p>
              </Stack>
            </Grid>
          );
        }
        return (
          <Grid item xs={3} className={styles.item_route_container} key={idx}>
            <Link to={ctx.link}>
              <Stack className={styles.col}>
                <div
                  className={styles.icon}
                  data-id={ctx.id}
                  data-message={hasNewMessage}
                >
                  {appIcons(ctx.icon)}
                  {ctx["id"] === "notifications" && hasNewMessage !== 0 && (
                    <span className={styles.badge}>
                      {hasNewMessage < 100 ? hasNewMessage : "+99"}
                    </span>
                  )}
                </div>
                <p>{ctx.title}</p>
              </Stack>
            </Link>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default memo(HeadRoutes);
