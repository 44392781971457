import { useEffect, useState } from "react";
import { useHistory } from "react-router";
// --> Components
import { CopyWithInputID } from "../../../../../helpers/CopyToClipboard";
import ReactCodeInput from "react-verification-code-input";
import { errorMessage, successMessage } from "../../../../../utils/messages";
// --> Icons, Styles
import styles from "./TwoFactor.module.scss";
import ArrowBackIcon from "../../../../../styles/icon/arrow_back_fill.svg";
import GooglePlayIcon from "../../../../../styles/pics/GooglePlay.svg";
import AppStoreIcon from "../../../../../styles/pics/AppStore.svg";
import CopyIcon from "../../../../../styles/icon/CopyIconGreen.svg";
import QRCodeMaker from "../../../../../utils/QRCodeMaker";
// --> Services
import {
  usersCheckTwoFacCode,
  usersCreateTwoFacCode,
} from "../../../../../services/userServices";
// --> Store
import { useDispatch, useSelector } from "react-redux";
import { userProfile } from "../../../../../actions/user";
//* --> Utils
import RTL from "../../../../../helpers/direction/RTL";
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";

// "https://cafebazaar.ir/app/com.google.android.apps.authenticator2"
// src={`/images/bazar/bazar.png`}
// "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US"
const bazarUrl =
  "https://cafebazaar.ir/app/com.google.android.apps.authenticator2";
const googlePlayUrl =
  "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US";
export function DownloadGoogleAuth({
  userInfo,
  history,
  verifyType,
  handleChange,
}) {
  return (
    <section className={styles.download_google_auth_container}>
      <div className={`font_color_white ${styles.title}`}>دانلود اپلیکیشن</div>
      <div className={`font_color_white ${styles.desc}`}>
        اپلیکیشن Google Authenticator را دانلود کنید.
      </div>
      <main className={styles.main_of_download_google_auth}>
        <div className="font_color_white">دانلود از</div>
        <div>
          <img
            src={GooglePlayIcon}
            alt="playstore-download"
            onClick={() => window.open(googlePlayUrl)}
            // width="150px"
          />
        </div>
        <div>
          <img
            src={AppStoreIcon}
            alt="IOS-AppStore"
            onClick={() =>
              window.open(
                "https://apps.apple.com/us/app/google-authenticator/id388497605"
              )
            }
          />
        </div>
        <section className={`twofacselect ${styles.choice_type}`}>
          <RTL>
            <FormControl sx={{ minWidth: 200 }}>
              <Select
                size="small"
                value={verifyType}
                onChange={handleChange}
                displayEmpty
                className="change_color_to_E1E2E4 change-border-light"
                sx={{
                  fontFamily: "IranSansWeb",
                  paddingTop: "30px",
                }}
                disabled={isEmpty(userInfo)}
                //   inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="">انتخاب نوع اعتبار سنجی</MenuItem>
                <MenuItem
                  value="sms"
                  disabled={userInfo?.user?.smsStatus ? false : true}
                >
                  موبایل
                </MenuItem>
                <MenuItem
                  value="email"
                  disabled={userInfo?.user?.emailStatus ? false : true}
                >
                  ایمیل
                </MenuItem>
              </Select>
              {!isEmpty(userInfo) && (
                <FormHelperText>
                  <span
                    className="change_color_to_E1E2E4 font-12"
                    style={{ fontFamily: "IranSansWeb" }}
                  >
                    {!userInfo.user.emailStatus ? (
                      <>
                        ایمیل شما تایید نشده -
                        <span
                          className="color_green cursor-pointer"
                          onClick={() =>
                            history.push(`/my-profile/authentication`)
                          }
                        >
                          {" "}
                          تایید اطلاعات تماس{" "}
                        </span>
                      </>
                    ) : !userInfo.user.smsStatus ? (
                      <>
                        موبایل شما تایید نشده -
                        <span
                          className="color_green cursor-pointer"
                          onClick={() =>
                            history.push(`/my-profile/authentication`)
                          }
                        >
                          {" "}
                          تایید اطلاعات تماس{" "}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </span>
                </FormHelperText>
              )}
            </FormControl>
          </RTL>
        </section>
      </main>
    </section>
  );
}
export function AppAuthCode({ twoFaCode }) {
  return (
    <section className={styles.download_google_auth_container}>
      <div className={`font_color_white ${styles.title}`}>کد را اسکن کنید</div>
      <div className={`font_color_white ${styles.desc}`}>
        این QRکد را در اپلیکیشن Google Authenticator یا اپلیکیشن های مشابه اسکن
        کنید.
      </div>
      <main className={styles.main_of_app_auth_code}>
        <div className={styles.QR_container}>
          <QRCodeMaker address={twoFaCode?.url ?? "Reload-APP"} />
        </div>
        <div className={`font_color_white ${styles.text_one}`}>
          و یا کد زیر را کپی کنید و در اپلیکیشن Google Authenticator وارد کنید
          تا کد مورد نظر برای ارزفی را تولید کنید
        </div>
        <div className={styles.input_of_QR}>
          <input
            type="text"
            readOnly
            className="font_color_white"
            value={twoFaCode.code}
            id="ValueOfInputAppAuthCode"
          />
          <div className={styles.copyIcon}>
            <img
              src={CopyIcon}
              alt="COPY"
              width={25}
              height={25}
              onClick={() => CopyWithInputID("ValueOfInputAppAuthCode")}
            />
          </div>
        </div>
        <div className={styles.text_two}>
          <div className="font_color_white">
            کد بالا را حتما در جایی یادداشت کنید.
          </div>
          <div className="font_color_white">
            اگر موبایلتان گم شد با این کد میتوانید Google Authenticator خود را
            بازیابی کنید.
          </div>
        </div>
      </main>
    </section>
  );
}
export function TakeAuthCodeFromUser({ history, type,sendCode,setSendCode,verifyType }) {
  const [gaCodeDetails, setGaCodeDetails] = useState({
    token: "",
    deactivatedBtn: false,
  });
  const handleCheckGaCode = async (e) => {
    e.preventDefault();
    if (gaCodeDetails.token.length < 6) return;
    if(!sendCode) return;
    setGaCodeDetails({ ...gaCodeDetails, deactivatedBtn: true });
    try {
      const { token } = gaCodeDetails;
      const data = await usersCheckTwoFacCode(JSON.stringify({ token: token, type: verifyType, code: sendCode }));
      if (data.data.error) {
        errorMessage(data.data.message);
        setGaCodeDetails({ ...gaCodeDetails, deactivatedBtn: false });
      } else {
        successMessage(data.data.message);
        history.replace("/my-profile/info");
      }
    } catch (ex) {
      setGaCodeDetails({ ...gaCodeDetails, deactivatedBtn: false });
    }
  };
  return (
    <section className={styles.download_google_auth_container}>
      <div className={`font_color_white ${styles.title}`}>کد را وارد کنید</div>
      <div className={`font_color_white ${styles.desc}`}>
        کد ۶ رقمی تولید شده برای ارزفی در اپلیکیشن Google Authenticator را وارد
        کنید
      </div>
      <main className={styles.main_of_verify_2fa_code}>
        <form onSubmit={(e) => handleCheckGaCode(e)}>
          <div
            className={`changeTextFieldBackgroundToWBlack ${styles.input_confirm_code}`}
          >
            <ReactCodeInput
              fields={6}
              className={styles.input_places}
              fieldWidth={43}
              fieldHeight={43}
              placeholder={["-", "-", "-", "-", "-", "-", "-", "-"]}
              autoFocus={true}
              onComplete={(e) =>
                setGaCodeDetails({ ...gaCodeDetails, token: e })
              }
            />
          </div>
          <div
            style={{
              paddingTop: "13px",
              paddingBottom: "20px",
            }}
            className="changeTextFieldBackgroundToWBlack"
          >
            <input
              className={styles.code_recived}
              placeholder={`کد دریافتی  ${type === "sms" ? "موبایل" : "ایمیل"}`}
              onChange={(e) => setSendCode(e.target.value)}
              value={sendCode}
            />
          </div>
          <button type="submit" disabled={gaCodeDetails.deactivatedBtn}>
            تایید
          </button>
        </form>
      </main>
    </section>
  );
}

export default function TwoFactor() {
  const { replace } = useHistory();
  const [step2fa, setStep2fa] = useState("1");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [verifyType, setVerifyType] = useState("");
  const [sendCode, setSendCode] = useState("");
  const handleChange = (e) => {
    setVerifyType(e.target.value);
  };
  const history = useHistory();
  //
  const userInfo = useSelector((state) => state.fullUserInfo.data_profile);
  const dispatch = useDispatch();
  const [twoFaCode, setTwoFaCode] = useState("");

  useEffect(() => {
    if (!verifyType) {
      setButtonDisabled(true);
    } else {
      (async () => {
        const { data } = await usersCreateTwoFacCode(
          JSON.stringify({ type: verifyType })
        );
        /* API CHANGED TO : DEVAPI ^ */
        if (data.error) {
          toast.error(data.message, { position: "top-center" });
        } else {
          setButtonDisabled(false);
          setTwoFaCode(data.secret);
          setStep2fa("2");
        }
      })();
    }
  }, [verifyType]);

  useEffect(() => {
    if (!userInfo) dispatch(userProfile());
  }, []);

  const changeSteps = (value) => {
    switch (value) {
      case "next":
        setStep2fa((Number(step2fa) + 1).toString());
        break;
      case "prev":
        setStep2fa((Number(step2fa) - 1).toString());

        break;
      default:
        break;
    }
  };
  return (
    <>
      {!userInfo?.user?.totpStatus ? (
        <section className={styles.TwoFactor_container}>
          <header>
            <div className={styles.back_btn}>
              <img
                src={ArrowBackIcon}
                alt="BACK"
                width={13}
                height={13}
                onClick={() => replace("/my-profile/info")}
              />
            </div>
            <div className={`font_color_white ${styles.title_of_2fa}`}>
              فعال سازی کد شناسایی دو عاملی
            </div>
          </header>
          <main>
            {step2fa === "1" ? (
              <DownloadGoogleAuth
                userInfo={userInfo}
                history={history}
                handleChange={handleChange}
                verifyType={verifyType}
              />
            ) : step2fa === "2" ? (
              <AppAuthCode twoFaCode={twoFaCode} setTwoFaCode={setTwoFaCode} />
            ) : step2fa === "3" ? (
              <TakeAuthCodeFromUser
                history={history}
                type={verifyType}
                setSendCode={setSendCode}
                sendCode={sendCode}
                verifyType={verifyType}
              />
            ) : (
              "RELOAD THE PAGE OR CONTACT SUPPORT"
            )}
          </main>
          <footer className={styles.steps_of_verify}>
            <div
              className={`${step2fa === "1" ? styles.active_step : ""}`}
              onClick={() => setStep2fa("1")}
            >
              دانلود
            </div>
            <div
              className={`${step2fa === "2" ? styles.active_step : ""}`}
              onClick={() => (!buttonDisabled ? setStep2fa("2") : null)}
            >
              اسکن
            </div>
            <div
              className={`${step2fa === "3" ? styles.active_step : ""}`}
              onClick={() => (!buttonDisabled ? setStep2fa("3") : null)}
            >
              تایید
            </div>
          </footer>
          <div className={styles.stepButtons}>
            <button
              onClick={() => changeSteps("prev")}
              disabled={step2fa === "1"}
            >
              قبلی
            </button>
            <button
              onClick={() => (!buttonDisabled ? changeSteps("next") : null)}
              disabled={buttonDisabled || step2fa === "3"}
            >
              بعدی
            </button>
          </div>
        </section>
      ) : (
        <div className={styles.twoFAEnabled}>
          کد شناسایی دو عاملی شما فعال است
        </div>
      )}
    </>
  );
}
