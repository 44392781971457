export const decreasePrice = (value, setValue) => {
  if (value) {
    let convertNumsToArray = value.split("");
    let lastNumberArray = convertNumsToArray.pop();
    if (+lastNumberArray === 0 || lastNumberArray === ".") {
      lastNumberArray = "";
    } else {
      lastNumberArray = lastNumberArray - 1;
    }
    convertNumsToArray.push(lastNumberArray);
    convertNumsToArray = convertNumsToArray.join("");
    setValue(convertNumsToArray);
  }
};
export const increasePrice = (value, setValue, market) => {
  if (value) {
    let convertNumsToArray = value.split("");
    let lastNumberArray = convertNumsToArray.pop();
    let lastNumberArray2 = convertNumsToArray.pop(
      convertNumsToArray.length - 2
    );
    if (+lastNumberArray === 9) {
      lastNumberArray2 = +lastNumberArray2 + 1;
      lastNumberArray = 0;
    } else {
      lastNumberArray = +lastNumberArray + 1;
    }
    convertNumsToArray.push(lastNumberArray2, lastNumberArray);
    convertNumsToArray = convertNumsToArray.join("");
    setValue(convertNumsToArray);
  } else {
    if (typeof market === "object") {
      if (market[0]) {
        setValue(market[0].price);
      }
    }
  }
};
