//* --> Icon's
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import ArrowFilDown
//* --> Syles
import styles from "./TradeType.module.scss";
import { useContext } from "react";
import { tradesCTX } from "../../../context/tradesCTX";
import ToolltipMt from "./TooltipMT";

const TradeStatus = [
  { id: "limit", name: "قیمت لیمیت" },
  { id: "market", name: "قیمت بازار" },
  { id: "stop-limit", name: "استاپ-لیمیت" },
  { id: "stop-market", name: "استاپ-مارکت" },
];
export default function TradeType(params) {
  const TradesContext = useContext(tradesCTX);
  const { setMenuStatusMarketType, marketType } = TradesContext;

  const handleOpenMenu = () => {
    setMenuStatusMarketType(true);
  };
  return (
    <section
      className={`${styles.cotainer} change_color_to_E1E2E4 border_bottom_272E40`}
    >
      <div className={styles.type} onClick={handleOpenMenu}>
        <div className={styles.title}>
          {TradeStatus.filter((c) => c.id === marketType)[0]?.name ??
            "بازار نامشخص"}
        </div>
        <div className={styles.icon}>
          <ArrowDropDownIcon fontSize="inherit" className="no-svg-change" />
        </div>
      </div>
      <div className={styles.info}>
        <ToolltipMt marketType={marketType}>
          <ErrorOutlineIcon fontSize="inherit" className="no-svg-change" />
        </ToolltipMt>
      </div>
    </section>
  );
}
