import { Img } from "react-image";
import { Rings } from "react-loader-spinner";
import serviceConfig from "../services/config.json";
const ImagePreview = ({ image, width, height }) => {
  return (
    <Img
      src={[
        `${serviceConfig.image_url}/${image}.png`,
        `https://via.placeholder.com/${width}x${height}`,
      ]}
      alt={image}
      width={width}
      height={height}
      loading="lazy"
      loader={<Rings color="#10D078" height={height} width={width} />}
    />
  );
};
export default ImagePreview;
