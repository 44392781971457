import { useContext, useMemo, useState } from "react";
import { tradesCTX } from "../../../context/tradesCTX";
import styles from "./DetailsMT.module.scss";
//* --> Icon's
import defaultList from "./icons/sell-buy-type.svg";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import buyList from "./icons/buy-type.svg";
import sellList from "./icons/sell-type.svg";
//* --> Components
import MuiSelect from "../../MuiSelect/Index";
//* --> Utils
import { isEmpty } from "lodash";
import AdvancedChart from "../../../utils/AdvancedChart";
import PercentTrade from "../PrecentTrade";
import { ArzfiChart } from "../../../utils/ArzfiChart";
import {  useSelector } from "react-redux";

export default function DetailsMT(props) {
  const OrdersContext = useContext(tradesCTX);
  const appTheme = useSelector((state) => state.appTheme);
  const {
    currentTrade,
    sortListBy,
    showSortListMenu,
    setShowSortListMenu,
    setMerge,
    merge,
    mergeList,
  } = OrdersContext;

  const ArzfiSymbolChart = useMemo(() => {
    if (!isEmpty(currentTrade)) {
      const symbol = currentTrade.asset;
      const area = currentTrade.trading_area;
      const ArzfiSymbol = `ArzFi:${symbol}/${area}`;
      return ArzfiSymbol;
    }
    return null;
  }, [currentTrade]);
  return (
    <>
      <section className={`border_bottom_to_151C30 ${styles.container}`}>
        <div className={`dir-rtl ${styles.buttons}`}>
          <div>
            <img
              src={
                sortListBy === ""
                  ? defaultList
                  : sortListBy === "buy"
                  ? buyList
                  : sellList
              }
              width={30}
              height={30}
              alt="sort-list"
              onClick={() => setShowSortListMenu(!showSortListMenu)}
            />
          </div>
          <div className={styles.list}>
            <MuiSelect
              sx={{ height: "20px", fontSize: "12px", borderRadius: "2px" }}
              onChange={setMerge}
              value={merge}
              lists={mergeList}
              disabled={
                currentTrade?.area_title === "IRT" || isEmpty(mergeList)
              }
            />
          </div>
        </div>
        <div className={styles.empty}>
          <PercentTrade />
        </div>
      </section>
      <section
        className={`${styles.chart} ${props.showChart ? styles.chart_show : ""}`}
      >
        {ArzfiSymbolChart && (
          <ArzfiChart symbol={ArzfiSymbolChart} isDarkMode={appTheme === "dark"} />
        )}
      </section>
    </>
  );
}
