import { Checkbox, FormControlLabel } from "@mui/material";
import { memo } from "react";

function MuiCheckbox(props) {
  const { label, disabled, checked, onChange } = props;
  return (
    <FormControlLabel
      sx={{
        padding: 0,
        margin: 0,
        height: "30px",
      }}
      label={
        <span
          style={{
            fontFamily: "IranSansWeb",
            fontSize: "12px",
            paddingRight: "5px",
            color: "#9D9EA8",
          }}
        >
          {label}
        </span>
      }
      control={
        <Checkbox
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          size="small"
          sx={{
            color: "#9D9EA8",
            padding: 0,
            "&.Mui-checked": {
              color: "#00c194",
            },
          }}
        />
      }
    />
  );
}
export default memo(MuiCheckbox);
