const changeThemeChoice = (str) => {
  localStorage.setItem("appThemeUsed", JSON.stringify(str));
};

export const choiceDarkMode = () => {
  return (dispatch) => {
    changeThemeChoice("dark");
    dispatch({ type: "CHOICE_DARK_MODE", payload: "dark" });
  };
};
export const choiceLightMode = () => {
  return (dispatch) => {
    changeThemeChoice("light");
    dispatch({ type: "CHOICE_LIGHT_MODE", payload: "light" });
  };
};
