import { useState, useEffect, useContext } from "react";
import { context } from "../../../../context/context";
//Components
import ReactCodeInput from "react-verification-code-input";
//Styles And Icons
import styles from "./CodeVerify.module.scss";
export default function CodeVerify() {
  const verifyCodeCTX = useContext(context);
  const {
    setVerifyCodeRecovery,
    setRecoveryAccSteps,
    recoveryAccSteps,
    handleVerifyCodeRecovery,
    handleRecoveryNum,
  } = verifyCodeCTX;
  const [timer, setTimer] = useState(null);
  function startCountDown(seconds) {
    let counter = seconds;
    let interval = setInterval(() => {
      setTimer(counter);
      counter--;

      if (counter <= 0) {
        clearInterval(interval);
        setTimer(0);
      }
    }, 1000);
    return () => clearInterval(interval);
  }
  useEffect(() => {
    startCountDown(60);
    return () => {
      setTimer(null);
    };
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={`font_color_change_to_green ${styles.confirm_title}`}>
          تائید کد ارسالی
        </div>
        <form onSubmit={(e) => handleVerifyCodeRecovery(e)}>
          <ReactCodeInput
            fields={5}
            className={styles.input_places}
            fieldWidth={43}
            fieldHeight={43}
            placeholder={["-", "-", "-", "-", "-"]}
            autoFocus={true}
            onChange={(e) => setVerifyCodeRecovery(e)}
          />
          <div className={styles.container_resend_datas}>
            <div className={styles.resend_timer_container}>
              <p className="font_color_white">
                00:{timer}
                {timer === 0 ? "0" : ""}
              </p>
            </div>
            <div className={styles.resend_code_container}>
              {timer !== 0 ? (
                <p className={` ${styles.grayColor}`}>ارسال مجدد کد</p>
              ) : (
                <p
                  onClick={(e) => {
                    startCountDown(60);
                    handleRecoveryNum(e);
                  }}
                  className="font_color_white"
                >
                  ارسال مجدد کد
                </p>
              )}
            </div>
          </div>
          <div className={styles.button_container}>
            <button type="submit" className={styles.continue_btn}>
              ادامه
            </button>
          </div>
        </form>
        <div className={styles.button_container}>
          <button
            className={styles.back_btn}
            onClick={() =>
              setRecoveryAccSteps({
                ...recoveryAccSteps,
                showVerifyCode: false,
              })
            }
          >
            بازگشت
          </button>
        </div>
      </div>
    </>
  );
}
