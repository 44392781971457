//Styles and Icons
import styles from "./ApplicationGuid.module.scss";
export default function ApplicationGuid() {
  return (
    <div className={styles.container}>
      <div
        style={{
          position: "fixed",
          width: "100%",
          height: "calc(100vh - 90px)",
          zIndex: "0"
        }}
      >
        <iframe
          width="100%"
          height="100%"
          style={{ backgroundColor: "#fff" }}
          id="frame"
          src="https://help.arzfi.net/fa"
          title="Guid to Trade"
        ></iframe>
      </div>
    </div>
  );
}
