import { useRef, useState } from "react";
import NotificationError from "../../../../helpers/errors_notifications/NotificationError";
import ArzfiBankAccount from "./ArzfiBankAccounts";
import styles from "./BankIncrease.module.scss";
import { bankPaymentIncrease } from "../../../../services/adminServices";
import { DatePicker } from "jalali-react-datepicker";
import { errorMessage, successMessage } from "../../../../utils/messages";
import Num2persian from "num2persian";
import { SuccessMuiBtn } from "../../../MuiButtons";
import { Stack } from "@mui/system";
import BankReportModal from "../../../../pageComponents/IncreaseBalance/BankReportModal";
import { Typography } from "@mui/material";

export default function BankIncrease({ setSwapButton }) {
  const [showAccountInformation, setShowAccountInformation] = useState(false);
  const [depositPic, setDepositPic] = useState("");
  const [toggleBankModalMessage, setToggleBankModalMessage] = useState(true);
  const [amount, setAmount] = useState("");
  const [transId, setTransId] = useState("");
  const [deactivatedBtn, setDeactivatedBtn] = useState(false);
  const ImageRef = useRef(null);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setDepositPic(event.target.files[0]);
    }
  };
  const handleSendBankDataIncrease = async (e) => {
    e.preventDefault();
    if (!amount) return errorMessage("مبلغ پرداخت را وارد کنید");
    if (!transId) return errorMessage("شماره پیگیری را وارد کنید");
    if (!depositPic) return errorMessage("تصویر فیش را ارسال کنید");
    try {
      setDeactivatedBtn(true);
      const formData = new FormData();
      formData.append("amount", amount);
      formData.append("transId", transId);
      formData.append("date", document.querySelector(".datePickerBank").value);
      formData.append("file", depositPic);
      const { data } = await bankPaymentIncrease(formData);
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          successMessage(data.message);
          setAmount("");
          setTransId("");
          setDepositPic("");
        }
      }
    } catch {
      errorMessage("خطایی رخ داده دوباره تلاش کنید");
    } finally {
      setDeactivatedBtn(false);
    }
  };

  const onAcceptBankRules = () => {
    setToggleBankModalMessage(false);
  };
  const onCancelBankRules = () => {
    setSwapButton("online-payment");
  };

  return (
    <>
      <ArzfiBankAccount
        showDetail={showAccountInformation}
        setDetail={setShowAccountInformation}
      />
      <div className={styles.container}>
        <div
          className={styles.AccountsInfo_BTN}
          onClick={() => setShowAccountInformation(true)}
        >
          اطلاعات حساب ها
        </div>
        <div style={{ marginBottom: "6px" }}>
          <NotificationError
            bgBorder="#F65555"
            fontColor="rgba(246, 85, 85, 1)"
            bgColor="rgba(246, 85, 85, 0.20)"
            text="پس از واریز مبلغ به یکی از حساب ها اطلاعات فرم زیر را تکمیل کنید."
          />
        </div>
        <div>
          <NotificationError
            bgBorder="rgba(44, 64, 101, 1)"
            fontColor="rgba(44, 64, 101, 1)"
            bgColor="rgba(44, 64, 101, 0.2)"
            text="در صورت واریز پایا پس از دریافت وجه حساب کاربری شارژ خواهد شد."
          />
        </div>
        <div className={styles.input_container}>
          <form onSubmit={(e) => handleSendBankDataIncrease(e)}>
            <input
              className="changeBackgroundColorToHardBlack font_color_white"
              type="number"
              inputMode="numeric"
              placeholder="مبلغ واریز(تومان)"
              onChange={(e) => setAmount(e.target.value)}
              value={amount}
            />
            <div className={styles.persian_price}>
              {amount ? Num2persian(amount) + " تومان" : ""}
            </div>
            <input
              className="changeBackgroundColorToHardBlack font_color_white"
              type="text"
              placeholder="شماره پیگیری"
              onChange={(e) => setTransId(e.target.value)}
              value={transId}
            />
            <div className={styles.dateTime_pic_container}>
              <div className={` ${styles.date_and_time}`}>
                <DatePicker
                  className="datePickerBank  changeBackgroundColorToHardBlack font_color_white"
                  label="تاریخ واریز"
                />
              </div>
              <label htmlFor="depositPicUpload">
                <div className={styles.upload_pic}>تصویر فیش واریزی</div>
                <input
                  accept="image/*"
                  type="file"
                  id="depositPicUpload"
                  style={{ display: "none" }}
                  ref={ImageRef}
                  onChange={onImageChange}
                />
              </label>
            </div>
            {depositPic ? (
              <div className={styles.pic_information}>
                <div>{depositPic.name}</div>
                {!deactivatedBtn && (
                  <button
                    type="reset"
                    onClick={() => {
                      setDepositPic("");
                      ImageRef.current.value = null;
                    }}
                  >
                    حذف عکس
                  </button>
                )}
              </div>
            ) : null}
            <Stack mt={2}>
              <SuccessMuiBtn
                text="ثبت"
                type="submit"
                disabled={deactivatedBtn}
              />
            </Stack>
          </form>
        </div>
      </div>

      <BankReportModal
        isOpen={toggleBankModalMessage}
        onRequestClose={onCancelBankRules}
        onAccept={onAcceptBankRules}
      >
        <Stack p={1.5} sx={{ direction: "rtl" }}>
          <Typography component="p" color="var(--secondary-color)">
            لطفا در فیش های اینترنت بانک و حضور در شعبه بانک حتما در قسمت شرح
            توضیحات یا علت واریز :{" "}
            <span style={{ color: "var(--danger-color)" }}>
              تادیه دیون یا بابت بدهی{" "}
            </span>{" "}
            قید گردد.
          </Typography>
          <Typography component="p" color="var(--secondary-color)">
            در غیر این صورت مبلغ به هیچ عنوان عودت داده نمیشود و حساب شما شارژ
            نخواهد شد.
          </Typography>
        </Stack>
      </BankReportModal>
    </>
  );
}
