import http from "./httpServices";
import config from "./config.json";
import axios from "axios";

export const adminCarts = () => {
  return http.get(`${config.arzfi}/users/sitecards`);
};

///////
///////Withdraw , Transfer COINS
///////
export const withdrawCoins = async (body) => {
  const res = await http.post(`${config.arzfi}/users/withdraw`, body, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return res;
};
export const internalTransferCoin = async (body) => {
  const res = await http.post(`${config.arzfi}/users/transfer`, body, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return res;
};

/////
//////// SEND MOBILE CODE AND EMAIL CODE
export const sendPhoneCode = () => {
  const res = http.get(`${config.arzfi}/auth/send-sms`);
  return res;
};
export const checkPhoneCode = (code) => {
  const res = http.get(`${config.arzfi}/auth/sms-verification/${code}`);
  return res;
};
//Email
export const sendEmailCode = () => {
  const res = http.get(`${config.arzfi}/auth/send-email`);
  return res;
};
export const checkEmailCode = (code) => {
  const res = http.get(`${config.arzfi}/auth/email-verification/${code}`);
  return res;
};

/////
///Upload ID-CARD and SELFIE Photo
////
export const uploadAuthUserFiles = (data) => {
  const token = localStorage.getItem("token");
  const headers = {
    "Content-Type": "multipart/form-data",
    "x-access-token": token,
  };
  return axios.post(`${config.arzfi}/users/upload`, data, {
    headers: headers,
  });
};

////Online PAYMENT AND BANK PAYMENT
export const onlinePaymentIncrease = (data) => {
  return http.post(`${config.arzfi}/users/payment/online`, data);
};
//Bank
export const bankPaymentIncrease = (data) => {
  const token = localStorage.getItem("token");
  const headers = {
    "Content-Type": "multipart/form-data",
    "x-access-token": token,
  };
  return axios.post(`${config.arzfi}/users/payment/bank-transfer`, data, {
    headers: headers,
  });
};
///WITHDRAW RIAL BANK ---
export const userBankRialWithdraw = (data) => {
  const body = JSON.stringify(data);
  return http.post(`${config.arzfi}/users/bank-withdrawal`, body);
};

/// REFERRAL CODE ---> EDIT ----> ADD -----> GET
export const usersReferralCodes = (signal) => {
  const options = signal ? { signal } : {};
  return http.get(`${config.arzfi}/users/refer`, options);
};
export const usersEditReferralCodes = (body) => {
  return http.post(`${config.arzfi}/users/edit_refer`, body);
};
export const usersCreateReferralCodes = (body) => {
  return http.post(`${config.arzfi}/users/refer`, body);
};

// BUY AND SELL COIN -------->
export const usersMakeOrders = (options) => {
  const body = JSON.stringify(options);
  return http.post(`${config.arzfi}/market/make-order`, body);
};

export const usersMakeChangeOrders = (options) => {
  const body = JSON.stringify(options);
  return http.post(`${config.arzfi}/market/make-change-order`, body);
};

////Verify Payment STATUS
export const checkVerifyOfPayment = (data) => {
  const body = JSON.stringify(data);
  return http.post(`${config.arzfi}/users/payment/verify`, body);
};

export const FetchNotifications = async (signal) => {
  return axios.get(`${config.blog_url}notes?_sort=published_at:DESC`, {
    signal,
    headers: null,
  });
};
export const FetchNotificationsCounts = async (signal) => {
  return axios.get(`${config.blog_url}notes/count`, {
    signal,
    headers: null,
  });
};
