import { createContext } from "react";

export const tradesCTX = createContext({
  orderPriceBuyLimit: "",
  setOrderPriceBuyLimit: () => {},
  orderAmountBuyLimit: "",
  setOrderAmountBuyLimit: () => {},
  orderPriceSellLimit: "",
  setOrderPriceSellLimit: () => {},
  orderAmountSellLimit: "",
  setOrderAmountSellLimit: () => {},
  market: "",
  setMarket: () => {},
  assetBalance: "",
  setAssetBalance: () => {},
  areaBalance: "",
  setAreaBalance: () => {},
  handleBuyLimit: () => {},
  handleSellLimit: () => {},
  disableSuccessBtn: false,
  setDisableSuccessBtn: () => {},
  disableDangerBtn: false,
  setDisableDangerBtn: () => {},
  orderPriceBuyStopLimit: "",
  setOrderPriceBuyStopLimit: () => {},
  orderAmountBuyStopLimit: "",
  setOrderAmountBuyStopLimit: () => {},
  orderPriceSellStopLimit: "",
  setOrderPriceSellStopLimit: () => {},
  orderAmountSellStopLimit: "",
  setOrderAmountSellStopLimit: () => {},
  orderBuyStopPrice: "",
  setOrderBuyStopPrice: () => {},
  orderSellStopPrice: "",
  setOrderSellStopPrice: () => {},
  handleBuyStopLimit: () => {},
  handleSellStopLimit: () => {},
  //* -->
  showThisMarketTable: "",
  setShowThisMarketTable: () => {},
  showThisMarketOpen: "",
  setShowThisMarketOpen: () => {},
  disabledCheckBoxTable: "",
  setDisabledCheckBoxTable: () => {},
  disabledCheckBoxOpen: "",
  setDisabledCheckBoxOpen: () => {},
  orderMarketAmount: "",
  setOrderMarketAmount: () => {},
  orderMarketPrice: "",
  setOrderMarketPrice: () => {},
  handleSellMarket: () => {},
  handleBuyMarket: () => {},
  lastTrades: "",
  setLastTrades: () => {},
  buyOffers: "",
  setBuyOffers: () => {},
  sellOffers: "",
  setSellOffers: () => {},
  lastOffer: "",
  setLastOffer: () => {},
  sumAllOffers: "",
  setSumAllOffers: () => {},
  handleReplaceTradesAmount: () => {},
  merge: "",
  setMerge: () => {},
  mergeList: "",
  setMergeList: () => {},
  refereshList: "",
  setRefreshList: () => {},
  marketWSInfo: "",
  setMarketWSInfo: () => {},
  orderBuyAmountStopMarket: "",
  setOrderBuyAmountStopMarket: () => {},
  orderBuyPriceStopMarket: "",
  setOrderBuyPriceStopMarket: () => {},
  orderSellAmountStopMarket: "",
  setOrderSellAmountStopMarket: () => {},
  orderSellPriceStopMarket: "",
  setOrderSellPriceStopMarket: () => {},
  handleBuyStopMarket: () => {},
  handleSellStopMarket: () => {},
  updateStopList: "",
  setUpdateStopList: () => {},
  getNewAssets: () => {},
  currentTrade: [],
  setCurrentTrade: () => {},
  currentTradeUpdate: [],
  setCurrentTradeUpdate: () => {},
  asideStatus: false,
  setAsideStatus: () => {},
  marketType: "",
  setMarketType: () => {},
  menuStatusMarketType: false,
  setMenuStatusMarketType: () => {},
  typeOfTrade: "buy",
  setTypeOfTrade: () => {},
  buyLimitSlider: 0,
  setBuyLimitSlider: () => {},
  sellLimitSlider: 0,
  setSellLimitSlider: () => {},
  buyMarketSlider: 0,
  setBuyMarketSlider: () => {},
  sellMarketSlider: 0,
  setSellMarketSlider: () => {},
  buyStopLimitSlider: 0,
  setBuyStopLimitSlider: () => {},
  sellStopLimitSlider: 0,
  setSellStopLimitSlider: () => {},
  buyStopMarketSlider: 0,
  setBuyStopMarketSlider: () => {},
  sellStopMarketSlider: 0,
  setSellStopMarketSlider: () => {},
  sortListBy: "",
  setSortListBy: () => {},
  showSortListMenu: "",
  setShowSortListMenu: () => {},
});
