import { useState } from "react";
//* --> Styles && Images && Icons
import styles from "../NormalOrdersTable/NormalOrdersTable.module.scss";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
//* --> Utils
import InfiniteScroll from "react-infinite-scroll-component";
import { isEmpty } from "lodash";
import moment from "jalali-moment";
//* --> Components
import NoOrders from "../../../../MarketTradeLayout/HistoryMT/NoOrders";
//* --> TYPES
import {
  MARKET_TRADE_TYPES,
  TYPE_TRADE,
} from "../../../../MarketTradeLayout/HistoryMT";

export default function StopOrdersTable(props) {
  const { items, cancelStatus, handleCancel, marketData } = props;
  //* --> State's
  const [showNums, setShowNums] = useState(5);
  const [hasMoreItem, setHasMoreItem] = useState(true);
  //* --> State's

  const handleNext = () => {
    if (items.slice(0, showNums).length >= items.length) {
      setHasMoreItem(false);
      return;
    }
    setShowNums(showNums + 20);
  };
  return (
    <>
      {!isEmpty(items) ? (
        <InfiniteScroll
          dataLength={items.slice(0, showNums).length}
          loader={<span>برروزرسانی</span>}
          next={handleNext}
          hasMore={hasMoreItem}
        >
          {items.slice(0, showNums).map((ord, idx) => (
            <div key={idx} className={`border_bottom_272E40 ${styles.item}`}>
              <>
                <div className={styles.one}>
                  <div className={styles.name}>
                    {ord.type === "BUY" ? (
                      <span
                        style={{ color: "#00C194" }}
                        className={styles.icon_buy_sell}
                      >
                        <FileDownloadOutlinedIcon
                          color="inherit"
                          fontSize="inherit"
                        />
                      </span>
                    ) : (
                      <span
                        style={{ color: "#ed6e72" }}
                        className={styles.icon_buy_sell}
                      >
                        <FileUploadOutlinedIcon
                          color="inherit"
                          fontSize="inherit"
                        />
                      </span>
                    )}
                    <span>{ord.faName}</span>
                    <span>‌({ord.market})</span>
                  </div>
                  <div className={styles.work}>
                    <button
                      className={styles.cancel_btn}
                      onClick={() => handleCancel(ord.id)}
                      disabled={cancelStatus}
                    >
                      لغو
                    </button>
                  </div>
                </div>
                <div className={`${styles.second} change_color_to_9D9EA8`}>
                  <div className={`eng-number-font ${styles.date}`}>
                    {moment(ord.createdAt, "YYYY-MM-DDThh:mm:ss.000Z")
                      .locale("fa")
                      .format("YYYY/MM/DD hh:mm:ss")}
                  </div>
                  <div className={styles.market_type}>
                    <span>
                      {MARKET_TRADE_TYPES.filter(
                        (c) => c.title === ord.tradeType
                      )[0]?.label ?? "-"}
                    </span>
                  </div>
                  <div className={styles.area_price}>
                    <div style={{ fontSize: "10px" }} className="dir-rtl">
                      مبلغ کل ({marketData?.area_title_fa})
                    </div>
                    <div>
                      <span className="eng-number-font">
                        {Number(ord?.totalPrice ?? 0) % 1
                          ? Number(ord?.totalPrice ?? 0)?.toFixed(2)
                          : Number(ord?.totalPrice ?? 0)}
                      </span>
                      <span style={{ paddingLeft: "3px" }}>
                        {marketData?.area_title}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.three}>
                  <div className={styles.amount}>
                    <div className={`${styles.titles} change_color_to_9D9EA8`}>
                      مقدار
                    </div>
                    <div className="change_color_to_E1E2E4 eng-number-font">
                      <span>{+ord.amount}</span>
                    </div>
                  </div>
                  <div className={styles.dones}>
                    <div className={`${styles.titles} change_color_to_9D9EA8`}>
                      استاپ
                    </div>
                    <div className="change_color_to_E1E2E4 eng-number-font">{ord.stop}</div>
                  </div>
                </div>
                <div className={styles.four}>
                  <div>
                    <div className={`${styles.titles} change_color_to_9D9EA8`}>
                      لیمیت
                    </div>
                    <div className="change_color_to_E1E2E4 eng-number-font">
                      {ord.price % 1 ? ord.price : +ord.price}
                    </div>
                  </div>
                  <div className={styles.trade_type}>
                    <div className={`${styles.titles} change_color_to_9D9EA8`}>
                      نوع
                    </div>
                    <div
                      className={
                        TYPE_TRADE.filter((c) => c.title === ord.type)[0]
                          ?.color ?? "-"
                      }
                    >
                      {TYPE_TRADE.filter((c) => c.title === ord.type)[0]
                        ?.label ?? "-"}
                    </div>
                  </div>
                </div>
              </>
            </div>
          ))}
        </InfiniteScroll>
      ) : (
        <div className="mt-40">
          <NoOrders text="سفارش فعالی یافت نشد" />
        </div>
      )}
    </>
  );
}
