function getPrecision(scinum) {
  var arr = new Array();
  arr = scinum.split("e");
  var exponent = Math.abs(arr[1]);
  var precision = new Number(exponent);
  if (arr[0].includes(".")) {
    arr = arr[0].split(".");
    precision += arr[1].length;
  }
  if (precision > 9) precision = 9;
  return precision;
}

export function showPrice(price) {
  let sPrice = 0;
  if (price > 1) {
    sPrice = parseFloat(price);
  } else {
    sPrice = price?.toString();
    if (sPrice?.match(/^[-+]?[0-9]?\.?[0-9]+e[-]?[1-9][0-9]*$/)) {
      sPrice = (+sPrice).toFixed(getPrecision(sPrice));
    } else {
      sPrice = (+sPrice)?.toFixed(9);
    }
  }
  if (sPrice.toString().includes("."))
    sPrice = sPrice.toString().replace(/\.?0+$/, "");
  var parts = sPrice.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function convertNumbers2English(string) {
  return string
    .replace(/\./g, "")
    .replace(/\u060C/g, ",")
    .replace(/[\u0660-\u0669]/g, function (c) {
      return c.charCodeAt(0) - 0x0660;
    })
    .replace(/[\u06f0-\u06f9]/g, function (c) {
      return c.charCodeAt(0) - 0x06f0;
    });
}
export function num2English(string) {
  return string
    .replace(/\u060C/g, ",")
    .replace(/[\u0660-\u0669]/g, function (c) {
      return c.charCodeAt(0) - 0x0660;
    })
    .replace(/[\u06f0-\u06f9]/g, function (c) {
      return c.charCodeAt(0) - 0x06f0;
    });
}
export function numFormatter(num) {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
  } else if (num < 900) {
    return num.toFixed(2); // if value < 1000, nothing to do
  }
}

export const showUserBalance = (num) => {
  if (!(num % 2)) {
    return num.toFixed(0);
  }
  if (num >= 0.01) {
    return num.toFixed(2);
  }
  return num.toFixed(4);
};

export function toFixedE(x) {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
}

export function getRandomNum(min, max) {
  return Math.ceil(Math.random() * (max - min) + min);
}
