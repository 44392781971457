import { repliesToTicket, usersTextTicket, usersTickets } from "../../services/userServices";

export const initialStates = {
  ticketsList: [],
  isLoading: true,
  isError: false,
  refershPageCount: 0,
  view: "tickets",
};

export const ticketsReducer = (state, action) => {
  switch (action.type) {
    case "VIEW":
      return { ...state, view: action.payload };
    case "INIT_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        ticketsList: action.payload,
      };
    case "INIT_FAILED":
      return { ...state, isError: true };
    case "REFERSH_NO_LOADING":
      return { ...state, refershPageCount: state.refershPageCount + 1 };
    case "REFRESH":
      return {
        ...state,
        refershPageCount: state.refershPageCount + 1,
        isLoading: true,
        isError: false,
      };
    default:
      return state;
  }
};

export async function getTicketsList(signal) {
  const res = {
    error: false,
    list: [],
  };
  try {
    const { data, status } = await usersTickets(signal);
    if (status === 200) {
      res.list = data.data;
    } else {
      res.error = true;
    }
  } catch {
    res.error = true;
  } finally {
    return res;
  }
}
export async function getSingleTicket(id, signal) {
  const res = {
    error: false,
    replies: [],
    message: {},
  };
  try {
    const { data, status } = await usersTextTicket(id, signal);
    if (status === 200) {
      res.message = data["ticket"];
      res.replies = data["replies"];
    } else {
      res.error = true;
    }
  } catch {
    res.error = true;
  } finally {
    return res;
  }
}