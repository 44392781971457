import React from "react";
import styles from "./InputViaButton.module.scss";

function InputViaButton(props) {
  const { buttonTitle, onClickButton, ...rest } = props;
  return (
    <div className={styles.container}>
      <input {...rest} />
      {buttonTitle && (
        <>
          <span className={styles.divider}></span>
          <button onClick={onClickButton}>{buttonTitle}</button>
        </>
      )}
    </div>
  );
}

export default InputViaButton;
