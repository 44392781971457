import { useContext, useState } from "react";
import styles from "./HistoryMT.module.scss";
//* --> Icons
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
//* --> Components
import OpenOrders from "./OpenOrders";
import OrdersHistory from "./OrdersHistory";
import { Link } from "react-router-dom";
import { tradesCTX } from "../../../context/tradesCTX";

const historyButtons = [
  { id: 0, name: "open-orders", faName: "سفارشات باز" },
  { id: 1, name: "orders-history", faName: "تاریخچه سفارشات" },
];
export const MARKET_TRADE_TYPES = [
  { title: "limit", label: "قیمت-لیمیت" },
  { title: "market", label: "مارکت" },
  { title: "stop-limit", label: "استاپ-لیمیت" },
  { title: "otc", label: "معمولی" },
];
export const TYPE_TRADE = [
  { title: "BUY", label: "خرید", color: "color_green" },
  { title: "SELL", label: "فروش", color: "color_red" },
];
export const statusTrade = [
  { title: "APPROVED", label: "تکمیل شده" },
  { title: "WAITING", label: "در صف انجام" },
  { title: "EXECUTING", label: "در حال اجرا" },
  { title: "CANCELED", label: "لغو شده" },
  { title: "REJECTED", label: "رد شده" },
];
export default function HistoryMT() {
  const MarketContext = useContext(tradesCTX);
  const { currentTrade } = MarketContext;
  const [switchHistoryButton, setSwitchHistoryButton] = useState(0);
  const handleChangeHistory = (value) => {
    setSwitchHistoryButton(value);
  };
  return (
    <section className={styles.container}>
      <div className={`border_bottom_to_151C30 ${styles.type}`}>
        {historyButtons.map((btn, idx) => (
          <button
            key={idx}
            onClick={() => handleChangeHistory(btn.id)}
            className={`font_color_white ${
              btn.id === switchHistoryButton ? styles.button_active : ""
            }`}
          >
            {btn.faName}
          </button>
        ))}
        <div className={`${styles.icon_history} font_color_white`}>
          <Link to={`/market/history?q=${currentTrade?.market}`}>
            <ListAltOutlinedIcon fontSize="inherit" className="no-svg-change" />
          </Link>
        </div>
      </div>
      <div>{!switchHistoryButton ? <OpenOrders /> : <OrdersHistory />}</div>
    </section>
  );
}
