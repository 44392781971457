import styles from "./PersonInfo.module.scss";
import { useHistory } from "react-router";
import { userProfile } from "../../../../actions/user";
import ArrowBackIcon from "../../../../styles/icon/arrow_back_fill.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hiddenLoadingPage,
  showLoadingPage,
} from "../../../../actions/loadingPage";
import { LoadingPage } from "../../../LoadingPage/LoadingPage";
import {
  checkEmail,
  checkIDCardNumber,
  checkMobileNum,
} from "../../../Validator/Validator";
import { ERROR_CODE1, ERROR_CODE2 } from "../../../Validator/ErrorCodes";
import { userEditProfile } from "../../../../services/userServices";
import { successMessage, errorMessage } from "../../../../utils/messages";

export default function PersonInfo() {
  const userData = useSelector((state) => state.fullUserInfo.data_profile);
  const loadingBar = useSelector((state) => state.loadingBar.loading);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      (async () => {
        dispatch(showLoadingPage());
        await dispatch(userProfile());
        dispatch(hiddenLoadingPage());
      })();
    }
    return () => (mounted = false);
  }, []);
  useEffect(() => {
    if (userData?.user?.fullname) {
      setUserInformation({
        ...userInformation,
        name: userData?.user?.fullname,
      });
    }
  }, [userData]);
  const [userInformation, setUserInformation] = useState({
    name: "",
    cardIdNumber: "",
    homePhoneNumber: "",
    /* CHECKED FOR ENTRY*/
    phoneNumber: "",
    email: "",
  });
  const [errorMessageApp, setErrorMessageApp] = useState({
    forName: "",
    forCardIdNumber: "",
    forHomePhone: "",
    forPhoneNumber: "",
    forEmail: "",
  });
  const [disabled, setDisabled] = useState(false);
  const handleUserPersonInfo = async (e) => {
    e.preventDefault();
    let current;
    let currentTwo;
    let checkFamily = /^[\u0600-\u06FF\s]+$/;
    if (
      userInformation.name.trim().length < 5 ||
      userInformation.name.trim().length >= 40
    )
      return setErrorMessageApp({
        ...errorMessageApp,
        forName:
          "نام و نام خانوادگی وارد شده نباید کمتر از 5 کلمه و بیشتر از  40 کلمه باشد",
      });

    if (!checkFamily.test(userInformation.name))
      return setErrorMessageApp({
        ...errorMessageApp,
        forName: "نام و نام خانوادگی باید به فارسی وارد شود",
      });
    if (!checkIDCardNumber(userInformation.cardIdNumber))
      return setErrorMessageApp({
        ...errorMessageApp,
        forCardIdNumber: "کد ملی معتبر نمیباشد",
      });
    if (!userData?.user?.smsStatus) {
      current =
        checkMobileNum(userInformation.phoneNumber) &&
        userInformation.phoneNumber.length === 11;
      if (!current)
        return setErrorMessageApp({
          ...errorMessageApp,
          forPhoneNumber: ERROR_CODE1,
        });
    }
    if (!userData?.user?.emailStatus) {
      currentTwo = checkEmail(userInformation.email);
      if (!currentTwo)
        return setErrorMessageApp({
          ...errorMessageApp,
          forEmail: ERROR_CODE2,
        });
    }
    try {
      setDisabled(true);
      const { data } = await userEditProfile({
        name: userInformation.name,
        idNumber: userInformation.cardIdNumber,
        telephone: userInformation.homePhoneNumber,
        mobileNumber: userInformation.phoneNumber,
        email: userInformation.email,
      });
      if (data.error) {
        errorMessage(data.message);
      } else {
        successMessage(data.message);
        history.replace("/my-profile/authentication");
      }
    } catch (ex) {
    } finally {
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (
      userData?.user?.docsLack === false &&
      userData?.user?.verified === true
    ) {
      return history.replace("/");
    }
  }, [userData]);
  return (
    <>
      {loadingBar && <LoadingPage />}
      <section className={styles.container}>
        <header>
          <div className={styles.back}>
            <img
              src={ArrowBackIcon}
              alt="BACK"
              width={15}
              height={15}
              onClick={() => history.push("/my-profile/authentication")}
              className="convertPicToWhite"
            />
          </div>
          <div className={`font_color_white ${styles.header_title}`}>
            اطلاعات فردی
          </div>
        </header>
        <form onSubmit={handleUserPersonInfo}>
          <main className={styles.container_of_inputs}>
            <input
              type="text"
              placeholder="نام و نام خانوادگی"
              className="changeBackgroundColorToHardBlack font_color_white"
              value={userInformation.name}
              onChange={(e) => {
                setUserInformation({
                  ...userInformation,
                  name: e.target.value,
                });
                setErrorMessageApp({ ...errorMessageApp, forName: "" });
              }}
            />
            {errorMessageApp.forName && (
              <div className={styles.error_container}>
                {errorMessageApp.forName}
              </div>
            )}
            <input
              type="number"
              placeholder="کد ملی"
              className="changeBackgroundColorToHardBlack font_color_white"
              value={userInformation.cardIdNumber}
              onChange={(e) => {
                setUserInformation({
                  ...userInformation,
                  cardIdNumber: e.target.value,
                });
                setErrorMessageApp({ ...errorMessageApp, forCardIdNumber: "" });
              }}
            />
            {errorMessageApp.forCardIdNumber && (
              <div className={styles.error_container}>
                {errorMessageApp.forCardIdNumber}
              </div>
            )}
            {!userData?.user?.smsStatus && (
              <input
                placeholder="شماره موبایل"
                inputMode="decimal"
                className="changeBackgroundColorToHardBlack font_color_white"
                value={userInformation.phoneNumber}
                onChange={(e) => {
                  setUserInformation({
                    ...userInformation,
                    phoneNumber: e.target.value,
                  });
                  setErrorMessageApp({
                    ...errorMessageApp,
                    forPhoneNumber: "",
                  });
                }}
              />
            )}
            {errorMessageApp.forPhoneNumber && (
              <div className={styles.error_container}>
                {errorMessageApp.forPhoneNumber}
              </div>
            )}
            <input
              id="PhoneTelephoneNumber"
              inputMode="decimal"
              placeholder="شماره ثابت (اختیاری)"
              className="changeBackgroundColorToHardBlack font_color_white"
              value={userInformation.homePhoneNumber}
              onChange={(e) => {
                setUserInformation({
                  ...userInformation,
                  homePhoneNumber: e.target.value,
                });
                setErrorMessageApp({ ...errorMessageApp, forHomePhone: "" });
              }}
            />
            {errorMessageApp.forHomePhone && (
              <div className={styles.error_container}>
                {errorMessageApp.forHomePhone}
              </div>
            )}
            {!userData?.user?.emailStatus && (
              <input
                type="email"
                placeholder="ایمیل"
                className="changeBackgroundColorToHardBlack font_color_white"
                value={userInformation.email}
                onChange={(e) =>
                  setUserInformation({
                    ...userInformation,
                    email: e.target.value,
                  })
                }
              />
            )}

            {errorMessageApp.forEmail && (
              <div className={styles.error_container}>
                {errorMessageApp.forEmail}
              </div>
            )}
            <button
              type="submit"
              className="changeBackgroundToGreen"
              disabled={disabled}
            >
              تایید
            </button>
          </main>
        </form>
      </section>
    </>
  );
}
