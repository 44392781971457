import styles from "./Buttons.module.scss";

const PaperButton = (props) => {
  const { children, type = "success", ...rest } = props;
  return (
    <button className={styles.paperButton} data-theme={type} {...rest}>
      {children}
    </button>
  );
};

export default PaperButton;
