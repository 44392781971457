import { memo, useEffect, useState } from "react";
import { adminCarts } from "../../../../services/adminServices";
import { CopyThisText } from "../../../../helpers/CopyToClipboard";
import { successMessage } from "../../../../utils/messages";
//Icon
import styles from "./ArzfiBankAccounts.module.scss";
import ArrowBack from "../../../../styles/icon/arrow_back_fill.svg";
import copyBlue from "../../../../styles/icon/copyblue.svg";
function ArzfiBankAccount({ showDetail, setDetail }) {
  const [accountInfos, setAccountInfos] = useState("");
  useEffect(() => {
    const getBankInfo = async () => {
      const Info = await adminCarts();
      setAccountInfos(Info.data.cards);
    };
    getBankInfo();
  }, []);
  return (
    <div
      className={`changeBackgroundColorToHardBlack font_color_white ${
        styles.container
      } ${showDetail ? styles.container_show : ""}`}
    >
      <div className={styles.back_btn}>
        <img
          src={ArrowBack}
          alt="BACK"
          width={20}
          height={20}
          onClick={() => setDetail(false)}
          className="convertPicToWhite"
        />
      </div>
      <div className={styles.title_of_page}>اطلاعات حساب ها</div>
      <div className={`changeBackgroundColorToGreen ${styles.line}`}></div>
      <header>
        <div className="changeBackgroundColorToGreen">نام صاحب حساب</div>
        <div className="changeBackgroundColorToGreen">اطلاعات حساب ها</div>
      </header>
      <main>
        {accountInfos &&
          accountInfos.map((info) => (
            <div className={styles.main} key={info.accountNumber}>
              <div
                className={`changeBackgroundColorToBlack font_color_white ${styles.person}`}
              >
                <div>{info.fullname}</div>
              </div>
              <div
                className={`changeBackgroundColorToBlack font_color_white ${styles.info}`}
              >
                <div className={styles.item_column}>
                  <div className={styles.item_title}>نام بانک:</div>
                  <div>{info.bankName}</div>
                </div>
                <div className={styles.item_column}>
                  <div className={styles.item_title}>شماره حساب:</div>
                  <div className={styles.item_row}>
                    <div className={styles.copy}>
                      <img
                        src={copyBlue}
                        alt="COPY"
                        width={18}
                        height={18}
                        onClick={() => {
                          CopyThisText(info.accountNumber);
                          successMessage("کپی شد");
                        }}
                        className="convertPicToGreen"
                      />
                    </div>
                    <div>{info.accountNumber}</div>
                  </div>
                </div>
                <div className={styles.item_column}>
                  <div className={styles.item_title}>شماره شبا:</div>
                  <div className={styles.item_row}>
                    <div className={styles.copy}>
                      <img
                        src={copyBlue}
                        alt="COPY"
                        width={15}
                        height={15}
                        onClick={() => {
                          CopyThisText(info.ibanNumber);
                          successMessage("کپی شد");
                        }}
                        className="convertPicToGreen"
                      />
                    </div>
                    <div>{info.ibanNumber}</div>
                  </div>
                </div>
                {info.cardNumber && (
                  <div className={styles.item_column}>
                    <div className={styles.item_title}>شماره کارت:</div>
                    <div className={styles.item_row}>
                      <div className={styles.copy}>
                        <img
                          src={copyBlue}
                          alt="COPY"
                          width={15}
                          height={15}
                          onClick={() => {
                            CopyThisText(info.cardNumber);
                            successMessage("کپی شد");
                          }}
                          className="convertPicToGreen"
                        />
                      </div>
                      <div>{info.cardNumber}</div>
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.line_two}></div>
              <div className={styles.line_two}></div>
            </div>
          ))}
      </main>
    </div>
  );
}
export default memo(ArzfiBankAccount);
