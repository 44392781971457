//* --> Components
import { useState } from "react";
//* --> Styles
import styles from "./OpenOrders.module.scss";
//* --> Components
import MuiCheckbox from "../../../MuiCheckbox";
import NormalOrdersTable from "./NormalOrdersTable";
import StopOrdersTable from "./StopOrdersTable";

//* --> STATIC's
const TRADE_BUTTONS = [
  {
    id: 0,
    name: "normal-orders",
    faName: "سفارش معمولی",
  },
  {
    id: 1,
    name: "stop-orders",
    faName: "سفارش استاپ",
  },
];
export default function OpenOrders(props) {
  const [tradeButton, setTradeButton] = useState(0);
  const {
    marketData,
    setShowThisMarkOpenOrders,
    showThisMarketOpenOrders,
    disabledCheckBoxOpen,
    normalOrders,
    cancel,
    update,
    handleCancelNormalOrder,
    handleUpdateNormalOrders,
    handleCancelStopOrder,
    stopOrders,
  } = props;

  return (
    <div className={styles.container}>
      <div className={`dir-rtl ${styles.checkbox}`}>
        <MuiCheckbox
          label={`فقط نمایش ${marketData?.asset_title_fa} ${marketData?.area_title_fa}`}
          disabled={disabledCheckBoxOpen}
          checked={showThisMarketOpenOrders}
          onChange={() => setShowThisMarkOpenOrders((show) => !show)}
        />
      </div>
      <div className={`dir-rtl mt-5 ${styles.trade_buttons}`}>
        {TRADE_BUTTONS.map((btn, idx) => (
          <button
            className={`button-shadow ${
              btn.id === tradeButton ? "button-shadow-active" : ""
            }`}
            key={idx}
            onClick={() => setTradeButton(btn.id)}
          >
            {btn.faName}(
            {!btn.id ? normalOrders?.length ?? 0 : stopOrders?.length ?? 0})
          </button>
        ))}
      </div>
      <div className={`mt-7 ${styles.main}`}>
        {!tradeButton ? (
          <NormalOrdersTable
            items={normalOrders}
            cancelStatus={cancel}
            updateStatus={update}
            handleCancel={handleCancelNormalOrder}
            handleUpdate={handleUpdateNormalOrders}
            marketData={marketData}
          />
        ) : (
          <StopOrdersTable
            cancelStatus={cancel}
            marketData={marketData}
            items={stopOrders}
            handleCancel={handleCancelStopOrder}
          />
        )}
      </div>
    </div>
  );
}
