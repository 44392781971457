import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import MuiInputOne from "../../../../market/MuiInput/MuiInputOne";
import { tradesCTX } from "../../../../../context/tradesCTX";
import MuiInputTwo from "../../../../market/MuiInput/MuiInputTwo";
import PercentBalance from "../../../../PercentBalance";
import HelperText1 from "../HelperTexts/HelperText1";

const DEBUG_FOCUS = (value) => {
  return;
};
export default function Limit({ type }) {
  const marketTradeContext = useContext(tradesCTX);
  const {
    //* --> State's Of Price And Amount
    orderPriceBuyLimit,
    setOrderPriceBuyLimit,
    orderAmountBuyLimit,
    setOrderAmountBuyLimit,
    orderPriceSellLimit,
    setOrderPriceSellLimit,
    orderAmountSellLimit,
    setOrderAmountSellLimit,
    areaBalance,
    assetBalance,
    lastTrades,
    //* --> State's Of Price And Amount
    //* --> Market Name
    currentTrade,
    //* <-- Market Name
    buyLimitSlider,
    setBuyLimitSlider,
    sellLimitSlider,
    setSellLimitSlider,
  } = marketTradeContext;

  const [amountFromWalletToBuy, setAmountFromWalletToBuy] = useState("");
  const [amountFromWalletToSell, setAmountFromWalletToSell] = useState("");
  //# buyorder, buyamount, buyasset, sellorder, sellamount, sellasset
  const [focusInputName, setFocusInputName] = useState("");

  const showUserEarn = (type) => {
    if (type === "sell") {
      let amount = orderPriceSellLimit * orderAmountSellLimit;
      let percent =
        orderPriceSellLimit * orderAmountSellLimit * currentTrade?.fee_rate ??
        0;
      amount = amount - percent;
      if (amount.toString().split(".")[1]?.length > currentTrade.sell_places) {
        amount = +amount.toFixed(currentTrade.sell_places);
      }
      if (amount < 0 || amount > 9999999999999 || isNaN(amount)) {
        amount = 0;
      }
      if (percent.toString().split(".")[1]?.length > currentTrade.sell_places) {
        percent = +percent.toFixed(currentTrade.sell_places);
      }
      return [amount, percent];
    } else {
      let amount = orderAmountBuyLimit * orderPriceBuyLimit;
      let percent = amount * currentTrade?.fee_rate ?? 0;

      amount = amount - percent;
      amount = amount / orderPriceBuyLimit;
      if (amount.toString().split(".")[1]?.length > currentTrade.sell_places) {
        amount = +amount.toFixed(currentTrade.sell_places);
      }
      if (amount < 0 || amount > 9999999999999 || isNaN(amount)) {
        amount = 0;
      }
      if (percent.toString().split(".")[1]?.length > currentTrade.sell_places) {
        percent = +percent.toFixed(currentTrade.sell_places);
      }
      return [amount, percent];
    }
  };

  useEffect(() => {
    if (buyLimitSlider) {
      let percent = +buyLimitSlider * 0.01;
      if (orderPriceBuyLimit) {
        let amount = (percent * areaBalance) / orderPriceBuyLimit;
        setOrderAmountBuyLimit(amount);
      }
    }
  }, [buyLimitSlider]);
  useEffect(() => {
    if (sellLimitSlider) {
      let percent = +sellLimitSlider * 0.01;
      let amount = percent * assetBalance;
      setOrderAmountSellLimit(amount);
    }
  }, [sellLimitSlider]);

  const BUY_PAY = useMemo(() => {
    return orderPriceBuyLimit * orderAmountBuyLimit;
  }, [orderPriceBuyLimit, orderAmountBuyLimit]);

  const handleAmountFromWalletToSell = (amount) => {
    setAmountFromWalletToSell(amount);
    if (orderPriceSellLimit) {
      const price = orderPriceSellLimit * 0.01;
      let earn = (amount * 0.01) / price;
      if (earn.toString().includes("e")) {
        setOrderAmountSellLimit(0);
      } else {
        earn += earn * currentTrade["fee_rate"] ?? 0;
        setOrderAmountSellLimit(earn);
      }
    }
  };

  const handleUpdateAmountSellLimit = (value) => {
    setOrderAmountSellLimit(value);
    if (orderPriceSellLimit) {
      const one = value * orderPriceSellLimit;
      const fee = one * currentTrade["fee_rate"] ?? 0;
      if (!isNaN(one - fee)) {
        setAmountFromWalletToSell((one - fee).toFixed(2));
      }
    }
  };
  const handleUpdatePriceSellLimit = (amount) => {
    setOrderPriceSellLimit(amount);
    if (orderAmountSellLimit) {
      const one = amount * orderAmountSellLimit;
      const fee = one * currentTrade["fee_rate"] ?? 0;
      if (!isNaN(one - fee)) {
        setAmountFromWalletToSell((one - fee).toFixed(2));
      }
    }
  };
  //# -->
  //# Buy Functions
  //# -->
  const handleAmountFromWalletToBuy = (amount) => {
    setAmountFromWalletToBuy(amount);
    if (orderPriceBuyLimit) {
      const price = orderPriceBuyLimit * 0.01;
      const earn = (amount * 0.01) / price;
      if (earn.toString().includes("e")) {
        setOrderAmountBuyLimit(0);
      } else {
        setOrderAmountBuyLimit(earn);
      }
    }
  };
  const updatePayAmount = (value) => {
    const p =
      (value * orderPriceBuyLimit) % 1
        ? (value * orderPriceBuyLimit).toFixed(2)
        : value * orderPriceBuyLimit;
    setAmountFromWalletToBuy(p);
  };
  const handleUpdateAmountBuyLimit = useCallback(
    (value) => {
      setOrderAmountBuyLimit(value);
      updatePayAmount(value);
    },
    [orderPriceBuyLimit]
  );

  const handleUpdatePriceBuyLimit = (value) => {
    setOrderPriceBuyLimit(value);
    if (orderAmountBuyLimit) {
      const p =
        (value * orderAmountBuyLimit) % 1
          ? (value * orderAmountBuyLimit).toFixed(2)
          : value * orderAmountBuyLimit;
      setAmountFromWalletToBuy(p);
    }
  };

  return (
    <div>
      {type === "buy" ? (
        <>
          <div>
            <MuiInputTwo
              amount={orderPriceBuyLimit}
              setAmount={
                focusInputName === "buyorder"
                  ? handleUpdatePriceBuyLimit
                  : DEBUG_FOCUS
              }
              onFoucsInput={() => setFocusInputName("buyorder")}
              decimalScale={currentTrade?.buy_places}
              placeholder={`(${currentTrade?.area_title_fa ?? "-"})قیمت`}
              market={lastTrades}
              xs={{ direction: "rtl" }}
            />
          </div>
          <div className="mt-15">
            <MuiInputOne
              amount={orderAmountBuyLimit}
              setAmount={
                focusInputName === "buyamount"
                  ? handleUpdateAmountBuyLimit
                  : DEBUG_FOCUS
              }
              onFoucsInput={() => setFocusInputName("buyamount")}
              decimalScale={currentTrade?.sell_places}
              adorment={currentTrade?.asset}
              placeholder={`(${currentTrade?.asset ?? "-"})مقدار`}
            />
          </div>
          <div className="mt-15">
            <PercentBalance
              value={buyLimitSlider}
              setValue={setBuyLimitSlider}
            />
          </div>
          <div className="mt-15">
            <MuiInputOne
              amount={amountFromWalletToBuy}
              setAmount={
                focusInputName === "buyasset"
                  ? handleAmountFromWalletToBuy
                  : DEBUG_FOCUS
              }
              onFoucsInput={() => setFocusInputName("buyasset")}
              adorment={currentTrade?.area_title}
              placeholder={`مبلغ خرید`}
            />
          </div>
          <div className="mt-10">
            <HelperText1
              paySymbol={currentTrade?.area_title}
              getSymbol={currentTrade?.asset}
              wageSymbol={currentTrade?.area_title}
              wage={
                orderPriceBuyLimit && orderAmountBuyLimit
                  ? showUserEarn("buy")[1]
                  : 0
              }
              pay={BUY_PAY % 1 ? BUY_PAY.toFixed(2) : BUY_PAY}
              get={
                orderPriceBuyLimit && orderAmountBuyLimit
                  ? showUserEarn("buy")[0]
                  : 0
              }
              balance={areaBalance}
            />
          </div>
        </>
      ) : (
        <>
          <div>
            <MuiInputTwo
              amount={orderPriceSellLimit}
              setAmount={
                focusInputName === "sellprice"
                  ? handleUpdatePriceSellLimit
                  : DEBUG_FOCUS
              }
              onFoucsInput={() => setFocusInputName("sellprice")}
              decimalScale={currentTrade?.buy_places}
              placeholder={`(${currentTrade?.area_title_fa ?? "-"})قیمت`}
              xs={{ direction: "rtl" }}
              market={lastTrades}
            />
          </div>
          <div className="mt-15">
            <MuiInputOne
              amount={orderAmountSellLimit}
              setAmount={
                focusInputName === "sellamount"
                  ? handleUpdateAmountSellLimit
                  : DEBUG_FOCUS
              }
              onFoucsInput={() => setFocusInputName("sellamount")}
              adorment={currentTrade?.asset}
              decimalScale={currentTrade?.sell_places}
              placeholder={`(${currentTrade?.asset ?? "-"})مقدار`}
            />
          </div>
          <div className="mt-15">
            <PercentBalance
              value={sellLimitSlider}
              setValue={setSellLimitSlider}
            />
          </div>
          <div className="mt-15">
            <MuiInputOne
              amount={amountFromWalletToSell}
              setAmount={
                focusInputName === "sellasset"
                  ? handleAmountFromWalletToSell
                  : DEBUG_FOCUS
              }
              onFoucsInput={() => setFocusInputName("sellasset")}
              adorment={currentTrade?.area_title}
              placeholder={`مبلغ فروش`}
            />
          </div>
          <div className="mt-10">
            <HelperText1
              paySymbol={currentTrade?.asset}
              getSymbol={currentTrade?.area_title}
              wage={
                orderPriceSellLimit && orderAmountSellLimit
                  ? showUserEarn("sell")[1]
                  : 0
              }
              pay={+orderAmountSellLimit}
              wageSymbol={currentTrade?.area_title}
              get={
                orderPriceSellLimit && orderAmountSellLimit
                  ? showUserEarn("sell")[0]
                  : 0
              }
              balance={assetBalance}
            />
          </div>
        </>
      )}
    </div>
  );
}
