import React from "react";
import BottomModal from "../BottomModal";
import { Stack } from "@mui/material";
import { ThreeDots } from "react-loader-spinner";

function LoadingModal({ visible = false }) {
  return (
    <BottomModal visible={visible}>
      <Stack
        sx={{ height: "100vh", alignItems: "center", justifyContent: "center" }}
      >
        <ThreeDots color="var(--primary-color)" width={35} height={35} />
      </Stack>
    </BottomModal>
  );
}

export default LoadingModal;
