export function addComma(num) {
  return num?.toLocaleString("en-US");
}
export function commafy( num ) {
  let str = num.toString().split('.');
  if (str[0].length >= 5) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }
  return str.join('.');
}
export function addCommaWithRialPrice(num) {
  let be = num?.toString()?.split(".");
  if (be && Number(be[0] < 50 && be[0] >= 1)) return Number(num).toPrecision(5);
  if (be && Number(be[0] > 10)) return commafy(Number(be[0]));
  return num?.toString()?.slice(0, 10);
}

export function numberWith3Commas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d|(\.\d{3})\D))/g, ",");
}

// export
export function showBetterBankCard(x) {
  return x.toString().replace(/\B(?=(\d{4})+(?!\d))/g, "-");
}

export function numberSeprator(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}