export const fullUserInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_USER_BALANCE_INFO":
      return action.payload;
    case "SET_USER_TRANSACTIONS_INFO":
      return action.payload;
    case "SET_USER_ORDERS_INFO":
      return action.payload;
    case "SET_USER_REFERRALS_INFO":
      return action.payload;
    case "SET_USER_DEP_WTH_INFO":
      return action.payload;
    case "SET_USER_COMMISSION_INFO":
      return action.payload;
    case "SET_USER_PROFILE_INFO":
      return action.payload;
    case "SET_USER_TICKETS_INFO":
      return action.payload;
    case "SET_USER_TWO_FAC_INFO":
      return action.payload;
    case "SET_USER_COIN_BALANCE_INFO":
      return action.payload;
    //For LogOut --->
    case "CLEAR_USER_FULL_INFO":
      return action.payload;
    default:
      return state;
  }
};
