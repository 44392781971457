import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { AppSocketInfoAction } from "../../actions/AppSocketInfoActions";
import configService from "../../services/config.json";
//# Socket URL
//# Decalare timer for setTimeout
let TIMER_WS_RECONNECT = null;
function WebSocketConnection() {
  const dispatch = useDispatch();
  const [reconnectWS, setReconnectWS] = useState(0);
  const AppSocketInfo = useSelector((state) => state.appSocketInfo);

  useEffect(() => {
    const client = new W3CWebSocket(configService.socket_url);
    client.onopen = () => {
      clearTimeout(TIMER_WS_RECONNECT);
      console.log(
        "%c Client opened at dir: WebSocketConnection",
        "background: #000; color: #03fc13"
      );
      const SocketSuccessOpen = {
        OPEN: true,
        CLOSED: false,
        CONNECTION: client,
        RECONNECT: reconnectWS,
      };
      dispatch(AppSocketInfoAction(SocketSuccessOpen));
    };
    client.onclose = () => {
      console.warn("Client closed at dir: Components/WebSocketConnection");
      TIMER_WS_RECONNECT = setTimeout(() => {
        setReconnectWS((prev) => prev + 1);
      }, 3000);
      dispatch(
        AppSocketInfoAction({
          ...AppSocketInfo,
          CLOSED: true,
          open: false,
          CONNECTION: null,
          RECONNECT: reconnectWS + 1,
        })
      );
    };
    return () => {
      client.close();
    };
  }, [dispatch, reconnectWS]);

  return null;
}
export default memo(WebSocketConnection);
