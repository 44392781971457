const changeThemeChoice = (str) => {
  localStorage.setItem("application_price", JSON.stringify(str));
};

export const choiceRialPrice = () => {
  return (dispatch) => {
    changeThemeChoice("IRT");
    dispatch({ type: "CHOICE_USDT_PRICE", payload: "IRT" });
  };
};
export const choiceUSDTPrice = () => {
  return (dispatch) => {
    changeThemeChoice("USDT");
    dispatch({ type: "CHOICE_IRT_PRICE", payload: "USDT" });
  };
};
