import styles from "./FooterT.module.scss";
//* --> Icons
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
//* --> Components
import { DangerMuiBtn, SuccessMuiBtn } from "../../MuiButtons";
import { Link } from "react-router-dom";
import { tradesCTX } from "../../../context/tradesCTX";
import { useContext } from "react";

export default function FooterT() {
  const MarketContext = useContext(tradesCTX);
  const { currentTrade } = MarketContext;
  return (
    <div className={`changeBackgroundColorToHardBlack  ${styles.container}`}>
      <div className={styles.alert}>
        <Link to="/">
          <HomeOutlinedIcon
            fontSize="inherit"
            className="change_color_to_E1E2E4"
          />
        </Link>
        <span className={styles.setting}>
          <Link to="/setting">
            <SettingsOutlinedIcon
              fontSize="inherit"
              className="change_color_to_E1E2E4"
            />
          </Link>
        </span>
      </div>
      <div className={styles.buttons}>
        <div>
          <Link to={`/market/trade/${currentTrade.market}?type=buy`}>
            <SuccessMuiBtn text="خرید" />
          </Link>
        </div>
        <div>
          <Link to={`/market/trade/${currentTrade.market}?type=sell`}>
            <DangerMuiBtn text="فروش" />
          </Link>
        </div>
      </div>
    </div>
  );
}
