import { useState, useContext } from "react";
import { withRouter } from "react-router";
import { context } from "../../../../context/context";
//Components
import TextField from "@mui/material/TextField";
import RTL from "../../../../helpers/direction/RTL";
//Styles and Icons
import styles from "./NewPassword.module.scss";
import LockIcon from "../../../../styles/icon/lock.svg";
function NewPassWord() {
  const changePassCTX = useContext(context);
  const {
    changePassword,
    setChangePassword,
    repeatChangePassword,
    setRepeatChangePassword,
    recoveryAccSteps,
    setRecoveryAccSteps,
    handleChangeUserPassword,
    validatorRecovery,
    setValidatorRecovery,
  } = changePassCTX;
  const [checkFocuse, setCheckFocuse] = useState("");
  return (
    <div className={styles.container}>
      <div className={`font_color_change_to_green ${styles.register_title}`}>
        تغییر رمز عبور{" "}
      </div>
      <form onSubmit={(e) => handleChangeUserPassword(e)}>
        <section className={styles.inputs_place}>
          <div
            className={`changeTextFieldBackgroundToWBlack ${styles.mobile_place}`}
          >
            <RTL>
              <TextField
                name="addNewPassword"
                label="رمز عبور جدید"
                color="success"
                onFocus={() => setCheckFocuse("mobile")}
                onBlur={() => setCheckFocuse("")}
                value={changePassword}
                onChange={(e) => {
                  setChangePassword(e.target.value);
                  setValidatorRecovery({ ...validatorRecovery, password: "" });
                }}
              />

              <div
                className={`${styles.icon_place}  ${
                  checkFocuse === "mobile" ? styles.icon_place_active : ""
                }`}
              >
                <img src={LockIcon} alt="" className="convertPicToGreen" />
              </div>
              <div className={styles.errorMessage}>
                {validatorRecovery?.password}
              </div>
            </RTL>
          </div>
          <div
            className={`changeTextFieldBackgroundToWBlack ${styles.pass_place}`}
          >
            <RTL>
              <TextField
                label="تکرار رمز عبور جدید"
                color="success"
                onFocus={() => setCheckFocuse("referral")}
                onBlur={() => setCheckFocuse("")}
                value={repeatChangePassword}
                onChange={(e) => {
                  setRepeatChangePassword(e.target.value);
                  setValidatorRecovery({
                    ...validatorRecovery,
                    repeatPassword: "",
                  });
                }}
              />

              <div
                className={`${styles.icon_place} ${
                  checkFocuse === "referral" ? styles.icon_place_active : ""
                }`}
              >
                <img src={LockIcon} alt="" className="convertPicToGreen" />
              </div>
              <div className={styles.errorMessage}>
                {validatorRecovery?.repeatPassword}
              </div>
            </RTL>
          </div>
        </section>

        <div className={styles.button_container}>
          <button className={styles.continue_button} type="submit">
            تغییر رمز عبور
          </button>
        </div>
      </form>
      <div className={styles.button_container}>
        <button
          className={styles.back_button}
          onClick={() =>
            setRecoveryAccSteps({
              ...recoveryAccSteps,
              showVerifyCode: true,
              showNewPassword: false,
            })
          }
        >
          بازگشت
        </button>
      </div>
    </div>
  );
}
export default withRouter(NewPassWord);
