import styles from "./AuthGuid.module.scss";
import ArrowBackIcon from "../../../../../styles/icon/arrow_back_fill.svg";
import { useHistory } from "react-router";

export default function AuthGuid() {
  const { goBack } = useHistory();

  return (
    <div className={styles.container}>
      <header>
        <div className={styles.back_btn}>
          <img
            src={ArrowBackIcon}
            alt="BACK"
            width={13}
            height={13}
            onClick={() => goBack()}
          />
        </div>
        <div className={styles.title_of_page}>راهنمای احراز هویت</div>
      </header>
      <div className={styles.text_of_guid}>
        پس از کلیک بر روی قسمت احراز هویت ابتدا اطلاعات فردی خود را تکمیل و ثبت
        نمایید. پس از تکمیل اطلاعات فردی قسمت های مورد نظر از جمله: شماره موبایل
        و ایمیل خود از طریق دریافت کد اعتبار سنجی فعال کنید.
      </div>
      <div className={styles.text_of_guid}>
        بعد از آن در قسمت احراز هویت کارت ملی خود را آپلود کنید تا تایید شود.
        بعد از آن در مرحله بعد تصویری از خود به همراه نوشته ی زیر و همراه با اصل
        کارت ملی یا اصل شناسنامه یا اصل پاسپورت با حداقل اعتبار یک ساله همراه با
        چهره خود به صورت واضح و خوانا گرفته و ارسال میکنید.
      </div>
      <div className={styles.text_of_guid}>
        متن: برای ارزفی اینجانب قوانین سایت ارزفی را مطالعه و قبول می نمایم
        <br />
        <br />
        نمونه عکس :
      </div>
      <div className={styles.guid_pic_container}>
        <img src="/images/auth_guid.jpg" alt="GUID" width="100%" />
      </div>
    </div>
  );
}
