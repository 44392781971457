import { FormControl, MenuItem, Select } from "@mui/material";
import JSS from "../../helpers/direction/RTL";
export default function MuiSelect(props) {
  return (
    <div className="select-market">
      <JSS>
        <FormControl fullWidth>
          <Select
            id="demo-simple-select"
            disabled={props.disabled}
            value={props.value}
            sx={props.sx}
            className="changeBackgroundColorToHardBlack change_color_to_E1E2E4"
            onChange={(e) => props.onChange(e.target.value)}
            MenuProps={{
              className: "select-market-input-props"
            }}
          >
            {props.lists &&
              props.lists.map((list, index) => (
                <MenuItem value={list.value} key={index}>
                  {list.title}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </JSS>
    </div>
  );
}
