import { Container } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import PreviousPage from "../components/PreviousPage";
import styles from "../styles/pageStyles/MarketLists.module.scss";
import MuiTextField from "../components/MuiTextField";
//# Page Components
import MarketCoins from "../pageComponents/MarketLists/MarketCoins";
import FavoriteCoins from "../pageComponents/MarketLists/FavoriteCoins";
import OrderCoins from "../pageComponents/MarketLists/OrderCoins";
import { useMemo } from "react";
import { useSelector } from "react-redux";
//# Icons
import IranIcon from "../styles/icon/iran.png";
import TetherIcon from "../styles/icon/tether.png";
import { useDispatch } from "react-redux";
import { AddSocketPrice } from "../actions/appSocketPriceAction";
import { changeMarketRoute } from "../actions/RouteStateActions";
//# Page Components
const Buttons = [
  { name: "fav", title: "مورد علاقه", sub: null },
  { name: "markets", title: "بازار ها" },
  { name: "order", title: "سفارش سریع", sub: null },
];
const SubButton = {
  markets: [
    { title: "تومان", value: "IRT", icon: IranIcon },
    { title: "تتر", value: "USDT", icon: TetherIcon },
  ],
};

export default function MarketLists() {
  const dispatch = useDispatch();
  const socketReducer = useSelector((state) => state.appSocket);
  const AppSocketInfo = useSelector((state) => state.appSocketInfo);
  const CashRoute = useSelector((state) => state.cashRoutes.marketPage);
  const [subSelectedList, setSubSelectedList] = useState({
    markets: "IRT",
  });
  const [search, setSearch] = useState("");

  useEffect(() => {
    let prevState = null;
    if (AppSocketInfo.CONNECTION) {
      AppSocketInfo.CONNECTION.send(
        JSON.stringify({
          id: 3,
          method: "state.subscribe",
          params: [],
        })
      );
      AppSocketInfo.CONNECTION.onmessage = (message) => {
        const parseDeals = JSON.parse(message.data);
        if (parseDeals.method === "state.update") {
          if (!prevState) {
            if (parseDeals.params[0]) {
              prevState = parseDeals.params[0];
              dispatch(AddSocketPrice(prevState));
            }
          } else {
            for (const key in parseDeals.params[0]) {
              const element = parseDeals.params[0][key];
              prevState[key] = element;
            }
            dispatch(AddSocketPrice(prevState));
          }
        }
      };
    }
    return () => {
      prevState = null;
      if (AppSocketInfo.CONNECTION) {
        AppSocketInfo.CONNECTION.send(
          JSON.stringify({
            id: 3,
            method: "state.unsubscribe",
            params: [],
          })
        );
      }
    };
  }, [dispatch, AppSocketInfo]);
  const Childs = useMemo(() => {
    return {
      markets: (
        <MarketCoins
          showBy={subSelectedList.markets}
          search={search}
          marketWSInfo={socketReducer}
        />
      ),
      fav: <FavoriteCoins search={search} marketWSInfo={socketReducer} />,
      order: <OrderCoins search={search} marketWSInfo={socketReducer} />,
    };
  }, [subSelectedList, search, socketReducer]);

  const handleChangeSelectedList = (e) => {
    return dispatch(changeMarketRoute(e.target.name));
  };
  const handleChangesubSelected = (e) => {
    setSubSelectedList((prev) => ({ ...prev, [CashRoute]: e.target.name }));
  };
  return (
    <Container disableGutters>
      <Stack>
        <PreviousPage />
      </Stack>
      <Stack className={styles.buttons}>
        {Buttons.map((ctx, idx) => {
          return (
            <button
              key={idx}
              name={ctx.name}
              data-active={CashRoute === ctx.name}
              data-index={idx}
              onClick={handleChangeSelectedList}
            >
              {ctx.title}
            </button>
          );
        })}
      </Stack>
      {SubButton[CashRoute] && (
        <Stack className={styles.sub_button}>
          {SubButton[CashRoute].map((ctx, idx) => {
            return (
              <button
                key={idx}
                name={ctx.value}
                data-active={subSelectedList[CashRoute] === ctx.value}
                onClick={handleChangesubSelected}
              >
                {ctx.title}
                {ctx.icon && (
                  <span className={styles.icon}>
                    <img
                      src={ctx.icon}
                      alt={ctx.value}
                      width={20}
                      height={20}
                      name={ctx.value}
                    />
                  </span>
                )}
              </button>
            );
          })}
        </Stack>
      )}
      <Stack className={styles.search}>
        <MuiTextField
          placeholder="جست و جو..."
          size="small"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
      </Stack>
      <Stack className={styles.main} data-children={!!SubButton[CashRoute]}>
        {Childs[CashRoute]}
      </Stack>
    </Container>
  );
}
