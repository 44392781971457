export const hiddenAppMenu = () => {
  return (dispatch) => {
    dispatch({ type: "HIDDEN_BOTTOM_APP_MENU", payload: true });
  };
};
export const showAppMenu = () => {
  return (dispatch) => {
    dispatch({ type: "SHOW_BOTTOM_APP_MENU", payload: false });
  };
};
