/*
 * @Name: Detect Iranian Banks
 * @Author: Max Base
 * @Date: 2022-02-25
 * @Language: Javascript
 * @Repository: https://github.com/BaseMax/DetectIranianBankJS
 */

export const detectBanks = [
  {
    card_no: 627381,
    bank_name: "ansar",
    bank_title: "بانک انصار",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/ansar-05.svg",
    color: "#26eeb900",
  },
  {
    card_no: 636214,
    bank_name: "ayandeh",
    bank_title: "بانک آینده",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/ayande-03.svg",
    color: "#3388391d",
  },
  {
    card_no: 502938,
    bank_name: "dey",
    bank_title: "بانک دی",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/day-12.svg",
    color: "#0dffffff",
  },
  {
    card_no: 627412,
    bank_name: "eghtesad_novin",
    bank_title: "بانک اقتصادنوین",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/eghtesad-04.svg",
    color: "#1a662891",
  },
  {
    card_no: 628157,
    bank_name: "etebari_tosee",
    bank_title: "موسسه اعتباری توسعه",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/tose-11.svg",
    color: "#33000000",
  },
  {
    card_no: 505416,
    bank_name: "gardeshgari",
    bank_title: "بانک گردشگری",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/gardeshgari-20.svg",
    color: "#29344143",
  },
  {
    card_no: 639599,
    bank_name: "ghavvamin",
    bank_title: "بانک قوامین",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/ghavvamin-31.svg",
    color: "#1afff400",
  },
  {
    card_no: 627488,
    bank_name: "kar_afarin",
    bank_title: "بانک کارآفرین",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/karafarin-06.svg",
    color: "#333a881d",
  },
  {
    card_no: 502910,
    bank_name: "kar_afarin",
    bank_title: "بانک کارآفرین",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/karafarin-06.svg",
    color: "#333a881d",
  },
  {
    card_no: 603770,
    bank_name: "keshavarzi",
    bank_title: "بانک کشاورزی",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/keshavarzi-07.svg",
    color: "#29aa9a22",
  },
  {
    card_no: 639217,
    bank_name: "keshavarzi",
    bank_title: "بانک کشاورزی",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/keshavarzi-07.svg",
    color: "#29aa9a22",
  },
  {
    card_no: 628023,
    bank_name: "maskan",
    bank_title: "بانک مسکن",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/maskan-26.svg",
    color: "#1a000000",
  },
  {
    card_no: 639370,
    bank_name: "mehr_e_eghtesad",
    bank_title: "بانک مهر اقتصاد",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/mehreghtesad-14.svg",
    color: "#1a656565",
  },
  {
    card_no: 606373,
    bank_name: "mehr_e_iranian",
    bank_title: "بانک قرض الحسنه مهر ایرانیان",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/mehriran-15.svg",
    color: "#1a209b1c",
  },
  {
    card_no: 603799,
    bank_name: "meli",
    bank_title: "بانک ملی ایران",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/melli-22.svg",
    color: "#1a00e0d7",
  },
  {
    card_no: 610433,
    bank_name: "mellat",
    bank_title: "بانک ملت",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/mellat-02.svg",
    color: "#1aba0b22",
  },
  {
    card_no: 991975,
    bank_name: "mellat",
    bank_title: "بانک ملت",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/mellat-02.svg",
    color: "#1aba0b22",
  },
  {
    card_no: 111111,
    bank_name: "ok",
    bank_title: "همه کارتخوان‌ها",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/shetab.svg",
    color: "#1a00e0d7",
  },
  {
    card_no: 622106,
    bank_name: "parsian",
    bank_title: "بانک پارسیان",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/pasargad-21.svg",
    color: "#29980000",
  },
  {
    card_no: 502229,
    bank_name: "pasargad",
    bank_title: "بانک پاسارگاد",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/pasargad-10.svg",
    color: "#4d000000",
  },
  {
    card_no: 639347,
    bank_name: "pasargad",
    bank_title: "بانک پاسارگاد",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/pasargad-10.svg",
    color: "#4d000000",
  },
  {
    card_no: 627760,
    bank_name: "post_bank",
    bank_title: "پست بانک ایران",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/post-09.svg",
    color: "#1a14763e",
  },
  {
    card_no: 589463,
    bank_name: "refah",
    bank_title: "بانک رفاه",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/refahkargaran-28.svg",
    color: "#0d000000",
  },
  {
    card_no: 627961,
    bank_name: "saanat_va_maadan",
    bank_title: "بانک صنعت و معدن",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/sanatmadan-16.svg",
    color: "#339b814c",
  },
  {
    card_no: 603769,
    bank_name: "saderat",
    bank_title: "بانک صادرات",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/saderat-29.svg",
    color: "#1a2d2a68",
  },
  {
    card_no: 621986,
    bank_name: "saman",
    bank_title: "بانک سامان",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/saman-01.svg",
    color: "#1a7dcdf1",
  },
  {
    card_no: 639607,
    bank_name: "sarmayeh",
    bank_title: "بانک سرمایه",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/sarmaye-17.svg",
    color: "#29bd9ff5",
  },
  {
    card_no: 589210,
    bank_name: "sepah",
    bank_title: "بانک سپه",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/sepah-24.svg",
    color: "#33ffc400",
  },
  {
    card_no: 504706,
    bank_name: "shahr",
    bank_title: "بانک شهر",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/shahr-25.svg",
    color: "#29ffafb2",
  },
  {
    card_no: 502806,
    bank_name: "shahr",
    bank_title: "بانک شهر",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/shahr-25.svg",
    color: "#29ffafb2",
  },
  {
    card_no: 639346,
    bank_name: "sina",
    bank_title: "بانک سینا",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/sina-23.svg",
    color: "#1a277eb5",
  },
  {
    card_no: 627353,
    bank_name: "tejarat",
    bank_title: "بانک تجارت",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/tejarat-13.svg",
    color: "#290fadc7",
  },
  {
    card_no: 585983,
    bank_name: "tejarat",
    bank_title: "بانک تجارت",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/tejarat-13.svg",
    color: "#290fadc7",
  },
  {
    card_no: 636949,
    bank_name: "tejarat",
    bank_title: "بانک حکمت",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/hekmat-30.svg",
    color: "#29563288",
  },
  {
    card_no: 627648,
    bank_name: "tosee_saderat",
    bank_title: "بانک توسعه صادرات",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/tosesaderat-18.svg",
    color: "#1f6d6900",
  },
  {
    card_no: 502908,
    bank_name: "tosee_taavon",
    bank_title: "بانک توسعه تعاون",
    bank_logo:
      "https://basemax.github.io/DetectIranianBankWeb/images/tosetaavon-19.svg",
    color: "#290076ff",
  },
];
