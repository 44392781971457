export const ERROR_CODE1 = "شماره وارد شده صحیح نمی باشد";
export const ERROR_CODE2 = "ایمیل وارد شده صحیح نمی باشد";
export const ERROR_CODE3 = "حداقل ۶ کاراکتر باید وارد شود";
export const ERROR_CODE4 = "تکرار رمز عبور یکسان نمی باشد";
export const ERROR_CODE5 = "کد اعتبار سنجی صحیح نمی باشد";
export const ERROR_CODE6 = "موضوع تیکت را انتخاب کنید";
export const ERROR_CODE7 =
  "عنوان تیکت نباید کمتر از ۳ و بیشتر از ۲۰ کاراکتر باشد";
export const ERROR_CODE8 = "متن تیکت نباید کمتر از ۱۰ کاراکتر باشد";
export const ERROR_CODE9 =
  "فایل ها باید یا پسوند JPG,JPEG و یا PNG داشته باشند";
