import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { clearUserFullInfo, clearUserHashCode } from "../actions/user";
import { useHistory } from "react-router";
import axios from "axios";

export default function Logout() {
  const deleteAxiosToken = () => {
    axios.defaults.headers.common["x-access-token"] = "";
  };
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(clearUserHashCode());
    dispatch(clearUserFullInfo());
    deleteAxiosToken();
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    history.replace("/");
  }, []);
  return null;
}
