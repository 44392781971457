import { getCoinList, getMarketData } from "../services/marketServices";

export const marketData = () => {
  return async (dispatch) => {
    const { data } = await getMarketData();
    await dispatch({ type: "INIT", payload: data });
  };
};

export const coinList = () => {
  return async (dispatch) => {
    const { data } = await getCoinList();
    await dispatch({ type: "GET_COINS_LIST", payload: data });
  };
};
